import React from 'react'
import Analytics from "../../controller/Analytics";
import ds from "../../controller/DataService";
import {getMobileOperatingSystem} from "../../controller/Exports";

export default async function Get(props) {

	Analytics.logEvent("open_get_link", {
		screen: 'web',
		error: undefined,
		from: props.from,
		referrer: document.referrer,
		url_param: new URLSearchParams(window.location.search).get('id'),
		date: new Date().toLocaleDateString(),
		time: new Date().getTime(),
		os: getMobileOperatingSystem()
	});

	let otherURL = 'https://myrealfood.app/';
	let iOSURL = 'https://apps.apple.com/es/app/myrealfood/id1458031749';
	let androidURL = 'https://play.google.com/store/apps/details?id=es.myrealfood.myrealfood';
	let huaweiURL = 'https://appgallery.cloud.huawei.com/ag/n/app/C103495711';
	switch (props.from) {
		case '/get':
			androidURL = 'https://play.google.com/store/apps/details?id=es.myrealfood.myrealfood&referrer=utm_source%3Dmyrealfood.app%26utm_term%3Dget%26utm_campaign%3DGET';
			iOSURL = 'https://apps.apple.com/app/apple-store/id1458031749?pt=120012356&ct=get&mt=8';
			huaweiURL = 'https://appgallery.cloud.huawei.com/ag/n/app/C103495711?locale=es_ES&source=myrealfood.app&subsource=GET&shareTo=world&shareFrom=myrealfood.app';
			break;
		case '/valorar':
			androidURL = 'https://play.google.com/store/apps/details?id=es.myrealfood.myrealfood&referrer=utm_source%3Dmyrealfood.app%26utm_term%3Dvalorar%26utm_campaign%3DVALORAR';
			iOSURL = 'https://apps.apple.com/app/apple-store/id1458031749?pt=120012356&ct=valorar&mt=8';
			huaweiURL = 'https://appgallery.cloud.huawei.com/ag/n/app/C103495711?locale=es_ES&source=myrealfood.app&subsource=VALORAR&shareTo=world&shareFrom=myrealfood.app';
			break;
		case '/rate':
			androidURL = 'https://play.google.com/store/apps/details?id=es.myrealfood.myrealfood&referrer=utm_source%3Dmyrealfood.app%26utm_term%3Drate%26utm_campaign%3DRATE';
			iOSURL = 'https://apps.apple.com/app/apple-store/id1458031749?pt=120012356&ct=rate&mt=8';
			huaweiURL = 'https://appgallery.cloud.huawei.com/ag/n/app/C103495711?locale=es_ES&source=myrealfood.app&subsource=RATE&shareTo=world&shareFrom=myrealfood.app';
			break;
		case '/campaign':
			androidURL = `https://play.google.com/store/apps/details?id=es.myrealfood.myrealfood&referrer=utm_source%3Dinstagram%26utm_campaign%3D${new URLSearchParams(window.location.search).get('id')}`;
			iOSURL = `https://apps.apple.com/app/apple-store/id1458031749?pt=120012356&ct=${new URLSearchParams(window.location.search).get('id')}&mt=8`;
			huaweiURL = `https://appgallery.cloud.huawei.com/ag/n/app/C103495711?locale=es_ES&source=myrealfood.app&subsource=${new URLSearchParams(window.location.search).get('id')}&shareTo=world&shareFrom=myrealfood.app`;
			break;
		default:
			break;
	}

	await ds.storeUserCampaign({
		path: '/get',
		from: props.from ?? '',
		referrer: document.referrer ?? '',
		url_param: new URLSearchParams(window.location.search).get('id') ?? '',
		os: getMobileOperatingSystem() ?? '',
		otherURL: otherURL ?? '',
		iOSURL: iOSURL ?? '',
		androidURL: androidURL ?? '',
		huaweiURL: huaweiURL ?? '',
		search: window.location.search ?? '',
		unique: new URLSearchParams(window.location.search).get('id') ?? '',
		uref: new URLSearchParams(window.location.search).get('uref') ?? ''
	});

	if(new URLSearchParams(window.location.search).get('id') === 'YOUTUBE') {
		window.location.assign('https://campaigns.myrealfood.app/descargar/yt/1')
	} else {
		return (
			<div style={{marginTop: '1em'}}>
				{getMobileOperatingSystem() === 'Android' && window.location.assign(androidURL)}
				{getMobileOperatingSystem() === 'iOS' && window.location.assign(iOSURL)}
				{getMobileOperatingSystem() === 'Huawei' && window.location.assign(huaweiURL)}
				{getMobileOperatingSystem() === 'unknown' && window.location.assign(otherURL)}
			</div>
		)
	}
}
