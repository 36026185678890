import React, { useEffect, useState } from "react";

import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";

import style from "./style";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Footer from "../../common/Footer";
import { useHistory } from "react-router";
import ds from "../../controller/DataService";
import Locale from "../../controller/locale";
import Typography from "@material-ui/core/Typography";
import {
  __DEFAULT_PROMOCODE__,
  __DEV__,
  DEFAULT_PREMIUM_BASIC_OPTIONS
} from "../../controller/Exports";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Link from "@material-ui/core/Link";
import PlanBox from "./PlanBox";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField/TextField";

import {
  CardElement,
  PaymentRequestButtonElement,
  useElements,
  useStripe
} from "@stripe/react-stripe-js";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import BasicHeader from "../../common/Header/BasicHeader";
import ReactPixel from "react-facebook-pixel";

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#000",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#828282"
      }
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a"
    }
  }
};

const USER_COUNTRY_STRIPE_MAP = {
  ES: "ES",
  GB: "GB",
  UK: "GB"
};

const USER_COUNTRY_CURRENCY_STRIPE_MAP = {
  ES: "eur",
  GB: "gbp",
  UK: "gbp"
};

export default function Subscribe() {
  const classes = style();
  const history = useHistory();

  const stripe = useStripe();
  const [paymentConfig, setPaymentConfig] = useState(null);
  const [paymentRequest, setPaymentRequest] = useState(null);

  const elements = useElements();

  const [auth, setAuth] = useState(undefined);

  const [platform, setPlatform] = useState("");
  const [plan, setPlan] = useState(12);

  const [current, setCurrent] = useState(null);
  const [premiumBasicOptions, setPremiumBasicOptions] = useState(null);

  const [showModal, setShowModal] = useState(false);

  const [cardName, setCardName] = useState("");
  const [cardNameError, setCardNameError] = useState(false);

  const [cardAddress, setCardAddress] = useState("");
  const [cardAddressError, setCardAddressError] = useState(false);

  const [cardPostalCode, setCardPostalCode] = useState("");
  const [cardPostalCodeError, setCardPostalCodeError] = useState(false);

  const [enabledCountries, setEnabledCountries] = useState();
  const [userCountry, setUserCountry] = useState(null);

  const [cardCountry, setCardCountry] = useState("España");
  const [cardCountryError, setCardCountryError] = useState(false);

  const [cardNumber, setCardNumber] = useState("");
  const [cardNumberError, setCardNumberError] = useState(false);

  const [cardCVV, setCardCVV] = useState("");
  const [cardCVVError, setCardCVVError] = useState(false);

  const [cardExpirationDate, setCardExpirationDate] = useState("");
  const [cardExpirationDateError, setCardExpirationDateError] = useState(false);

  const [promo, setPromo] = useState(ds.retrunAvailablePromocode(__DEFAULT_PROMOCODE__[0]));
  const [promoError, setPromoError] = useState(false);

  const [discount, setDiscount] = useState(undefined);
  const [discountApplied, setDiscountApplied] = useState(undefined);

  const [requesting, setRequesting] = useState(false);
  const [requestingError, setRequestingError] = useState(false);

  const [requestingWalletPay, setRequestingWalletPay] = useState(false);
  const [requestingWalletPayError, setRequestingWalletPayError] = useState(false);
  const [price, setPrice] = useState(0);
  useEffect(() => {
    if (ds.retrieveAuthData()) {
      retrieveConfig();
    }
  }, [discount]);

  const retrieveConfig = async () => {
    const country = await ds.retrieveUserCountry();
    const userCountry =
      !!discount && !!discount.metadata && !!discount.metadata.country
        ? discount.metadata.country
        : "ES";
    setUserCountry(userCountry);
    ds.retrievePremiumBasicOptions(userCountry)
      .then((options) => {
        setPremiumBasicOptions(options);
        setPrice(Object.values(options).filter((option) => option.period === 1)[0].price * 12);
        setCurrent(Object.values(options).filter((option) => option.period === plan)[0]);
      })
      .catch((error) => {
        const options = DEFAULT_PREMIUM_BASIC_OPTIONS(userCountry);
        setPremiumBasicOptions(options);
        setCurrent(Object.values(options).filter((option) => option.period === plan)[0]);
        __DEV__ &&
          console.log(
            `[retrievePremiumBasicOptions] Error retrieving premium basic options: ${error}`
          );
      });
  };

  useEffect(() => {
    if (!ds.retrieveAuthData()) {
      let uri = `/subscribe/${encodeURIComponent(window.location.search)}`;
      let paramMrt = new URLSearchParams(window.location.search).get('mrt');
      history.push(`/login/${paramMrt ? `?mrt=${paramMrt}&` : `?`}redirect=${uri}`)
    } else {
      if (ds.isPlusUser()) {
        if (!ds.hasCompletedPlusOnboarding()) {
          history.push("/onboarding");
        } else {
          history.push("/plus/menu");
        }
      } else {
        if (
          !(
            !!ds.retrieveUserData() &&
            !!ds.retrieveUserData()["public"] &&
            !!ds.retrieveUserData()["public"]["nickname"]
          )
        ) {
          let uri = `/subscribe/${encodeURIComponent(window.location.search)}`;
          history.push(`/register/complete/?redirect=${uri}`);
        } else {
          ds.subscribeToAuthChanges("Subscribe", (user) => {
            setAuth(user);
            if (ds.isPlusUser()) {
              window.scrollTo({ top: 0, behavior: "smooth" });
              setTimeout(() => {
                if (!ds.hasCompletedPlusOnboarding()) {
                  history.push("/onboarding/?payment=succeed");
                } else {
                  history.push("/plus/menu");
                }
              }, 250);
            }
          });

          let paramPlan = new URLSearchParams(window.location.search).get("plan");
          if (!!paramPlan && !isNaN(paramPlan) && !!premiumBasicOptions) {
            setPlan(Number(paramPlan));
            if (Number(paramPlan) === 12 || Number(paramPlan) === 6 || Number(paramPlan) === 1) {
              setCurrent(
                Object.values(premiumBasicOptions).filter(
                  (option) => option.period === Number(paramPlan)
                )[0]
              );
            }
          }

          let paramPlatform = new URLSearchParams(window.location.search).get("platform");
          if (!!paramPlatform && !!paramPlatform.length) {
            setPlatform(paramPlatform);
          }

          let paramPromo =
            new URLSearchParams(window.location.search).get("promo") ||
            ds.retrunAvailablePromocode(__DEFAULT_PROMOCODE__[0]);
          if (!!paramPromo && !!paramPromo.length) {
            setPromo(paramPromo);
          }
          ds.retrievePromoCode(
            paramPromo,
            false,
            (data, applied) => {
              if (!!applied || data.id === "NONE") {
                setPromo(data.id);
                setDiscount(data);
                setDiscountApplied(data.id !== "NONE" ? true : undefined);
              } else {
                setDiscount(data);
                setDiscountApplied(undefined);
              }
            },
            (error, data) => {
              setDiscount(data);
            }
          );

          ds.retrieveEnabledCountries()
            .then((countries) => {
              setEnabledCountries(countries.map((c) => c.name));
              // Check user country in enabledCountries
              const userEnabledCountry = countries.filter((c) => c.code === userCountry);
              if (userEnabledCountry.length === 1) {
                setCardCountry(userEnabledCountry[0].name);
              }
            })
            .catch((error) => {
              console.log(`Error retrieving enabled countries`, error);
            });

          if (!!current) {
            window.ttq.track("InitiateCheckout", {
              content_id: plan,
              freeTrial: freeTrial,
              content_type: "SUBSCRIPTION",
              content_name: current.period,
              quantity: 1,
              price: freeTrial ? "0" : Number(Number(current.price).toFixed(2)),
              value: freeTrial ? "0" : Number(Number(current.price).toFixed(2)),
              currency: current.currency
            });
          }

          return () => {
            ds.unsubscribeFromAuthChanges("Subscribe");
          };
        }
      }
    }
    if (!!premiumBasicOptions) {
      setCurrent(Object.values(premiumBasicOptions).filter((option) => option.period === plan)[0]);
    }
  }, [plan, auth]);

  useEffect(() => {
    const selectedCurrent = current;
    const selectedDiscount = discount;
    if (stripe && selectedCurrent && selectedDiscount) {
      configurePaymentRequest(stripe, selectedCurrent, selectedDiscount);
    }
  }, [stripe, current, discount]);

  const getPriceId = () => {
    if (!current || !discount) {
      return null;
    }
    let priceId;
    let country = userCountry;
    const period = current.period;
    const priceIdToPeriod = discount["priceIdToPeriod"];
    const priceIdToPeriodLocales = discount["priceIdToPeriod_locales"];
    if (!!priceIdToPeriodLocales) {
      if (!!priceIdToPeriodLocales[country]) {
        priceId = priceIdToPeriodLocales[country][period];
      } else {
        priceId = priceIdToPeriodLocales["_"][period];
      }
    } else {
      priceId = priceIdToPeriod[period];
    }
    console.log(
      `[getPriceId] Returning priceID ${priceId} for period ${period} and country ${country}`
    );
    return priceId;
  };

  const getCoupon = () => {
    if (!current || !discount) {
      return null;
    }
    let coupon;
    let country = userCountry;
    const period = current.period;
    const couponToPeriod = discount["couponIdToPeriod"];
    const couponToPeriodLocales = discount["couponIdToPeriod_locales"];
    if (!!couponToPeriodLocales) {
      if (!!couponToPeriodLocales[country]) {
        coupon = couponToPeriodLocales[country][period];
      } else {
        coupon = couponToPeriodLocales["_"][period];
      }
    } else {
      coupon = couponToPeriod[period];
    }
    console.log(
      `[getCoupon] Returning coupon ${coupon} for period ${period} and country ${country}`
    );
    return coupon;
  };

  const getCountryCode = () => {
    return userCountry;
  };

  const configurePaymentRequest = (stripe, selectedCurrent, selectedDiscount) => {
    const pricePaidDiscount =
      !!selectedDiscount &&
      !!selectedDiscount.discountToPeriod &&
      !!selectedCurrent.period &&
      !!selectedDiscount.discountToPeriod[selectedCurrent.period]
        ? selectedDiscount.discountToPeriod[selectedCurrent.period]
        : 1;
    const selectedCurrentPrice = Math.trunc(selectedCurrent.price * 100) / 100;
    const selectedCurrentPriceWithDiscount = Number.parseInt(
      Number(selectedCurrentPrice * pricePaidDiscount) * 100
    );
    const paymentRequestCountry = USER_COUNTRY_STRIPE_MAP[userCountry];
    const paymentRequestCurrency = USER_COUNTRY_CURRENCY_STRIPE_MAP[userCountry];
    const paymentRequest = stripe.paymentRequest({
      country: paymentRequestCountry || "ES",
      currency: paymentRequestCurrency || "eur",
      total: {
        label: `${
          selectedCurrent.period === 1
            ? Locale.getString("subscribe_payment_request_monthly")
            : selectedCurrent.period === 6
            ? Locale.getString("subscribe_payment_request_biannual")
            : Locale.getString("subscribe_payment_request_yearly")
        }`,
        amount: freeTrial ? 0 : selectedCurrentPriceWithDiscount
      },
      requestPayerName: true,
      requestPayerEmail: true
    });

    // Check the availability of the Payment Request API.
    paymentRequest.canMakePayment().then((result) => {
      if (result) {
        const config = {
          paymentRequest: paymentRequest,
          current: current,
          price: freeTrial ? "0" : selectedCurrentPriceWithDiscount
        };
        setPaymentConfig(config);
      }
    });

    paymentRequest.on("paymentmethod", async (ev) => {
      if (!requestingWalletPay) {
        setRequestingWalletPay(true);
        setRequestingWalletPayError(undefined);

        const paymentMethodId = ev.paymentMethod.id;
        const payerName = ev.payerName;
        const payerEmail = ev.payerEmail;
        const walletName = ev.walletName;

        ds.retrieveAuthToken(
          async (token) => {
            try {
              const result = await createSubscription({
                token,
                paymentMethodId,
                priceId: getPriceId(), //discount['priceIdToPeriod'][current.period],
                coupon: getCoupon(), // discount['couponIdToPeriod'][current.period],
                period: current.period,
                code: discount.id,
                cardName: payerName,
                cardAddress: payerEmail,
                cardPostalCode: walletName,
                cardCountry: walletName,
                method: walletName,
                countryCode: getCountryCode(),
                currency: current.currency,
                trialDays:
                  !!current &&
                  !!current.period &&
                  !!discount &&
                  !!discount.freeTrialToPeriod &&
                  !!discount.freeTrialToPeriod[current.period]
                    ? discount.trialDays
                    : undefined
              });
              ev.complete("success");
            } catch (error) {
              ev.complete("fail");
              !!error &&
                !!error.error &&
                !!error.error.message &&
                setRequestingWalletPayError(error.error.message);
              !!error && Object.keys(error).length && setRequestingWalletPay(false);
              ds.log(
                {},
                {
                  id: "subscribe_handle_submit_pay_1",
                  data: {
                    error: error
                  }
                }
              );
            }
          },
          (error) => {
            ev.complete("fail");
            !!error &&
              !!error.error &&
              !!error.error.message &&
              setRequestingWalletPayError(error.error.message);
            !!error && Object.keys(error).length && setRequestingWalletPay(false);
            ds.log(
              {},
              {
                id: "subscribe_handle_submit_pay_2",
                data: {
                  error: error
                }
              }
            );
          }
        );
      }
    });
  };

  const handleSubmit = async (event) => {
    setRequesting(true);
    setRequestingError(undefined);

    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();
    if (!stripe || !elements) {
      ds.log(
        {},
        {
          id: "subscribe_handle_submit_1",
          data: {
            stripe: !stripe,
            elements: !elements
          }
        }
      );
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);

    // If a previous payment was attempted, get the latest invoice
    const latestInvoicePaymentIntentStatus = localStorage.getItem(
      "latestInvoicePaymentIntentStatus"
    );
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement
    });

    if (error) {
      __DEV__ && console.log("[createPaymentMethod error]", error);
      !!error && !!error && !!error.message && setRequestingError(error.message);
      !!error && Object.keys(error).length && setRequesting(false);
      ds.log(
        {},
        {
          id: "subscribe_handle_submit_2",
          data: {
            error: error
          }
        }
      );
    } else {
      __DEV__ && console.log("[PaymentMethod]", paymentMethod);
      const paymentMethodId = paymentMethod.id;
      if (latestInvoicePaymentIntentStatus === "requires_payment_method") {
        // Update the payment method and retry invoice payment
        const invoiceId = localStorage.getItem("latestInvoiceId");
        ds.retrieveAuthToken(
          (token) => {
            retryInvoiceWithNewPaymentMethod({
              token,
              paymentMethodId,
              invoiceId,
              priceId: getPriceId(), //discount['priceIdToPeriod'][current.period],
              coupon: getCoupon(), // discount['couponIdToPeriod'][current.period],
              period: current.period,
              code: discount.id,
              cardName,
              cardAddress,
              cardPostalCode,
              cardCountry
            });
          },
          (error) => {
            __DEV__ && console.log(`[handleSubmit] Error ${error}`);
            !!error &&
              !!error.error &&
              !!error.error.message &&
              setRequestingError(error.error.message);
            !!error && Object.keys(error).length && setRequesting(false);
            ds.log(
              {},
              {
                id: "subscribe_handle_submit_3",
                data: {
                  error: error
                }
              }
            );
          }
        );
      } else {
        // Create the subscription
        ds.retrieveAuthToken(
          (token) => {
            createSubscription({
              token,
              paymentMethodId,
              priceId: getPriceId(), //discount['priceIdToPeriod'][current.period],
              coupon: getCoupon(), // discount['couponIdToPeriod'][current.period],
              period: current.period,
              code: discount.id,
              cardName,
              cardAddress,
              cardPostalCode,
              cardCountry,
              method: "card",
              countryCode: userCountry,
              currency: current.currency,
              trialDays:
                !!current &&
                !!current.period &&
                !!discount &&
                !!discount.freeTrialToPeriod &&
                !!discount.freeTrialToPeriod[current.period]
                  ? discount.trialDays
                  : undefined
            });
          },
          (error) => {
            __DEV__ && console.log(error);
            !!error &&
              !!error.error &&
              !!error.error.message &&
              setRequestingError(error.error.message);
            !!error && Object.keys(error).length && setRequesting(false);
            ds.log(
              {},
              {
                id: "subscribe_handle_submit_4",
                data: {
                  error: error
                }
              }
            );
          }
        );
      }
    }
  };

  function createSubscription({
    token,
    paymentMethodId,
    priceId,
    coupon,
    period,
    code,
    cardName,
    cardAddress,
    cardPostalCode,
    cardCountry,
    method,
    countryCode,
    currency,
    trialDays
  }) {
    return (
      fetch(
        "https://europe-west1-realfooding-app.cloudfunctions.net/stripe_create_subscription_euw1",
        {
          method: "post",
          headers: {
            "Content-type": "application/json",
            "Access-Control-Allow-Origin": "*"
          },
          body: JSON.stringify({
            auth: token,
            paymentMethodId: paymentMethodId,
            priceId: priceId,
            coupon: coupon,
            trialDays: trialDays,
            period: period,
            code: code,
            uname: cardName,
            uaddress: cardAddress,
            ucity: cardPostalCode,
            ucountry: cardCountry,
            countryCode: countryCode,
            method: method,
            platform: "web",
            currency: currency
          })
        }
      )
        .then((response) => {
          return response.json();
        })
        // If the card is declined, display an error to the user.
        .then((result) => {
          if (result.error) {
            ds.log(
              {},
              {
                id: "subscribe_handle_submit_5",
                data: {
                  error: result.error
                }
              }
            );
            // The card had an error when trying to attach it to a customer.
            throw result;
          }
          return result;
        })
        // Normalize the result to contain the object returned by Stripe.
        // Add the additional details we need.
        .then((result) => {
          return {
            uid: ds.retrieveUserData()["public"]["uid"],
            paymentMethodId: paymentMethodId,
            priceId: priceId,
            coupon: coupon,
            subscription: result,
            period: period,
            code: code,

            uname: cardName,
            uaddress: cardAddress,
            ucity: cardPostalCode,
            ucountry: cardCountry,
            method: method
          };
        })
        // Some payment methods require a customer to be on session
        // to complete the payment process. Check the status of the
        // payment intent to handle these actions.
        .then(handlePaymentThatRequiresCustomerAction)
        // If attaching this card to a Customer object succeeds,
        // but attempts to charge the customer fail, you
        // get a requires_payment_method error.
        .then(handleRequiresPaymentMethod)
        // No more actions required. Provision your service for the user.
        .then(onSubscriptionComplete)
        .catch((error) => {
          // An error has happened. Display the failure to the user here.
          // We utilize the HTML element we created.
          __DEV__ && console.log(error);
          !!error &&
            !!error.error &&
            !!error.error.message &&
            setRequestingError(error.error.message);
          !!error && Object.keys(error).length && setRequesting(false);
          ds.log(
            {},
            {
              id: "subscribe_handle_submit_6",
              data: {
                error: error
              }
            }
          );
        })
    );
  }

  function retryInvoiceWithNewPaymentMethod({
    token,
    paymentMethodId,
    invoiceId,
    priceId,
    coupon,
    period,
    code,
    cardName,
    cardAddress,
    cardPostalCode,
    cardCountry,
    method
  }) {
    return (
      fetch("https://europe-west1-realfooding-app.cloudfunctions.net/stripe_retry_invoice_euw1", {
        method: "post",
        headers: {
          "Content-type": "application/json",
          "Access-Control-Allow-Origin": "*"
        },
        body: JSON.stringify({
          auth: token,
          paymentMethodId: paymentMethodId,
          invoiceId: invoiceId,
          code: code,

          uname: cardName,
          uaddress: cardAddress,
          ucity: cardPostalCode,
          ucountry: cardCountry
        })
      })
        .then((response) => {
          return response.json();
        })
        // If the card is declined, display an error to the user.
        .then((result) => {
          if (result.error) {
            ds.log(
              {},
              {
                id: "subscribe_handle_submit_7",
                data: {
                  error: result.error
                }
              }
            );
            // The card had an error when trying to attach it to a customer.
            throw result;
          }
          return result;
        })
        // Normalize the result to contain the object returned by Stripe.
        // Add the additional details we need.
        .then((result) => {
          return {
            // Use the Stripe 'object' property on the
            // returned result to understand what object is returned.
            uid: ds.retrieveUserData()["public"]["uid"],
            invoice: result,
            paymentMethodId: paymentMethodId,
            priceId: priceId,
            coupon: coupon,
            period: period,
            code: code,
            isRetry: true,

            uname: cardName,
            uaddress: cardAddress,
            ucity: cardPostalCode,
            ucountry: cardCountry,
            method: method
          };
        })
        // Some payment methods require a customer to be on session
        // to complete the payment process. Check the status of the
        // payment intent to handle these actions.
        .then(handlePaymentThatRequiresCustomerAction)
        // No more actions required. Provision your service for the user.
        .then(onSubscriptionComplete)
        .catch((error) => {
          // An error has happened. Display the failure to the user here.
          // We utilize the HTML element we created.
          __DEV__ && console.log(error);
          !!error &&
            !!error.error &&
            !!error.error.message &&
            setRequestingError(error.error.message);
          !!error && Object.keys(error).length && setRequesting(false);
          ds.log(
            {},
            {
              id: "subscribe_handle_submit_8",
              data: {
                error: error
              }
            }
          );
        })
    );
  }

  function handlePaymentThatRequiresCustomerAction({
    subscription,
    invoice,
    priceId,
    paymentMethodId,
    isRetry
  }) {
    if (subscription && subscription.status === "active") {
      // Subscription is active, no customer actions required.
      return { subscription, priceId, paymentMethodId };
    }

    // If it's a first payment attempt, the payment intent is on the subscription latest invoice.
    // If it's a retry, the payment intent will be on the invoice itself.
    let paymentIntent = invoice
      ? invoice.payment_intent
      : subscription.latest_invoice.payment_intent;

    if (
      paymentIntent.status === "requires_action" ||
      (isRetry === true && paymentIntent.status === "requires_payment_method")
    ) {
      return stripe
        .confirmCardPayment(paymentIntent.client_secret, {
          payment_method: paymentMethodId
        })
        .then((result) => {
          if (result.error) {
            ds.log(
              {},
              {
                id: "subscribe_handle_submit_9",
                data: {
                  error: result.error
                }
              }
            );
            // Start code flow to handle updating the payment details.
            // Display error message in your UI.
            // The card was declined (i.e. insufficient funds, card has expired, etc).
            throw result;
          } else {
            if (result.paymentIntent.status === "succeeded") {
              // Show a success message to your customer.
              // There's a risk of the customer closing the window before the callback.
              // We recommend setting up webhook endpoints later in this guide.
              return {
                priceId: priceId,
                subscription: subscription,
                invoice: invoice,
                paymentMethodId: paymentMethodId
              };
            }
          }
        })
        .catch((error) => {
          __DEV__ && console.log(error);
          !!error &&
            !!error.error &&
            !!error.error.message &&
            setRequestingError(error.error.message);
          !!error && Object.keys(error).length && setRequesting(false);
          ds.log(
            {},
            {
              id: "subscribe_handle_submit_10",
              data: {
                error: error
              }
            }
          );
        });
    } else {
      // No customer action needed.
      return { subscription, priceId, paymentMethodId };
    }
  }

  function handleRequiresPaymentMethod({ subscription, paymentMethodId, priceId, method }) {
    if (subscription.status === "active") {
      // subscription is active, no customer actions required.
      return { subscription, priceId, paymentMethodId };
    } else if (subscription.latest_invoice.payment_intent.status === "requires_payment_method") {
      // Using localStorage to manage the state of the retry here,
      // feel free to replace with what you prefer.
      // Store the latest invoice ID and status.
      localStorage.setItem("latestInvoiceId", subscription.latest_invoice.id);
      localStorage.setItem(
        "latestInvoicePaymentIntentStatus",
        subscription.latest_invoice.payment_intent.status
      );

      ds.log(
        {},
        {
          id: "subscribe_handle_submit_11",
          data: {
            error: { message: "Tu tarjeta ha sido rechazada" }
          }
        }
      );
      throw { error: { message: "Tu tarjeta ha sido rechazada" } };
    } else {
      return { subscription, priceId, paymentMethodId, method };
    }
  }

  function onSubscriptionComplete(result) {
    const paymentMethod = !!result.method ? result.method : "";
    // Payment was successful.
    if (result.subscription.status === "active") {
      // Change your UI to show a success message to your customer.
      // Call your backend to grant access to your service based on
      // `result.subscription.items.data[0].price.product` the customer subscribed to.
      try {
        window.ttq.track("Subscribe", {
          content_id: plan,
          freeTrial: freeTrial,
          content_type: "SUBSCRIPTION",
          content_name: current.period,
          quantity: 1,
          price: freeTrial ? "0" : Number(Number(current.price).toFixed(2)),
          value: freeTrial ? "0" : Number(Number(current.price).toFixed(2)),
          currency: current.currency,
          paymentMethod: paymentMethod
        });
        window.ttq.track("CompletePayment", {
          content_id: plan,
          freeTrial: freeTrial,
          content_type: "SUBSCRIPTION",
          content_name: current.period,
          quantity: 1,
          price: freeTrial ? "0" : Number(Number(current.price).toFixed(2)),
          value: freeTrial ? "0" : Number(Number(current.price).toFixed(2)),
          currency: current.currency,
          paymentMethod: paymentMethod
        });
        ReactPixel.track("Purchase", {
          currency: current.currency,
          value: freeTrial
            ? "0"
            : Number(current.price * discount.discountToPeriod[current.period]).toFixed(2)
        });
        ReactPixel.trackCustom("mrf_purchase", {
          uid: ds.retrieveUserData()["public"]["uid"],
          email: ds.retrieveUserEmail(),
          nickname: ds.retrieveUserData()["public"]["nickname"],
          plan: plan,
          current: current,
          priceId: getPriceId(), //discount['priceIdToPeriod'][current.period],
          coupon: getCoupon(), // discount['couponIdToPeriod'][current.period],
          promo: promo,
          discount: discount,
          period: current.period,
          code: discount.id,
          name: cardName,
          address: cardAddress,
          city: cardPostalCode,
          country: cardCountry,
          paymentMethod: paymentMethod,
          freeTrial: freeTrial
        });
      } catch (error) {
        __DEV__ && console.log(`[onSubscriptionComplete] Error catch ${error}`);
      }
    }
  }

  function localizeStripeMessages(error) {
    switch (error) {
      case "Your card was declined.":
        return "Tu tarjeta fue rechazada.";
      case "Your card has insufficient funds.":
        return "Tu tarjeta no tiene fondos suficientes.";
      case "Your card has expired":
        return "Tu tarjeta ha caducado.";
      case "Your card's security code is incorrect.":
        return "El código de seguridad de su tarjeta es incorrecto.";
      case "An error occurred while processing your card. Try again in a little bit.":
        return "Se produjo un error al procesar su tarjeta. Vuelve a intentarlo en un momento.";
      case "Your postal code is incomplete.":
        return "El código postal está incompleto.";
      case "Tu código postal está incompleto.":
        return "El código postal está incompleto.";
      case "Tu tarjeta ha sido rechazada":
        return "Tu tarjeta ha sido rechazada";
      case "El número de tu tarjeta está incompleto.":
        return "El número de tu tarjeta está incompleto.";
      case "El número de tu tarjeta no es válido.":
        return "El número de tu tarjeta no es válido.";
      case "El código de seguridad de tu tarjeta está incompleto.":
        return "El código de seguridad de tu tarjeta está incompleto.";
      case "La fecha de caducidad de tu tarjeta está incompleta.":
        return "La fecha de caducidad de tu tarjeta está incompleta.";
      case "Your card's security code is incomplete.":
        return "El código de seguridad de tu tarjeta está incompleto.";
      case "El número de tu tarjeta es incorrecto.":
        return "El número de tu tarjeta es incorrecto.";
      case "Your card number is incomplete.":
        return "El número de tu tarjeta está incompleto.";
      case "El código de seguridad de la tarjeta está incompleto.":
        return "El código de seguridad de la tarjeta está incompleto.";
      case "El año de caducidad de la tarjeta no es válido.":
        return "El año de caducidad de la tarjeta no es válido.";
      case "Tu tarjeta ha sido rechazada.":
        return "Tu tarjeta ha sido rechazada.";
      case "El año de caducidad de la tarjeta ya pasó.":
        return "El año de caducidad de la tarjeta ya pasó.";
      case "Your card number is invalid.":
        return "El número de tarjeta es inválido.";
      case "Your card number is incorrect.":
        return "El número de la tarjeta es incorrecto.";
      case "Estamos teniendo problemas para conectar con nuestro proveedor de pagos. No se te ha cobrado nada. Comprueba si tienes conexión a Internet y vuelve a intentarlo.":
        return "Estamos teniendo problemas para conectar con nuestro proveedor de pagos. No se te ha cobrado nada. Comprueba si tienes conexión a Internet y vuelve a intentarlo.";
      case "Votre numéro de carte est incomplet.":
        return "El número de tu tarjeta está incompleto";
      case "Your card's expiration date is incomplete.":
        return "El código de seguridad de la tarjeta está incompleto.";
      case "Your card does not support this type of purchase.":
        return "Tu tarjeta no soporta este tipo de pago.";
      case "Tu tarjeta no se admite.":
        return "Tu tarjeta no se admite.";
      case "Your card has expired.":
        return "Tu tarjeta ha caducado.";
      case "El número de tarjeta está incompleto.":
        return "El número de tarjeta está incompleto.";
      case "La fecha de caducidad de tu tarjeta ya ha pasado.":
        return "La fecha de caducidad de tu tarjeta ya ha pasado.";
      case "Le code de sécurité de votre carte est incomplet.":
        return "El código de seguridad de tu tarjeta está incompleto.";
      case "Il codice di sicurezza della tua carta è incompleto.":
        return "El código de seguridad de tu tarjeta está incompleto.";
      case "We are experiencing issues connecting to our payments provider. You have not been charged. Please check your internet connection and try again.":
        return "Estamos teniendo problemas para conectar con nuestro proveedor de pagos. No se te ha cobrado nada. Comprueba si tienes conexión a Internet y vuelve a intentarlo.";
      case "Asegúrate de que el campo del nombre no contenga un número de tarjeta.":
        return "Asegúrate de que el campo del nombre no contenga un número de tarjeta.";
      case "Se ha rechazado tu tarjeta. La solicitud se ha hecho en el modo real, pero nos consta que has usado una tarjeta de prueba.":
        return "Se ha rechazado tu tarjeta. La solicitud se ha hecho en el modo real, pero nos consta que has usado una tarjeta de prueba.";
      case "Il numero della tua carta è incompleto.":
        return "El número de tu tarjeta está incompleto.";
      case "El número de tarjeta no es válido.":
        return "El número de tu tarjeta no es válido.";
      default:
        return "Se produjo un error al procesar su tarjeta. Vuelve a intentarlo en un momento.";
    }
  }

  function renderPaymentRequestButton(paymentConfig) {
    // const testWithDevUser = !!ds.retrieveUserData() && !!ds.retrieveUserData()['private'] && ds.retrieveUserData()['private']['dev'];
    if (paymentConfig) {
      const paymentRequest = paymentConfig.paymentRequest;
      const paymentCurrent = paymentConfig.current;
      const paymentPrice = paymentConfig.price;
      if (paymentRequest) {
        return (
          <div key={paymentPrice} className={classes.paymentRequestButtonContainer}>
            <PaymentRequestButtonElement key={paymentPrice} options={{ paymentRequest }} />
            {!!requestingWalletPayError && (
              <Typography className={classes.paymentRequestButtonError}>
                {localizeStripeMessages(requestingWalletPayError)}
              </Typography>
            )}
            <div className={classes.paymentRequestButtonHintContainer}>
              <div className={classes.paymentRequestButtonHintLine} />
              <div className={classes.paymentRequestButtonHintText}>
                <Typography className={classes.rootBodyContentRightAccountHint}>
                  {Locale.getString("subscribe_pay_with_card")}
                </Typography>
              </div>
              <div className={classes.paymentRequestButtonHintLine} />
            </div>
          </div>
        );
      }
    }
  }

  function renderPromoCode() {
    return (
      <>
        {(!discount || (!!discount && !discount.hidden)) && (
          <>
            <Grid className={classes.rootBodyContentPadding} />
            <Typography className={classes.rootBodyContentLeftSubtitle}>
              {Locale.getString("subscribe_promocode")}
            </Typography>
            <Grid container direction={"row"} alignItems={"center"} justify={"center"}>
              <Grid item xs={7} sm={7} md={7}>
                <TextField
                  className={classes.rootBodyContentForm}
                  error={!!promoError}
                  id="standard-error-helper-text"
                  label={Locale.getString("subscribe_code")}
                  defaultValue={promo}
                  helperText={!!promoError ? promoError : ""}
                  value={promo}
                  onChange={(event) => {
                    setPromo(event.target.value);
                    setDiscountApplied(undefined);
                  }}
                />
              </Grid>

              <Grid item xs={1} sm={1} md={1} />
              <Grid item xs={4} sm={4} md={4}>
                <Button
                  variant={"outlined"}
                  disabled={discountApplied}
                  className={
                    discountApplied
                      ? classes.rootBodyContentFormButtonAplied
                      : classes.rootBodyContentFormButton
                  }
                  onClick={() => {
                    ds.retrievePromoCode(
                      promo,
                      false,
                      (data, applied) => {
                        if (!!applied || data.id === "NONE") {
                          setPromo(data.id);
                          setDiscount(data);
                          setDiscountApplied(data.id !== "NONE" ? true : undefined);
                        } else {
                          setDiscount(data);
                          setDiscountApplied(false);
                        }
                      },
                      (error, data) => {
                        setDiscount(data);
                        setDiscountApplied(Locale.getString("discount_no_exist"));
                      }
                    );
                  }}
                >
                  <Typography
                    className={
                      discountApplied
                        ? classes.rootBodyContentFormButtonTextAplied
                        : classes.rootBodyContentFormButtonText
                    }
                  >
                    {discountApplied
                      ? Locale.getString("subscribe_applied") + "⠀✓"
                      : Locale.getString("subscribe_apply")}
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </>
        )}

        {(!discount || (!!discount && !discount.hidden)) && discountApplied !== undefined && (
          <Typography
            className={
              !!discountApplied
                ? classes.rootBodyContentSubscribePromotionalCodeApplied
                : classes.rootBodyContentSubscribePromotionalCodeNotApplied
            }
          >
            {!!discountApplied
              ? Locale.getString("subscribe_applied_code")
              : Locale.getString("subscribe_applied_code_invalid")}
          </Typography>
        )}
        <Grid className={classes.rootBodyContentPadding} />
      </>
    );
  }

  function isMobileUserAgent() {
    let check = false;
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
  }

  function isMobile() {
    const isMobileWitdh = window.screen.width <= 800;
    const isPlatformMobile = !!platform && platform === "mobile";
    return isPlatformMobile || isMobileWitdh || isMobileUserAgent();
  }

  if (!current) {
    return null;
  }

  const freeTrial =
    !!current &&
    !!current.period &&
    !!discount &&
    !!discount.freeTrialToPeriod &&
    !!discount.freeTrialToPeriod[current.period];

  return (
    <div>
      <Container className={classes.root} maxWidth="xl">
        <BasicHeader />
        <Grid className={classes.rootBody} container md={12} spacing={2}>
          <Grid className={classes.rootBodyContent} container md={12} direction={"row"}>
            <Grid
              className={classes.rootBodyContentLeft}
              container
              md={8}
              direction={"column"}
              justify={"center"}
            >
              {!isMobile() && (
                <Typography className={classes.rootBodyContentLeftTitle}>
                  {Locale.getString(!freeTrial ? "subscribe_title" : "subscribe_title_freetrial")}
                </Typography>
              )}
              <Grid
                className={classes.rootBodyContentRightMobile}
                container
                md={5}
                direction={"row"}
              >
                {!isMobile() && <Grid className={classes.rootSeparatorGrey} />}

                <Grid item md={5} xs={5} alignItems={"center"} justify={"center"}>
                  <Typography className={classes.rootBodyContentRightTitle}>
                    {Locale.getString("subscribe_chosen_plan")}
                  </Typography>
                </Grid>
                <Grid item md={6} xs={6} alignItems={"center"} justify={"center"}>
                  <Link
                    className={classes.rootBodyContentRightLink}
                    component="button"
                    onClick={() => {
                      setShowModal(true);
                    }}
                  >
                    <Typography className={classes.rootBodyContentRightSubtitle}>
                      {Locale.getString("subscribe_choose_plan")}
                    </Typography>
                  </Link>
                </Grid>

                <Grid container md={12} xs={12} direction={"column"}>
                  <Typography className={classes.rootBodyContentMobileBoxContainerSubtitle}>
                    {current.period}{" "}
                    {current.period === 1
                      ? Locale.getString("subscribe_month")
                      : Locale.getString("subscribe_months")}
                  </Typography>
                  {!!discount &&
                    !!discount.enabled &&
                    !!discount.applyToPeriod &&
                    !!discount.applyToPeriod[current.period] &&
                    Number(discount.discountToPeriod[current.period]) <= 1 && (
                      <Typography className={classes.rootBodyContentMobileBoxContainerPrice}>
                        {current.currencySymbolStart === true ? current.currencySymbol : ""}
                        {Number(discount.discountToPeriod[current]) >= 1
                          ? "-"
                          : Number(price).toFixed(2)}
                        {current.currencySymbolStart === false ? current.currencySymbol : ""}{" "}
                      </Typography>
                    )}
                  {!freeTrial ? (
                    !!discount &&
                    !!discount.enabled &&
                    !!discount.applyToPeriod &&
                    !!discount.applyToPeriod[current.period] ? (
                      <Typography className={classes.rootBodyContentMobileBoxContainerFinalPrice}>
                        {current.currencySymbolStart === true ? current.currencySymbol : ""}
                        {Math.trunc(
                          current.price * discount.discountToPeriod[current.period] * 100
                        ) / 100}
                        {current.currencySymbolStart === false ? current.currencySymbol : ""}
                      </Typography>
                    ) : (
                      <Typography className={classes.rootBodyContentMobileBoxContainerFinalPrice}>
                        {current.currencySymbolStart === true ? current.currencySymbol : ""}
                        {Number(current.price).toFixed(2)}
                        {current.currencySymbolStart === false ? current.currencySymbol : ""}
                      </Typography>
                    )
                  ) : (
                    <Typography className={classes.rootBodyContentMobileBoxContainerFinalPrice}>
                      {Locale.getString("first_n_days_free", [`${discount.trialDays}`])}
                    </Typography>
                  )}
                  {!freeTrial &&
                    !!discount &&
                    !!discount.enabled &&
                    !!discount.applyToPeriod &&
                    !!discount.applyToPeriod[current.period] && (
                      <Typography className={classes.rootBodyContentMobileBoxContainerHintPrice}>
                        {current.currencySymbolStart === true ? current.currencySymbol : ""}
                        {Math.trunc(
                          current.price * discount.discountToPeriod[current.period] * 100
                        ) / 100}
                        {current.currencySymbolStart === false ? current.currencySymbol : ""}{" "}
                        {Locale.getString("subscribe_during")}{" "}
                        {current.period === 12
                          ? `12 ${Locale.getString("subscribe_months")}`
                          : current.period === 6
                          ? `6 ${Locale.getString("subscribe_months")}`
                          : `1 ${Locale.getString("subscribe_month")}`}
                        . {Locale.getString("subscribe_after")}{" "}
                        {current.currencySymbolStart === true ? current.currencySymbol : ""}
                        {Number(current.price).toFixed(2)}
                        {current.currencySymbolStart === false ? current.currencySymbol : ""}
                        {current.period === 12
                          ? `${Locale.getString("subscribe_yearly_slash")}. `
                          : current.period === 6
                          ? `${Locale.getString("subscribe_biannual_slash")}. `
                          : `${Locale.getString("subscribe_monthly_slash")}. `}
                        {Locale.getString("subscribe_cancel_when_you_want")}.
                      </Typography>
                    )}
                  {freeTrial &&
                    !!discount &&
                    !!discount.enabled &&
                    !!discount.applyToPeriod &&
                    !!discount.applyToPeriod[current.period] && (
                      <Typography className={classes.rootBodyContentMobileBoxContainerHintPrice}>
                        {Locale.getString("subscribe_after")}{" "}
                        {current.currencySymbolStart === true ? current.currencySymbol : ""}
                        {Number(current.price).toFixed(2)}
                        {current.currencySymbolStart === false ? current.currencySymbol : ""}
                        {current.period === 12
                          ? `${Locale.getString("subscribe_yearly_slash")}. `
                          : current.period === 6
                          ? `${Locale.getString("subscribe_biannual_slash")}. `
                          : `${Locale.getString("subscribe_monthly_slash")}. `}
                        {Locale.getString("subscribe_cancel_when_you_want")}.
                      </Typography>
                    )}
                  {!(
                    !!discount &&
                    !!discount.enabled &&
                    !!discount.applyToPeriod &&
                    !!discount.applyToPeriod[current.period]
                  ) && (
                    <Typography className={classes.rootBodyContentMobileBoxContainerHintPrice}>
                      {current.currencySymbolStart === true ? current.currencySymbol : ""}
                      {Number(current.price).toFixed(2)}
                      {current.currencySymbolStart === false ? current.currencySymbol : ""}
                      {current.period === 12
                        ? ` ${Locale.getString("subscribe_per_year")}. `
                        : current.period === 6
                        ? ` ${Locale.getString("subscribe_per_6_months")}. `
                        : ` ${Locale.getString("subscribe_per_month")}. `}
                      {Locale.getString("subscribe_cancel_when_you_want")}.
                    </Typography>
                  )}
                  <Grid
                    container
                    className={classes.rootBodyContentImagePoweredByStripeContent}
                    alignItems={"center"}
                  >
                    <Typography align={"center"}>
                      <img
                        className={classes.rootBodyContentImagePoweredByStripe}
                        src={"/assets/backgrounds/pbs.png"}
                        alt={"Powered by stripe"}
                      />
                    </Typography>
                  </Grid>
                </Grid>

                <Grid className={classes.rootSeparatorGrey} />
              </Grid>

              {!isMobile() && (
                <Typography className={classes.rootBodyContentLeftSubtitle}>
                  {Locale.getString("subscribe_account")}
                </Typography>
              )}

              {!isMobile() &&
                (!!ds.retrieveUserEmail() ||
                  (!!ds.retrieveUserData() &&
                    !!ds.retrieveUserData()["public"] &&
                    !!ds.retrieveUserData()["public"]["nickname"])) && (
                  <Grid container direction={"column"}>
                    {!!ds.retrieveUserEmail() && (
                      <TextField
                        disabled={true}
                        className={classes.rootBodyContentForm}
                        id="standard-error-helper-text"
                        label={Locale.getString("subscribe_email")}
                        defaultValue={ds.retrieveUserEmail()}
                        value={ds.retrieveUserEmail()}
                      />
                    )}
                    {!!ds.retrieveUserData() &&
                      !!ds.retrieveUserData()["public"] &&
                      !!ds.retrieveUserData()["public"]["nickname"] && (
                        <TextField
                          disabled={true}
                          className={classes.rootBodyContentForm}
                          id="standard-error-helper-text"
                          label={Locale.getString("subscribe_nickname")}
                          defaultValue={"@" + ds.retrieveUserData()["public"]["nickname"]}
                          value={"@" + ds.retrieveUserData()["public"]["nickname"]}
                        />
                      )}

                    <Typography className={classes.rootBodyContentRightAccountHint}>
                      {Locale.getString("subscribe_plus_account_hint")}
                    </Typography>
                  </Grid>
                )}

              {!promo.includes("FREETRIAL") && renderPromoCode()}

              {renderPaymentRequestButton(paymentConfig)}

              <Grid
                container
                direction={"row"}
                className={classes.rootBodyContentLeftSubtitleContainer}
              >
                <Typography className={classes.rootBodyContentLeftSubtitle}>
                  {Locale.getString("subscribe_card_info")}
                </Typography>
                <img
                  className={classes.rootBodyContentCardsIcons}
                  src={"/assets/icons/cards.png"}
                  alt={"Powered by stripe"}
                />
              </Grid>
              <div>
                <CardElement options={CARD_ELEMENT_OPTIONS} />
              </div>

              <Grid className={classes.rootBodyContentPadding} />

              <Typography className={classes.rootBodyContentLeftSubtitle}>
                {Locale.getString("subscribe_bill_info")}
              </Typography>

              <Grid container direction={"column"}>
                <TextField
                  className={classes.rootBodyContentForm}
                  error={!!cardNameError}
                  id="standard-error-helper-text"
                  label={Locale.getString("subscribe_card_name")}
                  defaultValue={cardName}
                  helperText={!!cardNameError ? cardNameError : ""}
                  value={cardName}
                  onChange={(event) => setCardName(event.target.value)}
                  onBlur={() => {
                    if (cardName.length < 3) {
                      setCardNameError(Locale.getString("subscribe_card_name_error"));
                    } else {
                      setCardNameError(undefined);
                    }
                  }}
                />
                <TextField
                  className={classes.rootBodyContentForm}
                  error={!!cardAddressError}
                  id="standard-error-helper-text"
                  label={Locale.getString("subscribe_card_address")}
                  defaultValue={cardAddress}
                  helperText={!!cardAddressError ? cardAddressError : ""}
                  value={cardAddress}
                  onChange={(event) => setCardAddress(event.target.value)}
                  onBlur={() => {
                    if (cardAddress.length < 3) {
                      setCardAddressError(Locale.getString("subscribe_card_adress_error"));
                    } else {
                      setCardAddressError(undefined);
                    }
                  }}
                />

                <Grid container direction={"row"} alignItems={"center"} justify={"space-between"}>
                  <Grid>
                    <TextField
                      className={classes.rootBodyContentForm}
                      error={!!cardPostalCodeError}
                      id="standard-error-helper-text"
                      label={Locale.getString("subscribe_postal_code")}
                      defaultValue={cardPostalCode}
                      helperText={!!cardPostalCodeError ? cardPostalCodeError : ""}
                      value={cardPostalCode}
                      onChange={(event) => setCardPostalCode(event.target.value)}
                      onBlur={() => {
                        if (cardPostalCode.length < 4) {
                          setCardPostalCodeError(
                            Locale.getString("subscribe_card_postalcode_error")
                          );
                        } else {
                          setCardPostalCodeError(undefined);
                        }
                      }}
                    />
                  </Grid>
                  <Grid>
                    <FormControl
                      style={{
                        minWidth: 200
                      }}
                    >
                      <InputLabel id="demo-simple-select-label">
                        {Locale.getString("subscribe_country")}
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={cardCountry}
                        defaultValue={cardCountry}
                        error={!!cardCountryError}
                        helperText={!!cardCountryError ? cardCountryError : ""}
                        onChange={(event) => setCardCountry(event.target.value)}
                        // disabled={!__DEV__}
                        onBlur={() => {
                          if (!!cardCountry && cardCountry.length < 5) {
                            setCardCountryError(
                              Locale.getString("subscribe_choose_country_placeholder")
                            );
                          } else {
                            setCardCountryError(undefined);
                          }
                        }}
                      >
                        {!!enabledCountries &&
                          enabledCountries.map((countries) => {
                            return <MenuItem value={countries}>{countries}</MenuItem>;
                          })}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>

              {!requesting && (
                <Button
                  disabled={
                    !!requesting ||
                    !stripe ||
                    !elements ||
                    cardName.length < 3 ||
                    cardAddress.length < 3 ||
                    !cardPostalCode ||
                    cardPostalCode.length < 3 ||
                    cardCountry.length < 3 ||
                    !!cardNameError ||
                    !!cardAddressError ||
                    !!cardPostalCodeError ||
                    !!cardCountryError
                  }
                  variant={"outlined"}
                  className={
                    !stripe ||
                    !elements ||
                    cardName.length < 3 ||
                    cardAddress.length < 3 ||
                    !cardPostalCode ||
                    cardPostalCode.length < 3 ||
                    cardCountry.length < 3 ||
                    !!cardNameError ||
                    !!cardAddressError ||
                    !!cardPostalCodeError ||
                    !!cardCountryError
                      ? classes.rootBodyContentLeftButtonDisabled
                      : classes.rootBodyContentLeftButton
                  }
                  onClick={handleSubmit}
                >
                  <Typography className={classes.rootBodyContentSubscribeButtonText}>
                    {Locale.getString(
                      !freeTrial ? "subscribe_purchase" : "subscribe_purchase_freetrial"
                    )}
                  </Typography>
                </Button>
              )}
              <Grid container justify={"center"}>
                <Typography
                  align={"center"}
                  className={classes.rootBodyContentSubscribeButtonSafePay}
                >
                  {Locale.getString("subscribe_secure_payment")}
                </Typography>
              </Grid>
              {requesting && (
                <Button
                  disableRipple={true}
                  disableFocusRipple={true}
                  variant={"outlined"}
                  className={classes.rootBodyContentLeftButton}
                >
                  <CircularProgress size={"4%"} color="secondary" />
                </Button>
              )}

              {!!requestingError && (
                <Typography className={classes.rootBodyContentSubscribeError}>
                  {localizeStripeMessages(requestingError)}
                </Typography>
              )}
            </Grid>

            <Grid className={classes.rootBodyContentRight} container md={4} direction={"column"}>
              <Grid container direction={"row"} alignItems={"center"} justify={"center"}>
                <Grid item xs={12} md={12} sm={12}>
                  <Typography className={classes.rootBodyContentRightTitle}>
                    {Locale.getString("subscribe_chosen_plan")}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12} sm={12}>
                  <Link
                    className={classes.rootBodyContentRightLink}
                    component="button"
                    onClick={() => {
                      setShowModal(true);
                    }}
                  >
                    <Typography className={classes.rootBodyContentRightSubtitle}>
                      {Locale.getString("subscribe_choose_plan")}
                    </Typography>
                  </Link>
                </Grid>
              </Grid>
              {!!current && (
                <Grid container direction={"column"}>
                  <PlanBox
                    freeTrial={freeTrial}
                    displayHint={true}
                    discount={discount}
                    item={current}
                    base={
                      Object.values(premiumBasicOptions).filter((option) => option.period === 1)[0]
                    }
                  />
                  <Grid
                    container
                    className={classes.rootBodyContentImagePoweredByStripeContent}
                    justify={"center"}
                  >
                    <img
                      className={classes.rootBodyContentImagePoweredByStripe}
                      src={"/assets/backgrounds/pbs.png"}
                      alt={"Powered by stripe"}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>

        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={showModal}
          onClose={() => {
            setShowModal(false);
          }}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500
          }}
        >
          <Fade in={showModal}>
            <Grid className={classes.paper} container alignItems={"center"} justify={"center"}>
              <h2 id="transition-modal-title">{Locale.getString("subscribe_choose_plan")}</h2>
              <Grid
                container
                md={12}
                direction={"row"}
                alignItems={"center"}
                justify={"space-around"}
                spacing={2}
              >
                {!!Object.values(premiumBasicOptions).filter((option) => option.period === 1)
                  .length && (
                  <Grid container md={4} xs={4} alignItems={"center"} justify={"center"}>
                    <PlanBox
                      discount={discount}
                      item={
                        Object.values(premiumBasicOptions).filter(
                          (option) => option.period === 1
                        )[0]
                      }
                      base={
                        Object.values(premiumBasicOptions).filter(
                          (option) => option.period === 1
                        )[0]
                      }
                      onClick={() => {
                        setShowModal(false);
                        setCurrent(
                          Object.values(premiumBasicOptions).filter(
                            (option) => option.period === 1
                          )[0]
                        );
                      }}
                    />
                  </Grid>
                )}
                {!!Object.values(premiumBasicOptions).filter((option) => option.period === 12)
                  .length && (
                  <Grid container md={4} xs={4} alignItems={"center"} justify={"center"}>
                    <PlanBox
                      discount={discount}
                      item={
                        Object.values(premiumBasicOptions).filter(
                          (option) => option.period === 12
                        )[0]
                      }
                      base={
                        Object.values(premiumBasicOptions).filter(
                          (option) => option.period === 1
                        )[0]
                      }
                      onClick={() => {
                        setShowModal(false);
                        setCurrent(
                          Object.values(premiumBasicOptions).filter(
                            (option) => option.period === 12
                          )[0]
                        );
                      }}
                    />
                  </Grid>
                )}
                {!!Object.values(premiumBasicOptions).filter((option) => option.period === 6)
                  .length && (
                  <Grid container md={4} xs={4} alignItems={"center"} justify={"center"}>
                    <PlanBox
                      discount={discount}
                      item={
                        Object.values(premiumBasicOptions).filter(
                          (option) => option.period === 6
                        )[0]
                      }
                      base={
                        Object.values(premiumBasicOptions).filter(
                          (option) => option.period === 1
                        )[0]
                      }
                      onClick={() => {
                        setShowModal(false);
                        setCurrent(
                          Object.values(premiumBasicOptions).filter(
                            (option) => option.period === 6
                          )[0]
                        );
                      }}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Fade>
        </Modal>
      </Container>
      <Footer />
    </div>
  );
}
