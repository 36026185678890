import {makeStyles} from "@material-ui/core/styles";

export default makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		backgroundColor: '#19C692',
		paddingLeft: '3%',
		paddingRight: '3%',
		[theme.breakpoints.up('md')]: {
			paddingLeft: '14%',
			paddingRight: '14%',
		},
	},
	rootSeparator: {
		height: 1,
		width: '16%',
		marginLeft: '42%',
		marginRight: '42%',
		marginTop: '10%',
		marginBottom: '7%',
		backgroundColor: '#19C692',
	},
	rootBodyHeader: {
		marginTop: '10%',
		marginBottom: '10%'
	},
	rootBodyHeaderTitle: {
		fontSize: 22,
		fontWeight: 'bold',
		color: 'white',
		textAlign: 'center',
		[theme.breakpoints.up('md')]: {
			fontSize: 29,
		},
	},
	rootBodyHeaderSubTitle: {
		marginTop: '5%',
		fontSize: 20,
		fontWeight: 'bold',
		color: 'white',
		textAlign: 'center',
		marginRight: '5%',
		marginLeft: '5%',
		[theme.breakpoints.up('md')]: {
			marginTop: 16,
			fontSize: 25,
			marginRight: '0%',
			marginLeft: '0%',
		},
	},
	rootBodyBottomSubTitle: {
		marginTop: '5%',
		fontSize: 20,
		fontWeight: 'bold',
		color: 'white',
		textAlign: 'center',
		marginRight: '5%',
		marginLeft: '5%',
		[theme.breakpoints.up('md')]: {
			marginTop: 16,
			fontSize: 25,
			marginRight: '15%',
			marginLeft: '15%',
		},
	},
	rootBodyDownloadAppButton: {
		borderWidth: 1,
		borderColor: 'white',
		color: '#19C692',
		width: '80%',
		backgroundColor: 'white',
		borderRadius: 20,
		paddingTop: '3%',
		paddingBottom: '3%',
		paddingLeft: '4%',
		paddingRight: '4%',
		fontWeight: 'bold',
		marginTop: '3%',
		marginBottom: '0%',
		[theme.breakpoints.up('md')]: {
			paddingTop: '1%',
			paddingBottom: '1%',
			width: '30%',
		},
		'&:hover': {
			color: "#FFF",
		},
	},
	rootBodyBox: {
		marginTop: '10%',
		width: '95%',
		borderRadius: 10,
		[theme.breakpoints.up('md')]: {
			width: '60%',
			marginTop: '3%',
		},
	},
	rootBodyBoxInput: {
		marginTop: '4%',
		width: '80%',
		marginBottom: '3%',
		backgroundColor: '#F5F5F5',
	},
	rootBodyBoxButton: {
		marginLeft: '10%',
		borderWidth: 1,
		borderColor: '#19C692',
		backgroundColor: '#19C692',
		color: '#FFF',
		borderRadius: 20,
		fontWeight: 'bold',
		width: '40%',
		[theme.breakpoints.up('md')]: {
			width: '20%',
		},
	},
	rootBodyBoxHint: {
		marginTop: '3%',
		marginBottom: '3%'
	},
	rootBodyBoxHintTitle: {
		marginTop: '10%',
		fontSize: 15,
		color: 'black',
		[theme.breakpoints.up('md')]: {
			marginTop: 0,
		},
	},
	rootBodyBoxHintSubtitle: {
		marginBottom: '5%',
		fontSize: 15,
		fontWeight: 'bold',
		color: '#19C692',
		[theme.breakpoints.up('md')]: {
			marginBottom: '0%',
		},
	},
	rootBodyContentError: {
		fontWeight: 'normal',
		fontSize: 14,
		letterSpacing: 0,
		lineHeight: 1.3,
		color: '#ff0000',
		marginTop: '4%',
		marginBottom: '4%',
		textAlign: 'center',
		marginRight: '10%',
		marginLeft: '10%',
		[theme.breakpoints.up('md')]: {
			marginRight: '0%',
			marginLeft: '0%',
		},
	},

	rootBodyDownloadApp: {
		marginTop: '5%'
	},
	rootBodyDownloadAppTitle: {
		fontSize: 18,
		fontWeight: 'bold',
		color: 'white',
		textAlign: 'center',
		[theme.breakpoints.up('md')]: {
			fontSize: 24,
		},
	},
	rootBodyDownloadAppStoreButton: {
		marginTop: '3%',
		width: '50%',
		marginBottom: '3%',
		[theme.breakpoints.up('md')]: {
			marginBottom: '15%',
		},
	},
	rootBodyDownloadAppStoreButtonImage: {
		width: '100%'
	}
}), {index: 1});
