import React from 'react';

import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions'

import style from './style';
import Grid from '@material-ui/core/Grid';
import Box from "@material-ui/core/Box";
import Input from '@material-ui/core/Input';
import Typography from "@material-ui/core/Typography";

export default function CardImageInput(props) {
	const classes = style();

	return (
		<Grid
			container
			md={6}
			alignItems={"center"}
			justify={"center"}
		>
			<Box
				className={classes.rootBodyCardOption}
			>
				<img
					className={classes.rootBodyCardOptionImage}
					src={props.image}
					alt={"MyRealFood"}
				/>
				<Input
					className={classes.rootBodyCardOptionInput}
					id="standard-adornment-weight"
					value={props.value}
					onBlur={props.onBlur}
					onChange={props.onChange}
					placeholder={props.hint}
					aria-describedby="standard-weight-helper-text"
					inputProps={{
						'aria-label': 'weight',
						'min': 0,
						style: {
							textAlign: 'center'
						}
					}}
				/>
				<Typography
					className={classes.rootBodyCardOptionInputHint}
				>
					{props.units}
				</Typography>
			</Box>
		</Grid>
	);
}


