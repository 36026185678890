import { DateKey, WeekKey, WeekMenu, WeekMenuPrimitives } from "@myrealfood-sl/domain";

import ds from "./../../../../../../controller/DataService";

import { MenuRepository } from "../../domain/menu-repository/MenuRepository";
import { weekMenuTranslator } from "../../../../translators/WeekMenuTranslator";
import HttpRequesterCloud from "../../../../_shared/infrastructure copy/http/HttpRequesterCloud";
import { FullMenu } from "src/_new_arch/contexts/translators/FullDocumentMenu";

export class MenuCloudRepository implements MenuRepository {
  private readonly path: string;
  constructor() {
    this.path = `/menuUserV2App/users/menu`;
  }

  private async getToken(): Promise<string> {
    return new Promise((resolve) => {
      ds.retrieveAuthToken((token: string) => {
        resolve(token);
      });
    });
  }

  public async getMenu(week: WeekKey): Promise<WeekMenu> {
    try {
      const response = await HttpRequesterCloud.get(
        `${this.path}/get-menu`,
        {
          weekKey: week.toString()
        },
        await this.getToken()
      );

      console.log("[getMenu] Status :", response.status);

      if (response.status < 200 || response.status >= 300) {
        return WeekMenu.fromPrimitives(
          weekMenuTranslator.fromMenuDocumentToPrimitives(
            {
              fullMenu: [
                {
                  day: new DateKey(new Date(), "-").toString(),
                  recipes: {}
                }
              ] as FullMenu,
              created_at: new Date()
            },
            week
          )
        );
      }

      const data = response.data as WeekMenuPrimitives;

      return WeekMenu.fromPrimitives(data);
    } catch (error) {
      return WeekMenu.fromPrimitives(
        weekMenuTranslator.fromMenuDocumentToPrimitives(
          {
            fullMenu: [
              {
                day: new DateKey(new Date(), "-").toString(),
                recipes: {}
              }
            ] as FullMenu,
            created_at: new Date()
          },
          week
        )
      );
    }
  }
}
