import {makeStyles} from "@material-ui/core/styles";

export default makeStyles((theme) => ({
	rootBodyCardOption: {
		width: '80%',
		paddingBottom: '10%',
	},
	rootBodyCardOptionImage: {
		objectFit: 'contain',
		width: '100%',
		paddingTop: '10%',
		paddingBottom: '10%',
		paddingRight: '20%',
		paddingLeft: '20%',
	},
	rootBodyCardOptionTitle: {
		textAlign: 'center',
		fontWeight: 'bold',
		fontSize: 15,
	},
	rootBodyCardOptionTitleSelected: {
		textAlign: 'center',
		fontWeight: 'bold',
		color: 'white',
		fontSize: 15,
	},
	rootBodyCardOptionSubtitle: {
		paddingTop: '5%',
		textAlign: 'center',
		fontWeight: 'bold',
		paddingBottom: '10%',
		fontSize: 13,
		color: '#828282',
		whiteSpace: "pre-wrap"
	},
	rootBodyCardOptionSubtitleSelected: {
		paddingTop: '5%',
		textAlign: 'center',
		fontWeight: 'bold',
		paddingBottom: '10%',
		color: 'white',
		fontSize: 13,
		whiteSpace: "pre-wrap"
	}
}), { index: 1 });
