import React, {useEffect, useState} from 'react'
import Analytics from '../../controller/Analytics'
import ds from "../../controller/DataService";
import {useHistory} from "react-router";
import {getMobileOperatingSystem} from "../../controller/Exports";

export default async function Ref() {
	const history = useHistory();

	const [auth, setAuth] = useState(undefined);

	let id = undefined;
	let url = 'https://myrealfood.app/';
	let needToAuth = false;
	let redirect = new URLSearchParams(window.location.search).get('id');

	switch (redirect) {
		case 'galbani':
			needToAuth = false;
			id = 'galbani';
			url = 'https://galbani.es/recetas/?_sfm_tipo_receta=tiramisu';
			break;
		case 'cashback':
			needToAuth = false;
			id = 'cashback';
			url = 'https://galbani.tiendeo.com/';
			break;
		case 'ebook_diciembre_2020':
			needToAuth = false;
			id = 'ebook_diciembre_2020';
			url = 'https://myrealfood.app/shared/ebook_mrf_diciembre_2020.pdf';
			break;
		case 'eosnutrisolutions':
			needToAuth = false;
			id = 'eosnutrisolutions';
			url = 'https://www.eosnutrisolutions.com/category/recetas/';
			break;
		case 'minimalism':
			needToAuth = false;
			id = 'minimalism';
			url = 'https://minimalismbrand.com/collections/myrealfood-minimalism';
			break;
		case 'huawei':
			needToAuth = false;
			id = 'huawei';
			url = 'https://myrealfood.app/';
			break;
		case 'mqrf':
			needToAuth = false;
			id = 'mqrf';
			url = 'https://myrealfood.app/get/';
			break;
		case 'GORILLAS':
			needToAuth = false;
			id = 'GORILLAS';
			url = 'https://bit.ly/3eQm8dc';
			break;
		default:
			needToAuth = false;
			id = 'default';
			url = 'https://myrealfood.app/';
			break;
	}

	useEffect(() => {
		if (!!needToAuth) {
			if (!ds.retrieveAuthData()) {
				let uri = `/ref/${encodeURIComponent(window.location.search)}`;
				let paramMrt = new URLSearchParams(window.location.search).get('mrt');
				history.push(`/login/${paramMrt ? `?mrt=${paramMrt}&` : `?`}redirect=${uri}`)
			} else {
				ds.subscribeToAuthChanges('Ref', (user) => {
					setAuth(user);
				});

				return () => {
					ds.unsubscribeFromAuthChanges('Ref');
				}
			}
		}
	}, [auth]);

	await ds.storeUserCampaign({
		path: '/ref',
		id: id ?? '',
		url: url ?? '',
		needToAuth: needToAuth ?? '',
		redirect: redirect ?? '',
		os: getMobileOperatingSystem() ?? '',
		search: window.location.search ?? '',
		unique: id ?? '',
	});

	if (!needToAuth) {
		!!id && !!url && Analytics.onAppLaunched();
		!!id && !!url && Analytics.logEvent("open_ref_link", {
			screen: 'web',
			error: undefined,
			value: id,
			url: url,
		});

		return (
			<div style={{marginTop: '1em'}}>
				{window.location.assign(url)}
			</div>
		);
	} else {
		!!auth && !!id && !!url && Analytics.onAppLaunched();
		!!auth && !!id && !!url && Analytics.logEvent("open_ref_link", {
			screen: 'web',
			error: undefined,
			value: id,
			url: url,
		});

		return (
			<div style={{marginTop: '1em'}}>
				{!!auth && window.location.assign(url)}
			</div>
		);
	}
}
