import React, {useEffect, useRef, useState} from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import style from "./style";
import Button from "@material-ui/core/Button";
import PlusFAQ from "../Plus/PlusFAQ";
import PricingCardComponet from "./PricingCard";
import ReviewComponent from "./Reviews";
import {useHistory} from 'react-router-dom';
import SmallFooter from "../../common/Footer/SmallFooter";
import BasicHeader from "../../common/Header/BasicHeader";
import ds from "../../controller/DataService";
import Link from "@material-ui/core/Link";
import Locale from '../../controller/locale'
import {__DEFAULT_PROMOCODE__, DEFAULT_PREMIUM_BASIC_OPTIONS} from "../../controller/Exports";

const OfferLanding = () => {
    const classes = style();
    const history = useHistory();
    const [auth, setAuth] = useState(undefined);
    const [selectedPlan, setSelectedPlan] = useState(12);
    const [promo, setPromo] = useState(ds.retrunAvailablePromocode(__DEFAULT_PROMOCODE__[0]));
    const [discount, setDiscount] = useState(undefined);
    const [discountApplied, setDiscountApplied] = useState(undefined);
    const [priceCards, setPriceCards] = useState([]);

    const _faqRef = useRef(null);

    const reviews = [
        {
            title: Locale.getString('offer_review_title_1'),
            name: '@irehamti',
            text: Locale.getString('offer_review_text_1')
        },
        {
            title: Locale.getString('offer_review_title_2'),
            name: '@aldaara',
            text: Locale.getString('offer_review_text_2')
        },
        {
            title: Locale.getString('offer_review_title_3'),
            name: '@fabriciademocos',
            text: Locale.getString('offer_review_text_3')
        }
    ];

    // useEffect(() => {
    // 	async function getCountry() {
    // 		try {
    // 			const country = await ds.retrieveBrowserCountry();
    // 			setCountry(country);
    // 		} catch(error) {
    // 			setCountry('ES');
    // 		}
    // 	}
    // 	getCountry();
    // }, [])

    useEffect(() => {
        if (new URLSearchParams(decodeURIComponent(window.location.search)).get('promo') === 'PLUS20') {
            window.location.assign('https://campaigns.myrealfood.app/?utm_id=PLUS&utm_country=GLOBAL&utm_campaign=CONVERSION&utm_source=instagram&utm_medium=stories&utm_content=&utm_term=offer&utm_category=intern_carlosriosq&utm_metadata=PLUS20_')
            return (<div/>)
        } else {
            ds.subscribeToAuthChanges('Offer', (user) => {
                setAuth(user);
                if (ds.isPlusUser()) {
                    window.scrollTo({top: 0, behavior: 'smooth'});
                    setTimeout(() => {
                        const redirect = new URLSearchParams(decodeURIComponent(window.location.search)).get('redirect')
                        if(redirect) {
                            history.push(redirect)
                        }
                        if (!ds.hasCompletedPlusOnboarding()) {
                            history.push('/onboarding/?payment=succeed')
                        } else {
                            history.push('/plus/menu')
                        }
                    }, 250);
                }
            });

            let paramRnd = new URLSearchParams(decodeURIComponent(window.location.search)).get('rnd');
            if (!ds.retrieveAuthData() && !!paramRnd && !!paramRnd.length) {
                ds.signInWithAuthToken(paramRnd);
            }

            let paramMrt = new URLSearchParams(window.location.search).get('mrt');
            if (!ds.retrieveAuthData() && !!paramMrt && !!paramMrt.length) {
                ds.signInWithAESToken(paramMrt);
            }

            let paramPlan = new URLSearchParams(decodeURIComponent(window.location.search)).get('plan');
            if (!!paramPlan && !!paramPlan.length) {
                if (Number(paramPlan) === 12 || Number(paramPlan) === 6 || Number(paramPlan) === 1) {
                    setSelectedPlan(Number(paramPlan));
                }
            }

            let paramPromo = new URLSearchParams(decodeURIComponent(window.location.search)).get('promo');
            if (!!paramPromo && !!paramPromo.length) {
                setPromo(paramPromo);

                ds.retrievePromoCode(paramPromo, false, (data, applied) => {
                    if (!!applied && data.id !== 'NONE') {
                        setDiscount(data);
                        setDiscountApplied(data.id !== 'NONE' ? true : undefined);
                        ds.retrieveBrowserCountry().then(country => {
                            computePriceCards(country, data);
                        });
                    } else {
                        setDiscount(data);
                        setDiscountApplied(undefined);
                        history.push('/')
                    }
                }, (error, data) => {
                    setDiscount(data);
                    ds.retrieveBrowserCountry().then(country => {
                        computePriceCards(country);
                    });
                });
            } else {
                history.push('/')
            }

            async function storeUserCampaign() {
                await ds.storeUserCampaign({
                    path: '/offer',
                    selectedPlan: selectedPlan ?? '',
                    promo: promo ?? '',
                    discount: discount ?? '',
                    discountApplied: discountApplied ?? '',
                    paramRnd: new URLSearchParams(decodeURIComponent(window.location.search)).get('rnd') ?? '',
                    paramMrt: new URLSearchParams(decodeURIComponent(window.location.search)).get('mrt') ?? '',
                    paramPlan: new URLSearchParams(decodeURIComponent(window.location.search)).get('plan') ?? '',
                    paramPromo: new URLSearchParams(decodeURIComponent(window.location.search)).get('promo') ?? '',
                    search: window.location.search ?? '',
                    unique: new URLSearchParams(decodeURIComponent(window.location.search)).get('promo') ?? '',
                });
            }

            storeUserCampaign();

            return () => {
                ds.unsubscribeFromAuthChanges('Offer');
            }
        }
    }, [auth]);

    const handlerGoToSubcribe = (plan) => {
        setSelectedPlan(plan);
        const subcribePlan = plan ?? selectedPlan;
        history.push({
            pathname: '/subscribe',
            search: '?plan=' + subcribePlan + '&promo=' + promo
        });
    }

    const freeTrial = (!!discount && !!discount.freeTrialToPeriod && !!Object.keys(discount.freeTrialToPeriod).some(key => discount.freeTrialToPeriod[key]));

    const computePriceCards = (userCountry, userDiscount = null) => {
        let priceCards = [];
        const freeTrial = (!!discount && !!discount.freeTrialToPeriod && !!Object.keys(discount.freeTrialToPeriod).some(key => discount.freeTrialToPeriod[key]));

        userCountry = !!userDiscount && !!userDiscount.metadata && !!userDiscount.metadata.country ? userDiscount.metadata.country : 'ES'

        async function setDefaultPriceCards() {
            const defaultPriceCards = [
                {
                    title: Locale.getString('monthly'),
                    subtitle: Locale.getString('monthly_pay'),
                    price: Locale.getString('monthly_price', [userCountry === 'GB' ? '£' : '€']),
                    period: 1,
                    text: !!userDiscount && Number(userDiscount.discountToPeriod[1]) !== 1 ? `${Locale.getString('monthly_text')}<b>${Number((!!userDiscount ? Number(DEFAULT_PREMIUM_BASIC_OPTIONS(userCountry)[2].price * userDiscount.discountToPeriod[6]) : DEFAULT_PREMIUM_BASIC_OPTIONS(userCountry)[2].price) / 1).toFixed(2)} ${Locale.getString('monthly_text_2')}</b>` : '</b>',
                    order: [1, 3]
                },
                {
                    title: Locale.getString('twelve_months'),
                    subtitle: Locale.getString('annual_pay'),
                    price: (!!userDiscount ? (`${Number(DEFAULT_PREMIUM_BASIC_OPTIONS(userCountry)[0].price * userDiscount.discountToPeriod[12]).toFixed(2)}${Locale.getString('currency_symbol', [userCountry === 'GB' ? '£' : '€'])}`) : Locale.getString('annual_price', [userCountry === 'GB' ? '£' : '€'])),
                    period: 12,
                    order: [2, 1],
                    text: !!userDiscount && Number(userDiscount.discountToPeriod[12]) !== 1 ? `${Locale.getString('annual_text')}${Number((!!userDiscount ? Number(DEFAULT_PREMIUM_BASIC_OPTIONS(userCountry)[0].price * userDiscount.discountToPeriod[12]) : DEFAULT_PREMIUM_BASIC_OPTIONS(userCountry)[0].price) / 12).toFixed(2)}${Locale.getString('currency_symbol', [userCountry === 'GB' ? '£' : '€'])} ${Locale.getString('monthly_text_2')}` : '',
                    oldPrice: !!freeTrial ? Locale.getString('n_days_free', [`${userDiscount.trialDays}`]) : (!!userDiscount && Number(userDiscount.discountToPeriod[12]) !== 1 ? Locale.getString('old_price_offer_text', [userCountry === 'GB' ? '£' : '€']) : Locale.getString('old_price_higher_offer_text', [userCountry === 'GB' ? '£' : '€'])),
                },
                {
                    title: Locale.getString('six_months'),
                    subtitle: Locale.getString('biannual_pay'),
                    price: !!userDiscount ? (`${Number(DEFAULT_PREMIUM_BASIC_OPTIONS(userCountry)[1].price * userDiscount.discountToPeriod[6]).toFixed(2)}${Locale.getString('currency_symbol', [userCountry === 'GB' ? '£' : '€'])}`) : Locale.getString('biannual_price', [userCountry === 'GB' ? '£' : '€']),
                    period: 6,
                    order: [3, 2],
                    text: !!userDiscount && Number(userDiscount.discountToPeriod[6]) !== 1 ? `${Locale.getString('monthly_text')}<b>${Number((!!userDiscount ? Number(DEFAULT_PREMIUM_BASIC_OPTIONS(userCountry)[1].price * userDiscount.discountToPeriod[6]) : DEFAULT_PREMIUM_BASIC_OPTIONS(userCountry)[1].price) / 6).toFixed(2)} ${Locale.getString('monthly_text_2')}</b>` : '</b>',
                    oldPrice: !!freeTrial ? Locale.getString('n_days_free', [`${userDiscount.trialDays}`]) : (!!userDiscount && Number(userDiscount.discountToPeriod[6]) !== 1 ? undefined : /*'ANTES <span style="text-decoration:line-through;">41,94€</span>'*/ ''),
                }
            ];
            setPriceCards(defaultPriceCards);
        }

        if (!!userCountry) {
            ds.retrievePremiumBasicOptions(userCountry).then(options => {
                const freeTrial = (!!userDiscount && !!userDiscount.freeTrialToPeriod && !!Object.keys(userDiscount.freeTrialToPeriod).some(key => userDiscount.freeTrialToPeriod[key]));

                for (const option of options) {
                    let text, oldPrice = null;
                    if (option.period === 1) {
                        text = !!userDiscount && Number(userDiscount.discountToPeriod[option.period]) !== 1 ? `${Locale.getString('monthly_text')}<b>${Number((!!userDiscount ? Number(option.price * userDiscount.discountToPeriod[option.period]) : option.price) / option.period).toFixed(2)} ${Locale.getString('monthly_text_2')}</b>` : '</b>';
                    }
                    if (option.period === 6) {
                        oldPrice = !!freeTrial ? Locale.getString('n_days_free', [`${userDiscount.trialDays}`]) : (!!userDiscount && Number(userDiscount.discountToPeriod[option.period]) !== 1 ? undefined : /*'ANTES <span style="text-decoration:line-through;">41,94€</span>'*/ '');
                        text = !!userDiscount && Number(userDiscount.discountToPeriod[option.period]) !== 1 ? `${Locale.getString('monthly_text')}<b>${Number((!!userDiscount ? Number(option.price * userDiscount.discountToPeriod[option.period]) : option.price) / option.period).toFixed(2)} ${Locale.getString('monthly_text_2')}</b>` : '</b>';
                    }
                    if (option.period === 12) {
                        oldPrice = !!freeTrial ? Locale.getString('n_days_free', [`${userDiscount.trialDays}`]) : (!!userDiscount && Number(userDiscount.discountToPeriod[option.period]) !== 1 ? `${Locale.getString('before')} <span style="text-decoration:line-through;">${option.currencySymbolStart === true ? option.currencySymbol : ''}${Number(options.filter(option => option.period === 1)[0].price * 12).toFixed(2)}${option.currencySymbolStart === false ? option.currencySymbol : ''}</span>` : `${Locale.getString('before')} <span style="text-decoration:line-through;">${option.currencySymbolStart === true ? option.currencySymbol : ''}${Number(option.price * 1.8).toFixed(2)}${option.currencySymbolStart === false ? option.currencySymbol : ''}</span>`);
                        text = !!userDiscount && Number(userDiscount.discountToPeriod[option.period]) !== 1 ? `${Locale.getString('annual_text')}${option.currencySymbolStart === true ? option.currencySymbol : ''}${Number((!!userDiscount ? Number(option.price * userDiscount.discountToPeriod[option.period]) : option.price) / option.period).toFixed(2)}${option.currencySymbolStart === false ? option.currencySymbol : ''} ${Locale.getString('monthly_text_2')}` : '';
                    }
                    const currencySymbol = option.currencySymbol;
                    const currencySymbolStart = option.currencySymbolStart;
                    const period = option.period;
                    const title = option.period === 12 ? Locale.getString('twelve_months') : (option.period === 6 ? Locale.getString('six_months') : Locale.getString('monthly'));
                    const subtitle = option.period === 12 ? Locale.getString('annual_pay') : (option.period === 6 ? Locale.getString('biannual_pay') : Locale.getString('monthly_pay'));
                    const price = !!userDiscount ? `${option.currencySymbolStart === true ? option.currencySymbol : ''}${Number(option.price * userDiscount.discountToPeriod[option.period]).toFixed(2)}${option.currencySymbolStart === false ? option.currencySymbol : ''}` : `${option.currencySymbolStart === true ? option.currencySymbol : ''}${option.price}${option.currencySymbolStart === false ? option.currencySymbol : ''}`;
                    const priceNumber = !!userDiscount ? Number(option.price * userDiscount.discountToPeriod[option.period]) : option.price;
                    const originalPriceNumber = option.price
                    const order = option.period === 12 ? [2, 1] : (option.period === 6 ? [3, 2] : [1, 3]);
                    priceCards.push({
                        title,
                        subtitle,
                        price,
                        period,
                        text,
                        order,
                        oldPrice,
                        originalPriceNumber,
                        priceNumber,
                        currencySymbol,
                        currencySymbolStart
                    })
                }
                setPriceCards(priceCards);
            }).catch(error => {
                console.log(`[retrievePremiumBasicOptions] Error ${error}`);
                setDefaultPriceCards();
            })
        } else {
            setDefaultPriceCards();
        }
    }

    return (
        <>
            <Grid
                maxWidth="xl"
                container
                className={classes.root}
            >
                <BasicHeader/>
                <Grid
                    container
                    direction={"column"}
                    justify={"center"}
                    alignContent={"center"}
                >
                    {!!discount && !discount.hidden ?
                        (!!discount && !!discount.metadata && !!discount.metadata.code && ['ABRIL20', 'PLUS20'].includes(discount.metadata.code) ?
                            <Typography
                                align={'center'}
                                className={classes.rootBodyContentTitle}
                            >
                                {`${Locale.getString('supper_offer_text_1')}`}{`${Locale.getString('supper_offer_text_2')}`}{`${Locale.getString('supper_offer_text_3')}`}
                            </Typography> :
                            <Typography
                                align={'center'}
                                className={classes.rootBodyContentTitle}
                            >
                                {`${Locale.getString((freeTrial ? 'freetrial' : 'select_offer') + '_text_1')}`}<b>{`${Locale.getString((freeTrial ? 'freetrial' : 'select_offer') + '_text_2')}`}</b>{`${Locale.getString((freeTrial ? 'freetrial' : 'select_offer') + '_text_3')}`}<b>{`${Locale.getString((freeTrial ? 'freetrial' : 'select_offer') + '_text_4')}`}</b>
                            </Typography>) :
                        (!!discount && !!discount.metadata && !!discount.metadata.code && ['ABRIL20', 'PLUS20'].includes(discount.metadata.code) ?
                            <Typography
                                align={'center'}
                                className={classes.rootBodyContentTitle}
                            >
                                {`${Locale.getString('supper_offer_text_1')}`}{`${Locale.getString('supper_offer_text_2')}`}{`${Locale.getString('supper_offer_text_3')}`}
                            </Typography> :
                            <Typography
                                align={'center'}
                                className={classes.rootBodyContentTitle}
                            >
                                {`${Locale.getString((freeTrial ? 'freetrial' : 'select_offer') + '_text_1')}`}<b>{`${Locale.getString((freeTrial ? 'freetrial' : 'select_offer') + '_text_2')}`}</b>{`${Locale.getString((freeTrial ? 'freetrial' : 'select_offer') + '_text_3')}`}<b>{`${Locale.getString((freeTrial ? 'freetrial' : 'select_offer') + '_text_4')}`}</b>
                            </Typography>)
                    }

                    {!!discount && !!priceCards && !!priceCards.length && (!discount.hidden || (!!discount.metadata && !!discount.metadata.hint)) &&
                        <Typography
                            align={'center'}
                            color={'primary'}
                            className={classes.rootBodyContentSubtitle}
                        >
                            {!!discount ? (!discount.hidden && !!discount.metadata && !!discount.metadata.name ? ((!!discount.freeTrialToPeriod && !!Object.keys(discount.freeTrialToPeriod).some(key => discount.freeTrialToPeriod[key])) ? (`¡${discount.metadata.name} ${Locale.getString('annual_quote_freetrial_1')} ${discount.trialDays} ${Locale.getString('annual_quote_freetrial_2')}`) : (`¡${discount.metadata.name} ${Locale.getString('annual_quote_discount_1')} ${priceCards.filter(p => p.period === 12)[0].currencySymbolStart === true ? priceCards.filter(p => p.period === 12)[0].currencySymbol : ''}${(Number(priceCards.filter(p => p.period === 12)[0].originalPriceNumber).toFixed(2) - Number(priceCards.filter(p => p.period === 12)[0].originalPriceNumber * discount.discountToPeriod[12]).toFixed(2))}${priceCards.filter(p => p.period === 12)[0].currencySymbolStart === false ? priceCards.filter(p => p.period === 12)[0].currencySymbol : ''} ${Locale.getString('annual_quote_discount_2')}`)) : (!!discount.metadata && !!discount.metadata.hint ? Locale.getLocaledStringValue('hint', discount.metadata) : ``)) : ``}
                        </Typography>
                    }
                </Grid>
                <Grid
                    container
                    justify={"center"}
                    direction={"row"}>
                    {
                        !!priceCards && !!priceCards.length && priceCards.map((offer, index) => (
                            <PricingCardComponet
                                key={offer.period}
                                offer={offer}
                                selected={selectedPlan === offer.period}
                                onClick={i => {
                                    handlerGoToSubcribe(offer.period);
                                }}
                            />
                        ))
                    }
                    <Grid
                        container
                        direction="column"
                        alignItems="center"
                        justify="center"
                        className={classes.rootBodyContentNextButtonContainer}>
                        <Grid
                            justify={"center"}
                            item>
                            <Button
                                className={classes.rootBodyContentNextButton}
                                onClick={() => {
                                    handlerGoToSubcribe();
                                }}>
                                <Typography
                                    className={classes.rootBodyContentNextButtonText}>
                                    {(!!discount && !!discount.freeTrialToPeriod && !!Object.keys(discount.freeTrialToPeriod).some(key => discount.freeTrialToPeriod[key])) ? Locale.getString('start_free_trial') : Locale.getString('next')}
                                </Typography>
                            </Button>
                            <br/>
                            <br/>
                            {promo === 'O_IPHONE_13' && <Link
                                component="button"
                                onClick={() => {
                                    window.location.assign("/docs/bases_legales_sorteo_plus_iphone_13.pdf")
                                }}
                                className={classes.rootLinkText}
                            >{Locale.getString('terms_and_condition_contest')}</Link>}
                        </Grid>
                    </Grid>

                </Grid>
                <Grid
                    container
                    direction={"row-reverse"}
                    className={classes.rootBodyBannerContainer}
                >
                    <Grid
                        item
                        md={4}
                        xs={12}
                        className={classes.rootBodyBannerImage}>
                    </Grid>
                    <Grid
                        item
                        className={classes.rootBodyBanner}
                        md={8}
                        xs={12}>
                        <Grid
                            container
                            direction={"column"}
                            alignItems={"center"}>
                            <Typography
                                align={"center"}
                                className={classes.rootBodyBannerTitle}
                            >
                                {Locale.getString('eat_healthy_with_mrf')}
                            </Typography>
                            <Grid item className={classes.rootBodyBannerTextA}>
                                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(index =>
                                    <Typography
                                        className={classes.rootBodyBannerText}>
                                        &bull;
                                        <b>{`${Locale.getString(`offer_banner_text_${index}_1`)}`}</b>{`${Locale.getString(`offer_banner_text_${index}_2`)}`}<b>{`${Locale.getString(`offer_banner_text_${index}_3`)}`}</b>
                                    </Typography>
                                )}
                                <br/><br/>
                                <br/><br/>
                                <br/><br/>
                                <br/><br/>
                                <br/><br/>
                                <br/><br/>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    container
                    justify={"center"}
                    className={classes.rootReviewContainer}>
                    <Grid item>
                        <Typography
                            align={"center"}
                            className={classes.rootReviewTitle}>
                            {Locale.getString('happy_user_mrf')}
                        </Typography>
                    </Grid>
                    <Grid
                        justify={"center"}
                        container>
                        {
                            reviews.map((review) => (
                                <ReviewComponent
                                    review={review}/>
                            ))
                        }
                    </Grid>
                </Grid>
                <div className={classes.rootSeparator}/>
                <PlusFAQ
                    ref={_faqRef}/>
            </Grid>
            <SmallFooter/>
        </>
    )
}

export default OfferLanding;
