import React, {useState} from "react";
import style from "./style";

const NextButton = ({onNext, disabled}) => {
    const classes = style();

    return (
        <div
            className={disabled ? classes.rootDisabled : classes.root}
            onClick={() => {
                disabled || onNext();
            }}
        >
            Siguiente
        </div>
    )
}

export default NextButton;