import {makeStyles} from "@material-ui/core/styles";

export default makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		backgroundColor: 'white',
		paddingLeft: '7%',
		paddingRight: '7%',
		marginTop: '4%',
		[theme.breakpoints.up('md')]: {
			paddingLeft: '14%',
			paddingRight: '14%',
		},
	},
	rootSeparator: {
		height: 1,
		marginLeft: 'auto',
		marginRight: 'auto',
		backgroundColor: '#19C692',
		width: '40%',
		marginTop: '20%',
		marginBottom: '20%',
		[theme.breakpoints.up('md')]: {
			width: '16%',
			marginTop: '7%',
			marginBottom: '7%',
		},
	},
	rootSeparatorTransparent: {
		height: 1,
		marginLeft: 'auto',
		marginRight: 'auto',
		backgroundColor: '#FFF0',
		width: '40%',
		marginTop: '10%',
		marginBottom: '10%',
		[theme.breakpoints.up('md')]: {
			width: '16%',
			marginTop: '7%',
			marginBottom: '7%',
		},
	},
	rootBody: {},

	rootBodySectionFreeTrial: {
		height: '380px',
		marginBottom: '3%',
		[theme.breakpoints.up('sm')]: {
			height: '600px',
			marginBottom: '0%',
		},
		[theme.breakpoints.up('md')]: {
			height: '625px',
			marginBottom: '3%',
		},
	},
	rootBodySectionFreeTrialBackground: {
		position: 'absolute',
		width: '100%',
		zIndex: 0,
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		backgroundImage: `url("assets/backgrounds/bg_mobile_header.png")`,
		height: '370px',
		[theme.breakpoints.up('sm')]: {
			height: '520px',
		},
		[theme.breakpoints.up('md')]: {
			height: '605px',
			backgroundImage: `url("assets/backgrounds/bg_desktop_header.png")`,
		},
	},
	rootBodySectionFreeTrialLeft: {
		paddingRight: '1%',
		paddingLeft: '10%',
		paddingBottom: '10%'
	},
	rootBodySectionFreeTrialLeftTitle: {
		fontSize: 32,
		color: 'black',
		whiteSpace: "pre-wrap",
		marginTop: '35%',
		[theme.breakpoints.up('sm')]: {
			fontSize: 40,
			marginTop: '0%',
		},
		[theme.breakpoints.up('md')]: {
			fontSize: 45,
			marginTop: '23%',
		},
	},
	rootBodySectionFreeTrialLeftSubtitle: {
		fontSize: 18,
		color: 'black',
		whiteSpace: "pre-wrap",
		marginTop: '6%',
		[theme.breakpoints.up('md')]: {
			fontSize: 20,
		},
	},
	rootBodySectionFreeTrialLeftButtons: {
		flexGrow: 1,
		display: 'none',
		[theme.breakpoints.up('md')]: {
			display: 'flex',
		},
	},
	rootBodySectionFreeTrialRightDesktop: {
		paddingRight: '4%',
		width: '30%',
		display: 'none',
		[theme.breakpoints.up('md')]: {
			width: '50%',
			display: 'flex',
		},
	},
	rootBodySectionFreeTrialRightMobile: {
		display: 'flex',
		[theme.breakpoints.up('md')]: {
			display: 'none',
		},
	},
	rootBodySectionFreeTrialRightMobileButtons: {},
	rootBodySectionFreeTrialRightMobileButtonsSubscribe: {
		borderWidth: 1,
		borderColor: 'white',
		color: '#19C692',
		width: '95%',
		backgroundColor: 'white',
		borderRadius: 20,
		paddingTop: '3%',
		paddingBottom: '3%',
		paddingLeft: '4%',
		paddingRight: '4%',
		fontWeight: 'bold',
		marginTop: '15%',
		marginBottom: '15%',
		[theme.breakpoints.up('md')]: {
			paddingTop: '2%',
			paddingBottom: '2%',
			width: '60%',
		},
		'&:hover': {
			color: "#FFF",
		},
	},
	rootBodySectionFreeTrialRightDesktopImage: {
		objectFit: 'contain',
		width: '75%',
		marginLeft: '30%',
		marginTop: '-7%'
	},
	rootBodySectionFreeTrialRightMobileImage: {
		objectFit: 'contain',
		width: '100%',
	},

	rootBodySection1: {
		marginLeft: 0,
		marginRight: 0,

	},
	rootBodySection1Left: {
		paddingRight: '1%',
		paddingLeft: '3%',
		paddingTop: '20%',
		paddingBottom: '20%'
	},
	rootBodySection1LeftTitle: {
		marginBottom: '5%',
		fontSize: 26,
		marginTop: '5%',
		[theme.breakpoints.up('sm')]: {
			fontSize: 48,
			marginTop: '0%'
		},
		[theme.breakpoints.up('md')]: {
			fontSize: 48,
			marginTop: '0%'
		},
	},
	rootBodySection1LeftSubtitle: {
		marginBottom: '0%',
		fontSize: 18,
		[theme.breakpoints.up('md')]: {
			fontSize: 26,
			marginBottom: '18%',
		},
	},
	rootBodySection1LeftButtons: {
		flexGrow: 1,
		display: 'flex',
		marginTop: '6%',
		marginLeft: '-2%',
		[theme.breakpoints.up('md')]: {
			display: 'flex',
		},
	},
	rootBodySection1LeftButtonsLeft: {
		paddingRight: '4%',
		width: '100%',
		[theme.breakpoints.up('md')]: {
			width: '100%',
		},
	},
	rootBodySection1RightMobileButtonsLeft: {
		width: '70%',
	},
	rootBodySection1LeftButtonsLeftImage: {
		objectFit: 'contain',
		width: '100%'
	},
	rootBodySection1RightMobileButtonsLeftImage: {
		objectFit: 'contain',
		width: '100%'
	},
	rootBodySection1LeftButtonsRight: {
		paddingRight: '4%',
		width: '100%',
		[theme.breakpoints.up('md')]: {
			width: '100%',
		},
	},
	rootBodySection1LeftMobileButtonsRight: {
		width: '70%',
	},
	rootBodySection1LeftButtonsRightImage: {
		objectFit: 'contain',
		width: '100%'
	},
	rootBodySection1LeftMobileButtonsRightImage: {
		objectFit: 'contain',
		width: '100%'
	},
	rootBodySection1RightDesktop: {
		paddingRight: '4%',
		width: '30%',
		display: 'none',
		[theme.breakpoints.up('md')]: {
			width: '50%',
			display: 'flex',
		},
	},
	rootBodySection1RightMobile: {
		display: 'flex',
		[theme.breakpoints.up('md')]: {
			display: 'none',
		},
	},
	rootBodySection1RightButtons: {
		display: 'flex',
		[theme.breakpoints.up('md')]: {
			display: 'none',
		},
	},
	rootBodySection1RightMobileButtons: {
		marginTop: '-20%'
	},
	rootBodySection1RightDesktopImage: {
		objectFit: 'contain',
		width: '80%',
		marginLeft: '20%'
	},
	rootBodySection1RightMobileImage: {
		objectFit: 'contain',
		width: '100%',
	},

	rootBodySection2: {},
	rootBodySection2Header: {},
	rootBodySection2HeaderTitle: {
		marginBottom: '8%',
		textAlign: 'center',
		fontSize: 26,
		[theme.breakpoints.up('md')]: {
			fontSize: 48,
			marginBottom: '3%',
		},
	},
	rootBodySection2HeaderSubtitle: {
		marginBottom: '5%',
		textAlign: 'center',
		fontSize: 16,
		[theme.breakpoints.up('md')]: {
			fontSize: 26,
			fontWeight: 'bold',
		},
	},
	rootBodySection2Body: {},
	rootBodySection2BodyPaper: {
		paddingLeft: '1%',
		paddingRight: '1%',
		paddingTop: '1%',
		paddingBottom: '1%',
		marginBottom: '2%',
		[theme.breakpoints.up('md')]: {
			height: '50%',
			marginBottom: '0%',
		},
	},
	rootBodySection2BodyPaperContent: {
		backgroundColor: '#fff',
		borderRadius: 32,
		shadowColor: 'transparent',

		paddingBottom: '5%'
	},
	rootBodySection2BodyPaperImage: {
		objectFit: 'contain',
		width: '70%',
	},
	rootBodySection2BodyPaperTitle: {
		fontWeight: 'bold',
		marginBottom: '2%',
		textAlign: 'center',
		paddingLeft: '15%',
		paddingRight: '15%',
	},
	rootBodySection2BodyPaperDescription: {
		textAlign: 'center',
		paddingLeft: '22%',
		paddingRight: '22%',
	},

	rootBodySectionButtonMobile: {
		marginTop: '-2%',
		marginBottom: '8%',
		display: 'flex',
		[theme.breakpoints.up('md')]: {
			marginTop: '2%',
			display: 'none',
		},
	},
	rootBodySectionButtonDesktop: {
		width: '100%',
		marginTop: '5%',
		display: 'none',
		[theme.breakpoints.up('md')]: {
			display: 'flex',
		},
	},
	rootBodySectionButtonContent: {
		borderWidth: 1,
		borderRadius: 10,
		paddingTop: '3%',
		paddingBottom: '3%',
		paddingLeft: '4%',
		paddingRight: '4%',
		fontWeight: 'bold',
		[theme.breakpoints.up('md')]: {
			paddingTop: '1%',
			paddingBottom: '1%',
		},
		backgroundColor: '#19C692',
		color: 'white',
		'&:hover': {
			backgroundColor: '#0D7D5C',
		}
	},
	rootBodySectionButtonContentWhite: {
		borderWidth: 1,
		borderRadius: 10,
		paddingTop: '3%',
		paddingBottom: '3%',
		paddingLeft: '4%',
		paddingRight: '4%',
		fontWeight: 'bold',
		[theme.breakpoints.up('md')]: {
			paddingTop: '1%',
			paddingBottom: '1%',
		},
		backgroundColor: '#FFF',
		color: '#19C692',
		'&:hover': {
			backgroundColor: '#FFF',
		}
	},

	rootBodySectionConsults: {
		marginTop: '5%',
	},
	rootBodySectionConsultsHeader: {
		[theme.breakpoints.up('md')]: {
			paddingLeft: '20%',
			paddingRight: '20%',
		},
	},
	rootBodySectionConsultsHeaderTitle: {
		marginBottom: '3%',
		textAlign: 'center',
		fontSize: 26,
		[theme.breakpoints.up('md')]: {
			fontSize: 48,
			marginBottom: '1%',
		},
	},
	rootBodySectionConsultsHeaderSubtitle: {
		textAlign: 'center',
		fontSize: 16,
		paddingLeft: '10%',
		paddingRight: '10%',
		[theme.breakpoints.up('md')]: {
			fontSize: 23,
			paddingLeft: '0%',
			paddingRight: '0%',
		},
	},
	rootBodySectionConsultsBodyRightContentButtonDesktop: {
		borderWidth: 1,
		borderRadius: 10,
		paddingTop: '3%',
		paddingBottom: '3%',
		fontWeight: 'bold',
		width: '60%',
		marginTop: '3%',
		display: 'flex',
		[theme.breakpoints.up('md')]: {
			display: 'flex'
		},
		backgroundColor: '#19C692',
		color: 'white',
		'&:hover': {
			backgroundColor: '#0D7D5C',
		}
	},

	rootBodySection3: {},
	rootBodySection3Header: {
		[theme.breakpoints.up('md')]: {
			paddingLeft: '20%',
			paddingRight: '20%',
		},
	},
	rootBodySection3HeaderTitle: {
		marginBottom: '3%',
		textAlign: 'center',
		fontSize: 26,
		[theme.breakpoints.up('md')]: {
			fontSize: 48,
			marginBottom: '1%',
		},
	},
	rootBodySection3HeaderSubtitle: {
		textAlign: 'center',
		fontSize: 16,
		paddingLeft: '10%',
		paddingRight: '10%',
		[theme.breakpoints.up('md')]: {
			fontSize: 26,
			fontWeight: 'bold',
			paddingLeft: '0%',
			paddingRight: '0%',
		},
	},

	rootBodySection3Body: {
		marginLeft: 0,
		marginRight: 0,

	},
	rootBodySection3BodyLeft: {
		marginTop: '3%',
		display: 'none',
		[theme.breakpoints.up('md')]: {
			display: 'flex',
		},
	},
	rootBodySection3BodyLeftImage: {
		objectFit: 'contain',
		width: '95%'
	},
	rootBodySection3BodyRight: {
		height: '100%',
		paddingRight: '2%',
		paddingLeft: '4%',
		paddingTop: '8%',
		paddingBottom: '8%'
	},
	rootBodySection3BodyRightContent: {
		marginTop: '10%',
		[theme.breakpoints.up('md')]: {
			marginTop: '0%'
		},
	},
	rootBodySection3BodyRightContentImage: {
		objectFit: 'contain',
		width: '100%',
		[theme.breakpoints.up('sm')]: {
			width: '80%',
		},
		[theme.breakpoints.up('md')]: {
			width: '100%',
		},
	},
	rootBodySection3BodyRightContentText: {
		fontSize: 16,
		paddingLeft: '10%',
		paddingRight: '10%',
		[theme.breakpoints.up('sm')]: {
			fontSize: 18,
		},
		[theme.breakpoints.up('md')]: {
			paddingTop: '3%',
			paddingBottom: '3%',
			paddingLeft: '3%',
			paddingRight: '3%',
			fontSize: 20
		},
	},
	rootBodySection3BodyRightContentButtonMobile: {
		borderWidth: 1,
		borderRadius: 10,
		paddingTop: '3%',
		paddingBottom: '3%',
		fontWeight: 'bold',
		width: '80%',
		marginTop: '5%',
		display: 'flex',
		[theme.breakpoints.up('md')]: {
			display: 'none'
		},
		backgroundColor: '#19C692',
		color: 'white',
		'&:hover': {
			backgroundColor: '#0D7D5C',
		}
	},
	rootBodySection3BodyRightContentButtonDesktop: {
		borderWidth: 1,
		borderRadius: 10,
		paddingTop: '3%',
		paddingBottom: '3%',
		fontWeight: 'bold',
		width: '100%',
		marginTop: '3%',
		display: 'none',
		[theme.breakpoints.up('md')]: {
			display: 'flex'
		},
		backgroundColor: '#19C692',
		color: 'white',
		'&:hover': {
			backgroundColor: '#0D7D5C',
		}
	},

	rootBodySection4: {
		borderRadius: 20,
		marginTop: '0%',
		backgroundColor: '#f2f7f0',
		[theme.breakpoints.up('md')]: {
			marginTop: '14%',
		},
	},
	rootBodySection4HeaderLeft: {
		paddingLeft: '10%',
	},
	rootBodySection4HeaderLeftTitle: {
		paddingTop: '10%',
		fontSize: 26,
		marginBottom: '2%',
		[theme.breakpoints.up('md')]: {
			paddingTop: '10%',
			fontSize: 48,
			marginBottom: '0%',
		},
	},
	rootBodySection4HeaderLeftSubtitle: {
		fontSize: 16,
		paddingRight: '5%',
		marginBottom: '3%',
		[theme.breakpoints.up('md')]: {
			fontSize: 26,
			fontWeight: 'bold',
			paddingLeft: '0%',
			paddingRight: '0%',
			marginBottom: '0%',
		},
	},
	rootBodySection4HeaderRight: {
		paddingRight: '10%',
		paddingTop: '5%',
		display: 'none',
		[theme.breakpoints.up('md')]: {
			display: 'flex',
		},
	},
	rootBodySection4HeaderRightTitle: {},
	rootBodySection4HeaderRightSubtitle: {
		fontWeight: 'bold',
	},
	rootBodySection4HeaderRightSOContent: {
		marginBottom: '6%'
	},
	rootBodySection4HeaderRightSOContentLeft: {},
	rootBodySection4HeaderRightSOContentLeftLogo: {
		width: '90%',
	},
	rootBodySection4HeaderRightSOContentRight: {},
	rootBodySection4HeaderRightSOContentRightStars: {
		marginTop: '4%',
		marginLeft: '4%'
	},
	rootBodySection4HeaderRightSOContentRightStarsImage: {
		objectFit: 'contain',
		paddingLeft: '2%',
		paddingRight: '2%',
		width: '20%'
	},
	rootBodySection4HeaderRightSOContentRightRank: {
		marginTop: '4%'
	},
	rootBodySection4HeaderRightSOContentRightRankText: {},
	rootBodySection4Reviews: {
		marginTop: '2%',
	},
	rootBodySection4ReviewsLeft: {
		paddingRight: '2%',
		display: 'none',
		[theme.breakpoints.up('md')]: {
			display: 'flex',
		},
	},
	rootBodySection4ReviewsLeftIcon: {},
	rootBodySection4ReviewsCenter: {},
	rootBodySection4ReviewsCenterImage: {
		objectFit: 'contain',
		height: 'auto',
		width: '80%',
		[theme.breakpoints.up('md')]: {
			width: '100%',
		},
	},
	rootBodySection4ReviewsRight: {
		paddingLeft: '2%',
		display: 'none',
		[theme.breakpoints.up('md')]: {
			display: 'flex',
		},
	},
	rootBodySection4ReviewsRightIcon: {},
	rootBodySection4ReviewsBottom: {
		width: '100%',
		marginTop: '6%',
		marginBottom: '3%'
	},
	rootBodySection4ReviewsBottomButton: {
		borderWidth: 1,
		borderRadius: 10,
		paddingTop: '2%',
		paddingBottom: '2%',
		paddingLeft: '10%',
		paddingRight: '10%',
		fontWeight: 'bold',
		marginBottom: '3%',
		[theme.breakpoints.up('md')]: {
			marginBottom: '0%',
			paddingTop: '1.5%',
			paddingBottom: '1.5%',
		},
		backgroundColor: '#19C692',
		color: 'white',
		'&:hover': {
			backgroundColor: '#0D7D5C',
		}
	},
	rootBodyPeople: {
		marginTop: '3%',
		width: '100%',
		borderRadius: 20,
		paddingTop: '5%',
		paddingBottom: '5%',
	},
	rootBodyPeopleContent: {
		minHeight: 300,
		[theme.breakpoints.up('md')]: {
			minHeight: 280,
		},
	},
	rootBodyPeopleContentLeft: {
		width: '100%',
	},
	rootBodyPeopleContentLeftImage: {
		objectFit: 'contain',
		height: '20%',
		width: '40%',
		[theme.breakpoints.up('md')]: {
			height: '100%',
			width: 'auto',
		},
	},
	rootBodyPeopleContentMiddle: {},
	rootBodyPeopleContentRight: {
		width: '100%',
	},
	rootBodyPeopleContentRightImage: {
		objectFit: 'contain',
		height: '20%',
		width: '40%',
		[theme.breakpoints.up('md')]: {
			height: '100%',
			width: 'auto',
		},
	},
	rootBodyPeopleContentMiddleContent: {
		width: '100%'
	},
	rootBodyPeopleContentMiddleContentAvatar: {
		paddingRight: '2%'
	},
	rootBodyPeopleContentMiddleContentAvatarImage: {
		objectFit: 'contain',
		width: '30%',
		height: 'auto'
	},
	rootBodyPeopleContentMiddleContentRealfooder: {},
	rootBodyPeopleContentMiddleContentRealfooderDisplayName: {
		fontWeight: 'bold',
		fontSize: 18,
		[theme.breakpoints.up('md')]: {
			fontSize: 20,
		},
	},
	rootBodyPeopleContentMiddleContentRealfooderNickname: {
		fontSize: 18
	},
	rootBodyPeopleContentMiddleContentRealfooderComment: {
		marginTop: '2%',
		width: '100%',
		textAlign: 'center',
		fontSize: 16,
		paddingRight: '8%',
		paddingLeft: '8%',
		paddingBottom: '2%',
		[theme.breakpoints.up('md')]: {
			fontSize: 22,
			paddingRight: '20%',
			paddingLeft: '20%',
		},
	},

	rootBodySection5: {
		marginBottom: '6%'
	},
	rootBodySection5Header: {
		[theme.breakpoints.up('md')]: {
			paddingLeft: '25%',
			paddingRight: '25%',
		},
	},
	rootBodySection5HeaderTitle: {
		fontSize: 26,
		textAlign: 'center',
		marginBottom: '3%',
		[theme.breakpoints.up('md')]: {
			textAlign: 'center',
			fontSize: 48,
		},
	},
	rootBodySection5Body: {
		marginLeft: 0,
		marginRight: 0,
		[theme.breakpoints.up('md')]: {
			marginTop: '0%',
		},
	},
	rootBodySection5BodyLeft: {
		marginTop: '0%',
		[theme.breakpoints.up('md')]: {
			marginTop: '3%',
		},
	},
	rootBodySection5BodyLeftImage: {
		objectFit: 'contain',
		width: '95%',
		marginTop: '-14%'
	},
	rootBodySection5BodyRight: {
		height: '100%',
		paddingBottom: '8%',
		[theme.breakpoints.up('md')]: {
			paddingTop: '3%',
			paddingRight: '5%',
			paddingLeft: '5%',
		},
	},
	rootBodySection5BodyRightContentText: {
		fontSize: 16,
		padding: '5%',
		marginTop: '10%',
		whiteSpace: "pre-wrap",
		[theme.breakpoints.up('md')]: {
			padding: '0%',
			fontWeight: 'bold',
			fontSize: 26,
			marginTop: '0%',
		},
	},
	rootBodySection5BodyRightContentButton: {
		borderWidth: 1,
		borderColor: '#19C692',
		color: '#19C692',
		borderRadius: 10,
		paddingTop: '4%',
		paddingBottom: '4%',
		fontWeight: 'bold',
		width: '100%',
		marginTop: '10%',
		display: 'none'
	},

	rootBodyBottom: {
		paddingTop: '1%',
		paddingBottom: '1%',
		backgroundColor: '#f2f7f0',
		display: 'none',
		[theme.breakpoints.up('md')]: {
			display: 'flex',
		},
	},
	rootBodyBottomGrow: {
		flexGrow: 1
	},
	rootBodyBottomTextLeft: {
		textAlign: 'right'
	},
	rootBodyBottomTextRight: {
		textAlign: 'left'
	},
	rootBodyBottomButton: {
		borderWidth: 1,
		borderRadius: 10,
		paddingTop: '2%',
		paddingBottom: '2%',
		paddingLeft: '4%',
		paddingRight: '4%',
		fontWeight: 'bold',
		backgroundColor: '#19C692',
		color: 'white',
		'&:hover': {
			backgroundColor: '#0D7D5C',
		}
	},
	rootBodyBottomButtonDownload: {},
	rootBodyBottomButtonDownloadImage: {
		objectFit: 'contain',
		width: '100%'
	},
}), {index: 1});
