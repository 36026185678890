import React from 'react';

import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions'

import style from './style';
import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography";
import Slider from '@material-ui/core/Slider';

export default function CardImageSlider(props) {
	const classes = style();

	return (
		<Grid
			container
			md={6}
			alignItems={"center"}
			justify={"center"}
		>
			<Grid
				container
				md={12}
				direction="column"
				alignItems={"center"}
				justify={"center"}
			>
				<img
					className={classes.rootBodyCardOptionImage}
					src={props.image}
					alt={"MyRealFood"}
				/>

				<Grid
					className={classes.rootBodyCardOptionSlider}
				>
					<Slider
						defaultValue={props.value}
						value={props.value}
						aria-labelledby="discrete-slider"
						valueLabelDisplay={props.valueLabelDisplay}
						valueLabelFormat={props.hint}
						step={props.step}
						marks
						min={props.min}
						max={props.max}
						onChange={props.onChange}
					/>
				</Grid>
				<Typography
					className={classes.rootBodyCardOptionTitle}
				>
					{props.title(props.value)}
				</Typography>
				<Typography
					className={classes.rootBodyCardOptionSubitle}
				>
					{props.subtitle(props.value)}
				</Typography>
			</Grid>
		</Grid>
	);
}


