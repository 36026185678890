import {makeStyles} from "@material-ui/core/styles";

export default makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		backgroundColor: 'white',
		paddingLeft: '14%',
		paddingRight: '14%',
	},
	rootSeparator: {
		height: 1,
		width: '16%',
		marginLeft: '42%',
		marginRight: '42%',
		marginTop: '10%',
		marginBottom: '7%',
		backgroundColor: '#19C692',
	},
	rootBody: {},
}), { index: 1 });
