import {makeStyles} from "@material-ui/core/styles";

export default makeStyles((theme) => ({
	rootBodyCardOption: {
		width: '100%',
	},
	rootBodyCardOptionImage: {
		objectFit: 'contain',
		paddingTop: '5%',
		paddingBottom: '10%',
		paddingRight: '0%',
		paddingLeft: '0%',
		width: '30%',
		[theme.breakpoints.up('md')]: {
			width: '80%',
			paddingRight: '20%',
			paddingLeft: '20%',
		}
	},
	rootBodyCardOptionSlider: {
		width: '90%',
		[theme.breakpoints.up('md')]: {
			width: '100%',
		}
	},
	rootBodyCardOptionTitle: {
		marginTop: '5%',
		textAlign: 'center',
		fontWeight: 'bold',
		fontSize: 22
	},
	rootBodyCardOptionSubitle: {
		textAlign: 'center',
		fontWeight: 'normal',
		paddingBottom: '10%',
		fontSize: 15,
		color: "#727272",
		whiteSpace: "pre-wrap"
	}
}), { index: 1 });
