import React, {useEffect, useState} from "react";
import style from "./style";
import {useHistory} from 'react-router-dom';
import Checkbox from "../../checkbox";
import NextButton from "../../nextButton";

const ObjectiveOptions = [
    {
        title: 'Verme mejor físicamente',
        id: 'look_better',
    },
    {
        title: 'Estar más saludable',
        id: 'healthier',
    },
    {
        title: 'Sentirme mejor',
        id: 'feel_better',
    },
    {
        title: 'Tener más energía',
        id: 'more_energy',
    },
    {
        title: "Otro",
        id: 'other',
    }
]


const Why = ({onNext}) => {
    const classes = style();
    const history = useHistory();

    const [selected, setSelected] = useState([]);

    useEffect(() => {
        const selected = localStorage.getItem('premium.basic.why');
        if(selected){
            setSelected(JSON.parse(selected));
        }
    }, [])

    return (
        <div
            className={classes.root}
        >
            {
                ObjectiveOptions.map((option, index) => (
                    <div
                        onClick={() => {
                            if(selected.includes(option.id)){
                                setSelected(selected.filter((id) => id !== option.id))
                            }else{
                                setSelected([...selected, option.id])
                            }
                        }}
                        key={index}
                        className={classes.option}
                    >
                        {
                            option.title
                        }
                        <Checkbox
                            check={selected.includes(option.id)}
                        />
                    </div>))
            }
            <NextButton
                disabled={selected.length === 0}
                onNext={() => {
                    localStorage.setItem('premium.basic.why', JSON.stringify(selected))
                    onNext();
                }}
            />
        </div>
    )
}

export default Why;
