import React from "react";
import style from "./style";
import {useHistory} from 'react-router-dom';

const Question = ({children, title, onNext, onBack}) => {
    const classes = style();
    const history = useHistory();

    return(
        <div
            className={classes.root}
        >
            <div
                className={classes.container}
            >
                <div
                    className={classes.header}
                >
                    <img
                        className={classes.headerIcon}
                        width={35}
                        height={35}
                        src={'/assets/arrow-left.png'}
                        alt={'MyRealFood'}
                        onClick={() => {
                          onBack && onBack();
                        }}
                    />
                    <div
                        className={classes.headerTitle}
                    >
                        {title}
                    </div>
                    <div></div>
                </div>
                {children}
            </div>
        </div>)
}

export default Question;
