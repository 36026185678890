import {makeStyles} from "@material-ui/core/styles";

export default makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		backgroundColor: 'white',
		marginTop: '4%',
		[theme.breakpoints.up('md')]: {
			paddingLeft: '14%',
			paddingRight: '14%',
		},
	},
	rootSeparator: {
		height: 1,
		width: '100%',
		marginTop: '0%',
		marginBottom: '3%',
		backgroundColor: '#e6e6e6',
	},
	rootBody: {},
	rootBodyGrow: {
		flexGrow: 1
	},
	rootBodyLogo: {
		marginTop: '0%',
		marginBottom: '3%'
	},
	rootBodyLogoImage: {
		objectFit: 'contain',
		marginTop: '5%',
		marginBottom: '3%',
		width: '60%',
		[theme.breakpoints.up('md')]: {
			marginTop: '0%',
			marginBottom: '0%',
			width: '100%',
		},
	},
	rootBodyContent: {
		paddingLeft: '10%',
		paddingRight: '10%',
		[theme.breakpoints.up('md')]: {
			paddingLeft: '30%',
			paddingRight: '30%',
		},
	},
	rootBodyContentTitle: {
		marginBottom: '12%'
	},
	rootBodyContentSubtitle: {
		fontWeight: 'bold',
		fontSize: 30,
		marginBottom: '6%',
	},
	rootBodyContentSignInWithApple: {
		width: '100%',
		backgroundColor: '#000',
		paddingTop: '2%',
		paddingBottom: '2%',
		borderRadius: 10,
		marginTop: '2%',
		marginBottom: '2%',
		'&:hover': {
			backgroundColor: '#000',
		}
	},
	rootBodyContentSignInWithAppleLogo: {
		width: '5%',
		marginRight: '3%'
	},
	rootBodyContentSignInWithAppleText: {
		color: 'white',
		fontWeight: 'bold',
	},
	rootBodyContentSignInWithGoogle: {
		width: '100%',
		backgroundColor: '#dc4e41',
		paddingTop: '2%',
		paddingBottom: '2%',
		borderRadius: 10,
		marginTop: '2%',
		marginBottom: '2%',
		'&:hover': {
			backgroundColor: '#aa302d',
		}
	},
	rootBodyContentSignInServerError: {
		fontWeight: 'normal',
		fontSize: 12,
		letterSpacing: 0,
		lineHeight: 1.3,
		color: '#ff0000',
		marginTop: '0%',
		marginBottom: '4%',
		textAlign: 'center'
	},
	rootBodyContentSignInWithGoogleLogo: {
		width: '5%',
		marginRight: '3%'
	},
	rootBodyContentSignInWithGoogleText: {
		color: 'white',
		fontWeight: 'bold',
	},
	rootBodyContentSeparator: {
		height: 2,
		width: '100%',
		backgroundColor: '#e6e6e6',
		marginTop: '5%',
		marginBottom: '5%'
	},
	rootBodyContentForm: {
		width: '100%',
		paddingBottom: '5%'
	},
	rootBodyContentSignIn: {},
	rootBodyContentSignInButton: {
		width: '50%',
		height: '70%',
		backgroundColor: '#19C692',
		paddingTop: '2%',
		paddingBottom: '2%',
		borderRadius: 10,
		marginTop: '2%',
		marginBottom: '2%',
		'&:hover': {
			backgroundColor: '#0D7D5C',
		}
	},
	rootBodyContentSignInButtonText: {
		color: 'white',
		fontWeight: 'bold',
		fontSize: 12,
		[theme.breakpoints.up('md')]: {
			fontSize: 14,
		},
	},
	rootBodyContentSignInButtonSpinner: {
		width: 1,
		height: 1
	},
	rootBodyContentRetrievePassword: {
		color: '#19C692',
		letterSpacing: 0.8,
		marginTop: '20%',
		marginBottom: '10%'
	},
	rootBodyContentGreenSeparator: {
		height: 1,
		width: '100%',
		backgroundColor: '#19C692',
		marginTop: '8%',
		marginBottom: '8%'
	},
	rootBodyContentRegister: {},
	rootBodyContentRegisterTitle: {
		fontWeight: 'bold',
		fontSize: 30,
		marginBottom: '6%',
	},
	rootBodyContentRegisterButton: {
		width: '100%',
		borderWidth: 1,
		borderColor: '#19C692',
		paddingTop: '2%',
		paddingBottom: '2%',
		borderRadius: 10,
		marginBottom: '50%'
	},
	rootBodyContentRegisterButtonText: {
		fontWeight: 'bold',
		color: '#19C692',
	},
	paper: {
		position: 'absolute',
		backgroundColor: theme.palette.background.paper,
		border: '1px solid #000',
		boxShadow: theme.shadows[5],
		top: `50%`,
		left: `50%`,
		transform: `translate(-50%, -50%)`,
		width: '90%',
		padding: '5%',
		[theme.breakpoints.up('sm')]: {
			width: '70%',
			padding: '3%',
		},
		[theme.breakpoints.up('md')]: {
			width: '30%',
			padding: '3%',
		},
	},
	paperTitle: {
		fontWeight: 'bold',
		fontSize: 16,
		marginBottom: '1%',
		[theme.breakpoints.up('md')]: {
			fontSize: 22,
		},
	},
	paperDescription: {
		fontWeight: 'normal',
		fontSize: 14,
		paddingBottom: '2%',
		[theme.breakpoints.up('md')]: {
			fontSize: 16,
		},
	},
	paperSeparator: {
		height: '3%'
	},
	paperButton: {
		backgroundColor: '#19C692',
		paddingTop: '2%',
		paddingBottom: '2%',
		paddingLeft: '3%',
		paddingRight: '3%',
		borderRadius: 10,
		marginTop: '2%',
		marginBottom: '4%'
	},
	paperButtonText: {
		color: 'white',
		fontWeight: 'bold',
		fontSize: 12,
		[theme.breakpoints.up('md')]: {
			fontSize: 14,
		},
	}
}), {index: 1});
