import { WeekKey, WeekPlan, WeekPlanPrimitives } from "@myrealfood-sl/domain";

import { dayPlanTranslator } from "./DayPlanTranslator";
import { FullMenu, MenuDay } from "./FullDocumentMenu";

export class WeekPlanTranslator {
  private static instance: WeekPlanTranslator;

  private constructor() {
    // Empty
  }

  public static getInstance(): WeekPlanTranslator {
    if (!WeekPlanTranslator.instance) {
      WeekPlanTranslator.instance = new WeekPlanTranslator();
    }

    return WeekPlanTranslator.instance;
  }

  public fromMenuDayDocumentToPrimitives(fullMenu: FullMenu, weekKey: WeekKey): WeekPlanPrimitives {
    return {
      week: weekKey.toString(),
      dayPlans: fullMenu.map((doc) =>
        dayPlanTranslator.fromMenuDayDocumentToPrimitives(doc, weekKey)
      )
    };
  }

  public fromPrimitivesToMenuDayBaseDocument(weekPlan: WeekPlan): MenuDay[] {
    return weekPlan
      .getDayPlan()
      .map((dayPlan) => dayPlanTranslator.fromPrimitivesToMenuDayDocument(dayPlan));
  }
}

export const weekPlanTranslator = WeekPlanTranslator.getInstance();
