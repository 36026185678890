import * as config from "../config";
import * as firebase from "firebase";
import {
    __DEFAULT_PROMOCODE__,
    __DEV__,
    CART_FIXED_VALUES,
    DEFAULT_PREMIUM_BASIC_OPTIONS,
    NOT_ADJUST_CATEGORIES
} from "./Exports";
import moment from "moment";
import Locale from "./locale/index";
import RemoteConfig from "./RemoteConfig";
import { ConfirmationNumber } from "@material-ui/icons";

const SUBSCRIPTION_STATES = {
    CREATED: "CREATED",
    ACTIVE: "ACTIVE",
    CANCELED: "CANCELED",
    PENDING: "PENDING",
    INACTIVE: "INACTIVE"
};

const STATES_WITH_ACCESS_TO_PREMIUM = [
    SUBSCRIPTION_STATES.CREATED,
    SUBSCRIPTION_STATES.ACTIVE,
    SUBSCRIPTION_STATES.PENDING,
    SUBSCRIPTION_STATES.CANCELED
];

class DataService {
    constructor() {
        const CONFIG = config.FIREBASE_CONFIG;
        CONFIG.authDomain = "myrealfood.app";
        this.firebase = firebase.initializeApp(config.FIREBASE_CONFIG);
        this.firestore = this.firebase.firestore();
        this.functions = this.firebase.functions();

        this.subscribers = [];

        this.user = [];
        this.isPlus = false;
        this.purchase = undefined;
        this.canHandleSubscriptionFromWeb = false;

        this.userElegibleForFreeTrial = true;

        this.checkPromocode();
    }

    async checkPromocode() {
        RemoteConfig.onAppLaunched()
            .then(() => {
                const data = RemoteConfig.getString("plus_free_trial");
                try {
                    const plus_free_trial = JSON.parse(RemoteConfig.getString("plus_free_trial"));
                    if (!!plus_free_trial && !!plus_free_trial["countries"]) {
                        const country = this.retrieveUserCountry();
                        if (
                            !!country &&
                            !!plus_free_trial["countries"][country] &&
                            !!plus_free_trial["countries"][country]["enabled"]
                        ) {
                            __DEFAULT_PROMOCODE__[0] = plus_free_trial["countries"][country]["promocode"];
                        } else {
                            if (
                                !!plus_free_trial["countries"]["_"] &&
                                !!plus_free_trial["countries"]["_"]["enabled"]
                            ) {
                                __DEFAULT_PROMOCODE__[0] = plus_free_trial["countries"]["_"]["promocode"];
                            }
                        }
                        console.log(plus_free_trial, __DEFAULT_PROMOCODE__);
                    }
                } catch (error) {}
            })
            .catch((error) => {});
    }

    async storeUserCampaign(info) {
        try {
            const response = await fetch("https://geolocation-db.com/json/");
            const data = await response.json();
            if (!!data && !!data.IPv4) {
                info.ipv4 = data.IPv4;
                info.date = firebase.firestore.FieldValue.serverTimestamp();
                await firebase.firestore().collection("thread").doc(data.IPv4).set(info);
            }
        } catch (error) {
            __DEV__ && console.log(`[storeUserCampaign] Error ${error}`);
        }
    }

    isPlusUser() {
        return (
            this.isPlus ||
            (!!this.user && !!this.user["private"] && !!this.user["private"]["canBypassPlusSecurity"])
        );
    }

    isProUser() {
        return (
            !!this.user["private"] &&
            !!this.user["private"]["premium"] &&
            !!this.user["private"]["premium"]["pro"] &&
            !!this.user["private"]["premium"]["pro"]["active"]
        );
    }

    plusData() {
        return this.purchase;
    }

    canHandleSubscription() {
        return !!this.canHandleSubscriptionFromWeb;
    }

    log(data, error, auth = undefined) {
        try {
            __DEV__ && console.log(data, error, auth);
            firebase
                .firestore()
                .collection("stripe")
                .doc("public")
                .collection("logs")
                .add({
                    date: firebase.firestore.FieldValue.serverTimestamp(),
                    uid:
                        !!firebase.auth().currentUser && !!firebase.auth().currentUser.uid
                            ? firebase.auth().currentUser.uid
                            : "",
                    email:
                        !!firebase.auth().currentUser && !!firebase.auth().currentUser.email
                            ? firebase.auth().currentUser.email
                            : "",
                    data: Object.assign({}, data),
                    error: Object.assign({}, error)
                })
                .catch((error) => {
                    __DEV__ && console.log(error);
                });
        } catch (e) {
            __DEV__ && console.log(e);
        }
    }

    isUserElegibleForFreeTrial() {
        return this.userElegibleForFreeTrial;
    }

    retrunAvailablePromocode(promo) {
        if (!!promo.includes("FREETRIAL") && !this.isUserElegibleForFreeTrial()) {
            return "NONE";
        }
        return promo;
    }

    checkPlus(onSucceed, onError) {
        !!this.stripeSnapshot && this.stripeSnapshot();
        !!this.subscriptionSnapshot && this.subscriptionSnapshot();
        this.stripeSnapshot = firebase
            .firestore()
            .collection("stripe")
            .doc("private")
            .collection("customers")
            .doc(firebase.auth().currentUser.uid)
            .onSnapshot(
                (doc) => {
                    this.canHandleSubscriptionFromWeb = !!doc.exists;
                },
                (error) => {
                    __DEV__ && console.log(error);
                    this.canHandleSubscriptionFromWeb = false;
                }
            );

        this.subscriptionSnapshot = firebase
            .firestore()
            .collection("subscriptions")
            .doc(firebase.auth().currentUser.uid)
            .onSnapshot(
                (doc) => {
                    if (!!doc && doc.exists) {
                        this.userElegibleForFreeTrial = false;
                        const subscription = doc.data();
                        this.storedSubscription = subscription;
                        const status = subscription.status;
                        const provider = subscription.provider;
                        const from = subscription.dateSubscriptionCurrentPeriodFrom;
                        const to = subscription.dateSubscriptionCurrentPeriodTo;
                        const activeSubscription = STATES_WITH_ACCESS_TO_PREMIUM.includes(status);

                        this.isPlus = activeSubscription;
                        !!onSucceed && onSucceed(this.isPlus);
                    } else {
                        this.userElegibleForFreeTrial = true;
                        this.isPlus = false;
                        !!onSucceed && onSucceed(this.isPlus);
                    }
                },
                (error) => {
                    __DEV__ && console.log(error);
                    this.isPlus = false;
                    !!onError && onError(error);
                }
            );
    }

    createPresentCode(email, months, provider, purchase, locale, onSucceed, onError) {
        firebase
            .firestore()
            .collection("presents")
            .add({
                creation_date: firebase.firestore.FieldValue.serverTimestamp(),
                email: email,
                months: months,
                redeemed: false,
                sended: false,
                provider: provider,
                purchase: purchase,
                locale: locale
            })
            .then((doc) => {
                !!onSucceed && onSucceed(doc.id);
            })
            .catch((error) => {
                !!onError && onError(error);
            });
    }

    retrievePremiumBasicOptions(country) {
        return new Promise(async (resolve, reject) => {
            if (!!country) {
                firebase
                    .firestore()
                    .collection("countries")
                    .doc(country)
                    .get()
                    .then(async (doc) => {
                        if (doc.exists) {
                            const data = doc.data();
                            const options =
                                !!data &&
                                !!data.config &&
                                !!data.config.premium &&
                                !!data.config.premium.basic &&
                                !!data.config.premium.basic.options
                                    ? data.config.premium.basic.options
                                    : null;
                            if (!!options) {
                                resolve(options);
                            } else {
                                __DEV__ &&
                                console.error(
                                    `[retrievePremiumBasicOptions] Country "${country}" does not have options`
                                );
                                resolve(DEFAULT_PREMIUM_BASIC_OPTIONS(country));
                            }
                        } else {
                            __DEV__ &&
                            console.error(`[retrievePremiumBasicOptions] Country "${country}" does not exist`);
                            resolve(DEFAULT_PREMIUM_BASIC_OPTIONS(country));
                        }
                    })
                    .catch(async (error) => {
                        __DEV__ &&
                        console.error(
                            `[retrievePremiumBasicOptions] Error retrieving premium basic options: ${error}`
                        );
                        resolve(DEFAULT_PREMIUM_BASIC_OPTIONS(country));
                    });
            } else {
                __DEV__ && console.error(`[retrievePremiumBasicOptions] No valid country ${country}`);
                resolve(DEFAULT_PREMIUM_BASIC_OPTIONS(country));
            }
        });
    }

    retrievePromoCode(code, present, onSucceed, onError) {
        firebase
            .firestore()
            .collection("promocodes")
            .doc("NONE".toLocaleUpperCase())
            .get()
            .then((nonePromocode) => {
                if (nonePromocode.exists) {
                    let none = nonePromocode.data();
                    none.id = nonePromocode.id;

                    if (this.retrunAvailablePromocode(code) === code) {
                        firebase
                            .firestore()
                            .collection("promocodes")
                            .doc((!!code && !!code.length ? code : "NONE").toLocaleUpperCase())
                            .get()
                            .then((promocode) => {
                                if (promocode.exists) {
                                    let tmp = promocode.data();
                                    tmp.id = promocode.id;
                                    if (!!code && !!code.length && !!promocode.data().enabled) {
                                        !!onSucceed &&
                                        onSucceed(tmp, !!code && !!code.length && !!promocode.data().enabled);
                                    } else {
                                        !!onSucceed && onSucceed(none, false);
                                    }
                                } else {
                                    !!onSucceed && onSucceed(none, false);
                                }
                            })
                            .catch((error) => {
                                !!onSucceed && onSucceed(none, false);
                            });
                    } else {
                        !!onSucceed && onSucceed(none, false);
                    }
                } else {
                    !!onError && onError("", {});
                }
            })
            .catch((error) => {
                !!onError && onError(error, {});
            });
    }

    handleUserChanges(history, onSucceed, onError) {
        firebase.auth().onAuthStateChanged(
            async (user) => {
                const userLanguage = await this.retrieveUserLanguage();
                await Locale.init(userLanguage);
                this.handleUserDataCompletion(
                    () => {
                        this.retrieveUserPublicData(() => {
                            this.retrieveUserPrivateData(() => {
                                this.checkPlus(
                                    (isPlus) => {
                                        if (isPlus) {
                                            this.retrieveUserSubscriptionPanel(
                                                (response) => {
                                                    response
                                                        .json()
                                                        .then((data) => {
                                                            if (user["private"] === undefined) {
                                                                user["private"] = [];
                                                            }
                                                            user["private"]["portalURL"] = data.url;
                                                            !!onSucceed && onSucceed(user);
                                                        })
                                                        .catch((error) => {
                                                            __DEV__ && console.log(error);
                                                        });
                                                },
                                                (error) => {
                                                    __DEV__ && console.log(error);
                                                }
                                            );
                                        }
                                        !!onSucceed && onSucceed(user);
                                    },
                                    (error) => {
                                        !!onError && onError(error);
                                    }
                                );
                            });
                        });
                    },
                    (error) => {
                        !!onError && onError(error);
                        //history.push('/register/complete/')
                    }
                );
            },
            (error) => {
                !!onError && onError(error);
            }
        );
    }

    handleSignInProviderChanges(onSucceed, onError) {
        firebase
            .auth()
            .getRedirectResult()
            .then(function (result) {
                if (result.credential) {
                    var accessToken = result.credential.accessToken;
                    var idToken = result.credential.idToken;
                    !!onSucceed && onSucceed(result.user);
                }
            })
            .catch(function (error) {
                var errorCode = error.code;
                var errorMessage = error.message;
                var email = error.email;
                var credential = error.credential;
                !!onError && onError(error);
            });
    }

    handleUserDataCompletion(onSucceed, onError) {
        if (!!firebase.auth().currentUser && !!firebase.auth().currentUser.uid) {
            firebase
                .firestore()
                .collection("users")
                .doc(firebase.auth().currentUser.uid)
                .get()
                .then((user) => {
                    if (!!user.exists) {
                        !!onSucceed && onSucceed();
                    } else {
                        !!onError && onError();
                    }
                })
                .catch((error) => {
                    !!onError && onError(error);
                });
        }
    }

    retrievePlusRecipe(rid, onSucceed, onError) {
        firebase
            .firestore()
            .collection("premium")
            .doc("basic")
            .collection("recipes")
            .doc(rid)
            .get()
            .then((recipe) => {
                if (recipe.exists) {
                    let tmp = recipe.data();
                    tmp.id = recipe.id;
                    !!onSucceed && onSucceed(tmp);
                } else {
                    !!onError && onError();
                }
            })
            .catch((error) => {
                !!onError && onError(error);
            });
    }

    retrieveFreeRecipe(rid, onSucceed, onError) {
        firebase
            .firestore()
            .collection("recipes")
            .doc(rid)
            .get()
            .then((recipe) => {
                if (recipe.exists) {
                    let tmp = recipe.data();
                    tmp.id = recipe.id;
                    !!onSucceed && onSucceed(tmp);
                } else {
                    !!onError && onError();
                }
            })
            .catch((error) => {
                !!onError && onError(error);
            });
    }

    retrieveUserPublicData(onSucceed, onError) {
        !!this.userPublicSnapshot && this.userPublicSnapshot();
        if (!!firebase.auth().currentUser && !!firebase.auth().currentUser.uid) {
            this.userPublicSnapshot = firebase
                .firestore()
                .collection("users")
                .doc(firebase.auth().currentUser.uid)
                .onSnapshot(
                    (data) => {
                        if (data.exists) {
                            this.user["public"] = data.data();
                            !!onSucceed && onSucceed();
                        }
                    },
                    (error) => {
                        __DEV__ && console.log(error);
                        !!onError && onError(error);
                    }
                );
        }
    }

    retrieveUserPrivateData(onSucceed, onError) {
        !!this.userPrivateSnapshot && this.userPrivateSnapshot();
        if (!!firebase.auth().currentUser && !!firebase.auth().currentUser.uid) {
            this.userPrivateSnapshot = firebase
                .firestore()
                .collection("users")
                .doc(firebase.auth().currentUser.uid)
                .collection("private")
                .doc("data")
                .onSnapshot(
                    (data) => {
                        if (data.exists) {
                            this.user["private"] = data.data();
                            !!onSucceed && onSucceed();
                        }
                    },
                    (error) => {
                        __DEV__ && console.log(error);
                        !!onError && onError(error);
                    }
                );
        }
    }

    retrieveUserData() {
        return this.user;
    }

    retrieveUserCountry = () => {
        return new Promise(async (resolve, reject) => {
            if (
                !!this.user &&
                !!this.user["private"] &&
                !!this.user["private"]["locale"] &&
                !!this.user["private"]["locale"]["country"]
            ) {
                resolve(this.user["private"]["locale"]["country"]);
            } else {
                const country = await this.retrieveBrowserCountry();
                resolve(country);
            }
        });
    };

    retrieveUserLanguage = () => {
        return new Promise(async (resolve, reject) => {
            if (
                !!this.user &&
                !!this.user["private"] &&
                !!this.user["private"]["locale"] &&
                !!this.user["private"]["locale"]["language"]
            ) {
                resolve(this.user["private"]["locale"]["language"]);
            } else if (navigator.language) {
                let res = navigator.language.split("-");
                resolve(res[0]);
            }
            resolve("en");
        });
    };

    retrieveBrowserCountry() {
        return new Promise(async (resolve, reject) => {
            if (false) {
                try {
                    const response = await fetch("https://geolocation-db.com/json/");
                    const data = await response.json();
                    if (!!data && !!data.country_code) {
                        resolve(data.country_code);
                    } else {
                        resolve("ES");
                    }
                } catch (error) {
                    __DEV__ && console.log(`[retrieveBrowserCountry] Error ${error}`);
                    resolve("ES");
                }
            } else {
                try {
                    const response = await fetch("https://pro.ip-api.com/json?key=cjbFUan7D1Wy7OQ");
                    const data = await response.json();
                    if (!!data && !!data.countryCode) {
                        resolve(data.countryCode);
                    } else {
                        resolve("ES");
                    }
                } catch (error) {
                    __DEV__ && console.log(`[retrieveBrowserCountry] Error ${error}`);
                    resolve("ES");
                }
            }
        });
    }

    retrieveUserEmail() {
        return !!firebase.auth() && !!firebase.auth().currentUser && !!firebase.auth().currentUser.email
            ? firebase.auth().currentUser.email
            : undefined;
    }

    retrieveAuthToken(onSucceed, onError) {
        firebase
            .auth()
            .currentUser.getIdToken()
            .then((token) => {
                !!onSucceed && onSucceed(token);
            })
            .catch((error) => {
                !!onError && onError(error);
            });
    }

    retrieveUserSubscriptionPanel(onSucceed, onError) {
        this.retrieveAuthToken(
            (token) => {
                fetch(
                    "https://europe-west1-realfooding-app.cloudfunctions.net/stripe_retrieve_customer_portal_euw1",
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            "Access-Control-Allow-Origin": "*"
                        },
                        body: JSON.stringify({ auth: token })
                    }
                )
                    .then((response) => {
                        if (response.status === 200) {
                            !!onSucceed && onSucceed(response);
                        } else {
                            !!onError && onError(`Response status ${response.status}`);
                        }
                    })
                    .catch((error) => {
                        !!onError && onError(error);
                    });
            },
            (error) => {
                !!onError && onError(error);
            }
        );
    }

    retrieveUserPlusData() {
        if (this.hasCompletedPlusOnboarding()) {
            return this.user["private"]["premium"]["basic"];
        }
        return undefined;
    }

    hasCompletedPlusOnboarding() {
        return (
            !!this.user &&
            !!this.user["private"] &&
            !!this.user["private"]["premium"] &&
            !!this.user["private"]["premium"]["basic"] &&
            this.user["private"]["premium"]["basic"]["menu"] !== undefined
        );
    }

    userRecoverPassword(email, onSucceed, onError) {
        firebase
            .auth()
            .sendPasswordResetEmail(email)
            .then(() => {
                !!onSucceed && onSucceed();
            })
            .catch((error) => {
                !!onError && onError(error);
            });
    }

    userLogIn(email, password, onSucceed, onError) {
        firebase
            .auth()
            .signInWithEmailAndPassword(email, password)
            .then((user) => {
                !!onSucceed && onSucceed(user);
            })
            .catch((error) => {
                !!onError && onError(error);
            });
    }

    logInWithGoogle(onSucceed, onError) {
        firebase.auth().useDeviceLanguage();
        firebase.auth().signInWithRedirect(new firebase.auth.GoogleAuthProvider());
    }

    logInWithApple(onSucceed, onError) {
        firebase.auth().useDeviceLanguage();
        firebase.auth().signInWithRedirect(new firebase.auth.OAuthProvider("apple.com"));
    }

    userSignOut(onSucceed, onError) {
        firebase
            .auth()
            .signOut()
            .then(() => {
                !!onSucceed && onSucceed();
            })
            .catch((error) => {
                !!onError && onError(error);
            });
    }

    retrieveAuthData() {
        return firebase.auth().currentUser;
    }

    signInWithAESToken(mrt) {
        fetch("https://europe-west1-realfooding-app.cloudfunctions.net/userTokenApp/v2/users/tokens", {
            method: "POST",
            headers: {
                "Content-type": "application/json",
                "Access-Control-Allow-Origin": "*"
            },
            body: JSON.stringify({
                mrt: mrt
            })
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                this.signInWithAuthToken(data.token);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    signInWithAuthToken(rnd) {
        firebase
            .auth()
            .signInWithCustomToken(rnd)
            .catch((error) => {
                __DEV__ && console.log(error);
                window.location.assign(window.location.href.split(`rnd=${rnd}&`).join(""));
            });
    }

    subscribeToAuthChanges(id, auth) {
        this.subscribers[id] = auth;
    }

    unsubscribeFromAuthChanges(id) {
        delete this.subscribers[id];
    }

    notifySubscribers(user) {
        user.last_updated = new Date();
        Object.keys(this.subscribers).forEach((key) => {
            this.subscribers[key](user);
        });
    }

    createUser(data, onSucceed, onError) {
        firebase
            .functions()
            .httpsCallable("request_new_user")({
                displayName: data.displayName,
                nickname: data.nickname,
                email: data.email,
                password: data.password,
                birthday: data.birthday,
                tos: data.tos,
                comm: data.comm,
                web: true
            })
            .then((response) => {
                !!onSucceed && onSucceed(response);
            })
            .catch((error) => {
                !!onError && onError(error);
            });
    }

    completeUser(data, onSucceed, onError) {
        firebase
            .functions()
            .httpsCallable("request_complete_user")({
                uid: firebase.auth().currentUser.uid,
                displayName: data.displayName,
                nickname: data.nickname,
                birthday: data.birthday,
                tos: data.tos,
                comm: data.comm,
                web: true
            })
            .then((response) => {
                !!onSucceed && onSucceed(response);
            })
            .catch((error) => {
                !!onError && onError(error);
            });
    }

    logInAsUser(email, password, onSucceed, onError) {
        firebase
            .auth()
            .signInWithEmailAndPassword(email, password)
            .then((user) => {
                !!onSucceed && onSucceed(user);
            })
            .catch((error) => {
                !!onError && onError(error);
            });
    }

    setPremiumPreferences(
        gender,
        years,
        height,
        weight,
        activity,
        type,
        glutenFree,
        foods,
        variety,
        goal,
        macros,
        autoAdjust,
        batchCooking,
        realfoodingLevel,
        onSucceed,
        onError
    ) {
        const ACTIVITY_VALUES = {
            w: [1.2, 1.3, 1.5, 1.6, 1.9],
            m: [1.2, 1.3, 1.6, 1.7, 2.1]
        };

        const GOAL_VALUES = {
            w: [-0.2, 0, +0.15],
            m: [-0.2, 0, +0.15]
        };

        const PLANS = {
            [`1`]: {
                [true]: {
                    [false]: "omni_gluten_free",
                    [true]: "omni_gluten_free_batch"
                },
                [false]: {
                    [false]: "omni_gluten",
                    [true]: "omni_gluten_batch"
                }
            },
            [`2`]: {
                [true]: {
                    [false]: "vegetarian_gluten_free",
                    [true]: "vegetarian_gluten_free_batch"
                },
                [false]: {
                    [false]: "vegetarian_gluten",
                    [true]: "vegetarian_gluten_batch"
                }
            },
            [`3`]: {
                [true]: {
                    [false]: "vegan_gluten_free",
                    [true]: "vegan_gluten_free_batch"
                },
                [false]: {
                    [false]: "vegan_gluten",
                    [true]: "vegan_gluten_batch"
                }
            }
        };

        const META_MACROS_VALUES = {
            0: [
                { carbohydrates: 30, proteins: 30, fats: 40 }, // Perry the Platypus
                { carbohydrates: 30, proteins: 30, fats: 40 }, //
                { carbohydrates: 30, proteins: 30, fats: 40 }, //
                { carbohydrates: 40, proteins: 30, fats: 30 }, //
                { carbohydrates: 40, proteins: 30, fats: 30 } // Chuck Norris
            ],
            1: [
                { carbohydrates: 40, proteins: 20, fats: 40 }, // Perry the Platypus
                { carbohydrates: 40, proteins: 20, fats: 40 }, //
                { carbohydrates: 40, proteins: 20, fats: 40 }, //
                { carbohydrates: 50, proteins: 20, fats: 30 }, //
                { carbohydrates: 50, proteins: 20, fats: 30 } // Chuck Norris
            ],
            2: [
                { carbohydrates: 50, proteins: 20, fats: 30 }, // Perry the Platypus
                { carbohydrates: 50, proteins: 20, fats: 30 }, //
                { carbohydrates: 50, proteins: 20, fats: 30 }, //
                { carbohydrates: 55, proteins: 20, fats: 25 }, //
                { carbohydrates: 55, proteins: 20, fats: 25 } // Chuck Norris
            ]
        };

        let TMB = 0;
        let GOAL = 0;
        if (goal === 0 && weight / ((height * height) / 10000) >= 26) {
            let idealWeight = (22 * height * height) / 10000;
            let calculatedWeight = idealWeight + 0.25 * (weight - idealWeight);
            TMB = Math.round(
                (10 * calculatedWeight +
                    6.25 * parseInt(height) -
                    5 * parseInt(years) +
                    (gender === "w" ? -161 : 5)) *
                ACTIVITY_VALUES[gender][activity]
            );
            GOAL = Math.round(TMB + TMB * GOAL_VALUES[gender][goal]);
        } else {
            TMB = Math.round(
                (10 * parseInt(weight) +
                    6.25 * parseInt(height) -
                    5 * parseInt(years) +
                    (gender === "w" ? -161 : 5)) *
                ACTIVITY_VALUES[gender][activity]
            );
            GOAL = Math.round(TMB + TMB * GOAL_VALUES[gender][goal]);
        }

        firebase
            .firestore()
            .collection("users")
            .doc(firebase.auth().currentUser.uid)
            .collection("private")
            .doc("data")
            .update({
                gender: gender,
                years: parseInt(years),
                height: parseInt(height),
                weight: parseInt(weight),
                isTrackerGramsEnabled: macros,
                isTrackerAutoAdjustEnabled: autoAdjust,
                realfoodingLevel: realfoodingLevel,
                premium: {
                    basic: {
                        goal: goal,
                        menu: type === "omni" ? 1 : type === "vegetarian" ? 2 : 3,
                        foods: Object.keys(foods).length,
                        foods_type: Object.assign({}, foods),
                        variety: 0,
                        glutenFree: glutenFree,
                        activity: activity,
                        currentKcal: TMB,
                        goalKcal: GOAL,
                        isBatchCookingEnabled: batchCooking,
                        macros: META_MACROS_VALUES[goal][activity],
                        plan: PLANS[type === "omni" ? 1 : type === "vegetarian" ? 2 : 3][glutenFree][
                            batchCooking
                            ],
                        last_update: new Date(),
                        pregnancy: {}
                    }
                }
            })
            .then(() => {
                firebase
                    .firestore()
                    .collection("users")
                    .doc(firebase.auth().currentUser.uid)
                    .update({
                        isTrackerGramsEnabled: macros,
                        isTrackerAutoAdjustEnabled: autoAdjust,
                        realfoodingLevel: realfoodingLevel
                    })
                    .then(() => {
                        fetch(
                            "https://europe-west1-realfooding-app.cloudfunctions.net/process_premium_basic_menu_plan",
                            {
                                method: "POST",
                                headers: {
                                    "Content-Type": "application/json",
                                    "Access-Control-Allow-Origin": "*"
                                },
                                body: JSON.stringify({ uid: firebase.auth().currentUser.uid })
                            }
                        )
                            .then((response) => {
                                !!onSucceed && onSucceed(response);
                            })
                            .catch((error) => {
                                !!onError && onError(error);
                            });
                    })
                    .catch((error) => {
                        __DEV__ && console.log(error);
                    });
            })
            .catch((error) => {
                __DEV__ && console.log(error);
            });
    }

    redeemCode(code, onSucceed, onError) {
        fetch("https://europe-west1-realfooding-app.cloudfunctions.net/redeem_present_code_euw1", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*"
            },
            body: JSON.stringify({
                uid: firebase.auth().currentUser.uid,
                code: code
            })
        })
            .then((response) => {
                !!onSucceed && onSucceed(response);
            })
            .catch((error) => {
                !!onError && onError(error);
            });
    }

    retrieveMyRealPlanDates(onSucceed, onError) {
        firebase
            .firestore()
            .collection("users")
            .doc(firebase.auth().currentUser.uid)
            .collection("premium")
            .doc("basic")
            .onSnapshot(
                (snapshot) => {
                    if (!!snapshot && !!snapshot.exists && !!snapshot.data().dates) {
                        let date = new Date();
                        let week = date.getFullYear() + "_" + this.getWeekNumber(date);
                        let sorted = Object.keys(snapshot.data().dates)
                            .sort((a, b) => {
                                try {
                                    const split_a = a.split("_");
                                    const split_b = b.split("_");

                                    return (
                                        Number(split_a[0]) * 1000 +
                                        Number(split_a[1]) -
                                        (Number(split_b[0]) * 1000 + Number(split_b[1]))
                                    );
                                } catch (e) {
                                    return a.localeCompare(b);
                                }
                            })
                            .filter((date) => date !== "2020_20");
                        let current = sorted.includes(week) ? week : sorted[sorted.length - 1];
                        !!onSucceed && onSucceed(sorted, current);
                    } else {
                        !!onSucceed && onSucceed([], "");
                    }
                },
                (error) => {
                    !!onError && onError(error);
                }
            );
    }

    retrieveProductsFromMenu(weekMenu, callback) {
        let ingredientsList = {};
        for (const dayMenu of weekMenu) {
            const day = dayMenu.day;
            const recipes = dayMenu.recipes;
            const dayTotalCaloriesWithoutAdjust = dayMenu.dayTotalCaloriesWithoutAdjust;
            const factor = 1; //goalKcal / dayTotalCaloriesWithoutAdjust;
            for (const food of Object.keys(recipes)) {
                const foodRecipes = recipes[food];
                for (const recipe of foodRecipes) {
                    if (recipe.hidden || recipe.deleted) {
                        continue;
                    }
                    console.log(recipe);
                    const people = recipe.nutritional.people;
                    const ingredients = recipe.nutritional.ingredients;
                    const relationalIngredients = recipe.nutritional.relationalIngredients;
                    for (const code of ingredients) {
                        const ingredient = relationalIngredients.find((ing) => ing.id === code);
                        console.log(code, ingredient);
                        const category = ingredient.category;
                        const fixed = ingredient.fixed;
                        const name = Locale.getLocaledStringValue("name", ingredient);
                        const relationalCategory = ingredient.relationalCategory;
                        const units = ingredient.units;
                        let amount = ingredient.amount;

                        if (units === 0) {
                            //amount *= fixed
                        }
                        let iunits = !!CART_FIXED_VALUES[code] ? 0 : units === 0 ? 0 : 1;

                        if (!!ingredientsList[code]) {
                            // Ingredient exists on ingredientsList -> Compare units and sum
                            const currentIngredient = { ...ingredientsList[code] };
                            if (Number(currentIngredient.units) === Number(iunits)) {
                                ingredientsList[code].amount += factor * (Number(amount) / Number(people));
                            }
                        } else {
                            // Add ingredient
                            ingredientsList[code] = {
                                amount: factor * (Number(amount) / Number(people)),
                                category,
                                code,
                                fixed: Number(fixed),
                                name,
                                relationalCategory,
                                units: Number(iunits)
                            };
                        }
                    }
                }
            }
        }

        let categories = [];
        let headers = [];
        let corrupted = [];
        Object.keys(ingredientsList).forEach((key) => {
            let value = ingredientsList[key];
            if (!!value.relationalCategory) {
                if (categories[value.relationalCategory] === undefined) {
                    categories[value.relationalCategory] = [];
                    headers[value.category] = value.relationalCategory;
                }
                value.id = key;
                categories[value.relationalCategory].push(value);
            } else {
                if (value.amount !== undefined && value.units !== undefined) {
                    let relational = "000000000000000000000";
                    let name = Locale.getString("others");
                    if (categories[relational] === undefined) {
                        categories[relational] = [];
                        headers[name] = relational;
                    }
                    value.id = key;
                    categories[relational].push(value);
                } else {
                    corrupted.push(key);
                }
            }
        });
        const orderedCategories = Object.keys(headers).sort();
        callback({ categories, headers, orderedCategories });
    }

    retrieveUserPlanPreferences(plan, week) {
        return new Promise((resolve, reject) => {
            firebase
                .firestore()
                .collection("users")
                .doc(firebase.auth().currentUser.uid)
                .collection("premium")
                .doc("basic")
                .collection("dates")
                .doc(week)
                .collection("preferences")
                .doc(week)
                .get()
                .then((data) => {
                    if (data.exists) {
                        resolve(data.data());
                    } else {
                        const ref = firebase
                            .firestore()
                            .collection("users")
                            .doc(firebase.auth().currentUser.uid)
                            .collection("premium")
                            .doc("basic")
                            .collection("preferences")
                            .doc(plan)
                            .collection("dates")
                            .doc(week);
                        ref
                            .get()
                            .then((doc) => {
                                if (doc.exists) {
                                    const data = doc.data();
                                    resolve(data);
                                } else {
                                    resolve(null);
                                }
                            })
                            .catch((error) => {
                                reject(error);
                            });
                    }
                });
        });
    }

    incrementABTest(id, option) {
        if (!!id) {
            return firebase
                .firestore()
                .collection("ab")
                .doc(id)
                .get()
                .then((ab) => {
                    if (ab.exists) {
                        return ab.ref
                            .update({
                                total: firebase.firestore.FieldValue.increment(1),
                                [`option.${option}`]: firebase.firestore.FieldValue.increment(1)
                            })
                            .then(() => {
                                __DEV__ && console.log("Updated");
                                return {};
                            })
                            .catch((error) => {
                                __DEV__ && console.log(error);
                                return {};
                            });
                    } else {
                        return ab.ref
                            .set({
                                total: 1,
                                option: {
                                    other: option === "other" ? 1 : 0,
                                    web: option === "web" ? 1 : 0,
                                    app: option === "app" ? 1 : 0
                                }
                            })
                            .then(() => {
                                __DEV__ && console.log("Created");
                                return {};
                            })
                            .catch((error) => {
                                __DEV__ && console.log(error);
                                return {};
                            });
                    }
                });
        }
        return {};
    }

    retrieveEnabledCountries() {
        return new Promise((resolve, reject) => {
            firebase
                .firestore()
                .collection("countries")
                .doc("summary")
                .get()
                .then((doc) => {
                    const countries = [];
                    const data = doc.data()["data"];
                    const countriesSymbols = Object.keys(data);
                    for (const countrySymbol of countriesSymbols) {
                        const country = data[countrySymbol];
                        if (country.enabled) {
                            countries.push({ ...country, code: countrySymbol });
                        }
                    }
                    resolve(countries);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    getWeekNumber(now) {
        let corrected = new Date(now.getTime());
        corrected.setDate(corrected.getDate());
        return moment(corrected).isoWeek() + 1;
        const onejan = new Date(now.getFullYear(), 0, 1);
        return Math.ceil(((now.getTime() - onejan.getTime()) / 86400000 + onejan.getDay() + 1) / 7);
    }

    toDate(obj) {
        try {
            if (!obj) {
                return new Date();
            }
            if (!!!obj._seconds) {
                return new Date(obj.toDate());
            } else {
                let date = new Date(Date.UTC(1970, 0, 1));
                date.setSeconds(obj._seconds);
                return date;
            }
        } catch (e) {
            return new Date();
        }
    }
}

export default new DataService();
