import {makeStyles} from "@material-ui/core/styles";

export default makeStyles((theme) => ({
	rootBodyCardOption: {
		width: '100%',
	},
	rootBodyCardOptionImage: {
		objectFit: 'contain',
		width: '50%',
		paddingTop: '5%',
		paddingBottom: '5%'
	},
	rootBodyCardOptionImagePadded: {
		marginTop: '-20%',
		width: '50%',
		paddingTop: '5%',
		paddingBottom: '5%'
	},
	rootBodyCardOptionIcon: {
		width: '80%',
		[theme.breakpoints.up('sm')]: {
			width: '60%',
		},
		[theme.breakpoints.up('md')]: {
			width: '100%',
		}
	},
	rootBodyCardOptionHeaderTitle: {
		fontWeight: 'bold',
		fontSize: 17,
		marginTop: '5%',
		marginBottom: '5%',
	},
	rootBodyCardOptionMacronutrientsTextBar: {
		fontWeight: 'normal',
		fontSize: 13,
		marginBottom: '3%',
		marginLeft: '2%',
	},
	rootBodyCardOptionMacronutrientsText: {
		fontWeight: 'normal',
		fontSize: 13,
		marginBottom: '3%',
	},
	rootBodyCardOptionMacronutrientsBar: {
		marginBottom: '5%',
	},
	rootBodyCardOptionSubtitle: {
		fontWeight: 'bold',
		fontSize: 13,
		color: '#828282',
		whiteSpace: "pre-wrap"
	}
}), { index: 1 });
