export interface MenuSettingsExclusionsPrimitive {
  allergens: string[];
  exclusions: string[];
  hiddenExclusions: string[];
}

export class MenuSettingsExclusions {
  constructor(
    private allergens: string[],
    private exclusions: string[],
    private hiddenExclusions: string[]
  ) {
    this.validateExclusions();
  }

  private validateExclusions() {
    this.exclusions = this.exclusions.filter((element, index) => {
      return this.exclusions.indexOf(element) === index;
    }).filter(item => item);

    this.hiddenExclusions = this.hiddenExclusions.filter((element, index) => {
      return this.hiddenExclusions.indexOf(element) === index;
    })

    this.hiddenExclusions = this.hiddenExclusions.filter(
      (hiddenExclusion) => !this.exclusions.includes(hiddenExclusion) && hiddenExclusion
    )

    this.allergens = this.allergens.filter(a => a);
  }

  public getAllergens(): string[] {
    return this.allergens;
  }

  public getExclusions(): string[] {
    return this.exclusions;
  }

  public getHiddenExclusions(): string[] {
    return this.hiddenExclusions;
  }

  public setAllergens(allergens: string[]) {
    this.allergens = allergens;
  }

  public setExclusions(exclusions: string[]) {
    this.exclusions = exclusions;
    this.validateExclusions();
  }

  public setHiddenExclusions(hiddenExclusions: string[]) {
    this.hiddenExclusions = hiddenExclusions;
    this.validateExclusions();
  }

  public toPrimitives(): MenuSettingsExclusionsPrimitive {
    return {
      allergens: this.allergens,
      exclusions: this.exclusions,
      hiddenExclusions: this.hiddenExclusions
    };
  }

  public static fromPrimitives(
    primitives: MenuSettingsExclusionsPrimitive
  ): MenuSettingsExclusions {
    return new MenuSettingsExclusions(
      primitives.allergens,
      primitives.exclusions,
      primitives.hiddenExclusions
    );
  }
}
