import React, {useEffect, useState} from "react";
import style from "./style";
import {useHistory} from 'react-router-dom';
import NextButton from "../../nextButton";
import TextField from "@material-ui/core/TextField";
import Info from "../../info";

const Height = ({onNext}) => {
    const classes = style();
    const history = useHistory();

    const [weight, setWeight] = useState('');
    const [error, setError] = useState();
    const [valid, setValid] = useState(true);

    useEffect(() => {
        const weight = localStorage.getItem('premium.basic.weight');
        if(weight) {
            setWeight(Number(weight));
        }
    }, []);

    return (
        <div
            className={classes.root}
        >
            <TextField
                className={classes.input}
                id="outlined-number"
                variant={'outlined'}
                placeholder={'60'}
                value={weight}
                helperText={error ?? 'Introduce tu peso en kilogramos.'}
                type="number"
                error={!!error}
                onChange={(e) => {
                    if(e.target.value) {
                        setWeight(Number(e.target.value));
                    }
                    else {
                        setWeight('');
                    }
                }}
            />
            <Info
                text={"Tu información personal nos sirve para poder personalizar tu Plan nutricional de acuerdo a tus objetivos."}
            />
            <NextButton
                disabled={!weight && !valid}
                onNext={() => {
                    if(weight < 30 || weight > 600) {
                        setError("No es un peso válido.");
                        setValid(false);
                    } else {
                        setValid(true);
                        localStorage.setItem('premium.basic.weight', weight)
                        onNext();
                    }
                }}
            />
        </div>
    )
}

export default Height;
