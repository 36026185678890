import {makeStyles} from "@material-ui/core/styles";

export default makeStyles((theme) => ({
	footer: {
		backgroundColor: '#19C692',
		paddingTop: '4%',
	},
	footerWhite: {
		backgroundColor: '#FFF',
		paddingTop: '4%',
	},
	whiteSeparator: {
		width: '100%',
		height: 2,
		backgroundColor: '#FFF'
	},
	footerLeft: {
		marginTop: '-5%'
	},
	footerLeftLogo: {
		width: '50%',
		paddingTop: '5%',
		paddingBottom: '5%',
		[theme.breakpoints.up('md')]: {
			width: '40%',
			paddingBottom: '15%',
			paddingTop: '0%',
		},
	},
	footerLeftDisplayImage: {
		objectFit: 'contain',
		width: '40%',
		display: 'none',
		[theme.breakpoints.up('md')]: {
			display: 'flex',
		},
	},
	footerRight: {},
	footerRightTop: {
		paddingLeft: '8%',
		paddingRight: '8%',
		[theme.breakpoints.up('md')]: {
			padding: '0%',
		},
	},
	footerRightTopHeader: {
		fontWeight: 'bold',
		color: 'white',
		marginTop: '15%',
		marginBottom: '2%',
		[theme.breakpoints.up('md')]: {
			marginTop: '1%',
			marginBottom: '10%',
		},
	},
	footerRightTopHeaderWhite: {
		fontWeight: 'bold',
		color: '#000',
		marginTop: '15%',
		marginBottom: '2%',
		[theme.breakpoints.up('md')]: {
			marginTop: '1%',
			marginBottom: '10%',
		},
	},
	footerRightTopText: {
		marginTop: '5%',
		marginBottom: '5%',
		fontWeight: 'normal',
		color: 'white',
		textAlign: 'left',
		paddingRight: '1%'
	},
	footerRightTopTextWhite: {
		marginTop: '5%',
		marginBottom: '5%',
		fontWeight: 'normal',
		color: '#000',
		textAlign: 'left',
		paddingRight: '1%'
	},
	footerRightTopGrow: {
		flexGrow: 1
	},
	footerRightBottom: {
		paddingTop: '5%',
		paddingBottom: '3%',
		display: 'none',
		[theme.breakpoints.up('sm')]: {
			display: 'flex',
		},
		[theme.breakpoints.up('md')]: {
			display: 'flex',
		},
	},
	footerRightBottomExtra: {
		paddingTop: '5%',
		paddingBottom: '3%',
		display: 'flex',
		[theme.breakpoints.up('sm')]: {
			display: 'none',
		},
		[theme.breakpoints.up('md')]: {
			display: 'none',
		},
	},
	footerLeftDisplayExtra: {
		width: '100%',
		height: '100%',
		paddingLeft: '20%',
		paddingRight: '20%',
		display: 'flex',
		[theme.breakpoints.up('md')]: {
			display: 'none',
		},
	},
	footerRightBottomText: {
		fontWeight: 'normal',
		color: 'white',
		fontSize: 12,
		marginTop: '5%',
		[theme.breakpoints.up('sm')]: {
			fontSize: 14,
			marginTop: '0%',
		},
		[theme.breakpoints.up('md')]: {
			fontSize: 16,
			marginTop: '0%',
		},
	},
	footerRightBottomTextWhite: {
		fontWeight: 'normal',
		color: '#000',
		fontSize: 12,
		marginTop: '5%',
		[theme.breakpoints.up('sm')]: {
			fontSize: 14,
			marginTop: '0%',
		},
		[theme.breakpoints.up('md')]: {
			fontSize: 16,
			marginTop: '0%',
		},
	},
	footerRightBottomGrow: {
		flexGrow: 1
	}
}), {index: 1});
