import { DayFood, MenuProductPrimitives, WeekKey } from "@myrealfood-sl/domain";

import { reducedProductTranslator } from "./ReducedProductTranslator";
import { MenuProductDocument } from "./FullDocumentMenu";

export class MenuProductTranslator {
  private static instance: MenuProductTranslator;

  private constructor() {
    // Empty
  }

  public static getInstance(): MenuProductTranslator {
    if (!MenuProductTranslator.instance) {
      MenuProductTranslator.instance = new MenuProductTranslator();
    }

    return MenuProductTranslator.instance;
  }

  public transformFromDocumentToPrimitives(
    document: MenuProductDocument,
    week: WeekKey,
    food: DayFood,
    day: string
  ): MenuProductPrimitives {
    return {
      entryReference: {
        week: week.toString(),
        food,
        day
      },
      product: reducedProductTranslator.fromDocumenToPrimitives(document)
    };
  }

  public transformFromPrimitivesToDocument(primitives: MenuProductPrimitives): MenuProductDocument {
    return {
      ...primitives.entryReference,
      ...reducedProductTranslator.fromPrimitivesToDocument(primitives.product)
    };
  }
}

export const menuProductTranslator = MenuProductTranslator.getInstance();
