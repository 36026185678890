import {MenuSettingsPersonalData, MenuSettingsPersonalDataPrimitives} from "./MenuSettingsPersonalData";
import {MenuSettingsPreferences, MenuSettingsPreferencesPrimitives} from "./MenuSettingsPreferences";
import {MenuSettingsExclusions, MenuSettingsExclusionsPrimitive} from "./MenuSettingsExclusions";
import {MenuSettingsInfo, MenuSettingsInfoPrimitives} from "./MenuSettingsInfo";
import {Gender} from "./constants/Gender";
import {Activity} from "./constants/Activity";
import {Goal} from "./constants/Goal";
import {MenuType, menuTypes} from "./constants/MenuType";
import {Macros} from "./Macros";
import {MacrosCalculator} from "./MacrosCalculator";
import {KcalCalculator} from "./KcalCalculator";

export interface MenuSettingsPrimitive {
    exclusions: MenuSettingsExclusionsPrimitive;
    info: MenuSettingsInfoPrimitives;
    personalData: MenuSettingsPersonalDataPrimitives;
    preferences: MenuSettingsPreferencesPrimitives;
}

export class MenuSettings {
    constructor(
        private personalData: MenuSettingsPersonalData,
        private preferences: MenuSettingsPreferences,
        private exclusions: MenuSettingsExclusions,
        private additionalInfo: MenuSettingsInfo
    ) {
    }

    public getGender(): Gender {
        return this.personalData.getGender();
    }

    public setGender(value: Gender) {
        this.personalData.setGender(value);
    }

    public getWeight(): number {
        return this.personalData.getWeight();
    }

    public setWeight(value: number) {
        this.personalData.setWeight(value);
    }

    public getAge(): number {
        return this.personalData.getAge();
    }

    public setAge(value: number) {
        this.personalData.setAge(value);
    }

    public getHeight(): number {
        return this.personalData.getHeight();
    }

    public setHeight(value: number) {
        this.personalData.setHeight(value);
    }

    public getActivity(): Activity {
        return this.personalData.getActivity();
    }

    public setActivity(value: Activity) {
        this.personalData.setActivity(value);
    }

    public getGoal(): Goal {
        return this.preferences.getGoal();
    }

    public setGoal(value: Goal) {
        this.preferences.setGoal(value);
    }

    public getGoalKcal(): number {
        return this.preferences.getGoalKcal();
    }

    public setGoalKcal(value: number): void {
        this.preferences.setGoalKcal(value);
    }

    public getDayFoods(): string[] {
        return this.preferences.getDayFoods();
    }

    public getMenuDayFoods(): string[] {
        return this.preferences.getMenuDayFoods();
    }

    public setDayFoods(value: string[]): void {
        this.preferences.setDayFoods(value);
    }

    public getMenuType(): MenuType {
        return this.preferences.getMenuType();
    }

    public setMenuType(value: MenuType): void {
        this.preferences.setMenuType(value);
    }

    public isBatchCookingEnabled(): boolean {
        return this.preferences.isBatchCookingEnabled();
    }

    public setBatchCookingEnabled(value: boolean): void {
        this.preferences.setBatchCookingEnabled(value);
    }

    public getMacros(): Macros {
        return this.preferences.getMacros();
    }

    public setMacros(value: Macros): void {
        this.preferences.setMacros(value);
    }

    public isShowWeekly(): boolean {
        return this.preferences.isShowWeekly();
    }

    public setShowWeekly(value: boolean) {
        this.preferences.setShowWeekly(value);
    }

    public isTrackerGramsEnabled(): boolean {
        return this.preferences.isTrackerGramsEnabled();
    }

    public setTrackerGramsEnabled(value: boolean): void {
        this.preferences.setTrackerGramsEnabled(value);
    }

    public isFastMenu(): boolean {
        return this.preferences.isFastMenu();
    }

    public setFastMenu(value: boolean): void {
        this.preferences.setFastMenu(value);
    }

    public getCountry(): string {
        return this.additionalInfo.getCountry();
    }

    public setCountry(country: string): void {
        this.additionalInfo.setCountry(country);
    }

    public isKeepMenu(): boolean {
        return this.additionalInfo.isKeepMenu();
    }

    public setKeepMenu(keepMenu: boolean) {
        this.additionalInfo.setKeepMenu(keepMenu);
    }

    public isSuccessGeneration(): boolean {
        return this.additionalInfo.isSuccessGeneration();
    }

    public setSuccessGeneration(successGeneration: boolean): void {
        this.additionalInfo.setSuccessGeneration(successGeneration);
    }

    public isOnboardingComplete(): boolean {
        return this.additionalInfo.isOnboardingComplete();
    }

    public setOnboardingComplete(onboardingComplete: boolean): void {
        this.additionalInfo.setOnboardingComplete(onboardingComplete);
    }

    public getAllergens(): string[] {
        return this.exclusions.getAllergens();
    }

    public setAllergens(allergens: string[]): void {
        this.exclusions.setAllergens(allergens);
    }

    public getExclusions(): string[] {
        return this.exclusions.getExclusions();
    }

    public setExclusions(exclusions: string[]): void {
        this.exclusions.setExclusions(exclusions);
    }

    public getHiddenExclusions(): string[] {
        return this.exclusions.getHiddenExclusions();
    }

    public setHiddenExclusions(hiddenExclusions: string[]): void {
        this.exclusions.setHiddenExclusions(hiddenExclusions);
    }

    public getCalculatedMacros(): Macros {
        return MacrosCalculator.calculate(this.getGoal(), this.getActivity());
    }

    public getPlanName(): string {
        const menu =
            this.getMenuType() == menuTypes.OMNI
                ? "omni"
                : this.getMenuType() == menuTypes.VEGAN
                    ? "vegan"
                    : this.getMenuType() == menuTypes.VEGETARIAN
                        ? "vegetarian"
                        : "pescetarian"

        return `${menu}`;
    }

    public getCalculatedKcals(): number {
        return KcalCalculator.calculate(
            this.getGender(),
            this.getWeight(),
            this.getHeight(),
            this.getAge(),
            this.getGoal(),
            this.getActivity()
        );
    }

    public static fromPrimitives(primitive: MenuSettingsPrimitive): MenuSettings {
        return new MenuSettings(
            MenuSettingsPersonalData.fromPrimitives(primitive.personalData),
            MenuSettingsPreferences.fromPrimitives(primitive.preferences),
            MenuSettingsExclusions.fromPrimitives(primitive.exclusions),
            MenuSettingsInfo.fromPrimitives(primitive.info)
        );
    }

    public toPrimitives(): MenuSettingsPrimitive {
        return {
            personalData: this.personalData.toPrimitives(),
            preferences: this.preferences.toPrimitives(),
            exclusions: this.exclusions.toPrimitives(),
            info: this.additionalInfo.toPrimitives()
        };
    }
}
