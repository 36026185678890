import React, {useEffect, useState} from "react";
import {useHistory} from 'react-router-dom';
import Footer from "./footer";
import Objective from "./steps/objective";
import Question from "./question";
import Why from "./steps/why";
import Diet from "./steps/diet";
import Allergens from "./steps/allergens";
import Foods from "./steps/foods";
import Health from "./steps/health";
import Activity from "./steps/activity";
import Gender from "./steps/gender";
import Age from "./steps/age";
import Height from "./steps/height";
import Weight from "./steps/weight";
import Review from "./steps/review";
import AuthOnBoarding from "./login";
import Analytics from "../../controller/Analytics";
import {getMobileOperatingSystem} from "../../controller/Exports";
import ds from "../../controller/DataService";
import {generateMenu} from "./login/GenerateMenu";


const OnBoarding = () => {
    const history = useHistory();
    const [step, setStep] = useState(0);
    const [loss, setLoss] = useState(false);
    const [promocode, setPromocode] = useState(null);
    const [auth, setAuth] = useState(false);

    useEffect(() => {
        const promocode = new URLSearchParams(window.location.search).get('promo');
        if (promocode) {
            setPromocode((promocode === "NONE") ? "JUNIO23WEB": (promocode ?? "NONE"));
        } else {
            const s = new URLSearchParams(window.location.search).get('s');
            if (s) {
                const split = s.split('$$');
                if (split.length === 3) {
                    try {
                        setPromocode((JSON.parse(split[2])['code'] === "NONE") ? "JUNIO23WEB": (JSON.parse(split[2])['code'] ?? "NONE"));
                    } catch (e) {
                    }
                }
            }
        }
    }, []);

    useEffect(() => {
        if (!!ds.retrieveAuthData()) {
            if (window.location.search.includes('redirect=')) {
                generateMenu();
                history.push(`/subscribe?plan=12&promo=${(promocode === "NONE") ? "JUNIO23WEB": (promocode ?? "NONE")}&redirect=/plus/menu`)
            }
        } else {
            ds.subscribeToAuthChanges('AuthOnBoarding', setAuth);
            return () => {
                ds.unsubscribeFromAuthChanges('AuthOnBoarding');
            }
        }
    });

    const nextStep = () => {
        Analytics.logEvent("onboarding_web_next", {
            screen: 'web',
            step: step
        });
        setStep(step + 1);
    }

    const backStep = () => {
        Analytics.logEvent("onboarding_web_back", {
            screen: 'web',
            step: step
        });
        setStep(step - 1);
    }

    const steps = [
        {
            component:
                <Objective
                    promocode={(promocode === "NONE") ? "JUNIO23WEB": (promocode ?? "NONE")}
                    onSelect={(loss) => {
                        setLoss(loss);
                        nextStep();
                    }}
                />
        },
        loss && {
            component:
                <Question
                    title={"¿Por qué quieres perder grasa?"}
                    onBack={backStep}
                >
                    <Why
                        onNext={nextStep}
                    />
                </Question>
        },
        {
            component:
                <Question
                    title={"¿Qué tipo de dieta quieres seguir?"}
                    onBack={backStep}
                >
                    <Diet
                        onNext={nextStep}
                    />
                </Question>
        },
        {
            component:
                <Question
                    title={"¿Hay algún alimento que no quieras comer?"}
                    onBack={backStep}
                >
                    <Allergens
                        onNext={nextStep}
                    />
                </Question>
        },
        {
            component:
                <Question
                    title={"¿Cuántas comidas haces al día?"}
                    onBack={backStep}
                >
                    <Foods
                        onNext={nextStep}
                    />
                </Question>
        },
        loss && {
            component:
                <Question
                    title={"Pierde grasa de forma saludable"}
                    onBack={backStep}
                >
                    <Health
                        onNext={nextStep}
                    />
                </Question>
        },
        {
            component:
                <Question
                    title={"¿Cuál es tu nivel de actividad física?"}
                    onBack={backStep}
                >
                    <Activity
                        onNext={nextStep}
                    />
                </Question>
        },
        {
            component:
                <Question
                    title={"¿Cuál es tu sexo?"}
                    onBack={backStep}
                >
                    <Gender
                        onNext={nextStep}
                    />
                </Question>
        },
        {
            component:
                <Question
                    title={"¿Cuántos años tienes?"}
                    onBack={backStep}
                >
                    <Age
                        onNext={nextStep}
                    />
                </Question>
        },
        {
            component:
                <Question
                    title={"¿Cuánto mides?"}
                    onBack={backStep}
                >
                    <Height
                        onNext={nextStep}
                    />
                </Question>
        },
        {
            component:
                <Question
                    title={"¿Cuánto pesas?"}
                    onBack={backStep}
                >
                    <Weight
                        onNext={nextStep}
                    />
                </Question>
        },
        {
            component:
                <Question
                    title={"Esto es lo que puedes esperar de tu Plan"}
                    onBack={backStep}
                >
                    <Review
                        onNext={nextStep}
                    />
                </Question>
        },
        {
            component:
                <AuthOnBoarding
                    promocode={(promocode === "NONE") ? "JUNIO23WEB": (promocode ?? "NONE")}
                />
        }
    ].filter((step) => step)

    return (
        <>
            {
                steps[step].component
            }
            <Footer/>
        </>
    )
}

export default OnBoarding;
