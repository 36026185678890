import React from 'react';

import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions'

import './App.css';
import {Redirect, Route, Switch, withRouter} from 'react-router'

import Home from "./routes/Home";
import Login from "./routes/Login";
import MRFPlus from "./common/MRFPlus";
import Register from "./routes/Register";
import Team from "./routes/Team";
import FAQ from "./routes/FAQ";
import OfferLanding from "./routes/Offer"

import ds from './controller/DataService';
import Profile from "./routes/Profile";

import DownloadAppBanner from "./common/DownloadAppBanner";

import ScrollToTop from "./common/ScrollToTop";
import Subscribe from "./routes/Subscribe";
import Plus from "./routes/Plus";
import PlusOnboarding from "./routes/Plus/PlusOnboarding";
import PlusMenu from "./routes/Plus/PlusMenu";
import PlusRecipe from "./routes/Plus/PlusRecipe";
import PlusCart from "./routes/Plus/PlusCart";
import {Elements} from "@stripe/react-stripe-js";

import {loadStripe} from '@stripe/stripe-js';
import Get from "./common/Get";
import CompleteData from "./routes/CompleteData";
import CookieBanner from "./common/CookieBanner";
import {__DEV__} from "./controller/Exports";
import Careers from "./common/Careers";
import Ref from "./common/Ref";
import PresentPurchase from "./routes/Present/Purchase";
import PresentRedeem from "./routes/Present/Redeem";
import Present from "./routes/Present";
import ReactPixel from 'react-facebook-pixel';
import Logout from "./routes/Logout";
import AB from "./common/AB";
import HaztePlus from "./common/HaztePlus";
import PlanPlus from "./common/PlanPlus";
import Analytics from "./controller/Analytics";
import Locale from "./controller/locale";
import Recipe from "./routes/Recipe";
import OnBoarding from "./routes/OnBoarding";
import PrivacyPolicy from './routes/PrivacyPolicy';
import References from "./routes/References";


//import Analytics from './controller/Analytics'

const stripePromise = loadStripe(__DEV__ ? "pk_test_5ZwKYgyMhFEhpssBPjroDzx300dDsy1neD" : "pk_live_50CGEH2VQuXTpvORQnX3HaBE0069Y9t3xq");

const app = function App({history}) {

	history.listen((location, action) => {
		// location is an object like window.location
		// console.log(action, location.pathname, location.state);
		ReactPixel.pageView();
		ReactPixel.track('mrf_page_view', {
			pathname: location.pathname
		});
		window.ttq.track('ViewContent', {
			pathname: location.pathname
		})

		Analytics.pageView({
			screen: 'web',
			error: undefined,
			pathname: location.pathname,
			date: new Date().toLocaleDateString(),
			time: new Date().getTime()
		});

		Analytics.logEvent("mrf_page_view", {
			screen: 'web',
			error: undefined,
			pathname: location.pathname,
			date: new Date().toLocaleDateString(),
			time: new Date().getTime()
		});

		window.gtag('event', 'mrf_page_view', {
			screen: 'web',
			error: undefined,
			pathname: location.pathname,
			date: new Date().toLocaleDateString(),
			time: new Date().getTime()
		});
	});

	ds.handleUserChanges(history,
		(user) => {
			ds.handleUserDataCompletion(() => {
				ds.notifySubscribers(user);
				Analytics.onUserSignedIn(user);
			}, (error) => {
				history.push('/register/complete/')
			})
		}, (error) => {
			console.log(error);
		});

	ds.handleSignInProviderChanges((user) => {
		ds.handleUserDataCompletion(() => {
			ds.notifySubscribers(user);
			Analytics.onUserSignedIn(user);
		}, (error) => {
			history.push('/register/complete/')
		})
	}, (error) => {
	});

	return (
		<ScrollToTop>
			<Switch>
				<Route path='/get'>
					<Get from={'/get'}/>
				</Route>
				<Route path='/ref'>
					<Ref/>
				</Route>
				<Route path='/login'>
					<Login/>
				</Route>
				<Route path='/register/complete'>
					<CompleteData/>
				</Route>
				<Route path='/register'>
					<Register/>
				</Route>
				<Route path='/profile'>
					<Profile/>
				</Route>
				<Route path='/plus/menu'>
					<PlusMenu/>
				</Route>
				<Route path='/plus/cart'>
					<PlusCart/>
				</Route>
				<Route path='/plus/config'>
					<PlusOnboarding/>
				</Route>
				<Route path='/plus/recipe'>
					<PlusRecipe/>
				</Route>
				<Route path='/recipe'>
					<Recipe/>
				</Route>
				<Route path='/plus'>
					<Plus/>
				</Route>
				<Route path='/subscribe'>
					<Elements stripe={stripePromise}>
						<Subscribe/>
					</Elements>
				</Route>
				<Route path='/team'>
					<Team/>
				</Route>
				<Route path='/careers'>
					<Careers/>
				</Route>
				<Route path='/faq'>
					<FAQ/>
				</Route>
				<Route path='/present/purchase'>
					<Elements stripe={stripePromise}>
						<PresentPurchase/>
					</Elements>
				</Route>
				<Route path='/present/redeem'>
					<PresentRedeem/>
				</Route>
				<Route path='/present'>
					<Present/>
				</Route>
				<Route path='/regalo/comprar'>
					<Elements stripe={stripePromise}>
						<PresentPurchase/>
					</Elements>
				</Route>
				<Route path='/regalo/canjear'>
					<PresentRedeem/>
				</Route>
				<Route path='/regalo'>
					<Present/>
				</Route>
				<Route path='/canjear'>
					<PresentRedeem/>
				</Route>
				<Route path='/offer'>
					<OfferLanding/>
				</Route>
				<Route path='/valorar'>
					<Get from={'/valorar'}/>
				</Route>
				<Route path='/rate'>
					<Get from={'/rate'}/>
				</Route>
				<Route path='/campaign'>
					<Get from={'/campaign'}/>
				</Route>
				<Route path='/ab'>
					<AB/>
				</Route>
				<Route path='/logout'>
					<Logout/>
				</Route>
				<Route path='/mrfplus'>
					<MRFPlus/>
				</Route>
				<Route path='/hazteplus'>
					<HaztePlus/>
				</Route>
				<Route path='/planplus'>
					<PlanPlus/>
				</Route>
				<Route path='/share'>
					<OnBoarding/>
				</Route>
				<Route path='/onboarding'>
					<OnBoarding/>
				</Route>
				<Route path='/privacy_policy'>
					<PrivacyPolicy/>
				</Route>
				<Route path='/references'>
					<References/>
				</Route>
				<Route path='/'>
					<Home/>
				</Route>
				<Route path='*'>
					<Redirect to='/'/>
				</Route>
			</Switch>
			<DownloadAppBanner/>
			<CookieBanner/>
		</ScrollToTop>
	);
}

export default withRouter(app);
