import { WeekKey, WeekMenuPrimitives, WeekPlan } from "@myrealfood-sl/domain";

import { FullMenu, MenuDocument } from "./FullDocumentMenu";
import { weekPlanTranslator } from "./WeekPlanTranslator";

export class WeekMenuTranslator {
  private static instance: WeekMenuTranslator;

  private constructor() {
    // Empty
  }

  public static getInstance(): WeekMenuTranslator {
    if (!WeekMenuTranslator.instance) {
      WeekMenuTranslator.instance = new WeekMenuTranslator();
    }

    return WeekMenuTranslator.instance;
  }

  public fromMenuDocumentToPrimitives(
    base: MenuDocument,
    weekKey: WeekKey
  ): WeekMenuPrimitives {
    return {
      plan: weekPlanTranslator.fromMenuDayDocumentToPrimitives(
        base.fullMenu,
        weekKey
      )
    };
  }

  public fromPrimitivesToMenuDayBaseDocument(weekPlan: WeekPlan): FullMenu {
    return weekPlanTranslator.fromPrimitivesToMenuDayBaseDocument(weekPlan);
  }
}

export const weekMenuTranslator = WeekMenuTranslator.getInstance();
