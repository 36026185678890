import React from 'react';

import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions'

import style from './style';
import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

export default function Card(props) {
	const classes = style();

	return (
		<Box
			className={classes.rootBodyCard}
			boxShadow={3}
			bgcolor="background.paper"
			m={1}
			p={1}
		>
			<Typography
				className={classes.rootBodyCardContentTitle}
				variant="h4"
			>
				{props.title}
			</Typography>

			<Grid
				container
				md={12}
				direction={!!props.column ? "column" : "row"}
				alignItems={"center"}
				justify={"space-around"}
			>
				{props.children}
			</Grid>
		</Box>
	);
}


