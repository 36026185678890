import ds from './DataService'
import {__DEV__} from "./Exports";
import * as firebase from "firebase";
import mixpanel from 'mixpanel-browser';

class Analytics {

    constructor() {
        this.lastScreenRecorded = undefined;
        this.lastScreenName = undefined;
        this.timers = [];

        this.events = undefined;

        this.onAppLaunched();
    }

    sendEvent(name) {
        return true;
    }

    onAppLaunched() {
        try {
            mixpanel.init('63e909761075a79fe00647258ea1043f');
        } catch (e) {
            __DEV__ && console.log(e)
        }
    }

    onUserSignedIn(user) {
        try {
            let tmp = [];
            Object.keys(user).forEach(key => {
                if (!key.includes('tracker_tmp')) {
                    tmp[key] = `${user[key]}`;
                }
            });

            if (!!user) {
                tmp = [];
                Object.keys(user).forEach(key => {
                    if (!key.includes('tracker_tmp')) {
                        tmp[key] = user[key];
                    }
                });
                mixpanel.identify(user.uid)
            }
        } catch (e) {
            __DEV__ && console.log(e);
        }
    }

    setUserProperty(name, value) {
        mixpanel.people.set(`${name}`, value);
    }

    onScreenLoaded(context, screenName, record = false, metadata) {
        try {
            let premium = ds.isPlusUser();
            if (this.lastScreenName !== undefined) {
                if (this.timers[this.lastScreenName] !== undefined) {
                    let elapsed = `${(new Date().getTime() - this.timers[this.lastScreenName])}`;
                    mixpanel.track("s_" + this.lastScreenName + "_time", Object.assign({}, {
                        screen: 'web',
                        platform: 'web',
                        elapsed: elapsed,
                        premium: `${premium}`,
                        dev: `${!!__DEV__}`,
                    }));
                    window.gtag('event', "s_" + this.lastScreenName + "_time", Object.assign({}, {
                        screen: 'web',
                        platform: 'web',
                        elapsed: elapsed,
                        premium: `${premium}`,
                        dev: `${!!__DEV__}`,
                    }));
                }
            }
            this.lastScreenName = screenName;

            this.timers[screenName] = new Date().getTime();

            if (record) {
                this.lastScreenRecorded = "s_" + screenName;
            } else {
                mixpanel.track("s_" + screenName + "_in", {
                    screen: 'web',
                    platform: 'web',
                    in: new Date().getTime(),
                    elapsed: '0',
                    premium: `${premium}`,
                    dev: `${!!__DEV__}`,
                    metadata
                });
                window.gtag('event', "s_" + screenName + "_in", {
                    screen: 'web',
                    platform: 'web',
                    in: new Date().getTime(),
                    elapsed: '0',
                    premium: `${premium}`,
                    dev: `${!!__DEV__}`,
                    metadata
                });
            }
        } catch (e) {
            __DEV__ && console.log(e)
        }
    }

    onScreenRemoved(context, screenName, record = false) {
        try {
            let elapsed = 0;
            let premium = ds.isPlusUser();
            if (this.timers[screenName] !== undefined) {
                elapsed = new Date().getTime() - this.timers[screenName];
            }

            if (record) {
                this.lastScreenRecorded = undefined;
            } else {
                mixpanel.track("s_" + screenName + "_out", {
                    screen: 'web',
                    platform: 'web',
                    out: new Date().getTime(),
                    elapsed: `${elapsed}`,
                    premium: `${premium}`,
                    dev: `${!!__DEV__}`
                });
                window.gtag('event', "s_" + screenName + "_out", {
                    screen: 'web',
                    platform: 'web',
                    out: new Date().getTime(),
                    elapsed: `${elapsed}`,
                    premium: `${premium}`,
                    dev: `${!!__DEV__}`
                });
            }
        } catch (e) {
            __DEV__ && console.log(e);
        }
    }

    pageView(params) {
        mixpanel.track_pageview(params);
    }

    logEvent(event, params) {
        try {
            let submitableParams = [];
            let premium = ds.isPlusUser();
            params['time'] = new Date().getTime();
            Object.keys(params).forEach(key => {
                if (params[key] !== undefined)
                    submitableParams[`${key}`] = `${params[key]}`;
            });
            submitableParams['premium'] = `${premium}`;
            submitableParams['dev'] = `${!!__DEV__}`;
            params['screen'] = 'web';
            params['platform'] = 'web';

            mixpanel.track((!!params.error ? "e_" : "i_") + event, Object.assign({}, submitableParams));
            mixpanel.track((!!submitableParams && !!submitableParams.screen ? ("s_" + submitableParams.screen + "_") : '') + (!!params.error ? "e_" : "i_") + event, Object.assign({}, submitableParams));
            window.gtag('event', (!!params.error ? "e_" : "i_") + event, Object.assign({}, submitableParams));
            window.gtag('event', (!!submitableParams && !!submitableParams.screen ? ("s_" + submitableParams.screen + "_") : '') + (!!params.error ? "e_" : "i_") + event, Object.assign({}, submitableParams));
        } catch (e) {
            __DEV__ && console.log("[Error]", e);
        }
    }

    logPayment(platform, productId, price, transactionId, uid = undefined, date = undefined) {
        try {
            if (!uid && !!firebase.auth().currentUser && !!firebase.auth().currentUser.uid) {
                uid = firebase.auth().currentUser.uid;
            }
            if (!date) {
                date = new Date();
            }
            mixpanel.people.trackCharge(Number(price), {
                uid,
                date: date.toISOString(),
                platform,
                productId,
                quantity: 1,
                price: Number(price),
                revenueType: "income"
            });
        } catch (e) {
            __DEV__ && console.log(e);
        }
    }

    calculateAge(birthday) {
        let ageDifMs = Date.now() - birthday;
        let ageDate = new Date(ageDifMs);
        return Math.abs(ageDate.getUTCFullYear() - 1970);
    }
}

const myRealFoodAnalytics: Analytics = new Analytics();
export default myRealFoodAnalytics;
