import {makeStyles} from "@material-ui/core/styles";
import React from "react";

export default makeStyles((theme) => ({
    checked: {
        width: '21px',
        height: '21px',
        background: '#19C692',
        backgroundImage: 'url(/assets/check.png)',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        borderRadius: '50%',
        border: '5px solid #19C692',
    },
    unchecked: {
        width: '25px',
        height: '25px',
        border: '1px solid #C7CACF',
        borderRadius: '50%',
    }
}), { index: 1 });
