import {makeStyles} from "@material-ui/core/styles";

export default makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		backgroundColor: 'white',
		paddingLeft: '3%',
		paddingRight: '3%',
		[theme.breakpoints.up('md')]: {
			paddingLeft: '14%',
			paddingRight: '14%',
		}
	},
	rootGrow: {
		flexGrow: 1,
	},
	rootDesktopOnly: {
		display: 'none',
		[theme.breakpoints.up('md')]: {
			display: 'flex',
		},
	},
	rootSeparator: {
		height: 1,
		width: '100%',
		marginTop: '2%',
		marginBottom: '5%',
		backgroundColor: '#e6e6e6',
	},
	rootBody: {
		marginTop: '1%',
		marginLeft: '1%',
		marginRight: '1%',
		marginBottom: '5%',
	},
	rootBodyContent: {
		marginTop: '10%',
	},
	rootBodyContentHeaderTitle: {
		fontSize: 20,
		fontWeight: 'bold',
		marginRight: '10%',
		marginLeft: '10%',
		whiteSpace: "pre-wrap",
		textAlign: 'center'
	},

	rootBodyContentShoppingList: {
		marginTop: '5%',
		marginLeft: '10%',
		marginRight: '10%',
		[theme.breakpoints.up('md')]: {
			marginTop: '0%'
		}
	},
	rootBodyContentShoppingListText: {
		fontWeight: 'bold',
		marginTop: '1%',
		[theme.breakpoints.up('md')]: {
			marginBottom: '2%',
		}
	},

	rootBodyContentCategory: {
		marginBottom: '10px',
	},
	rootBodyContentCategoryHeader: {
		padding: '10px',
		borderBottom: '2px solid #0D7D5C',
	},
	rootBodyContentCategoryHeaderIcon: {
		width: '30px',
		height: '30px'
	},
	rootBodyContentCategoryHeaderName: {
		marginLeft: '10px',
	},
	rootBodyContentCategoryIngredients: {
		padding: '10px'
	},
	rootBodyContentCategoryIngredientItem: {
		marginTop: '10px',
		marginBottom: '20px',
		marginLeft: '10px'
	},
	rootBodyContentCategoryIngredientItemText: {

	}
}), { index: 1 });
