import {
  DayFood,
  MenuRecipePrimitives,
  MenuTrackerPrimitives,
  WeekKey
} from "@myrealfood-sl/domain";
import { RecipeReducedDocument, recipeTranslator } from "./RecipeTranslator";

export type MenuRecipeDocument = RecipeReducedDocument &
  MenuTrackerPrimitives & {
    rations: number;
  };

export class MenuRecipeTranslator {
  private static instance: MenuRecipeTranslator;

  private constructor() {
    // Empty
  }

  public static getInstance(): MenuRecipeTranslator {
    if (!MenuRecipeTranslator.instance) {
      MenuRecipeTranslator.instance = new MenuRecipeTranslator();
    }

    return MenuRecipeTranslator.instance;
  }

  public transformFromDocumentToPrimitives(
    document: MenuRecipeDocument,
    week: WeekKey,
    food: DayFood,
    day: string
  ): MenuRecipePrimitives {
    const recipePrimitives = recipeTranslator.fromReducedDocumentToPrimitive(document);

    return {
      id: recipePrimitives.id,
      base: recipePrimitives.base,
      nutritional: recipePrimitives.nutritional,
      tracked: document.tracked,
      deleted: document.deleted,
      added: document.added,
      hidden: document.hidden,
      entryReference: document.entryReference ?? {
        week: week.toString(),
        food,
        day
      },
      replacingId: document.replacingId ?? null,
      rations: document.rations ?? 1
    };
  }

  public transformFromPrimitivesToDocument(primitives: MenuRecipePrimitives): MenuRecipeDocument {
    return {
      ...recipeTranslator.toReducedDocument(primitives),
      tracked: primitives.tracked,
      deleted: primitives.deleted,
      added: primitives.added,
      hidden: primitives.hidden,
      entryReference: primitives.entryReference,
      replacingId: primitives.replacingId ?? null,
      rations: primitives.rations ?? 1
    };
  }
}

export const menuRecipeTranslator = MenuRecipeTranslator.getInstance();
