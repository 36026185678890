import React from 'react';

import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions'

import Locale from "../../../controller/locale";

import style from './style';
import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";

export default function PlanBoxTrialCard(props) {
	const classes = style();

	const each = (!!props.discount && !!props.discount.oneTime) ? '' : (`/${(props.item.period === 1) ? Locale.getString('subscribe_month') : Locale.getString('year')}`);
	const header = (!!props.discount && !!props.discount.oneTime) ? (props.item.period === 12 ? ('12 ' + Locale.getString('subscribe_months')) : Locale.getString('one_moth')) : (props.item.header === Locale.getString('subscribe_popular') ? Locale.getString('anual') : props.item.header);

	return (
		<Box
			className={classes.rootBodyContentRightBox}
			border={2}
		>
			<Grid
				className={classes.rootBodyContentRightBoxContainer}
				container
				direction={"column"}
				justify={"center"}
			>
				{!!props.discount && !!props.discount.trialPeriodDays && <Button
					disabled={!!!props.onClick || true}
					variant={"outlined"}
					className={classes.rootBodyContentLeftButton}
					onClick={() => {
						!!props.onClick && props.onClick();
					}}
				>

					<Typography
						className={classes.rootBodyContentSubscribeButtonText}
					>
						{ `${Locale.getString('subscribe_pay_with_card')} ${props.discount.trialPeriodDays} ${Locale.getString('days')}`}
					</Typography>
				</Button>}

				<Typography
					className={classes.rootBodyContentRightBoxContainerName}
				>
					{`${Locale.getString('plan_box_trial_plus')} - ${header}`}
				</Typography>
				<Typography
					className={classes.rootBodyContentRightBoxContainerFinalPriceOld}
				>
					{props.item.currencySymbolStart === true ? props.item.currencySymbol : ''}{Number(!!props.base ? (12 * props.base.price) : props.item.price).toFixed(2)}{props.item.currencySymbolStart === false ? props.item.currencySymbol : ''}{""}{each}

				</Typography>
				<Typography
					className={classes.rootBodyContentRightBoxContainerFinalPrice}
				>
					{!!props.discount && !!props.discount.trialPeriodDays ? Locale.getString('finish_trial') : ''}<b>{props.item.currencySymbolStart === true ? props.item.currencySymbol : ''}{Number(props.item.price * (!!props.discount ? props.discount.discountToPeriod[props.item.period] : 1)).toFixed(2)}{props.item.currencySymbolStart === false ? props.item.currencySymbol : ''}</b>{each}<b>{(!!props.discount && !!props.discount.oneTime && (Number((1 - props.item.price / props.item.originalPrice) * 100).toFixed(0)) !== '0') ? (" - " + Locale.getString('save_money') + Number((1 - (Number(props.item.price * (!!props.discount.trialPeriodDays ? 1 : props.discount.discountToPeriod[props.item.period])).toFixed(2)) / (Number(!!props.base ? (12 * props.base.price) : props.item.price).toFixed(2))) * 100).toFixed(0) + '%') : ''}</b>
				</Typography>
				{!!props.discount && !!props.discount.trialPeriodDays && !!props.displayHint && <Typography
					className={classes.rootBodyContentRightBoxContainerHintPrice}
				>
					{`${Locale.getString('cancel_trial')} ${<a style={{color: '#424242'}}
													   href={Locale.getString('terms_and_conditions_link')}
													   target={"_blank"}>{Locale.getString('trial_conditions')}</a>}.`}
				</Typography>}

				{!!props.onClick &&
				<Button
					variant={"outlined"}
					className={props.item.period === props.current.period ? classes.rootBodyContentRightBoxContainerButton : classes.rootBodyContentRightBoxContainerButtonSelected}
					onClick={() => {
						props.onClick();
					}}
				>
					Plan {props.item.header === Locale.getString('subscribe_popular') ? Locale.getString('anual') : props.item.header}
				</Button>}
			</Grid>
		</Box>
	);
}
