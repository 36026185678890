import Typography from "@material-ui/core/Typography";
import React from "react";
import style from "./style";
import Grid from "@material-ui/core/Grid";

const ReviewComponent = (props) => {
    const classes = style();
    return(
        <Grid
            item
            xs={12}
            md={3}
            className={classes.reviewContainer}
        >
            <Grid
                direction={'row'}
                container
                justify={"center"}>
                <Grid
                    direction={'column'}
                    container
                    justify={"center"}>
                    <Typography
                        align={"center"}
                        className={classes.reviewTitle}
                    >
                        {props.review.title}
                    </Typography>
                    <Typography
                        align={"center"}
                        className={classes.reviewTitle}
                    >
                        {props.review.name}
                    </Typography>
                </Grid>
                    <Grid
                        container
                        justify={"center"}
                    >
                        <img src={"/assets/icons/star.png"} alt="star"/>
                        <img src={"/assets/icons/star.png"} alt="star"/>
                        <img src={"/assets/icons/star.png"} alt="star"/>
                        <img src={"/assets/icons/star.png"} alt="star"/>
                        <img src={"/assets/icons/star.png"} alt="star"/>
                    </Grid>
                    <Typography
                        className={classes.reviewText}
                        align={"center"}
                    >
                        {props.review.text}
                    </Typography>
            </Grid>
        </Grid>
    );
}

export default ReviewComponent;