import React, {useEffect, useState} from 'react';

import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions'

import style from './style';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Header from "../../common/Header";
import Footer from "../../common/Footer";
import {useHistory} from "react-router";
import ds from "../../controller/DataService";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import {ONBOARDING_VARIETY_SUBTITLES, UNITS} from "../../controller/Exports";
import Locale from '../../controller/locale'

export default function Recipe() {
    const classes = style();
    const history = useHistory();

    const [auth, setAuth] = useState(undefined);

    const [recipe, setRecipe] = useState(undefined);

    const [adjustFactor, setAdjustFactor] = useState(1);

    useEffect(() => {
        ds.subscribeToAuthChanges('Recipe', setAuth);

        const encodedAdjustFactor = new URLSearchParams(window.location.search).get('f');
        let adjustFactor = 1;

        if (!!encodedAdjustFactor) {
            const decoded = atob(encodedAdjustFactor).trim();
            if (isNaN(decoded) === false) {
                adjustFactor = decoded;
            }
        }

        let rid = window.location.pathname.split('/');
        rid = rid[rid.length - 1];
        rid = rid.split('?')[0];
        ds.retrieveFreeRecipe(rid,
            (data) => {
                setAdjustFactor(adjustFactor);
                setRecipe(data);
                console.log(data)
            }, (error) => {
                console.log(error);
                //history.push('/');
            });
        return () => {
            ds.unsubscribeFromAuthChanges('Recipe');
        }
    }, []);

    const roundHalf = (num) => {
        if (num < 0.5) {
            return 0.5;
        }
        return Math.round(num * 2) / 2;
    }

    return (
        <div>
            <Container
                className={classes.root}
                maxWidth="xl"
            >
                <Header/>
                {!!recipe &&
                    <Grid
                        className={classes.rootBody}
                        container
                        md={12}
                        xs={12}
                    >

                        <Grid
                            className={classes.rootBodyHeader}
                            container
                            md={12}
                            xs={12}
                            direction={"row"}
                        >
                            <Grid
                                className={classes.rootBodyHeaderLeftMobile}
                                container
                                md={12}
                                xs={12}
                                direction={"column"}
                            >
                                <Grid
                                    item
                                >
                                    <Typography
                                        className={classes.rootBodyHeaderLeftName}
                                    >
                                        {Locale.getLocaledStringValue('name', recipe)}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid
                                className={classes.rootBodyHeaderRight}
                                container
                                md={6}
                                xs={12}
                                direction={"column"}
                                alignItems={"flex-end"}
                            >
                                <img
                                    className={classes.rootBodyHeaderRightImage}
                                    src={recipe.photo}
                                    alt={"MyRealFood"}
                                />
                            </Grid>
                            <Grid
                                container
                                md={6}
                                xs={12}
                            >
                                <Grid
                                    md={12}
                                    className={classes.rootBodyHeaderLeftDesktop}
                                    item
                                >
                                    <Typography
                                        className={classes.rootBodyHeaderLeftName}
                                    >
                                        {Locale.getLocaledStringValue('name', recipe)}
                                    </Typography>
                                </Grid>
                                <Grid
                                    className={classes.rootBodyContentInfoDesktop}
                                    item
                                    md={12}
                                    xs={12}
                                >

                                </Grid>
                                <Grid
                                    className={classes.rootGrow}
                                    container
                                />
                                <Grid
                                    className={classes.rootBodyHeaderLeftTags}
                                    container
                                    md={12}
                                    xs={12}
                                    direction={"row"}
                                    justify={"center"}
                                >
                                    {Locale.getLocaledStringObj("relationalTags", recipe).map(tag => {
                                        return (
                                            <Grid
                                                item
                                                md={4}
                                                xs={5}
                                                alignItems={"center"}
                                                justify={"center"}
                                            >
                                                <Typography
                                                    className={classes.rootBodyHeaderLeftTag}
                                                >
                                                    {tag}
                                                </Typography>
                                            </Grid>
                                        )
                                    })}
                                </Grid>
                            </Grid>
                        </Grid>

                        <div
                            className={classes.rootBodySeparatorFit}
                        />

                        <Grid
                            className={classes.rootBodySeparatorContent}
                            container
                            md={12}
                            direction={"row"}
                            alignItems={"center"}
                        >
                            <Avatar
                                className={classes.rootBodySeparatorAvatar}
                                alt={recipe.author.nickname}
                                src={recipe.author.photo}
                            />

                            <Typography
                                className={classes.rootBodySeparatorAuthorText}
                            >
                                {recipe.author.uid === 'sJObRpDuAGYyikAeT9WP37DYXp02' ? Locale.getString('created_by') : Locale.getString('inpired_in')}
                                <b>{recipe.author.nickname}</b>
                            </Typography>
                        </Grid>

                        <div
                            className={classes.rootBodySeparatorFit}
                        />

                        <Grid
                            className={classes.rootBodyContent}
                            container
                            md={12}
                            direction={"row"}
                        >
                            <Grid
                                className={classes.rootBodyContentRight}
                                container
                                md={4}
                                direction={"column"}
                            >
                                <Grid
                                    item
                                >
                                    <Typography
                                        className={classes.rootBodyContentRightName}
                                    >
                                        {Locale.getString('ingredients')}
                                    </Typography>
                                    <Typography
                                        className={classes.rootBodyContentPeople}
                                    >
                                        {/* Raciones: {!!adjustFactor ? '1' : (!!recipe && !!recipe.people && !isNaN(recipe.people) ? `${recipe.people}` : "1")} */}
                                        {Locale.getString('rations')} {!!adjustFactor ? '1' : (!!recipe && !!recipe.people && !isNaN(recipe.people) ? `${recipe.people}` : "1")}
                                    </Typography>
                                </Grid>

                                <Grid
                                    className={classes.rootBodyContentRightIngredients}
                                    container
                                    direction={"row"}
                                >
                                    {Object.keys(recipe.relationalIngredients).map(id => {
                                        let units = recipe['relationalIngredients'][id].units;
                                        let amount = adjustFactor * recipe['relationalIngredients'][id].amount / recipe.people;
                                        let fixed = recipe['relationalIngredients'][id].fixed;
                                        let isLiquid = recipe['relationalIngredients'][id].isLiquid;
                                        return (
                                            <Grid
                                                className={classes.rootBodyContentRightIngredient}
                                                container
                                                md={12}
                                                direction={"row"}
                                                alignItems={"center"}
                                            >
                                                <Grid
                                                    container
                                                    md={2}
                                                    xs={1}
                                                    alignItems={"center"}
                                                >
                                                    {!!recipe['relationalIngredients'][id]['images'] && !!recipe['relationalIngredients'][id]['images']['product'] &&
                                                        <img
                                                            className={classes.rootBodyContentRightIngredientImage}
                                                            src={recipe['relationalIngredients'][id]['images']['product']}
                                                            alt={"MyRealFood"}
                                                        />}
                                                </Grid>
                                                <Grid
                                                    container
                                                    md={10}
                                                    xs={11}
                                                    direction={"column"}
                                                    justify={"center"}
                                                >
                                                    <Typography
                                                        className={classes.rootBodyContentRightIngredientName}
                                                    >
                                                        {Locale.getLocaledStringValue('name', recipe['relationalIngredients'][id])}
                                                    </Typography>
                                                    <Typography
                                                        className={classes.rootBodyContentRightIngredientBrand}
                                                    >
                                                        {units === 1 ?
                                                            (Math.ceil(Number(parseFloat(amount * fixed).toFixed(1))) + " " + (isLiquid ? ((amount > 1 ? 'mililitros' : 'mililitro')) : ((amount !== 1 ? Locale.getString(UNITS[units].plural) : Locale.getString(UNITS[units].name))))) :
                                                            (roundHalf(Number(parseFloat(amount / fixed).toFixed(1))) + " " + (Number(amount / fixed) !== 1 ? Locale.getString(UNITS[units].plural) : Locale.getString(UNITS[units].name)) + " (" + Math.ceil(Number(parseFloat(amount).toFixed(1))) + "g)")}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        )
                                    })}
                                </Grid>
                            </Grid>
                            <Grid
                                className={classes.rootBodyContentLeft}
                                container
                                md={8}
                                direction={"column"}
                            >
                                <Grid
                                    item
                                >
                                    <Typography
                                        className={classes.rootBodyContentLeftName}
                                    >
                                        {Locale.getString('preparation')}
                                    </Typography>
                                </Grid>

                                <Grid
                                    className={classes.rootBodyContentLeftSteps}
                                    container
                                    direction={"row"}
                                >

                                    {
                                        Object.keys(Locale.getLocaledStringValue("steps", recipe))
                                            .map(key => Number(key.replace('step_', '')))
                                            .sort((a, b) => a - b)
                                            .map(key => {
                                                const step_locale = Locale.getLocaledStringValue("steps", recipe)
                                                const step = step_locale['step_' + key];
                                                let image = '';
                                                if (!!step['images'] && !!step['images'].length && !!step['images'][0]) {
                                                    image = step['images'][0];
                                                }
                                                return (
                                                    <Grid
                                                        container
                                                        md={12}
                                                        direction={"row"}
                                                    >
                                                        <Grid
                                                            className={classes.rootBodyContentLeftStepBadge}
                                                            item
                                                            md={1}
                                                            xs={1}
                                                            alignItems={"flex-end"}
                                                            justify={"flex-end"}
                                                        >
                                                            <Typography
                                                                className={classes.rootBodyContentLeftStepBadgeText}
                                                            >
                                                                {Number(Number(key) + 1)}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid
                                                            container
                                                            md={11}
                                                            xs={11}
                                                            alignItems={"center"}
                                                        >
                                                            <Typography
                                                                className={classes.rootBodyContentLeftStep}
                                                            >
                                                                {Locale.getLocaledStringValue('description', step)}
                                                            </Typography>

                                                            {!!image &&
                                                                <img
                                                                    className={classes.rootBodyContentLeftImage}
                                                                    src={image}
                                                                    alt={"MyRealFood"}
                                                                />}
                                                        </Grid>
                                                    </Grid>
                                                )
                                            })}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>}
            </Container>
            <Footer/>
        </div>
    );
}
