import React, {useEffect, useRef, useState} from 'react';

import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions'

import style from './style';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Header from "../../common/Header";

import Typography from '@material-ui/core/Typography';

import Button from '@material-ui/core/Button';
import Footer from "../../common/Footer";
import {useHistory} from "react-router";
import ds from "../../controller/DataService";
import {__DEFAULT_PROMOCODE__, __FREE_TRIAL__} from "../../controller/Exports";
import Locale from '../../controller/locale'


const NEW_DESIGN = [];
NEW_DESIGN[0] = __FREE_TRIAL__ || true;

export default function Home() {
    const classes = style();
    const history = useHistory();

    const [auth, setAuth] = useState(undefined);

    const _aboutRef = useRef(null);

    const paramScroll = new URLSearchParams(window.location.search).get('s');
    if (!!paramScroll && !!paramScroll.length && paramScroll === 'about') {
        setTimeout(() => {
            if (!!_aboutRef && !!_aboutRef.current)
                window.scrollTo(0, _aboutRef.current.offsetTop)
        }, 200)
    }

    useEffect(() => {
        ds.subscribeToAuthChanges('Home', setAuth);

        let paramPromo = new URLSearchParams(window.location.search).get('promo') || ds.retrunAvailablePromocode(__DEFAULT_PROMOCODE__[0]);
        if (!!paramPromo && !!paramPromo.length && paramPromo === 'BFDESIGN') {
            NEW_DESIGN[0] = true;
        }

        return () => {
            ds.unsubscribeFromAuthChanges('Home');
        }
    }, [auth]);

    const reviews = [{
        user: '@alvarogs19',
        text: Locale.getString('home_review_1'),
    }, {
        user: 'Raquel Rodríguez',
        text: Locale.getString('home_review_2'),
    }, {
        user: '@lana_fender',
        text: Locale.getString('home_review_3'),
    }, {
        user: 'Álvaro Sánchez',
        text: Locale.getString('home_review_4'),
    }, {
        user: '@mariaab11',
        text: Locale.getString('home_review_5'),
    }, {
        user: '@lana_fender',
        text: Locale.getString('home_review_6'),
    }];
    const [review, setReview] = useState(0);

    return (
        <div>
            <Container
                className={classes.root}
                maxWidth="xl"
            >
                <Header/>
                <Grid
                    className={classes.rootBody}
                    container
                    md={12}
                    spacing={2}
                >
                    {!!!NEW_DESIGN[0] && <Grid
                        className={classes.rootBodySection1}
                        container
                        md={12}
                        direction="row"
                        justify="center"
                        alignItems="center"
                    >
                        <Grid
                            className={classes.rootBodySection1Left}
                            container
                            md={6}
                            justify="center"
                            alignItems="center"
                        >
                            <Typography
                                className={classes.rootBodySection1LeftTitle}
                                variant="h3"
                            >
                                {Locale.getString('home_eat_healthy_text')}
                            </Typography>
                            <Typography
                                className={classes.rootBodySection1LeftSubtitle}
                                variant="h4"
                            >
                                {Locale.getString('home_mrf_app_convert_text')}
                            </Typography>

                            <Grid
                                className={classes.rootBodySection1LeftButtons}
                                container
                                md={12}
                                alignItems="center"
                                direction="row"
                            >
                                <Grid
                                    item
                                    md={3}
                                >
                                    <Button
                                        className={classes.rootBodySection1LeftButtonsLeft}
                                        disableFocusRipple
                                        disableElevation
                                        onClick={() => {
                                            window.location.assign("https://apps.apple.com/es/app/myrealfood/id1458031749");
                                            window.ttq.track('Download', {
                                                os: 'ios'
                                            })
                                        }}
                                    >
                                        <img
                                            className={classes.rootBodySection1LeftButtonsLeftImage}
                                            src="/assets/buttons/ic_app_store.png"
                                            alt="App Store"
                                        />
                                    </Button>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                >
                                    <Button
                                        className={classes.rootBodySection1LeftButtonsRight}
                                        disableFocusRipple
                                        disableElevation
                                        onClick={() => {
                                            window.location.assign("https://play.google.com/store/apps/details?id=es.myrealfood.myrealfood");
                                            window.ttq.track('Download', {
                                                os: 'android'
                                            })
                                        }}
                                    >
                                        <img
                                            className={classes.rootBodySection1LeftButtonsRightImage}
                                            src="/assets/buttons/ic_google_play.png"
                                            alt="Google Play"
                                        />
                                    </Button>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                >
                                    <Button
                                        className={classes.rootBodySection1LeftButtonsRight}
                                        disableFocusRipple
                                        disableElevation
                                        onClick={() => {
                                            window.location.assign("https://appgallery.cloud.huawei.com/ag/n/app/C103495711?locale=es_ES&source=myrealfood.app&subsource=HOME&shareTo=world&shareFrom=myrealfood.app");
                                            window.ttq.track('Download', {
                                                os: 'huawei'
                                            })
                                        }}
                                    >
                                        <img
                                            className={classes.rootBodySection1LeftButtonsRightImage}
                                            src="/assets/buttons/ic_app_gallery.png"
                                            alt="App Gallery"
                                        />
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid
                            className={classes.rootBodySection1RightDesktop}
                            container
                            md={6}
                            direction="row"
                            justify="center"
                            alignItems="center"
                        >
                            <Grid
                                md={12}
                                alignItems="center"
                                justify={"center"}
                            >
                                <img
                                    className={classes.rootBodySection1RightDesktopImage}
                                    src="/assets/backgrounds/bg_app_tracker.png"
                                    alt="MyRealFood"
                                />
                            </Grid>
                        </Grid>

                        <Grid
                            className={classes.rootBodySection1RightMobile}
                            container
                            md={6}
                            direction="row"
                        >
                            <Grid
                                className={classes.rootBodySection1RightMobileButtons}
                                container
                                md={6}
                                xs={6}
                                direction="column"
                                justify={"center"}
                            >
                                <Button
                                    className={classes.rootBodySection1RightMobileButtonsLeft}
                                    disableFocusRipple
                                    disableElevation
                                    onClick={() => {
                                        window.location.assign("https://apps.apple.com/es/app/myrealfood/id1458031749");
                                        window.ttq.track('Download', {
                                            os: 'ios'
                                        })
                                    }}
                                >
                                    <img
                                        className={classes.rootBodySection1RightMobileButtonsLeftImage}
                                        src="/assets/buttons/ic_app_store.png"
                                        alt="App Store"
                                    />
                                </Button>
                                <Button
                                    className={classes.rootBodySection1LeftMobileButtonsRight}
                                    disableFocusRipple
                                    disableElevation
                                    onClick={() => {
                                        window.location.assign("https://play.google.com/store/apps/details?id=es.myrealfood.myrealfood");
                                        window.ttq.track('Download', {
                                            os: 'android'
                                        })
                                    }}
                                >
                                    <img
                                        className={classes.rootBodySection1LeftMobileButtonsRightImage}
                                        src="/assets/buttons/ic_google_play.png"
                                        alt="Google Play"
                                    />
                                </Button>
                                <Button
                                    className={classes.rootBodySection1LeftMobileButtonsRight}
                                    disableFocusRipple
                                    disableElevation
                                    onClick={() => {
                                        window.location.assign("https://appgallery.cloud.huawei.com/ag/n/app/C103495711?locale=es_ES&source=myrealfood.app&subsource=HOME&shareTo=world&shareFrom=myrealfood.app");
                                        window.ttq.track('Download', {
                                            os: 'huawei'
                                        })
                                    }}
                                >
                                    <img
                                        className={classes.rootBodySection1LeftMobileButtonsRightImage}
                                        src="/assets/buttons/ic_app_gallery.png"
                                        alt="App Gallery"
                                    />
                                </Button>
                            </Grid>
                            <Grid
                                className={classes.rootBodySection1RightMobileButtons}
                                container
                                md={6}
                                xs={6}
                                direction="column"
                                alignItems={"center"}
                                justify={"center"}
                            >
                                <img
                                    className={classes.rootBodySection1RightMobileImage}
                                    src="/assets/backgrounds/bg_app_tracker.png"
                                    alt="MyRealFood"
                                />
                            </Grid>

                        </Grid>
                    </Grid>}

                    {!!!NEW_DESIGN[0] && <div
                        className={classes.rootSeparator}
                    />}

                    {!!NEW_DESIGN[0] && <Grid
                        className={classes.rootBodySectionFreeTrial}
                        container
                        md={12}
                        direction="row"
                        justify="center"
                    >
                        <div
                            className={classes.rootBodySectionFreeTrialBackground}
                        />

                        <Grid
                            style={{zIndex: 100}}
                            container
                            md={12}
                            direction="row"
                            justify="center"
                            alignItems="center"
                        >
                            <Grid
                                className={classes.rootBodySectionFreeTrialLeft}
                                container
                                md={7}
                                alignItems="center"
                            >
                                <Grid
                                    md={12}
                                    sm={12}
                                    xs={12}
                                >
                                    <Typography
                                        className={classes.rootBodySectionFreeTrialLeftTitle}
                                        variant="h3"
                                    >
                                        {Locale.getString('home_better_style_life_text')}
                                    </Typography>
                                </Grid>
                                <Grid
                                    md={12}
                                    sm={12}
                                    xs={12}
                                >
                                    <Typography
                                        className={classes.rootBodySectionFreeTrialLeftSubtitle}
                                    >
                                        {Locale.getString('home_download_here')}
                                    </Typography>
                                </Grid>
                                <Grid
                                    className={classes.rootBodySection1LeftButtons}
                                    container
                                    md={12}
                                    sm={12}
                                    xs={12}
                                    alignItems="center"
                                    direction="row"
                                >
                                    {true && <Grid
                                        container
                                        md={3}
                                        sm={4}
                                        xs={4}
                                    >
                                        <Button
                                            className={classes.rootBodySection1LeftButtonsLeft}
                                            disableFocusRipple
                                            disableElevation
                                            onClick={() => {
                                                window.location.assign("https://apps.apple.com/es/app/myrealfood/id1458031749");
                                                window.ttq.track('Download', {
                                                    os: 'ios'
                                                })
                                            }}
                                        >
                                            <img
                                                className={classes.rootBodySection1LeftButtonsLeftImage}
                                                src="/assets/buttons/ic_app_store.png"
                                                alt="App Store"
                                            />
                                        </Button>
                                    </Grid>}
                                    {true && <Grid
                                        container
                                        md={3}
                                        sm={4}
                                        xs={4}
                                    >
                                        <Button
                                            className={classes.rootBodySection1LeftButtonsRight}
                                            disableFocusRipple
                                            disableElevation
                                            onClick={() => {
                                                window.location.assign("https://play.google.com/store/apps/details?id=es.myrealfood.myrealfood");
                                                window.ttq.track('Download', {
                                                    os: 'android'
                                                })
                                            }}
                                        >
                                            <img
                                                className={classes.rootBodySection1LeftButtonsRightImage}
                                                src="/assets/buttons/ic_google_play.png"
                                                alt="Google Play"
                                            />
                                        </Button>
                                    </Grid>}
                                    {true && <Grid
                                        container
                                        md={3}
                                        sm={4}
                                        xs={4}
                                    >
                                        <Button
                                            className={classes.rootBodySection1LeftButtonsRight}
                                            disableFocusRipple
                                            disableElevation
                                            onClick={() => {
                                                window.location.assign("https://appgallery.cloud.huawei.com/ag/n/app/C103495711?locale=es_ES&source=myrealfood.app&subsource=HOME&shareTo=world&shareFrom=myrealfood.app");
                                                window.ttq.track('Download', {
                                                    os: 'huawei'
                                                })
                                            }}
                                        >
                                            <img
                                                className={classes.rootBodySection1LeftButtonsRightImage}
                                                src="/assets/buttons/ic_app_gallery.png"
                                                alt="App Gallery"
                                            />
                                        </Button>
                                    </Grid>}
                                    {false && <Button
                                        className={classes.rootBodySectionButtonContentWhite}
                                        onClick={() => {
                                            window.scrollTo({top: 0, behavior: 'smooth'});
                                            history.push(`/plus/?period=12&promo=${ds.retrunAvailablePromocode(__DEFAULT_PROMOCODE__[0])}`)
                                        }}
                                    >
                                        {Locale.getString('home_go_plus')}
                                    </Button>}
                                </Grid>
                                <Grid
                                    md={12}
                                    sm={12}
                                    xs={12}
                                >
                                    <Typography
                                        className={classes.rootBodySectionFreeTrialLeftSubtitle}
                                        variant="h3"
                                    >
                                        <a style={{color: 'white'}}
                                           href={"https://myrealfood.app/docs/condiciones_especiales_prueba_gratuita.pdf"}
                                           target={"_blank"}></a>{"\n"}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid
                                className={classes.rootBodySectionFreeTrialRightDesktop}
                                container
                                md={5}
                                direction="row"
                                justify="center"
                                alignItems="center"
                            >
                                <Grid
                                    md={12}
                                    alignItems="center"
                                    justify={"center"}
                                >
                                    {false && <img
                                        className={classes.rootBodySectionFreeTrialRightDesktopImage}
                                        src="/assets/backgrounds/bg_app_plus.png"
                                        alt="MyRealFood"
                                    />}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>}

                    <Grid
                        className={classes.rootBodySectionConsults}
                        container
                        md={12}
                        direction="column"
                        justify="center"
                        alignItems="center"
                    >
                        <Typography
                            className={classes.rootBodySectionConsultsHeaderTitle}
                            variant="h3"
                        >
                            {Locale.getString('home_consults_pro')}
                        </Typography>
                        <Typography
                            variant="h4"
                            className={classes.rootBodySectionConsultsHeaderSubtitle}
                        >
                            {Locale.getString('home_consults_pro_description')}
                        </Typography>

                        <Button
                            className={classes.rootBodySectionConsultsBodyRightContentButtonDesktop}
                            onClick={() => {
                                window.location.assign("https://consultas.myrealfood.app/")
                            }}
                        >
                            {Locale.getString('home_consults_pro_button')}
                        </Button>
                    </Grid>

                    <div className={classes.rootSeparator}/>

                    <Grid
                        className={classes.rootBodySection2}
                        container
                        md={12}
                        direction="column"
                        justify="center"
                        alignItems="center"
                    >
                        <Grid
                            className={classes.rootBodySection2Header}
                            container
                            md={12}
                            direction="column"
                            justify="center"
                            alignItems="center"
                        >
                            <Typography
                                className={classes.rootBodySection2HeaderTitle}
                                variant="h3"
                            >
                                {Locale.getString('home_what_is_mrf')}
                            </Typography>
                        </Grid>
                    </Grid>

                    {!ds.retrieveAuthData() &&
                        <Grid
                            className={classes.rootBodySectionButtonMobile}
                            container
                            md={12}
                            justify="center"
                            alignItems="center"
                            spacing={2}
                        >
                            <Button
                                className={classes.rootBodySectionButtonContent}
                                onClick={() => {
                                    window.scrollTo({top: 0, behavior: 'smooth'});
                                    if (!!ds.retrieveAuthData()) {
                                        history.push("/profile")
                                    } else {
                                        history.push("/register")
                                    }
                                }}
                            >
                                {!!ds.retrieveAuthData() ? Locale.getString('home_go_to_perfile') : Locale.getString('home_create_user')}
                            </Button>
                        </Grid>}

                    <Grid
                        className={classes.rootBodySection2Body}
                        container
                        md={12}
                        justify="center"
                        alignItems="center"
                        spacing={2}
                    >
                        {[{
                            icon: "/assets/backgrounds/bg_pepper_chicken_table.png",
                            title: Locale.getString('home_community_title'),
                            description: Locale.getString('home_community_desc'),
                            alt: "Community"
                        }, {
                            icon: "/assets/backgrounds/bg_scanner_options.png",
                            title: Locale.getString('home_scanner_title'),
                            description: Locale.getString('home_scanner_desc'),
                            alt: "Scanner"
                        }, {
                            icon: "/assets/backgrounds/bg_cook_pepper.png",
                            title: Locale.getString('home_habits_title'),
                            description: Locale.getString('home_habits_desc'),
                            alt: "Habits"
                        }, {
                            icon: "/assets/backgrounds/bg_product_calification.png",
                            title: Locale.getString('home_tracker_title'),
                            description: Locale.getString('home_tracker_desc'),
                            alt: "Tracker"
                        }].map(item => {
                            return (
                                <Grid
                                    className={classes.rootBodySection2BodyPaper}
                                    container
                                    md={6}
                                    justify="center"
                                    alignItems="center"
                                >
                                    <Grid
                                        className={classes.rootBodySection2BodyPaperContent}
                                        container
                                        md={12}
                                        justify="center"
                                        alignItems="center"
                                    >
                                        <img
                                            className={classes.rootBodySection2BodyPaperImage}
                                            src={item.icon}
                                            alt={item.alt}
                                        />
                                        <Typography
                                            variant="h4"
                                            className={classes.rootBodySection2BodyPaperTitle}
                                        >
                                            {item.title}
                                        </Typography>
                                        <Typography
                                            variant="h6"
                                            className={classes.rootBodySection2BodyPaperDescription}
                                        >
                                            {item.description}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            )
                        })}
                    </Grid>

                    {!ds.retrieveAuthData() &&
                        <Grid
                            className={classes.rootBodySectionButtonDesktop}
                            container
                            md={12}
                            justify="center"
                            alignItems="center"
                            spacing={2}
                        >
                            <Button
                                className={classes.rootBodySectionButtonContent}
                                onClick={() => {
                                    window.scrollTo({top: 0, behavior: 'smooth'});
                                    if (!!ds.retrieveAuthData()) {
                                        history.push("/profile")
                                    } else {
                                        history.push("/register")
                                    }
                                }}
                            >
                                {!!ds.retrieveAuthData() ? Locale.getString('home_go_to_perfile') : Locale.getString('home_create_user')}
                            </Button>
                        </Grid>}

                    <div className={classes.rootSeparator}/>

                    <Grid
                        className={classes.rootBodySection3}
                        container
                        md={12}
                        direction="row"
                        justify="center"
                        alignItems="center"
                    >
                        <Grid
                            className={classes.rootBodySection3Header}
                            container
                            md={12}
                            direction="column"
                            justify="center"
                            alignItems="center"
                        >
                            <Typography
                                className={classes.rootBodySection3HeaderTitle}
                                variant="h3"
                            >
                                {Locale.getString('plan_plus')}
                            </Typography>
                            <Typography
                                variant="h4"
                                className={classes.rootBodySection3HeaderSubtitle}
                            >
                                {Locale.getString('home_plan_adapter_text')}
                            </Typography>
                        </Grid>

                        <Grid
                            className={classes.rootBodySection3Body}
                            container
                            md={12}
                            direction="row"
                            justify="center"
                            alignItems="center"
                        >
                            <Grid
                                className={classes.rootBodySection3BodyLeft}
                                container
                                md={6}
                                direction="row"
                                justify="center"
                                alignItems="center"
                            >
                                <img
                                    className={classes.rootBodySection3BodyLeftImage}
                                    src="/assets/backgrounds/bg_pepper_chicken_window.png"
                                    alt="MyRealFood"
                                />
                            </Grid>
                            <Button
                                className={classes.rootBodySection3BodyRightContentButtonMobile}
                                onClick={() => {
                                    window.scrollTo({top: 0, behavior: 'smooth'});
                                    history.push(`/plus/?period=12&promo=${ds.retrunAvailablePromocode(__DEFAULT_PROMOCODE__[0])}`)
                                }}
                            >
                                Descubre el Plan Plus
                            </Button>
                            <Grid
                                className={classes.rootBodySection3BodyRight}
                                container
                                md={6}
                                alignItems="center"
                            >
                                {[{
                                    icon: "/assets/backgrounds/bg_weekly_plan.png",
                                    alt: "Weekly Plan",
                                    boldText: Locale.getString('home_weekly_bold_text'),
                                    normalText: Locale.getString('home_weekly_text')
                                }, {
                                    icon: "/assets/backgrounds/bg_foods.png",
                                    alt: "Automatic Tracker",
                                    boldText: Locale.getString('home_automatic_tracker_bold_text'),
                                    normalText: Locale.getString('home_automatic_tracker_text')
                                }, {
                                    icon: "/assets/backgrounds/bg_shopping_list.png",
                                    alt: "Shopping List",
                                    boldText: Locale.getString('shopping_list'),
                                    normalText: Locale.getString('home_shopping_list_text')
                                }, {
                                    icon: "/assets/backgrounds/bg_plus_group.png",
                                    alt: "Exclusive Group",
                                    boldText: Locale.getString('home_exclusive_group_bold_text'),
                                    normalText: Locale.getString('home_exclusive_group_text')
                                }].map(item => {
                                    return (
                                        <Grid
                                            className={classes.rootBodySection3BodyRightContent}
                                            container
                                            md={12}
                                            alignItems="center"
                                            direction={"row"}
                                        >
                                            <Grid
                                                container
                                                md={1}
                                                xs={2}
                                                alignItems={"center"}
                                                justify={"center"}
                                            >
                                                <img
                                                    className={classes.rootBodySection3BodyRightContentImage}
                                                    src={item.icon}
                                                    alt={item.alt}
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                md={11}
                                                xs={10}
                                            >
                                                <Typography
                                                    className={classes.rootBodySection3BodyRightContentText}
                                                    variant="h5"
                                                >
                                                    <b>{item.boldText}</b>{" "}{item.normalText}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    )
                                })}
                                <Button
                                    className={classes.rootBodySection3BodyRightContentButtonDesktop}
                                    onClick={() => {
                                        window.scrollTo({top: 0, behavior: 'smooth'});
                                        history.push(`/plus/?period=12&promo=${ds.retrunAvailablePromocode(__DEFAULT_PROMOCODE__[0])}`)
                                    }}
                                >
                                    {Locale.getString('home_discover_plan')}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>

                    <div className={classes.rootSeparator}/>

                    <Grid
                        className={classes.rootBodySection4}
                        container
                        md={12}
                        direction="row"
                    >
                        <Grid
                            className={classes.rootBodySection4HeaderLeft}
                            container
                            md={6}
                            direction="column"
                            justify="center"
                        >
                            <Typography
                                className={classes.rootBodySection4HeaderLeftTitle}
                                variant="h3"
                            >
                                {Locale.getString('home_happy_realfooders')}
                            </Typography>
                        </Grid>

                        <Grid
                            className={classes.rootBodySection4HeaderRight}
                            container
                            md={6}
                            direction="row"
                        >
                            {[{
                                icon: "/assets/icons/ic_ios.png",
                                alt: "iOS",
                                description: Locale.getString('home_ios_ranking')
                            }, {
                                icon: "/assets/icons/ic_android.png",
                                alt: "Android",
                                description: Locale.getString('home_android_ranking')
                            }].map(item => {
                                return (
                                    <Grid
                                        className={classes.rootBodySection4HeaderRightSOContent}
                                        container
                                        md={12}
                                        direction="row"
                                        justify="flex-end"
                                    >
                                        <Grid
                                            className={classes.rootBodySection4HeaderRightSOContentLeft}
                                            item
                                            md={1}
                                        >
                                            <img
                                                className={classes.rootBodySection4HeaderRightSOContentLeftLogo}
                                                src={item.icon}
                                                alt={item.alt}
                                            />
                                        </Grid>
                                        <Grid
                                            className={classes.rootBodySection4HeaderRightSOContentRight}
                                            item
                                            md={4}
                                            direction="column"
                                        >
                                            <Grid
                                                className={classes.rootBodySection4HeaderRightSOContentRightStars}
                                                container
                                                md={12}
                                                direction="row"
                                                alignItems={"center"}
                                                justify={"space-around"}
                                            >
                                                {['1', '2', '3', '4', '5'].map(item => {
                                                    return (
                                                        <img
                                                            className={classes.rootBodySection4HeaderRightSOContentRightStarsImage}
                                                            src={"/assets/icons/ic_star.png"}
                                                            alt={"star"}
                                                        />
                                                    )
                                                })}
                                            </Grid>
                                            <Grid
                                                className={classes.rootBodySection4HeaderRightSOContentRightRank}
                                                container
                                                md={12}
                                                direction="row"
                                                alignItems={"center"}
                                                justify={"space-around"}
                                            >
                                                <Typography
                                                    className={classes.rootBodySection4HeaderRightSOContentRightRankText}
                                                >
                                                    {item.description}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )
                            })}
                        </Grid>

                        <Grid
                            className={classes.rootBodyPeopleContent}
                            container
                            md={12}
                            xs={12}
                            direction={"row"}
                            alignItems={"center"}
                            justify={"center"}
                        >
                            <Grid
                                className={classes.rootBodyPeopleContentLeft}
                                container
                                md={1}
                                xs={1}
                                alignItems={"center"}
                                justify={"center"}
                                onClick={() => {
                                    if (review - 1 < 0) {
                                        setReview(reviews.length - 1)
                                    } else {
                                        setReview(review - 1)
                                    }
                                }}
                            >
                                <img
                                    className={classes.rootBodyPeopleContentLeftImage}
                                    src={"/assets/icons/ic_left_arrow.png"}
                                    alt={"<"}
                                />
                            </Grid>
                            <Grid
                                className={classes.rootBodyPeopleContentMiddle}
                                conteiner
                                md={10}
                                xs={10}
                                direction={"column"}
                                alignItems={"center"}
                                justify={"center"}
                            >
                                <Grid
                                    className={classes.rootBodyPeopleContentMiddleContent}
                                    container
                                    direction={"row"}
                                    alignItems={"center"}
                                    justify={"center"}
                                >
                                    <Grid
                                        className={classes.rootBodyPeopleContentMiddleContentRealfooder}
                                        container
                                        md={12}
                                        direction={"column"}
                                        alignItems={"center"}
                                        justify={"center"}

                                    >
                                        <Typography
                                            className={classes.rootBodyPeopleContentMiddleContentRealfooderDisplayName}
                                        >
                                            {reviews[review].user}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Typography
                                    className={classes.rootBodyPeopleContentMiddleContentRealfooderComment}
                                >
                                    {reviews[review].text}
                                </Typography>
                            </Grid>
                            <Grid
                                className={classes.rootBodyPeopleContentRight}
                                container
                                md={1}
                                xs={1}
                                alignItems={"center"}
                                justify={"center"}
                                onClick={() => {
                                    if (review + 1 > reviews.length - 1) {
                                        setReview(0)
                                    } else {
                                        setReview(review + 1)
                                    }
                                }}
                            >
                                <img
                                    className={classes.rootBodyPeopleContentRightImage}
                                    src={"/assets/icons/ic_right_arrow.png"}
                                    alt={">"}
                                />
                            </Grid>
                        </Grid>

                        <Grid
                            className={classes.rootBodySection4ReviewsBottom}
                            container
                            md={12}
                            alignItems={"center"}
                            justify={"center"}
                        >
                            <Button
                                className={classes.rootBodySection4ReviewsBottomButton}
                                onClick={() => {
                                    Math.round(Math.random()) === 0 ? window.location.assign("https://apps.apple.com/es/app/myrealfood/id1458031749") : window.location.assign("https://play.google.com/store/apps/details?id=es.myrealfood.myrealfood")
                                    window.ttq.track('Download', {
                                        os: Math.round(Math.random()) === 0 ? 'ios' : 'android'
                                    });
                                }}
                            >
                                {Locale.getString('home_discover_user_cases')}
                            </Button>
                        </Grid>
                    </Grid>

                    <div
                        ref={_aboutRef}
                        className={classes.rootSeparatorTransparent}/>
                </Grid>
            </Container>

            <Grid
                className={classes.rootBodyBottom}
                container
                md={12}
                direction="row"
                justify="center"
                alignItems="center"
            >
                <Grid
                    className={classes.rootBodyBottomGrow}
                    item
                    md={1}
                />
                <Grid
                    item
                    md={3}
                >
                    <Typography
                        className={classes.rootBodyBottomTextLeft}
                        variant="h5"
                    >
                        {!!ds.retrieveAuthData() ? Locale.getString('home_access_plan') : Locale.getString('home_get_a_realfooder_here')}
                    </Typography>
                </Grid>
                <Grid
                    container
                    md={3}
                    alignItems={"center"}
                    justify={"center"}
                >
                    <Button
                        className={classes.rootBodyBottomButton}
                        onClick={() => {
                            window.scrollTo({top: 0, behavior: 'smooth'});
                            if (!!ds.retrieveAuthData()) {
                                history.push("/plus/menu")
                            } else {
                                history.push("/register")
                            }
                        }}
                    >
                        {!!ds.retrieveAuthData() ? (!!ds.isPlusUser() ? Locale.getString('home_access_plan_alt') : Locale.getString('home_discover_plan')) : Locale.getString('home_create_user')}
                    </Button>
                </Grid>
                <Grid
                    item
                    md={2}
                >
                    <Typography
                        className={classes.rootBodyBottomTextRight}
                        variant="h5"
                    >
                        {Locale.getString('home_or_in_app')}
                    </Typography>
                </Grid>
                <Grid
                    item
                    md={1}
                >
                    <Button
                        className={classes.rootBodyBottomButtonDownload}
                        disableFocusRipple
                        disableElevation
                        onClick={() => {
                            window.location.assign("https://apps.apple.com/es/app/myrealfood/id1458031749");
                            window.ttq.track('Download', {
                                os: 'ios'
                            })
                        }}
                    >
                        <img
                            className={classes.rootBodyBottomButtonDownloadImage}
                            src="/assets/buttons/ic_app_store.png"
                            alt="App Store"
                        />
                    </Button>
                </Grid>
                <Grid
                    item
                    md={1}
                >
                    <Button
                        className={classes.rootBodyBottomButtonDownload}
                        disableFocusRipple
                        disableElevation
                        onClick={() => {
                            window.location.assign("https://play.google.com/store/apps/details?id=es.myrealfood.myrealfood");
                            window.ttq.track('Download', {
                                os: 'android'
                            })
                        }}
                    >
                        <img
                            className={classes.rootBodyBottomButtonDownloadImage}
                            src="/assets/buttons/ic_google_play.png"
                            alt="Google Play"
                        />
                    </Button>
                </Grid>
                <Grid
                    item
                    md={1}
                >
                    <Button
                        className={classes.rootBodyBottomButtonDownload}
                        disableFocusRipple
                        disableElevation
                        onClick={() => {
                            window.location.assign("https://appgallery.cloud.huawei.com/ag/n/app/C103495711?locale=es_ES&source=myrealfood.app&subsource=HOME&shareTo=world&shareFrom=myrealfood.app");
                            window.ttq.track('Download', {
                                os: 'huawei'
                            })
                        }}
                    >
                        <img
                            className={classes.rootBodyBottomButtonDownloadImage}
                            src="/assets/buttons/ic_app_gallery.png"
                            alt="App Gallery"
                        />
                    </Button>
                </Grid>
                <Grid
                    className={classes.rootBodyBottomGrow}
                    item
                    md={1}
                />
            </Grid>
            <Footer/>
        </div>
    );
}


