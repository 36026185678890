import {makeStyles} from "@material-ui/core/styles";

export default makeStyles((theme) => ({
	rootBodyContentRightBox: {
		marginTop: '2%',
		marginBottom: '2%',
		borderWidth: '1',
		borderColor: '#19C692',
		borderRadius: 20,
		width: '95%',
		[theme.breakpoints.up('md')]: {
			width: '90%',
		},
	},
	rootBodyContentRightBoxContainer: {
		paddingLeft: '7%',
		paddingRight: '7%',
	},
	rootBodyContentLeftButton: {
		width: '100%',
		marginTop: '10%',
		color: '#FFF',
		backgroundColor: '#19C692',
		borderRadius: 10,
		paddingTop: '2%',
		paddingBottom: '2%',
		fontWeight: 'bold',
		fontSize: 12,
	},
	rootBodyContentSubscribeButtonText: {
		color: 'white',
		fontWeight: 'bold',
	},
	rootBodyContentRightBoxContainerName: {
		color: 'black',
		fontWeight: 'bold',
		marginTop: '5%',
		fontSize: 16,
		[theme.breakpoints.up('md')]: {
			fontSize: 17,
		},
	},
	rootBodyContentRightBoxContainerTitle: {
		borderTopRightRadius: 16,
		borderTopLeftRadius: 16,
		width: '100%',
		backgroundColor: '#19C692',
		textAlign: 'center',
		paddingTop: '6%',
		paddingBottom: '6%',
		fontWeight: 'bold',
		color: 'white',
		fontSize: 16,
		[theme.breakpoints.up('md')]: {
			fontSize: 18,
		},
	},
	rootBodyContentRightBoxContainerSubtitle: {
		fontWeight: 'bold',
		textAlign: 'center',
		marginTop: '15%',
		fontSize: 16,
		[theme.breakpoints.up('md')]: {
			fontSize: 18,
		},
	},
	rootBodyContentRightBoxContainerPrice: {
		textAlign: 'center',
		color: '#19C692',
		fontSize: 14,
		fontWeight: 'bold',
		[theme.breakpoints.up('md')]: {
			fontSize: 16,
		},
	},
	rootBodyContentRightBoxContainerFinalPriceOld: {
		textDecorationLine: 'line-through',
		textDecorationStyle: 'solid',

		color: '#121212',
		marginTop: '5%',
		fontSize: 14,
		whiteSpace: "pre-wrap",
		[theme.breakpoints.up('md')]: {
			fontSize: 15,
		},
	},
	rootBodyContentRightBoxContainerFinalPrice: {
		color: '#121212',
		marginTop: '5%',
		marginBottom: '5%',
		fontSize: 14,
		whiteSpace: "pre-wrap",
		[theme.breakpoints.up('md')]: {
			fontSize: 15,
		},
	},
	rootBodyContentRightBoxContainerMonthlyPrice: {
		textAlign: 'center',
		marginBottom: '15%',
		fontSize: 12,
		color: '#828282',
		[theme.breakpoints.up('md')]: {
			fontSize: 16,
			marginBottom: '10%',
		},
	},
	rootBodyContentRightBoxContainerButton: {
		marginTop: '5%',
		borderWidth: 1,
		color: '#FFF',
		backgroundColor: '#19C692',
		borderColor: '#19C692',
		borderRadius: 10,
		paddingTop: '3%',
		paddingBottom: '3%',
		paddingLeft: '4%',
		paddingRight: '4%',
		fontWeight: 'bold',
		fontSize: 10,
		marginBottom: '15%',
		width: '100%',
		marginLeft: '1%',
		marginRight: '1%',
		[theme.breakpoints.up('md')]: {
			marginLeft: '0%',
			marginRight: '0%',
			width: '100%',
			fontWeight: 'bold',
			fontSize: 12,
		},
	},
	rootBodyContentRightBoxContainerButtonSelected: {
		marginTop: '5%',
		borderWidth: 1,
		color: '#19C692',
		backgroundColor: '#FFF',
		borderColor: '#19C692',
		borderRadius: 10,
		paddingTop: '3%',
		paddingBottom: '3%',
		paddingLeft: '4%',
		paddingRight: '4%',
		fontWeight: 'bold',
		fontSize: 10,
		marginBottom: '15%',
		width: '100%',
		marginLeft: '1%',
		marginRight: '1%',
		[theme.breakpoints.up('md')]: {
			marginLeft: '0%',
			marginRight: '0%',
			width: '100%',
			fontWeight: 'bold',
			fontSize: 12,
		},
	},
	rootBodyContentRightBoxContainerHintPrice: {
		color: '#424242',
		whiteSpace: "pre-wrap",
		fontSize: 12,
		marginBottom: '10%',
		[theme.breakpoints.up('md')]: {
			fontSize: 13,
			marginTop: '0%',
			marginBottom: '10%',
		},
	}
}), { index: 1 });
