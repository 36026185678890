import React from 'react';

import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions'

import style from './style';
import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import LinearProgress from "@material-ui/core/LinearProgress";
import Locale from '../../../../controller/locale'

export default function CardSummaryGoals(props) {
	const classes = style();


	function LinearProgressWithLabel(props) {
		return (
			<Box width="100%" mr={1}>
				<LinearProgress variant="determinate" {...props} />
			</Box>
		);
	}

	return (
		<Grid
			container
			md={12}
			xs={12}
			alignItems={"center"}
			justify={"center"}
		>
			<Box
				className={classes.rootBodyCardOption}
				boxShadow={3}
				borderColor={"secondary.main"}
				bgcolor={"background.paper"}
				borderRadius={20}
				m={1}
				p={1}
			>
				<Grid
					container
					md={12}
					xs={12}
					direction={"row"}
					alignItems={"center"}
					justify={"center"}
				>
					<Grid
						container
						md={5}
						xs={5}
						alignItems={"center"}
						justify={"center"}
					>
						<Typography
							className={classes.rootBodyCardOptionHeaderTitle}
						>
							{props.head}
						</Typography>
					</Grid>
					<Grid
						container
						md={7}
						xs={7}
						direction={"column"}
						justify={"center"}
					>
						<Grid
							container
							md={7}
							xs={7}
							direction={"column"}
							justify={"center"}
							alignItems={"center"}
						>
							<Grid
								container
								md={12}
								xs={12}
								direction={"row"}
								alignItems={"center"}
								justify={"center"}
							>
								<Grid
									item
									md={2}
									xs={2}
								>
									<img
										className={classes.rootBodyCardOptionIcon}
										src={"/assets/backgrounds/bg_premium_basic_kcal.png"}
										alt={"MyRealFood"}
									/>
								</Grid>
								<Grid
									md={10}
									xs={6}
								>
									<Typography
										className={classes.rootBodyCardOptionMacronutrientsTextBar}
									>
										<b>{props.kcals}</b> {Locale.getString("kcal")}
									</Typography>
								</Grid>
							</Grid>
							<LinearProgressWithLabel
								className={classes.rootBodyCardOptionMacronutrientsBar}
								value={100}/>
						</Grid>
					</Grid>
				</Grid>
				<Grid
					container
					md={12}
					xs={12}
					direction={"row"}
					alignItems={"center"}
					justify={"center"}
				>
					<Grid
						container
						md={5}
						xs={5}
						alignItems={"center"}
						justify={"center"}
					>
						<img
							className={!!props.head ? classes.rootBodyCardOptionImage : classes.rootBodyCardOptionImagePadded}
							src={props.image}
							alt={"MyRealFood"}
						/>
					</Grid>
					<Grid
						container
						md={7}
						xs={7}
						direction={"column"}
						justify={"center"}
					>
						<Typography
							className={classes.rootBodyCardOptionMacronutrientsText}
						>
							{Locale.getString('carbohydrates')}: <b>{props.carbohydrates}{props.grams ? Locale.getString('grams_unit') : '%'}</b>
						</Typography>
						<Typography
							className={classes.rootBodyCardOptionMacronutrientsText}
						>
							{Locale.getString('proteins')}: <b>{props.proteins}{props.grams ? Locale.getString('grams_unit') : '%'}</b>
						</Typography>
						<Typography
							className={classes.rootBodyCardOptionMacronutrientsText}
						>
							{Locale.getString('fats')}: <b>{props.fats}{props.grams ? Locale.getString('grams_unit') : '%'}</b>
						</Typography>
					</Grid>
				</Grid>
			</Box>
		</Grid>
	);
}


