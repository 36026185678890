import React, {useEffect, useRef, useState} from 'react';

import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions'

import style from './style';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Header from "../../common/Header";
import Footer from "../../common/Footer";
import {useHistory} from "react-router";
import ds from "../../controller/DataService";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Avatar from "@material-ui/core/Avatar";
import PlanBox from "../Subscribe/PlanBox";
import {__DEFAULT_PROMOCODE__, __FREE_TRIAL__, DEFAULT_PREMIUM_BASIC_OPTIONS} from "../../controller/Exports";
import Collapse from "@material-ui/core/Collapse/Collapse";
import PlanBoxTrial from "../Subscribe/PlanBoxTrial";
import PlanBoxTrialCard from "../Subscribe/PlanBoxTrialCard";
import PlusFAQ from "./PlusFAQ";
import Locale from '../../controller/locale'

export default function Plus() {
	const classes = style();
	const history = useHistory();

	const [auth, setAuth] = useState(undefined);

	const [enabledCountries, setEnabledCountries] = useState();
	const [userCountry, setUserCountry] = useState(null);

	const [plan, setPlan] = useState(12);
	const [premiumBasicOptions, setPremiumBasicOptions] = useState(DEFAULT_PREMIUM_BASIC_OPTIONS(userCountry));
	const [current, setCurrent] = useState(Object.values(DEFAULT_PREMIUM_BASIC_OPTIONS(userCountry)).filter(option => option.period === 12)[0]);

	const [promo, setPromo] = useState(ds.retrunAvailablePromocode(__DEFAULT_PROMOCODE__[0]));
	const [promoError, setPromoError] = useState(false);

	const [discount, setDiscount] = useState(undefined);

	const [scrolled, setScrolled] = useState(false);
	const _onboardingRef = useRef(null);
	const _faqRef = useRef(null);

	const [review, setReview] = useState(0);
	const PLUS_REVIEWS = [{
		user: '@Martix68',
		text: Locale.getString('plus_reviews_1')
	}, {
		user: '@cynthiaasil',
		text: Locale.getString('plus_reviews_2')
	}, {
		user: '@agla_ya',
		text: Locale.getString('plus_reviews_3')
	}, {
		user: 'Ana Navarro',
		text: Locale.getString('plus_reviews_4')
	}, {
		user: '@sanyears1',
		text: Locale.getString('plus_reviews_5')
	}, {
		user: 'A Nordic Story Dinamarca',
		text: Locale.getString('plus_reviews_6')
	}]

	const paramScroll = new URLSearchParams(window.location.search).get('s');
	if (!!paramScroll && !!paramScroll.length && paramScroll === 'faq') {
		setTimeout(() => {
			if (!!_faqRef && !!_faqRef.current && !scrolled) {
				window.scrollTo(0, _faqRef.current.offsetTop - 70)
				setScrolled(true);
			}
		}, 200)
	} else if(!!paramScroll && !!paramScroll.length && paramScroll === 'plans') {
		setTimeout(() => {
			if (!!_onboardingRef && !!_onboardingRef.current && !scrolled) {
				window.scrollTo(0, _onboardingRef.current.offsetTop - 70)
				setScrolled(true);
			}
		}, 200)
	}

	useEffect(() => {
		ds.subscribeToAuthChanges('Subscribe', (user) => {
			setAuth(user);
			if (ds.isPlusUser()) {
				window.scrollTo({top: 0, behavior: 'smooth'});
				setTimeout(() => {
					if (!ds.hasCompletedPlusOnboarding()) {
						history.push('/onboarding/?payment=succeed')
					} else {
						history.push('/plus/menu')
					}
				}, 250);
			}
		});

		retrieveConfig();

		let paramRnd = new URLSearchParams(window.location.search).get('rnd');
		if (!ds.retrieveAuthData() && !!paramRnd && !!paramRnd.length) {
			ds.signInWithAuthToken(paramRnd);
		}

		let paramMrt = new URLSearchParams(window.location.search).get('mrt');
		if (!ds.retrieveAuthData() && !!paramMrt && !!paramMrt.length) {
			ds.signInWithAESToken(paramMrt);
		}

		let paramPlan = new URLSearchParams(window.location.search).get('plan');
		if (!!paramPlan && !isNaN(paramPlan)) {
			setPlan(Number(paramPlan));
			if (Number(paramPlan) === 12 || Number(paramPlan) === 6 || Number(paramPlan) === 1) {
				setCurrent(Object.values(premiumBasicOptions).filter(option => option.period === Number(paramPlan))[0]);
			}
		}

		let paramPromo = new URLSearchParams(window.location.search).get('promo') || ds.retrunAvailablePromocode(__DEFAULT_PROMOCODE__[0]);
		if (!!paramPromo && !!paramPromo.length) {
			setPromo(paramPromo);
		}

		ds.retrievePromoCode(paramPromo, false, data => {
			setDiscount(data);
		}, (error, data) => {
			setDiscount(data);
		});

		return () => {
			ds.unsubscribeFromAuthChanges('Subscribe');
		}
	}, [plan, auth]);

	const retrieveConfig = async () => {
		const country = await ds.retrieveUserCountry();
		setUserCountry(country);
		ds.retrievePremiumBasicOptions(country).then(options => {
			setPremiumBasicOptions(options);
			setCurrent(Object.values(options).filter(option => option.period === plan)[0])
		})
	}

	return (
		<div>
			<Container
				className={classes.root}
				maxWidth="xl"
			>
				<Header/>

				{!!__FREE_TRIAL__[0] && <Grid
					className={classes.rootBodySectionFreeTrial}
					container
					md={12}
					direction="row"
					justify="center"
				>
					<div
						className={classes.rootBodySectionFreeTrialBackground}
					/>

					<Grid
						style={{zIndex: 100}}
						container
						md={12}
						direction="row"
						alignItems="center"
					>
						<Grid
							className={classes.rootBodySectionFreeTrialLeft}
							container
							md={12}
							alignItems="center"
						>
							<Grid
								md={12}
								sm={12}
								xs={12}
							>
								<Typography
									className={classes.rootBodySectionFreeTrialLeftTitle}
									variant="h3"
								>
									{Locale.getString('plus_tier_trial')}
								</Typography>
							</Grid>
							<Grid
								md={12}
								sm={12}
								xs={12}
							>
								<Typography
									className={classes.rootBodySectionFreeTrialLeftSubtitle}
									variant="h3"
								>
									{Locale.getString('plan_plus_black_friday')}
								</Typography>
							</Grid>
							<Grid
								md={12}
								sm={12}
								xs={12}
								alignItems={'center'}
								justify={'center'}
							>
								<Button
									variant={"outlined"}
									className={classes.rootBodySectionFreeTrialRightMobileButtonsSubscribe}
									onClick={() => {
										window.scrollTo(0, _onboardingRef.current.offsetTop - 80)
									}}
								>
									{Locale.getString('see_plans_plus')}
								</Button>
							</Grid>
							<Grid
								md={12}
								sm={12}
								xs={12}
							>
								<Typography
									className={classes.rootBodySectionFreeTrialLeftSubtitleHint}
									variant="h3"
								>
									{`${Locale.getString('plan_plus_cancel_everyday')} ${<a style={{color: 'white'}} href={Locale.getString('plan_plus_free_condition_link')} target={"_blank"}>{Locale.getString('trial_conditions')}</a>}. \n ${Locale.getString('plan_plus_offer_end')}` }
								</Typography>
							</Grid>
						</Grid>
					</Grid>
				</Grid>}

				<Grid
					className={classes.rootBody}
					container
					md={12}
				>
					<Grid
						className={classes.rootBodyHeader}
						content
						md={12}
						direction={"column"}
						alignItems={"center"}
						justify={"center"}
					>
						{!!!__FREE_TRIAL__[0] && <Typography
							className={classes.rootBodyHeaderTitle}
							variant="h3"
						>
							{Locale.getString('plan_plus')}
						</Typography>}
						<Typography
							className={classes.rootBodyHeaderSubtitle}
							variant="h4"
						>
							{Locale.getString('plan_plus_designed_text')}
						</Typography>

						{!!!promo && <Grid
							container
							md={12}
							direction={"column"}
							alignItems={"center"}
							justify={"center"}
						>
							<Button
								className={classes.rootBodyHeaderButton}
								onClick={() => {
									window.scrollTo(0, _onboardingRef.current.offsetTop)
								}}
							>
								{Locale.getString('become_a_plus')}
							</Button>
						</Grid>}
					</Grid>

					{!!promo && !__FREE_TRIAL__[0] &&
					<Grid
						ref={_onboardingRef}
						container
						md={12}
						direction={"row"}
						justify={"content"}
					>
						<Grid
							className={classes.rootBodyOnboardingContent}
							container
							md={12}
							direction={"row"}
							alignItems={"center"}
							justify={"center"}
						>
							<Grid
								item
								md={6}
								xs={6}
							>
								<PlanBox
									discount={discount}
									item={Object.values(premiumBasicOptions).filter(option => option.period === 1)[0]}
									base={Object.values(premiumBasicOptions).filter(option => option.period === 1)[0]}
									onClick={() => {
										window.scrollTo({top: 0, behavior: 'smooth'});
										let promo = new URLSearchParams(window.location.search).get('promo') || ds.retrunAvailablePromocode(__DEFAULT_PROMOCODE__[0]);
										if (!!promo) {
											history.push(`/subscribe/?plan=1&promo=${promo}`);
										} else {
											history.push(`/subscribe/?plan=1&promo=${ds.retrunAvailablePromocode(__DEFAULT_PROMOCODE__[0])}`);
										}
									}}
								/>
							</Grid>
							<Grid
								item
								md={6}
								xs={6}
							>
								<PlanBox
									discount={discount}
									item={Object.values(premiumBasicOptions).filter(option => option.period === 12)[0]}
									base={Object.values(premiumBasicOptions).filter(option => option.period === 1)[0]}
									onClick={() => {
										window.scrollTo({top: 0, behavior: 'smooth'});
										let promo = new URLSearchParams(window.location.search).get('promo') || ds.retrunAvailablePromocode(__DEFAULT_PROMOCODE__[0]);
										if (!!promo) {
											history.push(`/subscribe/?plan=12&promo=${promo}`);
										} else {
											history.push(`/subscribe/?plan=12&promo=${ds.retrunAvailablePromocode(__DEFAULT_PROMOCODE__[0])}`);
										}
									}}
								/>
							</Grid>
						</Grid>

						<Grid
							className={classes.rootBodyOnboardingMobile}
							item
							md={6}
							xs={12}
						>
							<Typography
								className={classes.rootBodyOnboardingSubTitleMobile}
							>
							</Typography>
						</Grid>
					</Grid>}

					<Grid
						className={classes.rootBodySteps}
						container
						md={12}
						sm={12}
						xs={12}
					>
						<div
							className={classes.rootBodyStepsAbsolute}
						/>
						<Grid
							className={classes.rootBodyStep}
							container
							md={12}
							alignItems={"center"}
						>
							<Grid
								className={classes.rootBodyStepLeft}
								md={5}
								container
								alignItems={"center"}
								direction={"column"}
							>
								<Grid
									className={classes.rootBodyGrow}
								/>
								<Typography
									className={classes.rootBodyStepLeftTitle}
								>
									{Locale.getString('semanal_plan')}
								</Typography>
								<Typography
									className={classes.rootBodyStepLeftDescription}
								>
									{Locale.getString('plan_plus_adapter_preference')}
								</Typography>
								<Grid
									className={classes.rootBodyGrow}
								/>
							</Grid>
							<Grid
								className={classes.rootBodySteMiddle}
								container
								md={2}
								alignItems={"center"}
								justify={"center"}
							>
								<img
									className={classes.rootBodySteMiddleImage}
									src={"/assets/backgrounds/bg_weekly_plan.png"}
									alt={"MyRealFood"}
								/>
							</Grid>
							<Grid
								className={classes.rootBodyStepRight}
								container
								md={5}
							>

							</Grid>
						</Grid>

						<Grid
							className={classes.rootBodyStep}
							container
							md={12}
						>
							<Grid
								className={classes.rootBodyStepLeft}
								container
								md={5}
							>

							</Grid>
							<Grid
								className={classes.rootBodySteMiddle}
								container
								md={2}
								alignItems={"center"}
								justify={"center"}
							>
								<img
									className={classes.rootBodySteMiddleImage}
									src={"/assets/backgrounds/bg_foods.png"}
									alt={"MyRealFood"}
								/>
							</Grid>
							<Grid
								className={classes.rootBodyStepRight}
								md={5}
								container
								alignItems={"center"}
								direction={"column"}
							>
								<Grid
									className={classes.rootBodyGrow}
								/>
								<Typography
									className={classes.rootBodyStepRightTitle}
								>
									{Locale.getString('plan_plus_custom_objetive')}
								</Typography>
								<Typography
									className={classes.rootBodyStepRightDescription}
								>
									{Locale.getString('plan_plus_lost_weigth')}
								</Typography>
								<Grid
									className={classes.rootBodyGrow}
								/>
							</Grid>
						</Grid>

						<Grid
							className={classes.rootBodyStep}
							container
							md={12}
						>
							<Grid
								className={classes.rootBodyStepLeft}
								md={5}
								container
								alignItems={"center"}
								direction={"column"}
							>
								<Grid
									className={classes.rootBodyGrow}
								/>
								<Typography
									className={classes.rootBodyStepLeftTitle}
								>
									{Locale.getString('shopping_list')}
								</Typography>
								<Typography
									className={classes.rootBodyStepLeftDescription}
								>
									{Locale.getString('shopping_list_explain')}
								</Typography>
								<Grid
									className={classes.rootBodyGrow}
								/>
							</Grid>
							<Grid
								className={classes.rootBodySteMiddle}
								container
								md={2}
								alignItems={"center"}
								justify={"center"}
							>
								<img
									className={classes.rootBodySteMiddleImage}
									src={"/assets/backgrounds/bg_shopping_list.png"}
									alt={"MyRealFood"}
								/>
							</Grid>
							<Grid
								className={classes.rootBodyStepRight}
								container
								md={5}
							>

							</Grid>
						</Grid>

						<Grid
							className={classes.rootBodyStep}
							container
							md={12}
						>
							<Grid
								className={classes.rootBodyStepLeft}
								container
								md={5}
							>

							</Grid>
							<Grid
								className={classes.rootBodySteMiddle}
								container
								md={2}
								alignItems={"center"}
								justify={"center"}
							>
								<img
									className={classes.rootBodySteMiddleImage}
									src={"/assets/backgrounds/bg_plus_group.png"}
									alt={"MyRealFood"}
								/>
							</Grid>
							<Grid
								className={classes.rootBodyStepRight}
								md={5}
								container
								alignItems={"center"}
								direction={"column"}
							>
								<Grid
									className={classes.rootBodyGrow}
								/>
								<Typography
									className={classes.rootBodyStepRightTitle}
								>
									{Locale.getString('community_exclusive')}
								</Typography>
								<Typography
									className={classes.rootBodyStepRightDescription}
								>
									{Locale.getString('share_your_progress')}
								</Typography>
								<Grid
									className={classes.rootBodyGrow}
								/>
							</Grid>
						</Grid>
					</Grid>

					<Grid
						className={classes.rootBodyStepsMobile}
						container
						md={12}
					>
						{[{
							title: Locale.getString('semanal_plan'),
							description: Locale.getString('plan_plus_adapter_preference'),
							icon: '/assets/backgrounds/bg_weekly_plan.png'
						}, {
							title: Locale.getString('plan_plus_custom_objetive'),
							description: Locale.getString('plan_plus_lost_weigth'),
							icon: '/assets/backgrounds/bg_foods.png'
						}, {
							title: Locale.getString('shopping_list'),
							description: Locale.getString('shopping_list_explain'),
							icon: '/assets/backgrounds/bg_shopping_list.png'
						}, {
							title: Locale.getString('community_exclusive'),
							description: Locale.getString('share_your_progress'),
							icon: '/assets/backgrounds/bg_plus_group.png'
						}].map(item => {
							return (
								<Grid
									className={classes.rootBodyStepsMobileCard}
									container
									md={12}
									xs={12}
									sm={6}
								>
									<Grid
										className={classes.rootBodyStepsMobileCardContainer}
										alignItems={"center"}
										justify={"center"}
										direction={"column"}
										container
									>
										<img
											className={classes.rootBodyStepsMobileCardImage}
											src={item.icon}
											alt={"MyRealFood"}
										/>
										<Typography
											className={classes.rootBodyStepsMobileCardTitle}
										>
											{item.title}
										</Typography>
										<Typography
											className={classes.rootBodyStepsMobileCardDescription}
										>
											{item.description}
										</Typography>
									</Grid>
								</Grid>
							)
						})}
					</Grid>

					<Grid
						className={classes.rootBodyResults}
						container
						md={12}
						direction={"column"}
						alignItems={"center"}
						justify={"content"}
					>
						<Typography
							className={classes.rootBodyResultsTitle}
							variant="h3"
						>
							{Locale.getString('plan_plus_results')}
						</Typography>
						<Typography
							className={classes.rootBodyResultsSubtitle}
							variant="h4"
						>
							{Locale.getString('plan_plus_real_results')}
						</Typography>

						<Grid
							className={classes.rootBodyResultsContent}
							container
							direction={"row"}
							spacing={2}
							alignItems={"center"}
							justify={"center"}
						>
							<Grid
								className={classes.rootBodyResultsContentCard}
								container
								md={5}
								alignItems={"center"}
								justify={"content"}
							>
								<Typography
									className={classes.rootBodyResultsContentCardTitle}
									md={12}
								>
									{Locale.getString('plan_plus_people_percentage')}
								</Typography>
								<Typography
									className={classes.rootBodyResultsContentCardDescription}
									md={12}
								>
									De los usuarios afirman haber cambiado de alimentación tras 1 mes usando el Plan
									Plus
								</Typography>
							</Grid>
							<Grid
								className={classes.rootBodyResultsContentCard}
								container
								md={5}
								alignItems={"center"}
								justify={"content"}
							>
								<Typography
									className={classes.rootBodyResultsContentCardTitle}
									md={12}
								>
									{Locale.getString('plan_plus_people_change_habit_percentage')}
								</Typography>
								<Typography
									className={classes.rootBodyResultsContentCardDescription}
									md={12}
								>
									{Locale.getString('plan_plus_people_change_habit_percentage_text')}
								</Typography>
							</Grid>
						</Grid>

						<Grid
							className={classes.rootBodyResultsContentMobile}
							container
							spacing={2}
							alignItems={"center"}
							justify={"center"}
						>
							<Grid
								className={classes.rootBodyResultsContentMobileCard}
								container
								md={12}
								xs={12}
								alignItems={"center"}
								justify={"center"}
								direction={"row"}
							>
								<Grid
									item
									md={4}
									xs={4}
								>
									<Typography
										className={classes.rootBodyResultsContentMobileCardTitle}
									>
										{Locale.getString('plan_plus_people_percentage')}
									</Typography>
								</Grid>
								<Grid
									item
									md={8}
									xs={8}
								>
									<Typography
										className={classes.rootBodyResultsContentMobileCardDescription}
									>
										{Locale.getString('plan_plus_people_percentage_text')}
									</Typography>
								</Grid>
							</Grid>
							<Grid
								className={classes.rootBodyResultsContentMobileCard}
								container
								md={12}
								xs={12}
								alignItems={"center"}
								justify={"center"}
								direction={"row"}
							>
								<Grid
									item
									md={4}
									xs={4}
								>
									<Typography
										className={classes.rootBodyResultsContentMobileCardTitle}
									>
										{Locale.getString('plan_plus_people_change_habit_percentage')}
									</Typography>
								</Grid>
								<Grid
									item
									md={8}
									xs={8}
								>
									<Typography
										className={classes.rootBodyResultsContentMobileCardDescription}
									>
										{Locale.getString('plan_plus_people_change_habit_percentage_text')}
									</Typography>
								</Grid>
							</Grid>
						</Grid>
					</Grid>

					<Grid
						container
						alignItems={"center"}
						justify={"center"}
					>
						<Box
							className={classes.rootBodyPeople}
							boxShadow={3}
							bgcolor="background.paper"
							m={1}
							p={1}
						>
							<Grid
								className={classes.rootBodyPeopleContent}
								container
								md={12}
								xs={12}
								direction={"row"}
								alignItems={"center"}
								justify={"center"}
							>
								<Grid
									className={classes.rootBodyPeopleContentLeft}
									container
									md={1}
									xs={1}
									alignItems={"center"}
									justify={"center"}
									onClick={() => {
										if (review - 1 < 0) {
											setReview(PLUS_REVIEWS.length - 1)
										} else {
											setReview(review - 1)
										}
									}}
								>
									<img
										className={classes.rootBodyPeopleContentLeftImage}
										src={"/assets/icons/ic_left_arrow.png"}
										alt={"<"}
									/>
								</Grid>
								<Grid
									className={classes.rootBodyPeopleContentMiddle}
									conteiner
									md={10}
									xs={10}
									direction={"column"}
									alignItems={"center"}
									justify={"center"}
								>
									<Grid
										className={classes.rootBodyPeopleContentMiddleContent}
										container
										direction={"row"}
										alignItems={"center"}
										justify={"center"}
									>
										{false && <Grid
											className={classes.rootBodyPeopleContentMiddleContentAvatar}
											container
											md={6}
											alignItems={"center"}
											justify={"flex-end"}
										>
											<Avatar
												className={classes.rootBodyPeopleContentMiddleContentAvatarImage}
												alt={'MyRealFood'}
												src={"/assets/backgrounds/bg_weekly_plan.png"}
											/>
										</Grid>}
										<Grid
											className={classes.rootBodyPeopleContentMiddleContentRealfooder}
											container
											md={12}
											direction={"column"}
											alignItems={"center"}
											justify={"center"}

										>
											<Typography
												className={classes.rootBodyPeopleContentMiddleContentRealfooderDisplayName}
											>
												{PLUS_REVIEWS[review].user}
											</Typography>
											{false && <Typography
												className={classes.rootBodyPeopleContentMiddleContentRealfooderNickname}
											>

											</Typography>}
										</Grid>
									</Grid>
									<Typography
										className={classes.rootBodyPeopleContentMiddleContentRealfooderComment}
									>
										{PLUS_REVIEWS[review].text}
									</Typography>
								</Grid>
								<Grid
									className={classes.rootBodyPeopleContentRight}
									container
									md={1}
									xs={1}
									alignItems={"center"}
									justify={"center"}
									onClick={() => {
										if (review + 1 > PLUS_REVIEWS.length - 1) {
											setReview(0)
										} else {
											setReview(review + 1)
										}
									}}
								>
									<img
										className={classes.rootBodyPeopleContentRightImage}
										src={"/assets/icons/ic_right_arrow.png"}
										alt={">"}
									/>
								</Grid>

							</Grid>
						</Box>
					</Grid>

					{<Grid
						ref={_onboardingRef}
						className={classes.rootBodyOnboarding}
						container
						md={12}
						justify={"content"}
					>
						<Grid
							item
							md={12}
							xs={12}
							alignItems={"center"}
							justify={"center"}
						>
							<Typography
								className={classes.rootBodyOnboardingTitle}
								variant="h3"
							>
								{Locale.getString('we_start')}
							</Typography>
						</Grid>
						<Grid
							className={classes.rootBodyOnboardingDesktop}
							item
							md={12}
							xs={12}
						>
							<Typography
								className={classes.rootBodyOnboardingSubTitle}
							>
								{Locale.getString('select_plan_and_change')}
							</Typography>
						</Grid>

						<Grid
							className={classes.rootBodyOnboardingContent}
							container
							md={12}
							direction={"row"}
							alignItems={"center"}
							justify={"center"}
						>
							<Grid
								container
								md={!!!__FREE_TRIAL__[0] ? 4 : 4}
								xs={!!!__FREE_TRIAL__[0] ? 4 : 12}
								alignItems={"center"}
								justify={"center"}
							>
								{!!!__FREE_TRIAL__[0] && <PlanBox
									discount={discount}
									item={Object.values(premiumBasicOptions).filter(option => option.period === 1)[0]}
									base={Object.values(premiumBasicOptions).filter(option => option.period === 1)[0]}
									onClick={() => {
										window.scrollTo({top: 0, behavior: 'smooth'});
										let promo = new URLSearchParams(window.location.search).get('promo') || ds.retrunAvailablePromocode(__DEFAULT_PROMOCODE__[0]);
										if (!!promo) {
											history.push(`/subscribe/?plan=1&promo=${promo}`);
										} else {
											history.push(`/subscribe/?plan=1&promo=${ds.retrunAvailablePromocode(__DEFAULT_PROMOCODE__[0])}`);
										}
									}}
								/>}
								{!!__FREE_TRIAL__[0] && <PlanBoxTrial
									discount={discount}
									current={current}
									item={Object.values(premiumBasicOptions).filter(option => option.period === 1)[0]}
									base={Object.values(premiumBasicOptions).filter(option => option.period === 1)[0]}
									onClick={() => {
										setPlan(1)
										setCurrent(Object.values(premiumBasicOptions).filter(option => option.period === 1)[0]);
									}}
								/>}
							</Grid>
							<Grid
								container
								md={!!!__FREE_TRIAL__[0] ? 4 : 4}
								xs={!!!__FREE_TRIAL__[0] ? 4 : 12}
								alignItems={"center"}
								justify={"center"}
							>
								{!!!__FREE_TRIAL__[0] && <PlanBox
									discount={discount}
									item={Object.values(premiumBasicOptions).filter(option => option.period === 12)[0]}
									base={Object.values(premiumBasicOptions).filter(option => option.period === 1)[0]}
									onClick={() => {
										window.scrollTo({top: 0, behavior: 'smooth'});
										let promo = new URLSearchParams(window.location.search).get('promo') || ds.retrunAvailablePromocode(__DEFAULT_PROMOCODE__[0]);
										if (!!promo) {
											history.push(`/subscribe/?plan=12&promo=${promo}`);
										} else {
											history.push(`/subscribe/?plan=12&promo=${ds.retrunAvailablePromocode(__DEFAULT_PROMOCODE__[0])}`);
										}
									}}
								/>}
								{!!__FREE_TRIAL__[0] && <PlanBoxTrial
									discount={discount}
									current={current}
									item={Object.values(premiumBasicOptions).filter(option => option.period === 12)[0]}
									base={Object.values(premiumBasicOptions).filter(option => option.period === 1)[0]}
									onClick={() => {
										setPlan(12)
										setCurrent(Object.values(premiumBasicOptions).filter(option => option.period === 12)[0]);
									}}
								/>}
							</Grid>
						</Grid>

						<Grid
							className={classes.rootBodyOnboardingMobile}
							item
							md={6}
							xs={12}
						>
							<Typography
								className={classes.rootBodyOnboardingTitleMobile}
							>
								{Locale.getString('select_plan_and_change')}
							</Typography>
						</Grid>

						{!!__FREE_TRIAL__[0] && <Grid
							className={classes.rootBodyOnboardingContent}
							container
							md={12}
							direction={"row"}
							alignItems={"center"}
							justify={"center"}
						>
							<Button
								variant={"outlined"}
								className={classes.rootBodySectionFreeTrialRightMobileButtonsSubscribeTrial}
								onClick={() => {
									let promo = new URLSearchParams(window.location.search).get('promo') || ds.retrunAvailablePromocode(__DEFAULT_PROMOCODE__[0]);
									if(!!!promo) {
										promo = 'BLACKFRIDAY';
									}
									history.push(`/subscribe/?promo=${promo}&plan=${plan}`);
									window.scrollTo({top: 0, behavior: 'smooth'});
								}}
							>
								{Locale.getString('subscribe_trial_plan_text')}
							</Button>

							<Typography
								className={classes.rootBodyOnboardingSubTitleMobile}
							>
								{Locale.getString('trial_subscription_cancel_advice')}
							</Typography>
						</Grid>}
					</Grid>}
					<PlusFAQ
						ref={_faqRef}
					/>
				</Grid>
			</Container>
			<Footer/>
		</div>
	);
}


