import React from "react";
import style from "./style";

const Info = ({text}) => {
    const classes = style();

    return (
        <div className={classes.root}>
            <img
                width={22}
                height={22}
                src={'/assets/icons/info.png'}
            />
            {text}
        </div>
    )
}

export default Info;
