import React, {useEffect, useState} from "react";
import style from "./style";

const Checkbox = ({check}) => {
    const classes = style();
    const [checked, setChecked] = useState(check);

    useEffect(() => {
        setChecked(check)
    });

    return (
        <div
            className={
                checked ? classes.checked : classes.unchecked
            }/>
    )
}

export default Checkbox;