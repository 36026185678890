import {makeStyles} from "@material-ui/core/styles";
import React from "react";

export default makeStyles((theme) => ({
    root: {
        position: 'absolute',
        bottom: '80px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: '#19C692',
        borderRadius: '61px',
        width: '200px',
        height: '50px',
        cursor: 'pointer',
        color: '#fff',
        fontSize: '15px',
        left: 'calc(50% - 100px)',
        'media (max-width: 800px)': {
            width: '160px',
            height: '23px',
            borderRadius: '50px',
            left: 'calc(50% - 80px)',
        }
    },
    rootDisabled: {
        position: 'absolute',
        bottom: '80px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: '#DDDDDD',
        borderRadius: '61px',
        width: '200px',
        height: '50px',
        cursor: 'pointer',
        color: '#fff',
        fontSize: '15px',
        left: 'calc(50% - 100px)',
        'media (max-width: 800px)': {
            width: '160px',
            height: '23px',
            borderRadius: '50px',
            left: 'calc(50% - 80px)',
        }
    }
}), { index: 1 });
