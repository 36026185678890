import React, {useEffect, useState} from 'react';
import style from './style'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Link from "@material-ui/core/Link";
import {useHistory} from "react-router-dom";
import ds from '../../controller/DataService'
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Locale from '../../controller/locale'

export default function Header() {
  const classes = style();
  const history = useHistory();

  const [auth, setAuth] = useState(undefined);

  useEffect(() => {
    ds.subscribeToAuthChanges('Header', setAuth);
    return () => {
      ds.unsubscribeFromAuthChanges('Header');
    }
  });

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = !ds.retrieveAuthData() ? (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{vertical: 'top', horizontal: 'right'}}
      id={menuId}
      keepMounted
      transformOrigin={{vertical: 'top', horizontal: 'right'}}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={() => {
        handleMenuClose();
        history.push("/login")
      }}>{Locale.getString('init_session')}</MenuItem>
      <MenuItem onClick={() => {
        handleMenuClose();
        history.push("/register")
      }}>{Locale.getString('register')}</MenuItem>
    </Menu>
  ) : (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{vertical: 'top', horizontal: 'right'}}
      id={menuId}
      keepMounted
      transformOrigin={{vertical: 'top', horizontal: 'right'}}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {!!ds.retrieveUserEmail() && <MenuItem disabled={true}>{`${Locale.getString('subscribe_email')}: ${ds.retrieveUserEmail()}`}</MenuItem>}
      {!!ds.retrieveUserEmail() && <MenuItem
        disabled={true}>{`${Locale.getString('user')}: ${!!ds.retrieveUserData() && !!ds.retrieveUserData()['public'] && !!ds.retrieveUserData()['public']['nickname'] ? ("@" + ds.retrieveUserData()['public']['nickname'] + "") : ''}`}</MenuItem>}
      {ds.isPlusUser() && ds.canHandleSubscription() && <MenuItem onClick={() => {
        handleMenuClose();
        if (!!ds.retrieveUserData() && !!ds.retrieveUserData()['private'] && !!ds.retrieveUserData()['private']['portalURL']) {
          window.location = ds.retrieveUserData()['private']['portalURL'];
        } else {
          ds.retrieveUserSubscriptionPanel(
            response => {
              response.json().then(data => {
                console.log(data.url)
                window.location = data.url;
              }).catch(error => {
                console.log(error);
              })
            }, error => {
              console.log(error);
            })
        }
      }}>{Locale.getString('admin_subscriptions')}</MenuItem>}
      <MenuItem onClick={() => {
        handleMenuClose();
        ds.userSignOut(() => {
          history.push('/')
        })
      }}>{Locale.getString('exit')}</MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{vertical: 'top', horizontal: 'right'}}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{vertical: 'top', horizontal: 'right'}}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={() => {
        handleMenuClose();
        history.push("/login")
      }}>{Locale.getString('init_session')}</MenuItem>
      <MenuItem onClick={() => {
        handleMenuClose();
        history.push("/register")
      }}>{Locale.getString('register')}</MenuItem>
    </Menu>
  );

  return (
    <div className={classes.content}>
      <AppBar className={classes.appBar} position="sticky" color="white" elevation={0}>
        <Toolbar>
          <div className={classes.sectionDesktop}>
            <Link
              component="button"
              onClick={() => {
                if (window.location.pathname === '/') {
                  window.scrollTo({top: 0, behavior: 'smooth'});
                } else {
                  history.push("/")
                }
              }}
            >
              <img className={classes.image}
                   src={"/assets/icons/ic_myrealfood.png"}
                   alt="MyRealFood"/>
            </Link>
          </div>
          <div className={classes.sectionMobile}>
            <Link
              component="button"
              onClick={() => {
                history.push("/")
              }}
            >
              <img className={classes.imageMobile}
                   src="https://firebasestorage.googleapis.com/v0/b/realfooding-app.appspot.com/o/dynamic_links%2Fic_dls_myrealfood.png?alt=media&token=03b91856-a608-4acb-837f-d28bd386fc0e"
                   alt="MyRealFood"/>
            </Link>
          </div>
          <div className={classes.grow}/>
          <div className={classes.grow}/>
          <div className={classes.sectionDesktop}>
            {!ds.retrieveAuthData() ?
              <div className={classes.headerButton}>
                <Link
                  component="button"
                  onClick={() => {
                    history.push("/login")
                  }}
                >
                  <Typography
                    className={classes.headerSignInText}
                  >
                    {Locale.getString('init_session')}
                  </Typography>
                </Link>

                <Link
                  component="button"
                  onClick={() => {
                    history.push("/register")
                  }}
                >
                  <Button
                    className={classes.plusButton}
                    onClick={() => {
                      history.push("/register")
                    }}
                  >
                    <Typography
                      className={classes.headerSignUpText}
                    >
                      {Locale.getString('register')}
                    </Typography>
                  </Button>
                </Link>
              </div>
              :
              <Grid
                container
                md={12}
                direction={"row"}
                alignItems={'center'}
                justify={"center"}
                style={{width: 250}}
              >
                <Grid
                  item
                  md={8}
                >
                  {(!window.location.href.includes('/subscribe/') && !window.location.href.includes('/plus/')) &&
                  <Button
                    variant={"outlined"}
                    className={classes.plusButton}
                    onClick={() => {
                      {
                        ds.isPlusUser() ? history.push("/plus/menu") : history.push("/plus/?s=plans&period=12")
                      }
                    }}
                  >
                    {ds.isPlusUser() ? Locale.getString('header_your_plan') : Locale.getString('become_a_plus')}
                  </Button>}
                </Grid>
                <Grid
                  item
                  md={2}
                >
                  <div>
                    <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                      <Avatar
                        className={classes.avatar}
                        alt={ds.retrieveAuthData().nickname}
                        src={ds.retrieveAuthData().photoURL}
                      />
                    </Button>

                  </div>
                </Grid>
              </Grid>
            }
          </div>
          <div className={classes.sectionMobile}>
            {!ds.retrieveAuthData() ?
              <Grid
                style={{marginRight: 40}}
                container
                direction={"row"}
                alignItems={"center"}
                justify={"center"}
              >
                <Grid
                  item
                  md={7}
                  xs={6}
                >
                  <Link
                    component="button"
                    onClick={() => {
                      history.push("/login")
                    }}
                  >
                    <Typography
                      className={classes.headerSignInText}
                    >
                      {Locale.getString('init_session')}
                    </Typography>
                  </Link>
                </Grid>

                <Grid
                  item
                  md={4}
                  xs={4}
                >
                  <Link
                    component="button"
                    onClick={() => {
                      history.push("/register")
                    }}
                  >
                    <Button
                      variant={"outlined"}
                      className={classes.plusButton}
                      onClick={() => {
                        history.push("/register")
                      }}
                    >
                      <Typography
                        className={classes.headerSignUpText}
                      >
                        {Locale.getString('register')}
                      </Typography>
                    </Button>
                  </Link>
                </Grid>
              </Grid>
              :
              <Grid
                container
                md={12}
                direction={"row"}
                alignItems={'center'}
                justify={"flex-end"}
                style={{width: 250}}
              >
                <Grid
                  item
                  md={8}
                >
                  {(!window.location.href.includes('/subscribe/') && !window.location.href.includes('/plus/')) &&
                  <Button
                    className={classes.plusButton}
                    onClick={() => {
                      {
                        ds.isPlusUser() ? history.push("/plus/menu") : history.push("/plus/?s=plans&period=12")
                      }
                    }}
                  >
                    {ds.isPlusUser() ? Locale.getString('header_your_plan') : Locale.getString('become_a_plus')}
                  </Button>}
                </Grid>
                <Grid
                  item
                  md={2}
                >
                  <div>
                    <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                      <Avatar
                        className={classes.avatar}
                        alt={ds.retrieveAuthData().nickname}
                        src={ds.retrieveAuthData().photoURL}
                      />
                    </Button>

                  </div>
                </Grid>
              </Grid>
            }
          </div>
        </Toolbar>
      </AppBar>

      {renderMobileMenu}
      {renderMenu}
    </div>
  );
}

const TabPanel = (props) => {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}


TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
