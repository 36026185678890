import { HttpError } from "./HttpError";
import HttpRequester, {
  HttpMethod,
  HttpRequesterBody,
  HttpRequesterHeaders,
  HttpRequesterResponse
} from "./HttpRequester";

class HttpRequesterCloud extends HttpRequester {
  private headers: HttpRequesterHeaders;

  constructor() {
    super("https://europe-west1-realfooding-app.cloudfunctions.net");
    this.headers = {
      "Content-Type": "application/json"
    };
  }

  private getQueryParams(url: string, queryParams: Record<string, unknown>) {
    url += "?";
    for (const [key, value] of Object.entries(queryParams)) {
      url += `${key}=${value}&`;
    }

    return url.slice(0, -1);
  }

  public getUrl(url: string, queryParams: Record<string, unknown>): string {
    let route = url;

    if (queryParams) {
      route = this.getQueryParams(route, queryParams);
    }

    return `https://europe-west1-realfooding-app.cloudfunctions.net${route}`;
  }

  public async get(
    url: string,
    queryParams: Record<string, unknown>,
    auth?: string
  ): Promise<HttpRequesterResponse> {
    let route = url;

    if (queryParams) {
      route = this.getQueryParams(route, queryParams);
    }

    if (auth) {
      this.headers.Authorization = `Bearer ${auth}`;
    }

    return await this.request(route, HttpMethod.GET, this.headers);
  }

  public async post<T>(route: string, body: T, auth?: string): Promise<HttpRequesterResponse> {
    if (!route) {
      throw new HttpError("No valid URL");
    }

    if (auth) {
      this.headers.Authorization = `Bearer ${auth}`;
    }

    return await this.request(route, HttpMethod.POST, this.headers, body as HttpRequesterBody);
  }

  public async put<T>(route: string, body: T, auth?: string): Promise<HttpRequesterResponse> {
    if (!route) {
      throw new HttpError("No valid URL");
    }

    if (auth) {
      this.headers.Authorization = `Bearer ${auth}`;
    }

    return await this.request(route, HttpMethod.PUT, this.headers, body as HttpRequesterBody);
  }
}

export default new HttpRequesterCloud();
