import {makeStyles} from "@material-ui/core/styles";

export default makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		backgroundColor: 'white',
		marginTop: '0%',
		paddingLeft: '3%',
		paddingRight: '3%',
		[theme.breakpoints.up('md')]: {
			paddingLeft: '14%',
			paddingRight: '14%',
		},
	},
	rootSeparator: {
		height: 1,
		width: '100%',
		marginTop: '10%',
		marginBottom: '5%',
		backgroundColor: '#a6a6a6',
	},
	rootDesktopOnly: {
		display: 'none',
		[theme.breakpoints.up('md')]: {
			display: 'flex',
		},
	},
	rootBody: {},
	rootBodyContent: {
		marginTop: '5%',
		paddingLeft: '3%',
		paddingRight: '3%',
		[theme.breakpoints.up('md')]: {
			paddingLeft: '30%',
			paddingRight: '30%',
		},
	},
	rootBodyContentHeaderTitle: {
		marginTop: '20%',
		marginBottom: '10%',
		fontSize: 26,
		[theme.breakpoints.up('md')]: {
			marginTop: '5%',
			fontSize: 48,
		},
	},
	rootBodyContentImage: {
		objectFit: 'contain',
		width: '50%'
	},
	rootBodyContentTitle: {
		marginTop: '10%',
		fontWeight: 'bold',
		textAlign: 'center',
		marginBottom: '5%'
	},
	rootBodyContentSubtitle: {
		textAlign: 'center',
		marginBottom: '10%',
		fontSize: 16,
		paddingLeft: '5%',
		paddingRight: '5%',
		[theme.breakpoints.up('md')]: {
			fontSize: 18,
			paddingLeft: '0%',
			paddingRight: '0%',
		},
	},
	rootBodyContentStartButton: {
		width: '50%',
		backgroundColor: '#19C692',
		paddingTop: '2%',
		paddingBottom: '2%',
		borderRadius: 10,
		marginTop: '2%',
		marginBottom: '2%'
	},
	rootBodyContentStartButtonText: {
		color: 'white',
		fontWeight: 'bold',
	},
	rootBodyContentLatterButton: {
		width: '50%',
		backgroundColor: '#FFF',
		paddingTop: '2%',
		paddingBottom: '2%',
		borderRadius: 10,
		marginTop: '2%',
		marginBottom: '20%'
	},
	rootBodyContentLatterButtonText: {
		color: '#828282',
		fontWeight: 'bold',
		fontSize: 13
	},

	rootBodyStep: {
		marginBottom: '5%',
		marginTop: '5%'
	},
	rootBodyFirstContent: {
		marginTop: '5%',
		paddingLeft: '3%',
		paddingRight: '3%',
		[theme.breakpoints.up('md')]: {
			paddingLeft: '30%',
			paddingRight: '30%',
		},
	},
	rootBodyContentFirstHeaderTitle: {
		marginTop: '10%',
		fontWeight: 'bold',
		textAlign: 'center',
		fontSize: 26,
		[theme.breakpoints.up('md')]: {
			fontSize: 48,
		},
	},
	rootBodyContentFirstHeaderSubtitle: {
		marginTop: '3%',
		textAlign: 'center',
		fontSize: 16,
		paddingLeft: '5%',
		paddingRight: '5%',
		[theme.breakpoints.up('md')]: {
			fontSize: 18,
			paddingLeft: '0%',
			paddingRight: '0%',
		},
	},
	rootBodyContentFirstHeaderStepContainer: {
		width: 64,
		height: 64,
		borderRadius: 32,
		backgroundColor: '#white',
		borderWidth: 2,
		borderColor: '#19C692'
	},
	rootBodyContentFirstHeaderStepContainerSelected: {
		width: 64,
		height: 64,
		borderRadius: 32,
		backgroundColor: '#19C692',
		borderWidth: 1,
		borderColor: '#19C692'
	},
	rootBodyContentFirstHeaderStepContainerText: {
		textAlign: 'center',
		color: '#19C692',
		fontWeight: 'bold',
		fontSize: 20
	},
	rootBodyContentFirstHeaderStepContainerTextSelected: {
		textAlign: 'center',
		color: 'white',
		fontWeight: 'bold',
		fontSize: 20
	},

	rootBodyFirstContentNextButton: {
		height: '100%',
		borderWidth: 1,
		borderColor: '#19C692',
		color: '#19C692',
		borderRadius: 10,
		paddingTop: '3%',
		paddingBottom: '3%',
		paddingLeft: '12%',
		paddingRight: '12%',
		fontWeight: 'bold',
	},


	rootBodyContentError: {
		fontWeight: 'normal',
		fontSize: 14,
		letterSpacing: 0,
		lineHeight: 1.3,
		color: '#ff0000',
		marginTop: '4%',
		marginBottom: '4%',
		textAlign: 'center'
	},

	rootBodySummaryContent: {
		paddingLeft: '0%',
		paddingRight: '0%',
		[theme.breakpoints.up('md')]: {
			marginTop: '5%',
		},
	},

	rootBodyContentSummaryBottom: {
		marginTop: '5%'
	},
	rootBodyContentSummaryBottomButton: {
		width: '50%',
		backgroundColor: '#19C692',
		paddingTop: '2%',
		paddingBottom: '2%',
		borderRadius: 10,
		marginTop: '2%',
		marginBottom: '2%'
	},
	rootBodyContentSummaryBottomButtonText: {
		color: 'white',
		fontWeight: 'bold',
	},
	rootBodyContentSummaryBottomLatterButton: {
		width: '50%',
		backgroundColor: '#FFF',
		paddingTop: '2%',
		paddingBottom: '2%',
		borderRadius: 10,
		marginTop: '2%',
		marginBottom: '20%'
	},
	rootBodyContentSummaryBottomLatterButtonText: {
		color: '#828282',
		fontWeight: 'bold',
		fontSize: 13
	},
}), { index: 1 });
