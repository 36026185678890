import {makeStyles} from "@material-ui/core/styles";

export default makeStyles((theme) => ({
	paper: {
		position: 'absolute',
		backgroundColor: theme.palette.background.paper,
		border: '1px solid #000',
		boxShadow: theme.shadows[5],
		top: `50%`,
		left: `50%`,
		transform: `translate(-50%, -50%)`,
		overflow: 'scroll',
		height: '80%',
		display: 'block',
		width: '90%',
		padding: '5%',
		[theme.breakpoints.up('sm')]: {
			width: '70%',
			padding: '3%',
		},
		[theme.breakpoints.up('md')]: {
			width: '40%',
			padding: '3%',
		},
	},
	paperTitle: {
		fontWeight: 'bold',
		fontSize: 16,
		marginBottom: '1%',
		[theme.breakpoints.up('md')]: {
			fontSize: 22,
		},
	},
	paperDescription: {
		fontWeight: 'normal',
		fontSize: 14,
		paddingBottom: '2%',
		[theme.breakpoints.up('md')]: {
			fontSize: 16,
		},
	},
	paperSeparator: {
		height: '3%'
	},
	paperButton: {
		backgroundColor: '#19C692',
		paddingTop: '2%',
		paddingBottom: '2%',
		paddingLeft: '3%',
		paddingRight: '3%',
		borderRadius: 10,
		marginTop: '2%',
		marginBottom: '4%'
	},
	paperButtonText: {
		color: 'white',
		fontWeight: 'bold',
		fontSize: 12,
		[theme.breakpoints.up('md')]: {
			fontSize: 14,
		},
	}
}), { index: 1 });
