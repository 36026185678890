import {makeStyles} from "@material-ui/core/styles";
import React from "react";

export default makeStyles((theme) => ({
    footer: {
        height: '10vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        background: "#F8F8FC",
        color: "#82848F",
        padding: '0 50px',
        '@media (max-width: 800px)': {
            height: '20vh',
            justifyContent: 'center',
            flexDirection: 'column-reverse',
            alignItems: 'flex-start',
            padding: '0 28px',
        }
    },
    footerText: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: "#F8F8FC",
        color: "#82848F",
        width: '100%',
        '@media (max-width: 800px)': {
            height: '20vh',
            flexDirection: 'column',
            alignItems: 'flex-start',
            padding: '0 28px',
        }
    },
    footerPoint: {
        margin: '0 6px',
    },
    footerNonePoint: {
        margin: '0 6px',
        '@media (max-width: 800px)': {
            display: 'none',
            margin: '0 3px',
        }
    },
    footerAbout: {
        '@media (max-width: 800px)': {
            marginBottom: 26,
        }
    },
    link: {
        textDecoration: 'none',
        "&:link": {
            color: "#82848F"
        },
        "&:visited": {
            color: "#82848F"
        },
        "&:hover": {
            color: "#82848F"
        },
        "&:active": {
            color: "#82848F"
        }
    },
    media: {
        display: 'flex',
        gap: '16px',
        marginLeft: '16px',
        '@media (max-width: 800px)': {
            gap: '24px',
            padding: '32px 0 0 16px',
        }
    }
}), { index: 1 });
