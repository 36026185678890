import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Collapse from "@material-ui/core/Collapse/Collapse";
import React, {useState} from "react";
import style from "../style";
import Locale from '../../../controller/locale'

const PlusFAQ = (props) => {
    const classes = style();
    const [FAQ, setFAQ] = useState([]);

    return(
        <Grid
            ref={props.ref}
            className={classes.rootBodyFAQContent}
            container
            md={12}
            alignItems={"center"}
            justify={"center"}
        >
            <Typography
                className={classes.rootTitle}>
                {Locale.getString('questions')}
            </Typography>
        {[{
            question: Locale.getString('faq_question_1'),
            answer: Locale.getString('faq_answer_1')
        }, {
            question: Locale.getString('faq_question_2'),
            answer: Locale.getString('faq_answer_2')
        }, {
            question: Locale.getString('faq_question_3'),
            answer: Locale.getString('faq_answer_3')
        }, {
            question: Locale.getString('faq_question_4'),
            answer: Locale.getString('faq_answer_4')
        }].map((item, index, array) => {
            return (
                <Grid
                    className={classes.rootBodyFAQContentBody}
                    container
                    md={12}
                >
                    <Grid
                        container
                        md={12}
                        direction={"row"}
                        justify={"center"}
                    >
                        <Grid
                            item
                            md={10}
                            xs={10}
                        >
                            <Typography
                                className={classes.rootBodyFAQContentTitle}
                                onClick={() => {
                                    FAQ[index] = !!!FAQ[index];
                                    setFAQ([...FAQ]);
                                }}
                            >
                                {item.question}
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            md={2}
                            xs={2}
                            alignItems={"center"}
                            justify={"center"}
                        >
                            <Grid
                                className={classes.rootBodyFAQContentButton}
                                container
                                alignItems={"center"}
                                justify={"center"}
                                onClick={() => {
                                    FAQ[index] = !!!FAQ[index];
                                    setFAQ([...FAQ]);
                                }}
                            >
                                {!!FAQ[index] ? '-' : '+'}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Collapse in={!!FAQ[index]}>
                        <Typography
                            className={classes.rootBodyFAQContentDescription}
                            onClick={() => {
                                FAQ[index] = !!!FAQ[index];
                                setFAQ([...FAQ]);
                            }}
                        >
                            {item.answer}
                        </Typography>
                    </Collapse>
                </Grid>
            )
        })}
    </Grid>)
}

export default PlusFAQ;
