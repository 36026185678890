import {makeStyles} from "@material-ui/core/styles";

export default makeStyles((theme) => ({
	rootBodyContentRightBox: {
		marginTop: '8%',
		borderWidth: '1',
		borderColor: '#19C692',
		borderRadius: 20,
		width: '95%',
		[theme.breakpoints.up('md')]: {
			width: '80%',
		},
	},
	rootBodyContentRightBoxContainer: {},
	rootBodyContentRightBoxContainerTitle: {
		borderTopRightRadius: 16,
		borderTopLeftRadius: 16,
		width: '100%',
		backgroundColor: '#19C692',
		textAlign: 'center',
		paddingTop: '6%',
		paddingBottom: '6%',
		fontWeight: 'bold',
		color: 'white',
		fontSize: 16,
		[theme.breakpoints.up('md')]: {
			fontSize: 18,
		},
	},
	rootBodyContentRightBoxContainerSubtitle: {
		fontWeight: 'bold',
		textAlign: 'center',
		marginTop: '15%',
		fontSize: 16,
		[theme.breakpoints.up('md')]: {
			fontSize: 18,
		},
	},
	rootBodyContentRightBoxContainerPrice: {
		textAlign: 'center',
		marginTop: '10%',
		textDecoration: 'line-through',
		color: '#828282',
		fontSize: 16,
		[theme.breakpoints.up('md')]: {
			fontSize: 18,
		},
	},
	rootBodyContentRightBoxContainerFinalPrice: {
		textAlign: 'center',
		marginTop: '10%',
		marginBottom: '3%',
		fontSize: 16,
		[theme.breakpoints.up('md')]: {
			fontSize: 18,
		},
	},
	rootBodyContentRightBoxContainerMonthlyPrice: {
		textAlign: 'center',
		marginBottom: '15%',
		fontSize: 12,
		color: '#828282',
		[theme.breakpoints.up('md')]: {
			fontSize: 16,
			marginBottom: '10%',
		},
	},
	rootBodyContentRightBoxContainerButton: {
		borderWidth: 1,
		color: '#FFF',
		backgroundColor: '#19C692',
		borderRadius: 10,
		paddingTop: '3%',
		paddingBottom: '3%',
		paddingLeft: '4%',
		paddingRight: '4%',
		fontWeight: 'bold',
		fontSize: 10,
		marginBottom: '15%',
		width: '80%',
		marginLeft: '1%',
		marginRight: '1%',
		[theme.breakpoints.up('md')]: {
			marginLeft: '0%',
			marginRight: '0%',
			width: '80%',
			fontWeight: 'bold',
			fontSize: 12,
		},
		'&:hover': {
			backgroundColor: '#0D7D5C',
		}
	},
	rootBodyContentRightBoxContainerHintPrice: {
		textAlign: 'center',
		color: '#828282',
		whiteSpace: "pre-wrap",
		fontSize: 10,
		marginTop: '5%',
		marginBottom: '5%',
		paddingRight: '8%',
		paddingLeft: '8%',
		[theme.breakpoints.up('md')]: {
			fontSize: 12,
			marginTop: '0%',
			marginBottom: '10%',
			paddingRight: '5%',
			paddingLeft: '5%',
		},
	}
}), { index: 1 });
