import React, {useEffect} from 'react';

import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions'
import ds from "../../controller/DataService";

export default function Logout() {
	useEffect(() => {
		ds.userSignOut(() => {
			window.location.assign('https://myrealfood.app/')
		}, () => {
			window.location.assign('https://myrealfood.app/')
		})
	});

	return (
		<div style={{marginTop: '1em'}}>
		</div>
	);
}
