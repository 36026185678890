import React from "react";
import {Grid, Typography} from "@material-ui/core";
import style from "./style";
import Link from "@material-ui/core/Link";
import Locale from "../../../controller/locale";

const SmallFooter = () => {
    const classes = style();
    return(
        <Grid
            container
            direction={'row'}>
            <div className={classes.rootSeparator}/>
            <Grid
                container
                direction={'column'}
                className={classes.rootTextContainer}
            >
                <Typography
                    align={"center"}
                    className={classes.rootTextsText}
                > Copyright © {new Date().getFullYear()} MyRealFood. Rigen
                    <Link
                        component="button"
                        onClick={() => {
                            window.location.assign("/docs/terminos_del_servicio.pdf")
                        }}
                        className={classes.rootLinkText}
                    > {Locale.getString('mini_footer_terms_conditions')}</Link>
                </Typography>
            </Grid>
        </Grid>
    )
}

export default SmallFooter;