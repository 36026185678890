import {makeStyles} from "@material-ui/core/styles";

export default makeStyles((theme) => ({
	rootBodyCard: {
		width: '100%',
		borderRadius: 20,
		paddingBottom: '5%',
		marginBottom: '10%',
	},
	rootBodyCardContentTitle: {
		fontWeight: 'bold',
		textAlign: 'center',
		paddingTop: '5%',
		paddingBottom: '5%',

	},
}), { index: 1 });
