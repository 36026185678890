import {
  Language,
  Locales,
  MenuRecipePrimitives,
  MultimediaPrimitives,
  NutritionalInfoPrimitives,
  RecipeBasePrimitives,
  RecipeNutritionalPrimitives,
  RecipePrimitives,
  RecipeStepPrimitives,
  RecipeStepsLocales,
  RecipeTimes,
  Timestamp
} from "@myrealfood-sl/domain";
import { MenuRecipeDocument } from "./MenuRecipeTranslator";

export type MenuRecipeReducedPrimitives = {
  id: string;
  base: RecipeBasePrimitives;
  nutritional: RecipeNutritionalPrimitives;
};
export type RelationalIngredientDocument = Record<
  string,
  {
    id: string;
    code: string;
    name: string;
    name_locales: object;
    photo: string;
    images: {
      product: string;
      nutritional: string;
    };
    units: number;
    amount: number;
    fixed: number;
    nutritional: NutritionalInfoPrimitives;
    isLiquid: boolean;
    category: string;
    relationalCategory: string;
  }
>;

export type StepDocument = Record<string, RecipeStepPrimitives>;

export type StepsDocument = {
  steps: StepDocument;
  steps_locales: RecipeStepsLocales;
  time: number;
  times: RecipeTimes;
};

export interface RecipeReducedDocument {
  id: string;
  allergens: string[];
  author: {
    uid: string;
    photo: string;
    nickname: string;
  };
  name: string;
  name_locales: Locales;
  hasVideo: boolean;
  video_hls: string;
  generic: boolean;
  ingredients: string[];
  menus: string[];
  isPremium: boolean;
  thumb: string;
  visible: number;
  relationalIngredients: RelationalIngredientDocument;
  searchableIngredients: string[];
  searchableIngredients_locales: Record<string, Locales>;
  nutritional: NutritionalInfoPrimitives;
  people: number;
  photo: string;
  multimedia: MultimediaPrimitives[];
}
export type RecipeDocument = RecipeReducedDocument & {
  created_at: Date;
  comments: number;
  description: string;
  description_locales: Locales;
  featured: string[];
  groups: string[];
  likes: number;
  linked_recipes: string[];
  locale: {
    countries: Array<Language>;
    country: Language;
    language: Language;
    relationalCountries: Record<string, Language>;
  };
  relationalTags: Record<string, string>;
  relationalTags_locales: Record<string, Locales>;
  rank: number;
  steps: StepDocument;
  steps_locales: RecipeStepsLocales;
  stored: number;
  tag: string;
  tagged: boolean;
  tags: string[];
  time: number;
  times: RecipeTimes;
};

export class RecipeTranslator {
  private static instance: RecipeTranslator;

  private constructor() {
    // Empty
  }

  public static getInstance(): RecipeTranslator {
    if (!RecipeTranslator.instance) {
      RecipeTranslator.instance = new RecipeTranslator();
    }

    return RecipeTranslator.instance;
  }

  public fromReducedDocumentToPrimitive(document: MenuRecipeDocument): MenuRecipeReducedPrimitives {
    const relationalIngredients = Object.entries(document.relationalIngredients).map((item) => {
      return { ...item[1], id: item[0] };
    });

    return {
      id: document.id,
      base: {
        author: document.author,
        multimedia: {
          hasVideo: document.hasVideo,
          video_hls: document.video_hls,
          photo: document.photo,
          thumb: document.thumb ?? "",
          items: document.multimedia ?? []
        },
        name: document.name,
        name_locales: document.name_locales,
        isPremium: document.isPremium,
        visible: document.visible
      },
      nutritional: {
        ingredients: document.ingredients,
        menus: document.menus,
        nutritional: document.nutritional,
        relationalIngredients,
        searchableIngredients: [],
        generic: document.generic,
        allergens: document.allergens,
        people: document.people ?? 1
      }
    };
  }

  public fromDocumentToPrimitive(document: RecipeDocument): RecipePrimitives {
    const relationalIngredients = Object.entries(document.relationalIngredients).map((item) => {
      return { ...item[1], id: item[0] };
    });

    const steps = document.steps
      ? Object.keys(document.steps)
          .sort((a, b) => (Number(b.split("_")[1]) > Number(a.split("_")[1]) ? -1 : 1))
          .map((key) => {
            return document.steps[key as string];
          })
      : ([] as RecipeStepPrimitives[]);

    return {
      id: document.id,
      base: {
        author: document.author,
        multimedia: {
          hasVideo: document.hasVideo,
          video_hls: document.video_hls,
          photo: document.photo,
          thumb: document.thumb ?? "",
          items: document.multimedia ?? []
        },
        name: document.name,
        name_locales: document.name_locales,
        isPremium: document.isPremium,
        visible: document.visible
      },
      nutritional: {
        ingredients: document.ingredients,
        menus: document.menus,
        nutritional: document.nutritional,
        relationalIngredients,
        searchableIngredients: document.searchableIngredients,
        generic: document.generic,
        allergens: document.allergens,
        people: document.people ?? 1
      },
      tags: {
        tag: document.tag,
        tagged: document.tagged,
        tags: document.tags,
        relationalTags: document.relationalTags,
        relationalTags_locales: document.relationalTags_locales,
        rank: document.rank
      },
      steps: {
        steps,
        steps_locales: document.steps_locales,
        time: document.time,
        times: document.times
      },
      featured: {
        featured: document.featured,
        linkedRecipes: document.linked_recipes,
        locale: document.locale
      },
      social: {
        stored: document.stored,
        comments: document.comments,
        createdAt: new Timestamp(document.created_at),
        description: document.description,
        descriptionLocales: document.description_locales,
        likes: document.likes,
        groups: document.groups
      }
    };
  }

  public toReducedDocument(primitive: MenuRecipePrimitives): RecipeReducedDocument {
    const relationalIngredients: RelationalIngredientDocument = {};
    primitive.nutritional.relationalIngredients.forEach((item) => {
      relationalIngredients[item.id] = { ...item, code: item.id };
    });

    return {
      id: primitive.id,
      allergens: primitive.nutritional.allergens,
      author: primitive.base.author,
      name: primitive.base.name,
      name_locales: primitive.base.name_locales,
      hasVideo: primitive.base.multimedia.hasVideo ?? false,
      video_hls: primitive.base.multimedia.video_hls ?? "",
      generic: primitive.nutritional.generic,
      ingredients: primitive.nutritional.ingredients,
      menus: primitive.nutritional.menus,
      isPremium: primitive.base.isPremium,
      thumb: primitive.base.multimedia.thumb,
      visible: primitive.base.visible,
      relationalIngredients,
      searchableIngredients: [],
      searchableIngredients_locales: {},
      nutritional: primitive.nutritional.nutritional,
      people: primitive.nutritional.people,
      photo: primitive.base.multimedia.photo,
      multimedia: primitive.base.multimedia.items ?? []
    };
  }

  public toDocument(primitive: RecipePrimitives): RecipeDocument {
    const relationalIngredients: RelationalIngredientDocument = {};
    primitive.nutritional.relationalIngredients.forEach((item) => {
      relationalIngredients[item.id] = { ...item, code: item.id };
    });

    const steps: StepDocument = {};
    primitive.steps.steps.forEach((item, index) => {
      steps[`step_${index}`] = item;
    });

    return {
      id: primitive.id,
      allergens: primitive.nutritional.allergens,
      author: primitive.base.author,
      comments: primitive.social.comments,
      created_at: new Timestamp(primitive.social.createdAt).toDate(),
      description: primitive.social.description,
      description_locales: primitive.social.descriptionLocales,
      featured: primitive.featured.featured ?? [],
      hasVideo: primitive.base.multimedia.hasVideo ?? false,
      video_hls: primitive.base.multimedia.video_hls ?? "",
      generic: primitive.nutritional.generic,
      groups: primitive.social.groups,
      ingredients: primitive.nutritional.ingredients,
      isPremium: primitive.base.isPremium,
      likes: primitive.social.likes,
      linked_recipes: primitive.featured.linkedRecipes,
      locale: {
        countries: primitive.featured.locale.countries,
        country: primitive.featured.locale.country,
        language: primitive.featured.locale.language,
        relationalCountries: primitive.featured.locale.relationalCountries
      },
      menus: primitive.nutritional.menus,
      name: primitive.base.name,
      name_locales: primitive.base.name_locales,
      nutritional: primitive.nutritional.nutritional,
      people: primitive.nutritional.people,
      photo: primitive.base.multimedia.photo,
      rank: primitive.tags.rank,
      relationalIngredients,
      relationalTags: primitive.tags.relationalTags,
      relationalTags_locales: primitive.tags.relationalTags_locales,
      searchableIngredients: primitive.nutritional.searchableIngredients,
      searchableIngredients_locales: {},
      steps,
      steps_locales: primitive.steps.steps_locales,
      stored: primitive.social.stored,
      tag: primitive.tags.tag,
      tagged: primitive.tags.tagged,
      tags: primitive.tags.tags,
      thumb: primitive.base.multimedia.thumb,
      time: primitive.steps.time,
      times: primitive.steps.times,
      visible: primitive.base.visible,
      multimedia: primitive.base.multimedia.items ?? []
    };
  }
}
export const recipeTranslator = RecipeTranslator.getInstance();
