import React from 'react';

import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions'

import style from './style';
import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

export default function CardImageLongText(props) {
	const classes = style();

	return (
		<Grid
			container
			md={12}
			xs={6}
			alignItems={"center"}
			justify={"center"}
			onClick={props.onClick}
		>
			<Box
				className={classes.rootBodyCardOption}
				boxShadow={3}
				borderColor={!!props.selected ? "primary.main" : "secondary.main"}
				bgcolor={!!props.selected ? "primary.main" : "background.paper"}
				borderRadius={20}
				m={1}
				p={1}
			>
				<Grid
					container
					md={12}
					direction={"row"}
					alignItems={"center"}
					justify={"center"}
				>
					<Grid
						container
						md={3}
						alignItems={"center"}
						justify={"center"}
					>
						<img
							className={classes.rootBodyCardOptionImage}
							src={props.image}
							alt={"MyRealFood"}
						/>
					</Grid>
					<Grid
						container
						md={9}
						direction={"column"}
						justify={"center"}
					>
						<Typography
							className={!!props.selected ? classes.rootBodyCardOptionTitleSelected : classes.rootBodyCardOptionTitle}
						>
							{props.title}
						</Typography>
						{!!props.subtitle &&
						<Typography
							className={!!props.selected ? classes.rootBodyCardOptionSubtitleSelected : classes.rootBodyCardOptionSubtitle}
						>
							{props.subtitle}
						</Typography>}
					</Grid>
				</Grid>
			</Box>
		</Grid>
	);
}


