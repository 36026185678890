import {makeStyles} from "@material-ui/core/styles";

export default makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		backgroundColor: 'white',
		paddingLeft: '14%',
		paddingRight: '14%',
	},
	rootSeparator: {
		height: 1,
		width: '16%',
		marginLeft: '42%',
		marginRight: '42%',
		marginTop: '10%',
		marginBottom: '7%',
		backgroundColor: '#19C692',
	},
	rootBody: {
		paddingTop: '5%',
		paddingBottom: '5%',
		[theme.breakpoints.up('md')]: {
			fontSize: 32,
			paddingLeft: '10%',
			paddingRight: '10%',
		},
	},
	rootBodyFAQ: {

	},
	rootBodyFAQHeader: {
		fontWeight: 'bold',
		marginTop: '20%',
		marginBottom: '5%',
		paddingLeft: '1%',
		paddingRight: '1%',
		fontSize: 32,
		[theme.breakpoints.up('md')]: {
			fontSize: 34,
			marginTop: '2%',
			marginBottom: '2%',
		},
	},
	rootBodyFAQTitle: {
		fontWeight: 'bold',
		fontSize: 22,
		marginTop: '4%',
		marginBottom: '1%',
		[theme.breakpoints.up('md')]: {
			fontSize: 26,
			marginTop: '2%',
			marginBottom: '1%',
		},
	},
	rootBodyFAQDescription: {
		fontSize: 17
	}
}), {index: 1});
