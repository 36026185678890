const ENABLED_LANGUAGES = ['es_ES', 'en_EN'];
class Locale {
	#instance = undefined;
	#locale = undefined;
	#localeObject = undefined;

	constructor() {}

	static build() {
		if (this.instance === undefined) {
			this.instance = new Locale();
		}
		return this.instance;
	}

	init(locale) {
		this.locale = locale; //'es_ES';
		try {
			if(ENABLED_LANGUAGES.includes(this.locale)) {
				this.localeObject = require("./translations" + this.locale);
			}
			else {
				this.setDefaultLocale();
			}
		} catch (e) {
			this.setDefaultLocale();
		}
	}

	setDefaultLocale() {
		if (!!this.locale) {
			if (this.locale.includes('es')) {
				this.localeObject = require(`./translations/es_ES.json`);
				// console.log(`Locale ${this.locale} found. Using locale: es_ES`);
			}
			else if (this.locale.includes('en')) {
				this.localeObject = require(`./translations/en_EN.json`);
				// console.log(`Locale ${this.locale} found. Using locale: en_EN`);
			}
			else {
				this.localeObject = require(`./translations/es_ES.json`);
				// console.log(`Locale ${this.locale} not found. Using default locale: es_ES`);
			}
		} else {
			this.localeObject = require(`./translations/es_ES.json`);
			// console.log(`Locale ${this.locale} not found. Using default locale: es_ES`);
		}
	}

	getString(id, replace = []) {
		let base = this.localeObject !== undefined ? this.localeObject[id] : ' ';
		if (this.localeObject !== undefined && !this.localeObject[id]) {
			// console.log(`[locale - getString] Not found base for identifier ${id}`);
		}
		try {
			!!replace && replace.forEach((value, index, array) => {
				base = base.replace(`$${index + 1}`, value);
			});
			return base;
		} catch (error) {
			// console.log(`[locale - getString] Error ${error}`);
			return base;
		}
	}

	getLocaledStringValue(field, data) {
		const language = !!this.locale ? this.locale.split('_')[0] : '_';
		const localedField = `${field}_locales`;
		const fieldString = !!data && !!data[field] ? data[field] : '';
		const localedFieldData = !!data && !!localedField && !!data[localedField] ? data[localedField] : null;
		if (!!language && !!localedFieldData && !!localedFieldData[language]) {
			return localedFieldData[language];
		} else {
			const defaultLangKey = '_';
			return !!localedFieldData && !!localedFieldData[defaultLangKey] ? localedFieldData[defaultLangKey] : fieldString;
		}
	}

	getLocaledStringObj(field, data) {
		const language = !!this.locale ? this.locale.split('_')[0] : '_';
		const localedField = `${field}_locales`;
		const fieldString = !!data && !!data[field] ? data[field] : '';
		const localedFieldData = !!data && !!localedField && !!data[localedField] ? data[localedField] : null;

		if (!!language && !!localedFieldData && !!localedFieldData[language]) {
			return Object.keys(localedFieldData[language]).map(id => localedFieldData[language][id]);
		}
		else {
			const defaultLangKey = '_';
			return !!localedFieldData && !!localedFieldData[defaultLangKey] ? localedFieldData[defaultLangKey] : (Array.isArray(fieldString) ? fieldString : []);
		}
	}

	getLocaledCategoryProduct(id){
		return this.getString(`products_category_${id}`)
	}
}


const myRealFoodLocale = Locale.build();
export default myRealFoodLocale;
