import {makeStyles} from "@material-ui/core/styles";

export default makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		backgroundColor: 'white',
		paddingLeft: '3%',
		paddingRight: '3%',
		[theme.breakpoints.up('md')]: {
			paddingLeft: '14%',
			paddingRight: '14%',
		}
	},
	rootGrow: {
		flexGrow: 1,
	},
	rootDesktopOnly: {
		display: 'none',
		[theme.breakpoints.up('md')]: {
			display: 'flex',
		},
	},
	rootSeparator: {
		height: 1,
		width: '100%',
		marginTop: '2%',
		marginBottom: '5%',
		backgroundColor: '#e6e6e6',
	},
	rootBody: {
		marginTop: '1%',
		marginLeft: '1%',
		marginRight: '1%',
		marginBottom: '5%',
	},
	rootBodyContent: {
		marginTop: '10%',
	},
	rootBodyContentHeaderTitle: {
		textAlign: 'center',
	},
	rootBodyContentSummaryBottomButton: {
		width: '100%',
		borderColor: '#19C692',
		borderWidth: 1,
		paddingTop: '4%',
		paddingBottom: '4%',
		margin: '5%',
		borderRadius: 10,
	},
	rootBodyContentSummaryBottomButtonText: {
		color: '#19C692',
		fontWeight: 'bold',
	},
	rootBodyHeaderRecommendationsIcon: {
		width: '3.5%',
		[theme.breakpoints.up('md')]: {
			width: '1.5%',
		}
	},
	rootBodyHeaderRecommendationsText: {
		color: '#19C692',
		fontWeight: 'bold',
		padding: '1%',
		fontSize: 15
	},

	rootContentMenuHeader: {},
	rootContentMenuHeaderText: {
		fontWeight: 'bold',
		marginBottom: '10%',
		[theme.breakpoints.up('md')]: {
			marginBottom: '0%'
		}
	},
	rootContentMenuHeaderSelectorText: {
		fontSize: 20,
		fontWeight: 'bold',
		marginRight: '10%',
		marginLeft: '10%',
		whiteSpace: "pre-wrap",
		textAlign: 'center'
	},
	rootContentMenuHeaderSelectorIcon: {
		width: '40%',
		margin: '2%',
		[theme.breakpoints.up('md')]: {
			width: '30%',
			margin: '2%',
		}
	},

	rootBodyContentMenuDay: {
		marginTop: '5%',
		[theme.breakpoints.up('md')]: {
			marginTop: '0%'
		}
	},
	rootBodyContentMenuDayText: {
		fontWeight: 'bold',
		marginTop: '1%',
		[theme.breakpoints.up('md')]: {
			marginBottom: '2%',
		}
	},
	rootBodyContentMenuFoodText: {
		fontWeight: 'bold'
	},

	rootBodyContentMenuRecipe: {
		width: 150,
		height: 230,
		marginRight: 20,
		marginTop: 10,
		[theme.breakpoints.up('md')]: {
			width: 150,
			height: 230,
		}
	},
	rootBodyContentMenuRecipePhoto: {
		width: 150,
		height: 150,
		borderRadius: 20,
		objectFit: 'cover',
		[theme.breakpoints.up('md')]: {
			width: 150,
			height: 150,
		}
	},
	rootBodyContentMenuRecipeName: {
		width: '100%',
	},
	rootContentMenuRecipeSelector: {
		width: '3%',
		margin: '2%'
	},

	rootContentMenuSide: {
		width: '100%',
		[theme.breakpoints.up('md')]: {
			marginTop: '30%',
		}
	},
	rootContentMenuSideCard: {
		borderRadius: 20,
		width: '95%',
		[theme.breakpoints.up('md')]: {
			width: '80%',
		}
	},
	rootContentMenuSideCardTitle: {
		fontWeight: 'bold',
		paddingTop: '5%',
		paddingBottom: '5%',
		margin: '3%',
		fontSize: 22,
		lineHeight: 1.2,
		[theme.breakpoints.up('sm')]: {
			paddingBottom: '0%',
			margin: '0%',
			paddingRight: '3%',
			paddingLeft: '3%'
		}
	},
	rootContentMenuSideCardContent: {}
}), { index: 1 });
