import {makeStyles} from "@material-ui/core/styles";

export default makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		backgroundColor: 'white',
		marginTop: '4%',
		[theme.breakpoints.up('md')]: {
			paddingLeft: '14%',
			paddingRight: '14%',
		},
	},
	rootSeparator: {
		height: 1,
		width: '100%',
		marginTop: '0%',
		marginBottom: '3%',
		backgroundColor: '#e6e6e6',
	},
	rootBody: {},
	rootBodyGrow: {
		flexGrow: 1
	},
	rootBodyLogo: {
		marginTop: '0%',
		marginBottom: '3%'
	},
	rootBodyLogoImage: {
		objectFit: 'contain',
		marginTop: '5%',
		marginBottom: '3%',
		width: '60%',
		[theme.breakpoints.up('md')]: {
			marginTop: '0%',
			marginBottom: '0%',
			width: '100%',
		},
	},
	rootBodyContent: {
		paddingLeft: '10%',
		paddingRight: '10%',
		[theme.breakpoints.up('md')]: {
			paddingLeft: '30%',
			paddingRight: '30%',
		},
	},
	rootBodyContentTitle: {
		fontWeight: 'bold',
		fontSize: 30,
		marginBottom: '8%',
		marginRight: '0%',
		[theme.breakpoints.up('md')]: {
			marginRight: '40%',
		},
	},
	rootBodyContentSubtitle: {
		fontWeight: 'bold',
		fontSize: 17,
		letterSpacing: -0.3,
		marginBottom: '2%',
		marginRight: '40%'
	},
	rootBodyContentSignInWithApple: {
		width: '100%',
		backgroundColor: '#000',
		paddingTop: '2%',
		paddingBottom: '2%',
		borderRadius: 10,
		marginTop: '2%',
		marginBottom: '2%'
	},
	rootBodyContentSignInWithAppleLogo: {
		width: '5%',
		marginRight: '3%'
	},
	rootBodyContentSignInWithAppleText: {
		color: 'white',
		fontWeight: 'bold',
	},
	rootBodyContentSignInWithGoogle: {
		width: '100%',
		backgroundColor: '#dc4e41',
		paddingTop: '2%',
		paddingBottom: '2%',
		borderRadius: 10,
		marginTop: '2%',
		marginBottom: '2%'
	},
	rootBodyContentSignInWithGoogleLogo: {
		width: '5%',
		marginRight: '3%'
	},
	rootBodyContentSignInWithGoogleText: {
		color: 'white',
		fontWeight: 'bold',
	},
	rootBodyContentSeparator: {
		height: 2,
		width: '100%',
		backgroundColor: '#e6e6e6',
		marginTop: '5%',
		marginBottom: '5%'
	},
	rootBodyContentFormTitle: {
		fontWeight: 'bold',
		fontSize: 17,
		letterSpacing: -0.3,
		marginTop: '4%',
		marginBottom: '6%',
		marginRight: '40%'
	},
	rootBodyContentForm: {
		width: '100%',
		paddingBottom: '5%'
	},
	rootBodyContentSignIn: {},
	rootBodyContentSignInCheckbox: {
		marginBottom: '2%',
		fontSize: 14,
		[theme.breakpoints.up('md')]: {
			fontSize: 16,
		},
	},
	rootBodyContentSignInCheckboxHint: {
		fontWeight: 'normal',
		fontSize: 12,
		letterSpacing: 0,
		lineHeight: 1.3,
		color: '#828282',
		marginBottom: '4%'
	},
	rootBodyContentSignInError: {
		fontWeight: 'normal',
		fontSize: 12,
		letterSpacing: 0,
		lineHeight: 1.3,
		color: '#ff0000',
		marginTop: '-2%',
		marginBottom: '4%'
	},
	rootBodyContentSignInServerError: {
		fontWeight: 'normal',
		fontSize: 12,
		letterSpacing: 0,
		lineHeight: 1.3,
		color: '#ff0000',
		marginTop: '4%',
		marginBottom: '2%',
		textAlign: 'center'
	},
	rootBodyContentSignInButton: {
		width: '100%',
		height: '40%',
		backgroundColor: '#19C692',
		paddingTop: '2%',
		paddingBottom: '2%',
		borderRadius: 10,
		marginTop: '8%',
		marginBottom: '10%',
	},
	rootBodyContentSignInButtonText: {
		color: 'white',
		fontWeight: 'bold',
		fontSize: 12,
		[theme.breakpoints.up('md')]: {
			fontSize: 14,
		},
	},
	rootBodyContentRetrievePassword: {
		color: '#19C692',
		letterSpacing: 0.8,
		marginTop: '10%',
		marginBottom: '10%'
	},
	rootBodyContentGreenSeparator: {
		height: 1,
		width: '100%',
		backgroundColor: '#19C692',
		marginTop: '8%',
		marginBottom: '8%'
	},
	rootBodyContentRegister: {},
	rootBodyContentRegisterTitle: {
		fontWeight: 'bold',
		fontSize: 30,
		marginBottom: '6%',
	},
	rootBodyContentRegisterButton: {
		width: '100%',
		borderWidth: 1,
		borderColor: '#19C692',
		paddingTop: '2%',
		paddingBottom: '2%',
		borderRadius: 10,
		marginBottom: '50%'
	},
	rootBodyContentRegisterButtonText: {
		fontWeight: 'bold',
		color: '#19C692',
	}
}), { index: 1 });
