import React from 'react';
import ReactDOM from 'react-dom';

import {MuiThemeProvider} from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline/CssBaseline";

import './index.css';
import {theme} from "./styles";
import App from './App';

import * as serviceWorker from './serviceWorker';

import {CookiesProvider} from 'react-cookie';

import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import {BrowserRouter as Router} from 'react-router-dom';

import { createBrowserHistory } from 'history';
import Locale from './controller/locale';

const history = createBrowserHistory();

document.addEventListener('DOMContentLoaded', () => {
	document.querySelectorAll("[data-translate-key]")
		.forEach(translateIndexInfo)
})

const translateIndexInfo = (element) => {
	const key = element.getAttribute("data-translate-key");
	const translation = Locale.getString(key);
	element.innerText = translation
}

ReactDOM.render(
	<MuiThemeProvider theme={theme}>
		<CssBaseline/>
		<React.StrictMode>
			<MuiPickersUtilsProvider utils={DateFnsUtils}>
				<CookiesProvider>
					<Router history={history}>
						<App/>
					</Router>
				</CookiesProvider>
			</MuiPickersUtilsProvider>
		</React.StrictMode>
	</MuiThemeProvider>,
	document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
/*serviceWorker.register({
	onUpdate: () => {

	},
	onSuccess: (reg) => {
		if ('serviceWorker' in navigator) {
			const registrationWaiting = navigator.serviceWorker.waiting;

			if (registrationWaiting) {
				registrationWaiting.postMessage({type: 'SKIP_WAITING'});

				registrationWaiting.addEventListener('statechange', e => {
					if (e.target.state === 'activated') {
						window.location.reload();
					}
				});
			}
		}
	}
});*/
