import React, {useEffect, useState} from 'react';

import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions'

import style from './style';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Header from "../../../common/Header";
import Footer from "../../../common/Footer";
import {useHistory} from "react-router";
import ds from "../../../controller/DataService";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CardImageText from "./CardImageText";
import Card from "./Card";
import CardImageInput from "./CardImageInput";
import CardImageSlider from "./CardImageSlider";
import CardDotText from "./CardDotText";
import CardImageLongText from "./CardImageLongText";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import CardSummary from "./CardSummary";
import CardSummaryGoals from "./CardSummaryGoals";
import {
    __DEFAULT_PROMOCODE__,
    ONBOARDING_ACTIVITY_SUBTITLES,
    ONBOARDING_ACTIVITY_TITLES,
    ONBOARDING_GOAL_SUBTITLES,
    ONBOARDING_GOAL_TITLES,
    ONBOARDING_VARIETY_SUBTITLES,
    ONBOARDING_VARIETY_TITLES
} from "../../../controller/Exports";
import Locale from '../../../controller/locale'

export default function PlusOnboarding() {
    const classes = style();
    const history = useHistory();

    const [auth, setAuth] = useState(undefined);

    const [index, setIndex] = useState(0);

    const [gender, setGender] = useState('');
    const [years, setYears] = useState('');
    const [height, setHeight] = useState('');
    const [weight, setWeight] = useState('');

    const [activity, setActivity] = useState(2);
    const [type, setType] = useState(2);
    const [glutenFree, setGlutenFree] = useState(undefined);
    const [foods, setFoods] = useState([]);

    const [variety, setVariety] = useState(4);
    const [realfoodingLevel, setRealfoodingLevel] = useState(90);

    const [autoAdjustKcal, setAutoAdjustKcal] = useState(true);

    const [macros, setMacros] = useState(undefined);
    const [batchCooking, setBatchCooking] = useState(undefined);
    const [goal, setGoal] = useState(undefined);

    const [requesting, setRequesting] = useState(undefined);
    const [error, setError] = useState(false);

    useEffect(() => {
        if (!ds.retrieveAuthData()) {
            let paramMrt = new URLSearchParams(window.location.search).get('mrt');
            history.push(`/login/${paramMrt ? `?mrt=${paramMrt}&` : `?`}redirect=/onboarding`)
        } else {
            if (!!ds.isPlusUser()) {
                ds.subscribeToAuthChanges('PlusOnboarding', (auth) => {
                    setAuth(auth);
                    if (!!ds.retrieveUserData() && !!ds.retrieveUserData()['private'] && !!ds.retrieveUserData()['private']['premium'] && !!ds.retrieveUserData()['private']['premium']['basic']) {
                        let data = ds.retrieveUserData()['private'];

                        setGender(data.gender !== undefined ? data.gender : '');
                        setYears(data.years !== undefined ? data.years : '');
                        setHeight(data.height !== undefined ? data.height : '');
                        setWeight(data.weight !== undefined ? data.weight : '');

                        setActivity(data['premium']['basic'].activity !== undefined ? data['premium']['basic'].activity : 2);
                        setType(data['premium']['basic'].plan !== undefined ? (data['premium']['basic'].plan.includes('omni') ? 'omni' : (data['premium']['basic'].plan.includes('vegetarian') ? 'vegetarian' : 'vegan')) : 2);
                        setGlutenFree(data['premium']['basic'].glutenFree !== undefined ? data['premium']['basic'].glutenFree : undefined);
                        setFoods(data['premium']['basic'].foods_type !== undefined ? data['premium']['basic'].foods_type : []);

                        setVariety(data['premium']['basic'].variety !== undefined ? Math.abs(data['premium']['basic'].variety - 4) : 4);
                        setRealfoodingLevel(ds.retrieveUserData()['private']['realfoodingLevel'] !== undefined ? ds.retrieveUserData()['private']['realfoodingLevel'] : 90);
                        setGoal(data['premium']['basic'].goal !== undefined ? data['premium']['basic'].goal : undefined);
                        setMacros(ds.retrieveUserData()['private']['isTrackerGramsEnabled'] !== undefined ? ds.retrieveUserData()['private']['isTrackerGramsEnabled'] : undefined);
                        setAutoAdjustKcal(ds.retrieveUserData()['private']['isTrackerAutoAdjustEnabled'] !== undefined ? ds.retrieveUserData()['private']['isTrackerAutoAdjustEnabled'] : true);
                        setBatchCooking(data['premium']['basic'].isBatchCookingEnabled !== undefined ? data['premium']['basic'].isBatchCookingEnabled : undefined);
                    }
                });
                return () => {
                    ds.unsubscribeFromAuthChanges('PlusOnboarding');
                }
            } else {
                history.push(`/plus/?period=12&promo=${ds.retrunAvailablePromocode(__DEFAULT_PROMOCODE__[0])}`)
            }
        }
    }, []);

    const retrieveContent = () => {
        switch (index) {
            case 0:
                return retrieveContentMain();
            case 1:
                return retrieveContentFirst();
            case 2:
                return retrieveContentSecond();
            case 3:
                return retrieveContentThird();
            case 4:
                return retrieveContentSummary();
            default:
                return <div/>
        }
    };

    const checkPrecondition = (index) => {
        switch (index) {
            case 0:
                return true;
            case 1:
                return (gender !== '' && years >= 14 && years <= 99 && height >= 130 && height <= 272 && weight >= 35 && weight <= 250);
            case 2:
                return (activity >= 1 && activity <= 4 && (type === 'omni' || type === 'vegetarian' || type === 'vegan') && glutenFree !== undefined && Object.keys(foods).length >= 1);
            case 3:
                return (variety >= 0 && variety <= 4 && goal >= 0 && goal <= 2 && macros !== undefined && batchCooking !== undefined && realfoodingLevel >= 0 && realfoodingLevel <= 100);
            case 4:
                return true;
            default:
                return false
        }
    };

    const retrieveContentMain = () => {
        return (
            <Grid
                className={classes.rootBody}
                container
                md={12}
                spacing={2}
            >

                <Grid
                    className={classes.rootBodyContent}
                    container
                    md={12}
                    direction="column"
                    alignItems={"center"}
                    justify={"center"}
                >
                    <Typography
                        className={classes.rootBodyContentHeaderTitle}
                        variant="h3"
                    >
                        {Locale.getString('welcome')}
                    </Typography>
                    <Grid
                        container
                        alignItems={"center"}
                        justify={"center"}
                    >
                        <img
                            className={classes.rootBodyContentImage}
                            src={"/assets/backgrounds/bg_plus_onboarding_header.png"}
                            alt={"MyRealFood"}
                        />
                    </Grid>
                    <Typography
                        className={classes.rootBodyContentTitle}
                        variant="h4"
                    >
                        {!!(new URLSearchParams(window.location.search).get('payment')) ? Locale.getString('success_pay_plus') : ''}
                        <br/>
                        {!!(new URLSearchParams(window.location.search).get('payment')) ? Locale.getString('you_are_plus') : ''}
                    </Typography>

                    <Typography
                        className={classes.rootBodyContentSubtitle}
                    >
                        {Locale.getString('welcome_questions')}
                    </Typography>

                    <Button
                        className={classes.rootBodyContentStartButton}
                        onClick={() => {
                            window.scrollTo({top: 0, behavior: 'smooth'});
                            setTimeout(() => {
                                setIndex(index + 1);
                            }, 250);
                        }}
                    >
                        <Typography
                            className={classes.rootBodyContentStartButtonText}
                        >
                            {Locale.getString('start_onboarding')}
                        </Typography>
                    </Button>

                    <Button
                        className={classes.rootBodyContentLatterButton}
                        onClick={() => {
                            let redirect = new URLSearchParams(window.location.search).get('redirect');
                            if (!!redirect) {
                                history.push(encodeURIComponent(redirect));
                            } else {
                                history.push('/');
                            }
                        }}
                    >
                        <Typography
                            className={classes.rootBodyContentLatterButtonText}
                        >
                            {Locale.getString('later_onboarding')}
                        </Typography>
                    </Button>
                </Grid>
            </Grid>
        )
    }

    const retrieveStepHeader = () => {
        return (
            <Grid
                className={classes.rootBodyFirstContent}
                container
                md={12}
                direction="column"
                alignItems={"center"}
                justify={"center"}
            >
                <Typography
                    className={classes.rootBodyContentFirstHeaderTitle}
                    variant="h3"
                >
                    {Locale.getString('question_plus_onboarding')}
                </Typography>

                <Typography
                    className={classes.rootBodyContentFirstHeaderSubtitle}
                >
                    {Locale.getString('questions_onboarding_instructions')}
                </Typography>

                <div
                    className={classes.rootSeparator}
                />

                <Grid
                    container
                    md={12}
                    direction="row"
                    alignItems={"center"}
                    justify={"space-around"}
                >
                    {[1, 2, 3].map(step => {
                        return (
                            <Button
                                disabled={!checkPrecondition(step - 1)}
                                variant={"outlined"}
                                className={index === step ? classes.rootBodyContentFirstHeaderStepContainerSelected : classes.rootBodyContentFirstHeaderStepContainer}
                                container={true}
                                alignItems={"center"}
                                justify={"center"}
                                onClick={() => {
                                    setIndex(step);
                                }}
                            >
                                <Typography
                                    className={index === step ? classes.rootBodyContentFirstHeaderStepContainerTextSelected : classes.rootBodyContentFirstHeaderStepContainerText}
                                >
                                    {step}
                                </Typography>
                            </Button>
                        )
                    })}
                </Grid>
            </Grid>
        )
    }

    const retrieveContentFirst = () => {
        return (
            <Grid
                className={classes.rootBodyStep}
                container
                md={12}
                spacing={2}
                direction="column"
            >

                {retrieveStepHeader()}

                <Grid
                    className={classes.rootBodyFirstContent}
                    container
                    md={12}
                >
                    <Card
                        title={Locale.getString('gender_question_onboarding')}
                    >
                        <CardImageText
                            selected={gender === 'w'}
                            image={"/assets/backgrounds/bg_premium_basic_woman.png"}
                            title={Locale.getString('women_answer')}
                            onClick={() => {
                                setGender('w');
                            }}
                        />
                        <CardImageText
                            selected={gender === 'm'}
                            image={"/assets/backgrounds/bg_premium_basic_man.png"}
                            title={Locale.getString('man_answer')}
                            onClick={() => {
                                setGender('m');
                            }}
                        />
                    </Card>

                    <Card
                        title={Locale.getString('years_old_question_onboarding')}
                    >
                        <CardImageInput
                            image={"/assets/backgrounds/bg_premium_basic_birthday.png"}
                            units={Locale.getString('year_units')}
                            hint={25}
                            value={years}
                            onChange={(event) => {
                                let value = event.target.value;
                                if (!isNaN(value) && value.length < 3) {
                                    setYears(value)
                                }
                            }}
                            onBlur={() => {
                                if (Number(years) < 14) {
                                    setYears(14)
                                } else if (Number(years) > 99) {
                                    setYears(99)
                                }
                            }}
                        />
                    </Card>

                    <Card
                        title={Locale.getString('height_question_onboarding')}
                    >
                        <CardImageInput
                            image={"/assets/backgrounds/bg_premium_basic_height.png"}
                            units={Locale.getString('height_unit')}
                            hint={170}
                            value={height}
                            onChange={(event) => {
                                let value = event.target.value;
                                if (!isNaN(value) && value.length < 4) {
                                    setHeight(value)
                                }
                            }}
                            onBlur={() => {
                                if (Number(height) < 130) {
                                    setHeight(130)
                                } else if (Number(height) > 272) {
                                    setHeight(272)
                                }
                            }}
                        />
                    </Card>

                    <Card
                        title={Locale.getString('weight_question_onboarding')}
                    >
                        <CardImageInput
                            image={"/assets/backgrounds/bg_premium_basic_weight.png"}
                            units={Locale.getString('weight_unit')}
                            hint={70}
                            value={weight}
                            onChange={(event) => {
                                let value = event.target.value;
                                if (!isNaN(value) && value.length < 4) {
                                    setWeight(value)
                                }
                            }}
                            onBlur={() => {
                                if (Number(weight) < 35) {
                                    setWeight(35)
                                } else if (Number(weight) > 250) {
                                    setWeight(250)
                                }
                            }}
                        />
                    </Card>

                    <Grid
                        container
                        md={12}
                        alignItems={"flex-end"}
                        justify={"flex-end"}
                    >
                        <Button
                            disabled={!checkPrecondition(index)}
                            variant={"outlined"}
                            className={classes.rootBodyFirstContentNextButton}
                            onClick={() => {
                                window.scrollTo({top: 0, behavior: 'smooth'});
                                setTimeout(() => {
                                    setIndex(index + 1);
                                }, 250);
                            }}
                        >
                            {Locale.getString('next')}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        )
    };

    const retrieveContentSecond = () => {
        return (
            <Grid
                className={classes.rootBodyStep}
                container
                md={12}
                spacing={2}
                direction="column"
            >

                {retrieveStepHeader()}

                <Grid
                    className={classes.rootBodyFirstContent}
                    container
                    md={12}
                >
                    <Card
                        title={Locale.getString('activity_question_onboarding')}
                    >
                        <CardImageSlider
                            value={activity}
                            min={1}
                            max={4}
                            step={1}
                            valueLabelDisplay={"off"}
                            image={"/assets/backgrounds/bg_premium_basic_activity.png"}
                            title={(value) => {
                                return Locale.getString(ONBOARDING_ACTIVITY_TITLES[value]);
                            }}
                            subtitle={(value) => {
                                return Locale.getString(ONBOARDING_ACTIVITY_SUBTITLES[value]);
                            }}
                            onChange={(event, newValue) => {
                                setActivity(newValue)
                            }}
                        />
                    </Card>

                    <Card
                        title={Locale.getString('diet_type_question_onboarding')}
                    >
                        <CardImageText
                            selected={type === 'omni'}
                            image={"/assets/backgrounds/bg_premium_basic_omni.png"}
                            title={Locale.getString('omni_title')}
                            subtitle={Locale.getString('omni_subtitle')}
                            onClick={() => {
                                setType('omni');
                            }}
                        />
                        <CardImageText
                            selected={type === 'vegetarian'}
                            image={"/assets/backgrounds/bg_premium_basic_vegetarian.png"}
                            title={Locale.getString('vegetarian_title')}
                            subtitle={Locale.getString('vegetarian_subtitle')}
                            onClick={() => {
                                setType('vegetarian');
                            }}
                        />
                        <CardImageText
                            selected={type === 'vegan'}
                            image={"/assets/backgrounds/bg_premium_basic_vegan.png"}
                            title={Locale.getString('vegan_title')}
                            subtitle={Locale.getString('vegan_subtitle')}
                            onClick={() => {
                                setType('vegan');
                            }}
                        />
                    </Card>

                    <Card
                        title={Locale.getString('diet_without_gluten_question')}
                    >
                        <CardDotText
                            selected={glutenFree === true}
                            image={"/assets/backgrounds/bg_premium_basic_vegetarian.png"}
                            title={Locale.getString('yes')}
                            onClick={() => {
                                setGlutenFree(true);
                            }}
                        />
                        <CardDotText
                            selected={glutenFree === false}
                            image={"/assets/backgrounds/bg_premium_basic_vegetarian.png"}
                            title={Locale.getString('no')}
                            onClick={() => {
                                setGlutenFree(false);
                            }}
                        />
                    </Card>

                    <Card
                        title={Locale.getString('how_much_food_question')}
                    >
                        <CardImageText
                            selected={!!foods['breakfast']}
                            image={"/assets/backgrounds/bg_food_breakfast.png"}
                            title={Locale.getString('breakfast')}
                            onClick={() => {
                                let data = [];
                                Object.keys(foods).map(key => data[key] = true);
                                if (!!data['breakfast']) {
                                    delete data['breakfast'];
                                } else {
                                    data['breakfast'] = true;
                                }
                                setFoods(data);
                            }}
                        />
                        <CardImageText
                            selected={!!foods['lunch']}
                            image={"/assets/backgrounds/bg_food_lunch.png"}
                            title={Locale.getString('lunch')}
                            onClick={() => {
                                let data = [];
                                Object.keys(foods).map(key => data[key] = true);
                                if (!!data['lunch']) {
                                    delete data['lunch'];
                                } else {
                                    data['lunch'] = true;
                                }
                                setFoods(data);
                            }}
                        />
                        <CardImageText
                            selected={!!foods['dinner']}
                            image={"/assets/backgrounds/bg_food_dinner.png"}
                            title={Locale.getString('dinner')}
                            onClick={() => {
                                let data = [];
                                Object.keys(foods).map(key => data[key] = true);
                                if (!!data['dinner']) {
                                    delete data['dinner'];
                                } else {
                                    data['dinner'] = true;
                                }
                                setFoods(data);
                            }}
                        />
                        <CardImageText
                            selected={!!foods['snack']}
                            image={"/assets/backgrounds/bg_food_snacks.png"}
                            title={Locale.getString('snack')}
                            onClick={() => {
                                let data = [];
                                Object.keys(foods).map(key => data[key] = true);
                                if (!!data['snack']) {
                                    delete data['snack'];
                                } else {
                                    data['snack'] = true;
                                }
                                setFoods(data);
                            }}
                        />
                    </Card>

                    <Grid
                        container
                        md={12}
                        alignItems={"flex-end"}
                        justify={"flex-end"}
                    >
                        <Button
                            disabled={!checkPrecondition(index)}
                            variant={"outlined"}
                            className={classes.rootBodyFirstContentNextButton}
                            onClick={() => {
                                window.scrollTo({top: 0, behavior: 'smooth'});
                                setTimeout(() => {
                                    setIndex(index + 1);
                                }, 250);
                            }}
                        >
                            {Locale.getString('next')}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        )
    };

    const retrieveContentThird = () => {
        return (
            <Grid
                className={classes.rootBodyStep}
                container
                md={12}
                spacing={2}
                direction="column"
            >

                {retrieveStepHeader()}

                <Grid
                    className={classes.rootBodyFirstContent}
                    container
                    md={12}
                >
                    <Card
                        title={Locale.getString('batch_cooking_question')}
                    >
                        <CardDotText
                            selected={batchCooking === true}
                            image={"/assets/backgrounds/bg_premium_basic_vegetarian.png"}
                            title={Locale.getString('yes')}
                            onClick={() => {
                                setBatchCooking(true);
                                setVariety(4)
                            }}
                        />
                        <CardDotText
                            selected={batchCooking === false}
                            image={"/assets/backgrounds/bg_premium_basic_vegetarian.png"}
                            title={Locale.getString('no')}
                            onClick={() => {
                                setBatchCooking(false);
                            }}
                        />
                    </Card>

                    <Card
                        title={Locale.getString('what_is_your_objetive_question')}
                    >
                        <CardImageLongText
                            selected={goal === 1}
                            image={"/assets/backgrounds/bg_premium_basic_health.png"}
                            title={Locale.getString(ONBOARDING_GOAL_TITLES[1])}
                            subtitle={Locale.getString(ONBOARDING_GOAL_SUBTITLES[1])}
                            onClick={() => {
                                setGoal(1);
                            }}
                        />
                        <CardImageLongText
                            selected={goal === 2}
                            image={"/assets/backgrounds/bg_premium_basic_muscle.png"}
                            title={Locale.getString(ONBOARDING_GOAL_TITLES[2])}
                            subtitle={Locale.getString(ONBOARDING_GOAL_SUBTITLES[2])}
                            onClick={() => {
                                setGoal(2);
                            }}
                        />
                        <CardImageLongText
                            selected={goal === 0}
                            image={"/assets/backgrounds/bg_premium_basic_burn.png"}
                            title={Locale.getString(ONBOARDING_GOAL_TITLES[0])}
                            subtitle={Locale.getString(ONBOARDING_GOAL_SUBTITLES[0])}
                            onClick={() => {
                                setGoal(0);
                            }}
                        />
                    </Card>

                    <Card
                        title={Locale.getString('you_want_calculate_kcal')}
                    >
                        <CardDotText
                            selected={macros === true}
                            image={"/assets/backgrounds/bg_premium_basic_vegetarian.png"}
                            title={Locale.getString('yes')}
                            onClick={() => {
                                setMacros(true);
                            }}
                        />
                        <CardDotText
                            selected={macros === false}
                            image={"/assets/backgrounds/bg_premium_basic_vegetarian.png"}
                            title={Locale.getString('no')}
                            onClick={() => {
                                setMacros(false);
                            }}
                        />
                    </Card>

                    {false && <Card
                        title={Locale.getString('adjust_menu_with_objetive_question')}
                    >
                        <CardDotText
                            selected={autoAdjustKcal === true}
                            image={"/assets/backgrounds/bg_premium_basic_vegetarian.png"}
                            title={Locale.getString('yes')}
                            onClick={() => {
                                setAutoAdjustKcal(true);
                            }}
                        />
                        <CardDotText
                            selected={autoAdjustKcal === false}
                            image={"/assets/backgrounds/bg_premium_basic_vegetarian.png"}
                            title={Locale.getString('no')}
                            onClick={() => {
                                setAutoAdjustKcal(false);
                            }}
                        />
                    </Card>}

                    {false && !batchCooking && <Card
                        title={Locale.getString('variety_food_question')}
                    >
                        <CardImageSlider
                            value={variety}
                            min={0}
                            max={4}
                            step={1}
                            valueLabelDisplay={"off"}
                            image={"/assets/backgrounds/bg_premium_basic_variety.png"}
                            title={(value) => {
                                return Locale.getString(ONBOARDING_VARIETY_TITLES[value]);
                            }}
                            subtitle={(value) => {
                                return Locale.getString(ONBOARDING_VARIETY_SUBTITLES[value]);
                            }}
                            onChange={(event, newValue) => {
                                setVariety(newValue)
                            }}
                        />
                    </Card>}

                    <Card
                        title={Locale.getString('realfooder_objetive_question')}
                    >
                        <CardImageSlider
                            value={realfoodingLevel}
                            min={0}
                            max={100}
                            step={5}
                            valueLabelDisplay={"on"}
                            image={realfoodingLevel < 50 ? "/assets/backgrounds/bg_fakefooder.png" : (realfoodingLevel < 90 ? "/assets/backgrounds/bg_padawan.png" : "/assets/backgrounds/bg_realfooder.png")}
                            title={(value) => {
                                const values = [Locale.getString('realfooder_type_1'), Locale.getString('realfooder_type_2'), Locale.getString('realfooder_type_3')];
                                return values[value < 50 ? 0 : (value < 90 ? 1 : 2)];
                            }}
                            subtitle={(value) => {
                                return `${value}${Locale.getString('real_fooder_onboarding_subtitle_1')} ${100 - value}${Locale.getString('real_fooder_onboarding_subtitle_2')}`;
                            }}
                            onChange={(event, newValue) => {
                                setRealfoodingLevel(newValue)
                            }}
                        />
                    </Card>


                    <Grid
                        container
                        md={12}
                        alignItems={"flex-end"}
                        justify={"flex-end"}
                    >

                        {!requesting &&
                            <Button
                                className={classes.rootBodyFirstContentNextButton}
                                disabled={!checkPrecondition(index)}
                                variant={"outlined"}
                                onClick={() => {
                                    setRequesting(true);
                                    setError(false);
                                    ds.setPremiumPreferences(gender, years, height, weight, activity, type, glutenFree, foods, variety, goal, macros, autoAdjustKcal, batchCooking, realfoodingLevel, (data) => {
                                        if (data.status === 200) {
                                            setRequesting(false);
                                            window.scrollTo({top: 0, behavior: 'smooth'});
                                            setTimeout(() => {
                                                setIndex(index + 1);
                                            }, 250);
                                        } else {
                                            setError(Locale.getString('error_support_text'));
                                        }
                                    }, (error) => {
                                        setRequesting(false);
                                        setError(Locale.getString('error_support_text'));
                                        console.log(error);
                                    });
                                }}
                            >
                                Siguiente
                            </Button>}

                        {requesting &&
                            <Button
                                className={classes.rootBodyFirstContentNextButton}
                                variant={"outlined"}
                            >
                                <CircularProgress
                                    size={'4%'}
                                    color="primary"
                                />
                            </Button>}
                    </Grid>

                    {!!error &&
                        <Typography
                            className={classes.rootBodyContentError}
                        >
                            {error}
                        </Typography>
                    }
                </Grid>
            </Grid>
        )
    };

    const retrieveContentSummary = () => {
        return (
            <Grid
                container
                md={12}
                direction="column"
                alignItems={"center"}
                justify={"center"}
            >
                <Grid
                    className={classes.rootBodyFirstContent}
                    container
                    md={12}
                    direction="column"
                    alignItems={"center"}
                    justify={"center"}
                >
                    <Typography
                        className={classes.rootBodyContentFirstHeaderTitle}
                        variant="h3"
                    >
                        {Locale.getString('onboarding_finishing')}
                    </Typography>

                    <Typography
                        className={classes.rootBodyContentFirstHeaderSubtitle}
                    >
                        {Locale.getString('onboarding_resume')}
                    </Typography>

                    <div
                        className={classes.rootSeparator}
                    />

                    <Grid
                        className={classes.rootBodySummaryContent}
                        container
                        md={12}
                        xs={12}
                    >
                        <CardSummary
                            image={goal === 0 ? "/assets/backgrounds/bg_premium_basic_burn.png" : (goal === 1 ? "/assets/backgrounds/bg_premium_basic_health.png" : "/assets/backgrounds/bg_premium_basic_muscle.png")}
                            title={Locale.getString(ONBOARDING_GOAL_TITLES[goal])}
                        />

                        <CardSummary
                            selected={false}
                            image={type === 'omni' ? "/assets/backgrounds/bg_premium_basic_omni.png" : (type === 'vegetarian' ? "/assets/backgrounds/bg_premium_basic_vegetarian.png" : "/assets/backgrounds/bg_premium_basic_vegan.png")}
                            title={type === 'omni' ? Locale.getString('onboarding_menu_omni') : (type === 'vegetarian' ? Locale.getString('onboarding_menu_vegetarian') : Locale.getString('onboarding_menu_vegan'))}
                        />

                        <CardSummary
                            image={"/assets/backgrounds/bg_premium_basic_foods.png"}
                            title={`${!!Object.keys(foods).length ? Object.keys(foods).length : Number(foods + 1)} ${Locale.getString('onboarding_x_foot_days')}`}
                        />

                        <CardSummary
                            image={"/assets/backgrounds/bg_premium_basic_variety.png"}
                            title={Locale.getString(ONBOARDING_VARIETY_SUBTITLES[batchCooking ? ONBOARDING_VARIETY_SUBTITLES.length - 1 : variety])}
                        />

                        <CardSummaryGoals
                            image={"/assets/backgrounds/bg_premium_basic_variety.png"}
                            head={Locale.getString('goal')}
                            title={Locale.getString(ONBOARDING_VARIETY_SUBTITLES[batchCooking ? ONBOARDING_VARIETY_SUBTITLES.length - 1 : variety])}
                            kcals={!!ds.retrieveUserPlusData() ? ds.retrieveUserPlusData()['goalKcal'] : ''}
                            carbohydrates={!!ds.retrieveUserPlusData() ? ds.retrieveUserPlusData()['macros']['carbohydrates'] : ''}
                            proteins={!!ds.retrieveUserPlusData() ? ds.retrieveUserPlusData()['macros']['proteins'] : ''}
                            fats={!!ds.retrieveUserPlusData() ? ds.retrieveUserPlusData()['macros']['fats'] : ''}
                        />

                        <Grid
                            className={classes.rootBodyContentSummaryBottom}
                            container
                            md={12}
                            xs={12}
                            direction="column"
                            alignItems={"center"}
                            justify={"center"}
                        >
                            <Button
                                className={classes.rootBodyContentSummaryBottomButton}
                                onClick={() => {
                                    history.push('/plus/menu')
                                }}
                            >
                                <Typography
                                    className={classes.rootBodyContentSummaryBottomButtonText}
                                >
                                    {Locale.getString('onboarding_go_to_plan')}
                                </Typography>
                            </Button>

                            <Button
                                className={classes.rootBodyContentSummaryBottomLatterButton}
                                onClick={() => {
                                    window.scrollTo({top: 0, behavior: 'smooth'});
                                    setTimeout(() => {
                                        setIndex(1);
                                    }, 250);
                                }}
                            >
                                <Typography
                                    className={classes.rootBodyContentSummaryBottomLatterButtonText}
                                >
                                    {Locale.getString('onboarding_change_answers')}
                                </Typography>
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

        )
    };

    return (
        <div>
            <Container
                className={classes.root}
                maxWidth="xl"
            >
                <Header/>
                {retrieveContent()}
            </Container>
            <Footer/>
        </div>
    );
}


