import {makeStyles} from "@material-ui/core/styles";

export default makeStyles((theme) => ({
	rootBodyCardOption: {
		width: '80%',
	},
	rootBodyCardOptionImage: {
		objectFit: 'contain',
		width: '50%',
		paddingTop: '5%',
		paddingBottom: '5%'
	},
	rootBodyCardOptionTitle: {
		fontWeight: 'bold',
		fontSize: 15,
		textAlign: 'center',
		[theme.breakpoints.up('md')]: {
			textAlign: 'left'
		}
	},
	rootBodyCardOptionTitleSelected: {
		fontWeight: 'bold',
		color: 'white',
		fontSize: 15,
		textAlign: 'center',
		[theme.breakpoints.up('md')]: {
			textAlign: 'left'
		}
	},
	rootBodyCardOptionSubtitle: {
		fontWeight: 'bold',
		fontSize: 13,
		color: '#828282',
		whiteSpace: "pre-wrap",
		textAlign: 'center',
		paddingBottom: '2%',
		[theme.breakpoints.up('md')]: {
			textAlign: 'left',
			paddingBottom: '0%',
		}
	},
	rootBodyCardOptionSubtitleSelected: {
		fontWeight: 'bold',
		color: 'white',
		fontSize: 13,
		whiteSpace: "pre-wrap",
		textAlign: 'center',
		paddingBottom: '2%',
		[theme.breakpoints.up('md')]: {
			textAlign: 'left',
			paddingBottom: '0%'
		}
	}
}), { index: 1 });
