import axios, { AxiosError } from "axios";

export interface HttpRequesterResponse {
  data: unknown;
  status: number;
}

export type HttpRequesterHeaders = Record<string, string>;

export interface HttpRequesterOptions {
  url: string;
  method: string;
  headers?: HttpRequesterHeaders;
  data?: Record<string, unknown>;
  params?: Record<string, unknown>;
  timeout?: number;
}

export type HttpRequesterBody = Record<string, unknown>;

export enum HttpMethod {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
  PATCH = "PATCH"
}

export type HttpCDNParams = {
  fromCDN: boolean;
  queryParams: string;
  timeout?: number;
};

export class HttpRequester {
  constructor(private url: string) {
    this.url = url;
  }

  protected setUrl(url: string): void {
    this.url = url;
  }

  public async request(
    route: string,
    method: string,
    headers?: HttpRequesterHeaders,
    body?: HttpRequesterBody,
    qs?: Record<string, unknown>,
    timeout?: number
  ): Promise<HttpRequesterResponse> {
    const url = `${this.url}${route}`;

    const requestConfig: HttpRequesterOptions = {
      url,
      method,
      headers,
      data: body,
      params: qs,
      timeout: timeout || 60000
    };
    let res: HttpRequesterResponse;

    try {
      res = await axios.request(requestConfig);
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        res = {
          data: error.response?.data,
          status: error.response?.status || 500
        };
      } else if (error instanceof Error) {
        res = {
          status: 200,
          data: {
            error: error.message
          }
        };
      }
    }

    return res!;
  }
}

export default HttpRequester;
