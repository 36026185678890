import {Gender} from "./Gender";
import {Goal} from "./Goal";
import {Activity} from "./Activity";

export const activityMultiplier = {
  w: {
    0: 1.2,
    1: 1.3,
    2: 1.5,
    3: 1.6,
    4: 1.9
  },
  m: {
    0: 1.2,
    1: 1.3,
    2: 1.6,
    3: 1.7,
    4: 2.1
  }
};

export const goalsMultiplier = {
  0: 0.8,
  1: 1,
  2: 1.15
};

export class KcalMultiplierGetter {
  public static get(gender: Gender, goal: Goal, activity: Activity): number {
    return goalsMultiplier[goal] * activityMultiplier[gender][activity];
  }
}
