import { Goal } from "./constants/Goal";
import {Macros} from "./Macros";
import {MenuType} from "./constants/MenuType";

export interface MenuSettingsPreferencesPrimitives {
  batchCookingEnabled: boolean;
  dayFoods: string[];
  fastMenu: boolean;
  goal: Goal;
  goalKcal: number;
  macros: Macros;
  menuType: MenuType;
  showWeekly: boolean;
  trackerGramsEnabled: boolean;
}

export class MenuSettingsPreferences {
  constructor(
    private dayFoods: string[],
    private goal: Goal,
    private goalKcal: number,
    private menuType: MenuType,
    private batchCookingEnabled: boolean,
    private macros: Macros,
    private showWeekly: boolean,
    private trackerGramsEnabled: boolean,
    private fastMenu: boolean
  ) {}

  public getGoal(): Goal {
    return this.goal;
  }

  public setGoal(value: Goal) {
    this.goal = value;
  }

  public getGoalKcal(): number {
    return this.goalKcal;
  }

  public setGoalKcal(value: number) {
    this.goalKcal = value;
  }

  public getDayFoods(): string[] {
    return this.dayFoods;
  }

  public getMenuDayFoods(): string[] {
    const dayFoodsMenu = [...this.getDayFoods()];

    dayFoodsMenu.splice(dayFoodsMenu[0] === "breakfast" ? 1 : 0, 0, "fruits");

    return dayFoodsMenu;
  }

  public setDayFoods(value: string[]) {
    this.dayFoods = value;
  }

  public getMenuType(): MenuType {
    return this.menuType;
  }

  public setMenuType(value: MenuType) {
    this.menuType = value;
  }

  public isBatchCookingEnabled(): boolean {
    return this.batchCookingEnabled;
  }

  public setBatchCookingEnabled(value: boolean) {
    this.batchCookingEnabled = value;
  }

  public getMacros(): Macros {
    return this.macros;
  }

  public setMacros(value: Macros) {
    this.macros = value;
  }

  public isShowWeekly(): boolean {
    return this.showWeekly;
  }

  public setShowWeekly(value: boolean) {
    this.showWeekly = value;
  }

  public isTrackerGramsEnabled(): boolean {
    return this.trackerGramsEnabled;
  }

  public setTrackerGramsEnabled(value: boolean) {
    this.trackerGramsEnabled = value;
  }

  public isFastMenu(): boolean {
    return this.fastMenu;
  }

  public setFastMenu(value: boolean) {
    this.fastMenu = value;
  }

  public static fromPrimitives(
    menuSettingsPreferencesPrimitive: MenuSettingsPreferencesPrimitives
  ): MenuSettingsPreferences {
    return new MenuSettingsPreferences(
      menuSettingsPreferencesPrimitive.dayFoods,
      menuSettingsPreferencesPrimitive.goal,
      menuSettingsPreferencesPrimitive.goalKcal,
      menuSettingsPreferencesPrimitive.menuType,
      menuSettingsPreferencesPrimitive.batchCookingEnabled,
      menuSettingsPreferencesPrimitive.macros,
      menuSettingsPreferencesPrimitive.showWeekly,
      menuSettingsPreferencesPrimitive.trackerGramsEnabled,
      menuSettingsPreferencesPrimitive.fastMenu
    );
  }

  public toPrimitives(): MenuSettingsPreferencesPrimitives {
    return {
      dayFoods: this.getDayFoods(),
      goal: this.getGoal(),
      goalKcal: this.getGoalKcal(),
      menuType: this.getMenuType(),
      batchCookingEnabled: this.isBatchCookingEnabled(),
      macros: this.getMacros(),
      showWeekly: this.isShowWeekly(),
      trackerGramsEnabled: this.isTrackerGramsEnabled(),
      fastMenu: this.isFastMenu()
    };
  }
}
