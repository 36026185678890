import React, { useEffect, useState } from "react";

import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";

import style from "./style";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Header from "../../../common/Header";
import Footer from "../../../common/Footer";
import { useHistory } from "react-router";
import ds from "../../../controller/DataService";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";

import Box from "@material-ui/core/Box";
import CardSummary from "../PlusOnboarding/CardSummary";
import CardSummaryGoals from "../PlusOnboarding/CardSummaryGoals";
import {
  __DEFAULT_PROMOCODE__,
  __DEV__,
  ONBOARDING_GOAL_TITLES,
  ONBOARDING_VARIETY_SUBTITLES
} from "../../../controller/Exports";
import Locale from "../../../controller/locale";

import { plusMenuInteractor } from "./interactors/PlusMenuInteractor";
import { WeekKey } from "@myrealfood-sl/domain";

const MY_MENU_FRUITS = [
  "9Pg72jQ3W1h2vUm2X1vX",
  "YNXKLzT10jAGkR24cjSt",
  "KaQtFCPRhZapTzPQe14l",
  "KsuE2Xhuka8cDRvDkvTh",
  "vYgWzj8V8cqa8mmjsSpC",
  "PHn2F9yBOhig7C3tYuFK",
  "Cz7k1QAGwUDgUJXTKy92",
  "iS7zDUNd5M9lwp5sYiyy",
  "9jVGYEQpAtKvJ2MKPg90",
  "HzA5HdnhxZ46VDwbeVqN",
  "2txiwWIPJ2m8zn3InGv1",
  "zNjta0agWv9MTKV7CJIg",
  "qyp5SyOnEtQlcCA8yXKX",
  "Rei1wokjZirIkSFjBfQP",
  "8CLN1dDu9zhAwyrnPvYl",
  "swkrQPisWShqYpm8s6hP",
  "tCXgOoHAto8OFS6adrsn"
];
const FOOD_ORDER = {
  coffee: 0,
  fruits: 1,
  breakfast: 2,
  morning_snack: 3,
  lunch: 4,
  snack: 5,
  light_snack: 6,
  dinner: 7
};

const recipes = [];

const energy = {};
const processedDayKeys = {};

const offsets = [];

export default function PlusMenu() {
  ds.retrieveUserLanguage().then((lang) => {
    Locale.setDefaultLocale(lang);
  });

  const classes = style();
  const history = useHistory();

  const [auth, setAuth] = useState(undefined);

  const [menu, setMenu] = useState([]);
  const [dates, setDates] = useState([]);
  const [selectedWeek, setSelectedWeek] = useState("");
  const [preferences, setPreferences] = useState("");

  const [loaded, setLoaded] = useState(false);
  const [changed, setChanged] = useState(false);

  const [isPro, setIsPro] = useState(ds.isProUser());

  const DAYS = [
    Locale.getString("sunday"),
    Locale.getString("monday"),
    Locale.getString("tuesday"),
    Locale.getString("wednesday"),
    Locale.getString("thursday"),
    Locale.getString("friday"),
    Locale.getString("saturday")
  ];
  const MONTHS = [
    Locale.getString("january"),
    Locale.getString("february"),
    Locale.getString("march"),
    Locale.getString("april"),
    Locale.getString("may"),
    Locale.getString("june"),
    Locale.getString("july"),
    Locale.getString("august"),
    Locale.getString("september"),
    Locale.getString("october"),
    Locale.getString("november"),
    Locale.getString("december")
  ];
  const FOODS = {
    coffee: Locale.getString("coffee"),
    fruits: Locale.getString("fruits"),
    breakfast: Locale.getString("breakfast"),
    morning_snack: Locale.getString("snack"),
    lunch: Locale.getString("lunch"),
    snack: Locale.getString("snack"),
    light_snack: Locale.getString("snack"),
    dinner: Locale.getString("dinner")
  };

  const retrieveSelectedPlan = async (week) => {
    if (!!ds.hasCompletedPlusOnboarding()) {
      //
      if (week) {
        const menu = await plusMenuInteractor.getMenu(new WeekKey(week));
        const fullMenu = menu.toPrimitives().plan.dayPlans;
        setMenu(fullMenu);
        setLoaded(true);
        setChanged(true);
      }
    }
  };

  let seed;
  const setRandomSeed = (seed) => {
    seed = seed;
  };

  const random = () => {
    if (seed === undefined) {
      seed = 42; // The answer to life, universe and everything
    }

    let x = Math.sin(seed++) * 10000;
    return x - Math.floor(x);
  };

  let current = new Date();
  let currentString =
    current.getFullYear() +
    "-" +
    (current.getMonth() + 1 < 10 ? "0" + (current.getMonth() + 1) : current.getMonth() + 1) +
    "-" +
    (current.getDate() < 10 ? "0" + current.getDate() : current.getDate());

  let search = menu.filter((item) => {
    return item.day === currentString;
  });

  let currentPosition = 0;
  if (search.length === 1) {
    currentPosition = menu.indexOf(search[0]);
  }
  let orderedMenu = menu.slice(currentPosition, menu.length);
  orderedMenu = orderedMenu.concat(menu.slice(0, currentPosition));

  let indexes = [];
  if (!!menu && !!menu[0] && !!menu[0].day) {
    setRandomSeed(Math.floor(new Date(menu[0].day).getDate() / 7) + 1);

    indexes = [];
    for (let i = 0; i < 7; i++) {
      indexes.push(Number(((random() * 100) % MY_MENU_FRUITS.length) - 1).toFixed(0));
    }
  }

  useEffect(() => {
    if (!ds.retrieveAuthData()) {
      let paramMrt = new URLSearchParams(window.location.search).get('mrt');
      history.push(`/login/${paramMrt ? `?mrt=${paramMrt}&` : `?`}redirect=${`/plus/menu`}`)
    } else {
      if (ds.isPlusUser()) {
        if (!ds.hasCompletedPlusOnboarding() && !ds.isProUser()) {
          history.push("/onboarding");
        }

        ds.subscribeToAuthChanges("PlusMenu", setAuth);

        dates.length === 0 &&
          ds.retrieveMyRealPlanDates(
            (dates, current) => {
              setDates(dates);
              const storageWeek = localStorage.getItem("selectedWeek");
              const storageWeekDateTs = localStorage.getItem("selectedWeekDateTs");
              const nowTs = parseInt(new Date().getTime() / 1000);
              const diff = nowTs - storageWeekDateTs;
              let week = current;
              if (!!storageWeek && diff < 3600) {
                week = storageWeek;
              }
              setSelectedWeek(week);
              retrieveSelectedPlan(week);
            },
            (error) => {
              // console.log(error);
            }
          );

        dates.length !== 0 && retrieveSelectedPlan(selectedWeek);

        return () => {
          ds.unsubscribeFromAuthChanges("PlusMenu");
        };
      } else {
        history.push(
          `/plus/?period=12&promo=${ds.retrunAvailablePromocode(__DEFAULT_PROMOCODE__[0])}`
        );
      }
    }
  }, [selectedWeek, changed, history, dates.length]);

  const retrieveDayRecipes = (item) => {
    if (!!item.recipes.length) {
      let items = [];
      item.recipes.map((recipe, index, array) => {
        if (!recipe.hidden && !recipe.deleted) {
          items.push(
            <Grid item direction={"column"}>
              <Grid item>
                <Typography className={classes.rootBodyContentMenuFoodText} variant="h6">
                  {!items.length ? FOODS[item.key] : "⠀"}
                </Typography>
              </Grid>
              <Grid item direction={"row"}>
                <Grid
                  className={classes.rootBodyContentMenuRecipe}
                  item
                  direction={"column"}
                  onClick={() => {
                    const energy = recipe.nutritional.nutritional.energy;
                    const encodedEnergy = btoa(energy.toFixed(6));
                    let url = "/plus/recipe/" + recipe.id;
                    url += "?e=" + encodedEnergy;
                    history.push(url);
                  }}
                >
                  <img
                    className={classes.rootBodyContentMenuRecipePhoto}
                    src={recipe.base.multimedia.thumb || recipe.base.multimedia.photo}
                    alt={""}
                  />
                  <Typography className={classes.rootBodyContentMenuRecipeName}>
                    {Locale.getLocaledStringValue("name", recipe.base)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          );
        }
      });

      return items;
    } else {
      let items = [];
      items.push(<Grid container md={3} />);
      return items;
    }
  };

  return (
    <div>
      <Container className={classes.root} maxWidth="xl">
        <Header />
        <Grid className={classes.rootBody} container md={12}>
          <Grid
            className={classes.rootBodyContent}
            container
            md={12}
            direction={"row"}
            alignItems={"center"}
            justify={"center"}
          >
            <Grid
              className={classes.rootGrow}
              container
              md={12}
              alignItems={"center"}
              justify={"center"}
            >
              <Typography className={classes.rootBodyContentHeaderTitle} variant="h3">
                {Locale.getString("plan_plus")}
              </Typography>
            </Grid>
          </Grid>
          <Grid container md={12} alignItems={"center"} justify={"center"}>
            <Link component="button" onClick={() => {}}>
              <Grid className={classes.rootGrow} container md={2} />
              {false && (
                <Grid container md={12} direction={"row"} alignItems={"center"} justify={"center"}>
                  <img
                    className={classes.rootBodyHeaderRecommendationsIcon}
                    src={"/assets/icons/ic_recommendations.png"}
                    alt={"MyRealFood"}
                  />
                  <Typography className={classes.rootBodyHeaderRecommendationsText}>
                    {Locale.getString("see_recommendations")}
                  </Typography>
                </Grid>
              )}
              <Grid className={classes.rootGrow} container md={2} />
            </Link>
          </Grid>

          <div className={classes.rootSeparator} />

          <Grid container md={12}>
            <Grid container md={8}>
              <Grid
                className={classes.rootContentMenuHeader}
                container
                md={12}
                alignItems={"center"}
              >
                <Typography className={classes.rootContentMenuHeaderText} variant="h4">
                  {Locale.getString("weekly_menu")}
                </Typography>

                <Grid container md={12} direction={"row"} alignItems={"center"} justify={"center"}>
                  <Grid container md={3} xs={1} />
                  <Grid
                    container
                    md={7}
                    xs={10}
                    direction={"row"}
                    alignItems={"center"}
                    justify={"center"}
                  >
                    <Grid xs={2}>
                      <img
                        className={classes.rootContentMenuHeaderSelectorIcon}
                        src={
                          !dates ||
                          dates.indexOf(selectedWeek) === 0 ||
                          dates.indexOf(selectedWeek) === -1
                            ? "/assets/icons/ic_backward_disabled.png"
                            : "/assets/icons/ic_backward_enabled.png"
                        }
                        alt={"MyRealFood"}
                        onClick={() => {
                          let selected = "";
                          let index = dates.indexOf(selectedWeek);
                          if (index === -1 || index === 0) {
                            selected = dates[0];
                          } else {
                            selected = dates[index - 1];
                          }

                          if (selectedWeek !== selected) {
                            setSelectedWeek(selected);
                            localStorage.setItem("selectedWeek", selected);
                            localStorage.setItem(
                              "selectedWeekDateTs",
                              parseInt(new Date().getTime() / 1000)
                            );
                          }
                        }}
                      />
                    </Grid>
                    <Grid xs={8}>
                      <Typography
                        className={classes.rootContentMenuHeaderSelectorText}
                        variant="h5"
                      >
                        {!!menu && menu[0]
                          ? MONTHS[new Date(menu[0].day).getMonth()] +
                            `\n ${Locale.getString("week")} ` +
                            new Date(menu[0].day).getDate() +
                            "-" +
                            new Date(menu[6].day).getDate()
                          : Locale.getString("not_menu_this_week")}
                      </Typography>
                    </Grid>
                    <Grid xs={2}>
                      <img
                        className={classes.rootContentMenuHeaderSelectorIcon}
                        src={
                          !dates ||
                          dates.indexOf(selectedWeek) === -1 ||
                          dates.indexOf(selectedWeek) === dates.length - 1
                            ? "/assets/icons/ic_forward_disabled.png"
                            : "/assets/icons/ic_forward_enabled.png"
                        }
                        alt={"MyRealFood"}
                        onClick={() => {
                          let selected = "";
                          let index = dates.indexOf(selectedWeek);
                          if (index === -1 || index === dates.length - 1) {
                            selected = dates[dates.length - 1];
                          } else {
                            selected = dates[index + 1];
                          }

                          if (selected !== selectedWeek) {
                            setSelectedWeek(selected);
                            localStorage.setItem("selectedWeek", selected);
                            localStorage.setItem(
                              "selectedWeekDateTs",
                              parseInt(new Date().getTime() / 1000)
                            );
                          }
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container md={3} xs={1} />
                </Grid>
              </Grid>

              {orderedMenu.map((item) => {
                let current = new Date(item.day);
                current.setHours(0, 0, 0, 0);

                let today = new Date();
                today = new Date(today.getFullYear(), today.getMonth(), today.getDate());
                today.setHours(0, 0, 0, 0);

                let tomorrow = new Date(today);
                tomorrow.setDate(tomorrow.getDate() + 1);
                tomorrow.setHours(0, 0, 0, 0);
                energy[item.day] = item.dayTotalCaloriesWithoutAdjust;
                let total = 0;
                return (
                  <Grid className={classes.rootBodyContentMenuDay} container md={12}>
                    <Typography className={classes.rootBodyContentMenuDayText} variant="h5">
                      {(current.getTime() === today.getTime()
                        ? Locale.getString("today")
                        : current.getTime() === tomorrow.getTime()
                        ? Locale.getString("tomorrow")
                        : DAYS[new Date(item.day).getDay()]) + `, ${new Date(item.day).getDate()}`}
                    </Typography>

                    <Grid
                      style={{ position: "relative" }}
                      className={classes.rootBodyContentMenuDay}
                      container
                      md={12}
                      xs={12}
                      alignItems={"center"}
                      justify={"center"}
                    >
                      {offsets[item.day] - 6 >= 0 && false && (
                        <img
                          style={{
                            position: "absolute",
                            top: "35%",
                            left: "-4%"
                          }}
                          className={classes.rootContentMenuRecipeSelector}
                          src={
                            offsets[item.day] - 6 >= 0
                              ? "/assets/icons/ic_backward_enabled.png"
                              : "/assets/icons/ic_backward_disabled.png"
                          }
                          alt={"MyRealFood"}
                          onClick={() => {
                            if (offsets[item.day] - 6 >= 0) {
                              offsets[item.day] -= 6;
                              setChanged(!changed);
                            }
                          }}
                        />
                      )}
                      <div
                        style={{
                          width: "100%"
                        }}
                      >
                        {Object.keys(item.recipes)
                          .sort((s1, s2) => {
                            return FOOD_ORDER[s1] - FOOD_ORDER[s2];
                          })
                          .map((key) => {
                            return {
                              key: key,
                              day: item.day,
                              recipes: item.recipes[key]
                            };
                          })
                          .map((data) => {
                            return retrieveDayRecipes(data).map((day) => {
                              if (offsets[item.day] === undefined) {
                                offsets[item.day] = 0;
                              }
                              if (
                                (total >= 0 + offsets[item.day] &&
                                  total <= 5 + offsets[item.day]) ||
                                true
                              ) {
                                total++;
                                return (
                                  <div
                                    style={{
                                      display: "inline-block",
                                      verticalAlign: "top"
                                    }}
                                  >
                                    {day}
                                  </div>
                                );
                              } else {
                                total++;
                                return <div />;
                              }
                            });
                          })}
                      </div>
                      {5 + offsets[item.day] <
                        Object.keys(item.recipes).reduce(
                          (acc, key) => acc + item.recipes[key].length,
                          0
                        ) &&
                        false && (
                          <img
                            style={{
                              position: "absolute",
                              top: "35%",
                              right: "2%"
                            }}
                            className={classes.rootContentMenuRecipeSelector}
                            src={
                              5 + offsets[item.day] <
                              Object.keys(item.recipes).reduce(
                                (acc, key) => acc + item.recipes[key].length,
                                0
                              )
                                ? "/assets/icons/ic_forward_enabled.png"
                                : "/assets/icons/ic_forward_disabled.png"
                            }
                            alt={"MyRealFood"}
                            onClick={() => {
                              if (
                                5 + offsets[item.day] <
                                Object.keys(item.recipes).reduce(
                                  (acc, key) => acc + item.recipes[key].length,
                                  0
                                )
                              ) {
                                offsets[item.day] += 6;
                                setChanged(!changed);
                              }
                            }}
                          />
                        )}
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
            <Grid container md={4} justify={"center"}>
              {!!ds.hasCompletedPlusOnboarding() && (
                <Grid className={classes.rootContentMenuSide}>
                  <Box
                    className={classes.rootContentMenuSideCard}
                    boxShadow={3}
                    bgcolor="background.paper"
                    m={1}
                    p={1}
                  >
                    <Typography className={classes.rootContentMenuSideCardTitle}>
                      {Locale.getString("features_plan")}
                    </Typography>

                    <Grid container md={12}>
                      <CardSummary
                        image={
                          ds.retrieveUserData()["private"]["premium"]["basic"]["goal"] === 0
                            ? "/assets/backgrounds/bg_premium_basic_burn.png"
                            : ds.retrieveUserData()["private"]["premium"]["basic"]["goal"] === 1
                            ? "/assets/backgrounds/bg_premium_basic_health.png"
                            : "/assets/backgrounds/bg_premium_basic_muscle.png"
                        }
                        title={Locale.getString(
                          ONBOARDING_GOAL_TITLES[
                            ds.retrieveUserData()["private"]["premium"]["basic"]["goal"]
                          ]
                        )}
                      />

                      <CardSummary
                        selected={false}
                        image={
                          ds
                            .retrieveUserData()
                            ["private"]["premium"]["basic"]["plan"].includes("omni")
                            ? "/assets/backgrounds/bg_premium_basic_omni.png"
                            : ds
                                .retrieveUserData()
                                ["private"]["premium"]["basic"]["plan"].includes("vegetarian")
                            ? "/assets/backgrounds/bg_premium_basic_vegetarian.png"
                            : "/assets/backgrounds/bg_premium_basic_vegan.png"
                        }
                        title={
                          ds
                            .retrieveUserData()
                            ["private"]["premium"]["basic"]["plan"].includes("omni")
                            ? Locale.getString("omni_title")
                            : ds
                                .retrieveUserData()
                                ["private"]["premium"]["basic"]["plan"].includes("vegetarian")
                            ? Locale.getString("vegetarian_title")
                            : Locale.getString("vegan_title")
                        }
                      />

                      <CardSummary
                        image={"/assets/backgrounds/bg_premium_basic_foods.png"}
                        title={`${
                          !!ds.retrieveUserData()["private"] &&
                          !!ds.retrieveUserData()["private"]["premium"] &&
                          !!ds.retrieveUserData()["private"]["premium"]["basic"] &&
                          !!ds.retrieveUserData()["private"]["premium"]["basic"]["foods_type"] &&
                          !!Object.keys(
                            ds.retrieveUserData()["private"]["premium"]["basic"]["foods_type"]
                          ).length
                            ? Object.keys(
                                ds.retrieveUserData()["private"]["premium"]["basic"]["foods_type"]
                              ).filter(
                                (key) =>
                                  !["coffee", "fruits"].includes(key) &&
                                  !!ds.retrieveUserData()["private"]["premium"]["basic"][
                                    "foods_type"
                                  ][key]
                              ).length
                            : Number(
                                ds.retrieveUserData()["private"]["premium"]["basic"]["foods"] + 1
                              )
                        } ${Locale.getString("onboarding_x_foot_days")}`}
                      />

                      <CardSummary
                        image={"/assets/backgrounds/bg_premium_basic_variety.png"}
                        title={Locale.getString(
                          ONBOARDING_VARIETY_SUBTITLES[
                            !!ds.retrieveUserData()["private"]["premium"]["basic"][
                              "isBatchCookingEnabled"
                            ]
                              ? ONBOARDING_VARIETY_SUBTITLES.length - 1
                              : Math.abs(
                                  ds.retrieveUserData()["private"]["premium"]["basic"]["variety"] -
                                    4
                                )
                          ]
                        )}
                      />

                      <CardSummaryGoals
                        image={"/assets/backgrounds/bg_premium_basic_variety.png"}
                        head={Locale.getString("goal")}
                        title={Locale.getString(
                          ONBOARDING_VARIETY_SUBTITLES[
                            !!ds.retrieveUserData()["private"]["premium"]["basic"][
                              "isBatchCookingEnabled"
                            ]
                              ? ONBOARDING_VARIETY_SUBTITLES.length - 1
                              : Math.abs(
                                  ds.retrieveUserData()["private"]["premium"]["basic"]["variety"] -
                                    4
                                )
                          ]
                        )}
                        kcals={
                          !!ds.retrieveUserPlusData() ? ds.retrieveUserPlusData()["goalKcal"] : ""
                        }
                        carbohydrates={
                          !!ds.retrieveUserPlusData()
                            ? ds.retrieveUserPlusData()["macros"]["carbohydrates"]
                            : ""
                        }
                        proteins={
                          !!ds.retrieveUserPlusData()
                            ? ds.retrieveUserPlusData()["macros"]["proteins"]
                            : ""
                        }
                        fats={
                          !!ds.retrieveUserPlusData()
                            ? ds.retrieveUserPlusData()["macros"]["fats"]
                            : ""
                        }
                      />

                      {!isPro && (
                        <Button
                          variant={"outlined"}
                          className={classes.rootBodyContentSummaryBottomButton}
                          onClick={() => {
                            history.push("/onboarding");
                          }}
                        >
                          <Typography className={classes.rootBodyContentSummaryBottomButtonText}>
                            {Locale.getString("change_preferences")}
                          </Typography>
                        </Button>
                      )}
                      <Button
                        variant={"outlined"}
                        className={classes.rootBodyContentSummaryBottomButton}
                        onClick={() => {
                          const week = selectedWeek;
                          history.push(`/plus/cart/?week=${week}&redirect=/plus/menu`);
                        }}
                      >
                        <Typography className={classes.rootBodyContentSummaryBottomButtonText}>
                          {Locale.getString("see_shopping_list")}
                        </Typography>
                      </Button>
                    </Grid>
                  </Box>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </div>
  );
}
