import {makeStyles} from "@material-ui/core/styles";
import React from "react";

export default makeStyles((theme) => ({
    cardContainerNoneSelected: {
        borderRadius: 17,
        border: '2px solid #EBEBEB',
        margin: theme.spacing(2),
        padding: '20px',
        '&:hover': {
            border: '3px solid #19C692'
        },
    },
    cardContainerSelected: {
        borderRadius: 17,
        border: '2px solid #19C692',
        margin: theme.spacing(2),
        padding: '20px',
        '&:hover': {
            border: '3px solid #19C692'
        },
    },
    cardTitle: {
        fontSize: '20px',
        fontWeight: 'bold',
    },
    cardSubtitle: {
        fontSize: '14px',
    },
    cardText: {
        paddingTop: '30px',
        fontSize: '18px',
    }
}), { index: 1 });
