import React, {useEffect, useState} from "react";
import style from "./style";
import {useHistory} from 'react-router-dom';
import Checkbox from "../../checkbox";
import NextButton from "../../nextButton";
import Info from "../../info";

const ObjectiveOptions = [
    {
        title: 'Bulgur',
        id: "bulgur"
    },
    {
        title: 'Conejo',
        id: "rabbit"
    },
    {
        title: 'Chirimoya',
        id: "chirimoya"
    },
    {
      title: 'Jalapeños',
        id: "jalapenos"
    },
    {
        title: 'Mijo',
        id: "mijo"
    },
    {
        title: 'Tempeh',
        id: "tempeh"
    },
    {
        title: 'Tofu',
        id: "tofu"
    },
    {
        title: 'Seitán',
        id: "seitan"
    },
    {
        title: 'Gluten',
        id: "gluten",
    },
    {
        title: 'Lactosa',
        id: "lactose",
    },
    {
        title: 'Frutos secos',
        id: "nuts",
    },
    {
        title: 'Pescado',
        id: "fish",
    },
    {
        title: 'Soja',
        id: "soy",
    },
    {
        title: 'Huevo',
        id: "egg",
    },
    {
        title: 'Moluscos',
        id: "molluscs",
    },
    {
        title: 'Crustáceos',
        id: "crustaceans"
    }
]


const Allergens = ({onNext}) => {
    const classes = style();
    const history = useHistory();

    const [selected, setSelected] = useState([]);

    useEffect(() => {
        const selected = localStorage.getItem('premium.basic.allergens');
        if (selected) {
            setSelected(JSON.parse(selected));
        }
    }, []);

    return (
        <div
            className={classes.root}
        >
            {
                ObjectiveOptions.map((option, index) => (
                    <div
                        key={index}
                        className={classes.option}
                        onClick={() => {
                            if (selected.includes(option.id)) {
                                setSelected(selected.filter(item => item !== option.id))
                            }
                            else {
                                setSelected([...selected, option.id])
                            }
                        }}
                    >
                        {
                            option.title
                        }
                        <Checkbox
                            check={selected.includes(option.id)}
                        />
                    </div>))
            }
            <Info
                text={'En la app podrás seleccionar cualquier otro alimento que no quieres que aparezca en tu Plan nutricional.'}
            />
            <NextButton
                onNext={() => {
                    localStorage.setItem('premium.basic.allergens', JSON.stringify(selected))
                    onNext();
                }}
            />
        </div>
    )
}

export default Allergens;
