import {makeStyles} from "@material-ui/core/styles";

export default makeStyles((theme) => ({
	rootBodyCardOption: {
		width: '80%',
		marginBottom: '5%'
	},
	rootBodyCardOptionImage: {
		objectFit: 'contain',
		paddingTop: '10%',
		paddingBottom: '10%',
		paddingRight: '30%',
		paddingLeft: '30%',
		width: '100%',
		[theme.breakpoints.up('md')]: {
			paddingRight: '20%',
			paddingLeft: '20%',
		}
	},
	rootBodyCardOptionTitle: {
		textAlign: 'center',
		fontWeight: 'bold',
		paddingBottom: '10%',
	},
	rootBodyCardOptionInput: {
		fontWeight: 'bold',
		color: 'black',
		fontSize: 36,
	},
	rootBodyCardOptionInputHint: {
		fontWeight: 'bold',
		color: 'black',
		fontSize: 28,
		textAlign: 'center'
	},
	rootBodyCardOptionInputAdornment: {
		fontWeight: 'bold',
		color: 'black'
	}
}), { index: 1 });
