import React, {useEffect, useState} from "react";
import RegisterOnBoarding from "./Register";
import ds from "../../../controller/DataService";
import OnBoardingLogin from "./Login";
import {useHistory} from "react-router";
import {generateMenu} from "./GenerateMenu";

const AuthOnBoarding = ({promocode}) => {
    const [register, setRegister] = useState(true);
    const [auth, setAuth] = useState(false);
    const history = useHistory();

    useEffect(() => {
        if(!window.location.search.includes('redirect=/')) {
            history.push(`?redirect=/subscribe?plan=12&promo=${promocode ?? "NONE"}&redirect=/plus/menu`)
        }
    })

    useEffect(() => {
        let paramRnd = new URLSearchParams(window.location.search).get('rnd');
        if (!ds.retrieveAuthData() && !!paramRnd && !!paramRnd.length) {
            ds.signInWithAuthToken(paramRnd);
        }

        let paramMrt = new URLSearchParams(window.location.search).get('mrt');
        if (!ds.retrieveAuthData() && !!paramMrt && !!paramMrt.length) {
            ds.signInWithAESToken(paramMrt);
        }

        if (!!ds.retrieveAuthData()) {
            history.push(decodeURIComponent(`/subscribe?plan=12&redirect=/plus/menu&promo=${promocode ?? "NONE"}`));
            generateMenu();
        } else {
            ds.subscribeToAuthChanges('AuthOnBoarding', setAuth);
            return () => {
                ds.unsubscribeFromAuthChanges('AuthOnBoarding');
            }
        }
    }, []);

    return register ?
        <RegisterOnBoarding
            goToLogin={() => setRegister(false)}
        />
        : <OnBoardingLogin
            goToRegister={() => setRegister(true)}
        />
}

export default AuthOnBoarding;