import {makeStyles} from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    rootSeparator: {
        height: 2,
        width: '90%',
        margin: "auto",
        marginTop: '0%',
        marginBottom: '20px',
        backgroundColor: '#e6e6e6',
    },
    rootTextsText: {
        fontWeight: 600,
        fontSize: '13px'
    },
    rootLinkText: {
        fontWeight: 600,
        fontSize: '13px',
        marginLeft: '5px',
    },
    rootTextContainer: {
        marginBottom: '20px',
    },
}), {index: 1});
