import React, {useEffect, useState} from "react";
import style from "./style";
import {useHistory} from 'react-router-dom';
import NextButton from "../../nextButton";
import Info from "../../info";

const ObjectiveOptions = [
    {
        title: 'Hombre',
        id: 'm',
        icon: '/assets/icons/gender/male.png',
        iconWidth: 24,
        iconHeight: 24
    },
    {
        title: 'Mujer',
        id: 'w',
        icon: '/assets/icons/gender/female.png',
        iconWidth: 16,
        iconHeight: 23
    }
]


const Activity = ({onNext}) => {
    const classes = style();
    const history = useHistory();
    const [selected, setSelected] = useState();

    useEffect(() => {
        const gender = localStorage.getItem('premium.basic.gender');
        if(gender) {
            setSelected(gender)
        }
    }, []);

    return (
        <div
            className={classes.root}
        >
            {
                ObjectiveOptions.map((option, index) => (
                    <div
                        onClick={() => {
                            setSelected(option.id);
                        }}
                        key={index}
                        className={option.id === selected ? classes.optionSelected : classes.option}
                    >
                        <img
                            width={option.iconWidth}
                            height={option.iconHeight}
                            src={option.icon}/>
                        <div
                            className={classes.optionTitle}
                        >
                            {option.title}
                        </div>
                    </div>
                ))
            }
            <Info
                text={"Tu información personal nos sirve para poder personalizar tu Plan nutricional de acuerdo a tus objetivos."}
            />
            <NextButton
                disabled={!selected}
                onNext={() => {
                    localStorage.setItem('premium.basic.gender', selected)
                    onNext();
                }}
            />
        </div>
    )
}

export default Activity;
