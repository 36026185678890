import { WeekKey } from "@myrealfood-sl/domain";
import { MenuRepository } from "../../../domain/menu-repository/MenuRepository";

export class MenuFinder {
  public constructor(private readonly repository: MenuRepository) {}

  public async getMenuWeek(weekKey: WeekKey) {
    return await this.repository.getMenu(weekKey);
  }
}
