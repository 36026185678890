import {makeStyles} from "@material-ui/core/styles";

export default makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		backgroundColor: 'white',
		paddingLeft: '3%',
		paddingRight: '3%',
		[theme.breakpoints.up('md')]: {
			paddingLeft: '14%',
			paddingRight: '14%',
		},
	},
	rootSeparator: {
		height: 1,
		width: '16%',
		marginLeft: '42%',
		marginRight: '42%',
		marginTop: '10%',
		marginBottom: '7%',
		backgroundColor: '#19C692',
	},

	rootBodySectionFreeTrial: {
		height: '380px',
		marginBottom: '3%',
		[theme.breakpoints.up('sm')]: {
			height: '660px',
			marginBottom: '-20%',
		},
		[theme.breakpoints.up('md')]: {
			height: '680px',
			marginBottom: '-10%',
			paddingLeft: '5%',
			paddingRight: '5%'
		},
	},
	rootBodySectionFreeTrialBackground: {
		position: 'absolute',
		width: '100%',
		zIndex: 0,
		background: 'linear-gradient(#25ab92, #19C692)',
		height: '450px',
		[theme.breakpoints.up('sm')]: {
			height: '620px',
		},
		[theme.breakpoints.up('md')]: {
			height: '640px',
		},
	},
	rootBodySectionFreeTrialLeft: {
		paddingLeft: '5%',
		paddingTop: '5%',
		paddingBottom: '10%'
	},
	rootBodySectionFreeTrialLeftTitle: {
		fontSize: 28,
		color: 'white',
		whiteSpace: "pre-wrap",
		marginTop: '15%',
		[theme.breakpoints.up('sm')]: {
			fontSize: 40,
			marginTop: '0%',
		},
		[theme.breakpoints.up('md')]: {
			fontSize: 40,
			marginTop: '3%',
		},
	},
	rootBodySectionFreeTrialLeftSubtitle: {
		fontSize: 16,
		color: 'white',
		whiteSpace: "pre-wrap",
		marginTop: '10%',
		[theme.breakpoints.up('md')]: {
			marginTop: '3%',
			fontSize: 18,
		},
	},
	rootBodySectionFreeTrialLeftSubtitleHint: {
		fontSize: 14,
		color: 'white',
		whiteSpace: "pre-wrap",
		[theme.breakpoints.up('md')]: {
			fontSize: 16,
		},
	},
	rootBodySectionFreeTrialLeftButtons: {
		flexGrow: 1,
		display: 'none',
		[theme.breakpoints.up('md')]: {
			display: 'flex',
		},
	},
	rootBodySectionFreeTrialRightDesktop: {
		paddingRight: '4%',
		width: '30%',
		display: 'none',
		[theme.breakpoints.up('md')]: {
			width: '50%',
			display: 'flex',
		},
	},
	rootBodySectionFreeTrialRightMobile: {
		display: 'flex',
		[theme.breakpoints.up('md')]: {
			display: 'none',
		},
	},
	rootBodySectionFreeTrialRightMobileButtons: {},
	rootBodySectionFreeTrialRightMobileButtonsSubscribe: {
		borderWidth: 1,
		borderColor: 'white',
		color: '#19C692',
		width: '95%',
		backgroundColor: 'white',
		borderRadius: 20,
		paddingTop: '3%',
		paddingBottom: '3%',
		paddingLeft: '4%',
		paddingRight: '4%',
		fontWeight: 'bold',
		marginTop: '15%',
		marginBottom: '15%',
		[theme.breakpoints.up('md')]: {
			paddingTop: '1%',
			paddingBottom: '1%',
			width: '20%',
			marginTop: '5%',
			marginBottom: '5%',
		},
		'&:hover': {
			color: "#FFF",
		},
	},
	rootBodySectionFreeTrialRightMobileButtonsSubscribeTrial: {
		borderWidth: 1,
		borderColor: 'white',
		color: 'white',
		width: '95%',
		backgroundColor: '#19C692',
		borderRadius: 30,
		paddingTop: '3%',
		paddingBottom: '3%',
		paddingLeft: '4%',
		paddingRight: '4%',
		fontWeight: 'bold',
		marginTop: '-3%',
		marginBottom: '5%',
		[theme.breakpoints.up('md')]: {
			paddingTop: '2%',
			paddingBottom: '2%',
			width: '50%',
			marginTop: '-5%',
			marginBottom: '5%',
		},
		'&:hover': {
			background: '19C692',
			color: "#19C692",
		},
	},
	rootBodySectionFreeTrialRightDesktopImage: {
		objectFit: 'contain',
		width: '60%',
		marginLeft: '30%'
	},
	rootBodySectionFreeTrialRightMobileImage: {
		objectFit: 'contain',
		width: '100%',
	},

	rootBody: {
		margin: '1%',
		paddingTop: '10%'
	},
	rootBodyHeader: {
		marginTop: '10%',
		[theme.breakpoints.up('md')]: {
			marginTop: '0%',
			marginRight: '15%',
			marginLeft: '15%',
		},
	},
	rootBodyHeaderTitle: {
		textAlign: 'center',
		fontSize: 26,
		[theme.breakpoints.up('md')]: {
			fontSize: 48,
		},
	},
	rootBodyHeaderSubtitle: {
		marginTop: '5%',
		textAlign: 'center',
		fontSize: 16,
		paddingRight: '10%',
		paddingLeft: '10%',
		[theme.breakpoints.up('md')]: {
			paddingRight: '0%',
			paddingLeft: '0%',
			fontSize: 22,
		},
	},
	rootBodyHeaderButton: {
		width: '60%',
		marginTop: '10%',
		marginBottom: '10%',
		color: 'white',
		borderRadius: 10,
		paddingTop: '3%',
		paddingBottom: '3%',
		fontWeight: 'bold',
		fontSize: 20,
		backgroundColor: "#19C692",
		[theme.breakpoints.up('md')]: {
			marginTop: '5%',
			marginBottom: '0%',
		},
		'&:hover': {
			backgroundColor: '#0D7D5C',
		}
	},

	rootBodySteps: {
		marginTop: '10%',
		display: 'none',
		[theme.breakpoints.up('md')]: {
			display: 'flex',
		},
	},
	rootBodyStepsAbsolute: {
		width: 2,
		backgroundColor: '#19C692',
		height: '100%',
		position: 'absolute',
		alignSelf: 'center',
		justifySelf: 'center',
		zIndex: 0,
		right: '49%',
		[theme.breakpoints.up('md')]: {
			right: '49%',
		},
		[theme.breakpoints.up('lg')]: {
			right: '49%',
		},
		[theme.breakpoints.up('xl')]: {
			right: '49.5%',
		},
	},
	rootBodyStepsMobile: {
		display: 'flex',
		[theme.breakpoints.up('md')]: {
			display: 'none',
		},
	},
	rootBodyStepsMobileCard: {
		padding: '1%',
	},
	rootBodyStepsMobileCardContainer: {
		borderRadius: 20,
		backgroundColor: '#fff',
		padding: '5%',
		marginBottom: '5%',
	},
	rootBodyStepsMobileCardImage: {
		objectFit: 'contain',
		width: '20%',
		paddingLeft: 'auto',
		paddingRight: 'auto',
	},
	rootBodyStepsMobileCardTitle: {
		fontSize: 18,
		fontWeight: 'bold',
		textAlign: 'center',
		paddingTop: '5%'
	},
	rootBodyStepsMobileCardDescription: {
		fontSize: 16,
		fontWeight: 'normal',
		textAlign: 'center',
		paddingLeft: '5%',
		paddingRight: '5%',
		paddingBottom: '5%',
	},
	rootBodyStep: {
		zIndex: 1,
		paddingTop: '3%',
	},
	rootBodyStepLeft: {
		paddingLeft: '0%',
		paddingRight: '3%'
	},
	rootBodyStepLeftTitle: {
		fontSize: 30,
		fontWeight: 'bold',
		width: '100%'
	},
	rootBodyStepLeftDescription: {
		fontSize: 20,
		fontWeight: 'normal',
	},
	rootBodySteMiddle: {},
	rootBodySteMiddleImage: {
		objectFit: 'contain',
		width: '100%',
		height: '100%',
		padding: '25%'
	},
	rootBodyStepRight: {
		paddingLeft: '3%',
		paddingRight: '0%',
	},
	rootBodyGrow: {
		flexGrow: 1
	},
	rootBodyStepRightTitle: {
		fontSize: 30,
		fontWeight: 'bold',
		width: '100%'
	},
	rootBodyStepRightDescription: {
		fontSize: 20,
		fontWeight: 'normal'
	},

	rootBodyResults: {
		marginTop: '8%',
		padding: '5%'
	},
	rootBodyResultsTitle: {
		textAlign: 'center',
		fontSize: 26,
		[theme.breakpoints.up('md')]: {
			fontSize: 48,
		},
	},
	rootBodyResultsSubtitle: {
		marginTop: '2%',
		textAlign: 'center',
		fontSize: 16,
		paddingRight: '10%',
		paddingLeft: '10%',
		[theme.breakpoints.up('md')]: {
			paddingRight: '0%',
			paddingLeft: '0%',
			fontWeight: 'bold',
			fontSize: 22,
		},
	},
	rootBodyResultsContent: {
		marginTop: '5%',
		display: 'none',
		[theme.breakpoints.up('md')]: {
			display: 'flex',
		},
	},
	rootBodyResultsContentCard: {
		backgroundColor: '#f2f7f0',
		borderRadius: 20,
		padding: '5%',
		margin: '1%'
	},
	rootBodyResultsContentCardTitle: {
		width: '100%',
		textAlign: 'center',
		fontWeight: 'bold',
		fontSize: 50
	},
	rootBodyResultsContentCardDescription: {
		width: '100%',
		textAlign: 'center',
		fontWeight: 'normal',
		fontSize: 22
	},

	rootBodyResultsContentMobile: {
		marginTop: '5%',
		display: 'flex',
		[theme.breakpoints.up('md')]: {
			display: 'none',
		},
	},
	rootBodyResultsContentMobileCard: {
		backgroundColor: '#f2f7f0',
		borderRadius: 20,
		padding: '5%',
		marginBottom: '3%'
	},
	rootBodyResultsContentMobileCardTitle: {
		textAlign: 'center',
		fontWeight: 'bold',
		fontSize: 26
	},
	rootBodyResultsContentMobileCardDescription: {
		textAlign: 'center',
		fontWeight: 'normal',
		fontSize: 14,
		paddingRight: '3%'
	},

	rootBodyPeople: {
		marginTop: '3%',
		width: '80%',
		borderRadius: 20,
		paddingTop: '5%',
		paddingBottom: '5%',
	},
	rootBodyPeopleContent: {
		minHeight: 300,
		[theme.breakpoints.up('md')]: {
			minHeight: 240,
		},
	},
	rootBodyPeopleContentLeft: {
		width: '100%',
	},
	rootBodyPeopleContentLeftImage: {
		objectFit: 'contain',
		height: '20%',
		width: '40%',
		[theme.breakpoints.up('md')]: {
			height: '100%',
			width: 'auto',
		},
	},
	rootBodyPeopleContentMiddle: {},
	rootBodyPeopleContentRight: {
		width: '100%',
	},
	rootBodyPeopleContentRightImage: {
		objectFit: 'contain',
		height: '20%',
		width: '40%',
		[theme.breakpoints.up('md')]: {
			height: '100%',
			width: 'auto',
		},
	},
	rootBodyPeopleContentMiddleContent: {
		width: '100%'
	},
	rootBodyPeopleContentMiddleContentAvatar: {
		paddingRight: '2%'
	},
	rootBodyPeopleContentMiddleContentAvatarImage: {
		objectFit: 'contain',
		width: '30%',
		height: 'auto'
	},
	rootBodyPeopleContentMiddleContentRealfooder: {},
	rootBodyPeopleContentMiddleContentRealfooderDisplayName: {
		fontWeight: 'bold',
		fontSize: 18,
		[theme.breakpoints.up('md')]: {
			fontSize: 20,
		},
	},
	rootBodyPeopleContentMiddleContentRealfooderNickname: {
		fontSize: 18
	},
	rootBodyPeopleContentMiddleContentRealfooderComment: {
		marginTop: '2%',
		width: '100%',
		textAlign: 'center',
		fontSize: 16,
		paddingRight: '8%',
		paddingLeft: '8%',
		paddingBottom: '2%',
		[theme.breakpoints.up('md')]: {
			fontSize: 22,
			paddingRight: '5%',
			paddingLeft: '5%',
		},
	},
	rootBodyOnboarding: {
		marginTop: '8%'
	},
	rootBodyOnboardingTitle: {
		textAlign: 'center',
		fontSize: 26,
		[theme.breakpoints.up('md')]: {
			fontSize: 48,
		},
	},
	rootBodyOnboardingDesktop: {
		display: 'none',
		[theme.breakpoints.up('md')]: {
			display: 'flex',
		},
	},
	rootBodyOnboardingMobile: {
		display: 'flex',
		[theme.breakpoints.up('md')]: {
			display: 'none',
		},
	},
	rootBodyOnboardingTitleMobile: {
		fontWeight: 'normal',
		textAlign: 'center',
		fontSize: 12,
		paddingLeft: '10%',
		paddingRight: '10%',
		paddingBottom: '5%',
		color: '#424242',
		[theme.breakpoints.up('md')]: {
			fontSize: 14,
		},
	},
	rootBodyOnboardingSubTitle: {
		marginTop: '2%',
		textAlign: 'center',
		fontSize: 16,
		paddingRight: '10%',
		paddingLeft: '10%',
		[theme.breakpoints.up('md')]: {
			paddingRight: '15%',
			paddingLeft: '15%',
			fontSize: 22,
		},
	},
	rootBodyOnboardingSubTitleMobile: {
		fontWeight: 'normal',
		fontSize: 12,
		paddingLeft: '2%',
		paddingRight: '2%',
		paddingBottom: '5%',
		color: '#424242',
		marginRight: '10%',
		marginLeft: '10%',
		textAlign: 'center',
		[theme.breakpoints.up('md')]: {
			fontSize: 14,
		},
	},
	rootBodyOnboardingContent: {
		marginTop: '4%',
		marginBottom: '4%',
		[theme.breakpoints.up('md')]: {
			paddingLeft: '10%',
			paddingRight: '10%',
		},
	},
	rootBodyFAQContent: {
		marginTop: '50px',
		marginBottom: '60px',
		paddingLeft: '10%',
		paddingRight: '10%',
	},
	rootBodyFAQContentBody: {
		marginBottom: '3%',
		paddingLeft: '3%',
		paddingRight: '0%',
		[theme.breakpoints.up('md')]: {
			paddingLeft: '10%',
			paddingRight: '0%',
		},
	},
	rootBodyFAQContentTitle: {
		fontWeight: 'bold',
		fontSize: 16,
		marginBottom: '2%',
		[theme.breakpoints.up('md')]: {
			fontSize: 26,
		},
	},
	rootBodyFAQContentDescription: {
		fontWeight: 'normal',
		fontSize: 14,
		paddingRight: '20%',
		paddingBottom: '2%',
		[theme.breakpoints.up('md')]: {
			fontSize: 20,
		},
	},
	rootBodyFAQContentButton: {
		borderRadius: 12,
		width: 24,
		height: 24,
		border: '1px solid black',
		borderColor: 'black',
		textAlign: 'center',
		[theme.breakpoints.up('md')]: {
			borderRadius: 20,
			width: 40,
			height: 40,
		},
	},
	rootTitle: {
		fontSize: '29px',
		fontWeight: '600',
		marginBottom: '50px'
	}
}), { index: 1 });
