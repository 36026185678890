import { WeekKey, WeekMenu } from "@myrealfood-sl/domain";

import { MenuCloudRepository } from "../../../../_new_arch/contexts/plus/menu/infrastructure/menu-repository/MenuCloudRepository";
import { MenuFinder } from "../../../../_new_arch/contexts/plus/menu/application/menu/menu-finder/MenuFinder";

export class PlusMenuInteractor {
  private constructor(private readonly api: MenuCloudRepository) {}

  public static getInstance(): PlusMenuInteractor {
    return new PlusMenuInteractor(new MenuCloudRepository());
  }

  public async getMenu(weekkey: WeekKey): Promise<WeekMenu> {
    return await new MenuFinder(this.api).getMenuWeek(weekkey);
  }
}

export const plusMenuInteractor = PlusMenuInteractor.getInstance();
