import React from "react";
import style from "./style";
import {useHistory} from 'react-router-dom';
import NextButton from "../../nextButton";
import Info from "../../info";

const Health = ({onNext}) => {
    const classes = style();
    const history = useHistory();

    return (
        <div
            className={classes.root}
        >
            <img
                className={classes.img}
                src={'/assets/backgrounds/onboarding/graficaESP.png'}
            />
            <Info
                text={'Nuestros profesionales son graduados en Nutrición Humana y Dietética, con una amplia experiencia en la pérdida de grasa.'}
            />
            <NextButton
                onNext={onNext}
            />
        </div>
    )
}

export default Health;
