import {makeStyles} from "@material-ui/core/styles";

export default makeStyles((theme) => ({
	rootBodyContentRightBoxContainerRadioDesktop: {
		alignSelf: 'center',
		justifySelf: 'center',
		display: 'none',
		color: '#BABABA',
		'&$checked': {
			color: '#19C692',
		},
		[theme.breakpoints.up('md')]: {
			display: 'flex',
			marginTop: '-10%',
			marginBottom: '5%'
		},
	},
	rootBodyContentRightBoxContainerRadioMobile: {
		alignSelf: 'center',
		justifySelf: 'center',
		marginLeft: '50%',
		color: '#FFF',
		'&$checked': {
			color: '#FFF',
		},
		[theme.breakpoints.up('md')]: {
			display: 'none'
		},
	},
	rootBodyContentRightBox: {
		marginTop: '8%',
		borderWidth: '1',
		borderColor: '#19C692',
		borderRadius: 20,
		width: '85%',
		[theme.breakpoints.up('md')]: {
			width: '80%',
		},
	},
	rootBodyContentRightBoxUnselected: {
		marginTop: '8%',
		borderWidth: '1',
		borderColor: '#BABABA',
		borderRadius: 20,
		width: '85%',
		[theme.breakpoints.up('md')]: {
			width: '80%',
		},
	},
	rootBodyContentRightBoxContainer: {},
	rootBodyContentRightBoxContainerTitle: {
		borderTopRightRadius: 16,
		borderTopLeftRadius: 16,
		width: '100%',
		backgroundColor: '#19C692',
		textAlign: 'center',
		fontWeight: 'bold',
		color: 'white',
		fontSize: 16,
		paddingTop: '4%',
		paddingBottom: '4%',
		[theme.breakpoints.up('md')]: {
			fontSize: 18,
			paddingTop: '4%',
			paddingBottom: '4%',
		},
	},
	rootBodyContentRightBoxContainerTitleUnselected: {
		borderTopRightRadius: 16,
		borderTopLeftRadius: 16,
		width: '100%',
		backgroundColor: '#BABABA',
		textAlign: 'center',
		fontWeight: 'bold',
		color: 'white',
		fontSize: 16,
		paddingTop: '4%',
		paddingBottom: '4%',
		[theme.breakpoints.up('md')]: {
			fontSize: 18,
			paddingTop: '4%',
			paddingBottom: '4%',
		},
	},
	rootBodyContentRightBoxContainerSubtitle: {
		fontWeight: 'bold',
		textAlign: 'center',
		marginTop: '15%',
		fontSize: 16,
		[theme.breakpoints.up('md')]: {
			fontSize: 18,
		},
	},
	rootBodyContentRightBoxContainerPrice: {
		textAlign: 'center',
		color: '#19C692',
		fontSize: 14,
		fontWeight: 'bold',
		marginTop: '15%',
		marginBottom: '15%',
		[theme.breakpoints.up('md')]: {
			fontSize: 16,
			marginTop: '0%',
			marginBottom: '15%',
		},
	},
	rootBodyContentRightBoxContainerPriceUnselected: {
		textAlign: 'center',
		color: '#BABABA',
		fontSize: 14,
		fontWeight: 'bold',
		marginTop: '15%',
		marginBottom: '15%',
		[theme.breakpoints.up('md')]: {
			fontSize: 16,
			marginTop: '0%',
			marginBottom: '15%',
		},
	},
	rootBodyContentRightBoxContainerFinalPriceStroke: {
		textAlign: 'center',
		fontSize: 16,
		whiteSpace: "pre-wrap",
		marginTop: '15%',
		marginBottom: '15%',
		textDecorationLine: 'line-through',
		textDecorationStyle: 'solid',
		[theme.breakpoints.up('md')]: {
			fontSize: 18,
			marginTop: '10%',
			marginBottom: '5%',
		},
	},
	rootBodyContentRightBoxContainerFinalPrice: {
		textAlign: 'center',
		fontSize: 16,
		whiteSpace: "pre-wrap",
		marginTop: '15%',
		marginBottom: '15%',
		[theme.breakpoints.up('md')]: {
			fontSize: 18,
			marginTop: '10%',
			marginBottom: '5%',
		},
	},
	rootBodyContentRightBoxContainerMonthlyPrice: {
		textAlign: 'center',
		marginBottom: '15%',
		fontSize: 12,
		color: '#828282',
		[theme.breakpoints.up('md')]: {
			fontSize: 16,
			marginBottom: '10%',
		},
	},
	rootBodyContentRightBoxContainerButtonUnselected: {
		marginTop: '10%',
		borderWidth: 1,
		color: '#BABABA',
		backgroundColor: '#FFF',
		borderRadius: 10,
		paddingTop: '3%',
		paddingBottom: '3%',
		paddingLeft: '4%',
		paddingRight: '4%',
		fontWeight: 'bold',
		fontSize: 10,
		marginBottom: '15%',
		width: '80%',
		marginLeft: '1%',
		marginRight: '1%',
		display: 'none',
		[theme.breakpoints.up('md')]: {
			marginLeft: '0%',
			marginRight: '0%',
			width: '80%',
			fontWeight: 'bold',
			fontSize: 12,
			display: 'flex',
		},
	},
	rootBodyContentRightBoxContainerButton: {
		marginTop: '10%',
		borderWidth: 1,
		color: '#FFF',
		backgroundColor: '#19C692',
		borderRadius: 10,
		paddingTop: '3%',
		paddingBottom: '3%',
		paddingLeft: '4%',
		paddingRight: '4%',
		fontWeight: 'bold',
		fontSize: 10,
		marginBottom: '15%',
		width: '80%',
		marginLeft: '1%',
		marginRight: '1%',
		display: 'none',
		[theme.breakpoints.up('md')]: {
			marginLeft: '0%',
			marginRight: '0%',
			width: '80%',
			fontWeight: 'bold',
			fontSize: 12,
			display: 'flex',
		},
	},
	rootBodyContentRightBoxContainerHintPrice: {
		textAlign: 'center',
		color: '#828282',
		whiteSpace: "pre-wrap",
		fontSize: 10,
		marginTop: '5%',
		marginBottom: '5%',
		paddingRight: '8%',
		paddingLeft: '8%',
		[theme.breakpoints.up('md')]: {
			fontSize: 12,
			marginTop: '0%',
			marginBottom: '10%',
			paddingRight: '5%',
			paddingLeft: '5%',
		},
	}
}), {index: 1});
