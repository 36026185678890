import React, {useEffect, useState} from 'react';
import style from './style'
import Typography from '@material-ui/core/Typography';
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import {useHistory} from "react-router";
import ds from '../../controller/DataService'
import Locale from "../../controller/locale";
import {__DEFAULT_PROMOCODE__} from "../../controller/Exports";

export default function Footer(props) {
	const classes = style();
	const history = useHistory();

	const [auth, setAuth] = useState(undefined);
	const [visible, setVisible] = React.useState(true);

	useEffect(() => {
		ds.subscribeToAuthChanges('Footer', setAuth);
		return () => {
			ds.unsubscribeFromAuthChanges('Footer');
		}
	});

	useEffect(() => {
		setVisible(!(!!window.location && !!window.location.pathname && (
			window.location.pathname.includes('/login') ||
			window.location.pathname.includes('/subscribe')
		)))
	});

	if (!visible) {
		return (<div/>)
	} else {
		return (
			<div>
				{!!props.white && <Grid className={classes.whiteSeparator}/>}
				<Grid
					className={!!props.white ? classes.footerWhite : classes.footer}
					container
					md={12}
					direction="row"
					justify="center"
					alignItems="center"
				>
					<Grid
						className={classes.footerLeft}
						container
						direction="column"
						justify="center"
						alignItems="center"
						md={4}
					>
						{!!!props.white && <Link
							component="button"
							onClick={() => {
								window.scrollTo({top: 0, behavior: 'smooth'});
							}}
						>
							<img
								className={classes.footerLeftLogo}
								src="/assets/backgrounds/bg_myrealfood.png"
								alt="MyRealFood"
							/>
						</Link>}
						<img
							className={classes.footerLeftDisplayImage}
							src="/assets/backgrounds/bg_myrealfood_rocket.png"
							alt="MyRealFood"/>
					</Grid>
					<Grid
						className={classes.footerRight}
						item
						md={8}
					>
						<Grid
							className={classes.footerRightTop}
							container
							md={12}
							direction="row"
							justify="space-around"
						>
							<Grid
								container
								md={2}
								xs={6}
								direction="column"
								alignItems={"flex-start"}
							>
								<Typography
									className={!!props.white ? classes.footerRightTopHeaderWhite : classes.footerRightTopHeader}
									variant="h6"
								>
									{Locale.getString('footer_plan_plus')}
								</Typography>

								<Link
									component="button"
									onClick={() => {
										history.push(`/plus/?period=12&promo=${ds.retrunAvailablePromocode(__DEFAULT_PROMOCODE__[0])}`)
									}}
								>
									<Typography
										className={!!props.white ? classes.footerRightTopTextWhite : classes.footerRightTopText}
										variant="h6"
									>
										{Locale.getString('footer_about_plan_plus')}
									</Typography>
								</Link>

								{<Link
									component="button"
									onClick={() => {
										history.push("/regalo/comprar")
									}}
								>
									<Typography
										className={!!props.white ? classes.footerRightTopTextWhite : classes.footerRightTopText}
										variant="h6"
									>
										{Locale.getString('footer_gift_plan')}
									</Typography>
								</Link>}

								{<Link
									component="button"
									onClick={() => {
										history.push("/regalo/canjear")
									}}
								>
									<Typography
										className={!!props.white ? classes.footerRightTopTextWhite : classes.footerRightTopText}
										variant="h6"
									>
										{Locale.getString('redeem_gift_alternative')}
									</Typography>
								</Link>}

								<Link
									component="button"
									onClick={() => {
										history.push("/faq/")
									}}
								>
									<Typography
										className={!!props.white ? classes.footerRightTopTextWhite : classes.footerRightTopText}
										variant="h6"
									>
										{Locale.getString('frequent_questions')}
									</Typography>
								</Link>
							</Grid>
							<Grid
								container
								md={2}
								xs={6}
								direction="column"
								alignItems={"flex-start"}
							>
								<Typography
									className={!!props.white ? classes.footerRightTopHeaderWhite : classes.footerRightTopHeader}
									variant="h6"
								>
									{Locale.getString('footer_team')}
								</Typography>
								<Link
									component="button"
									onClick={() => {
										window.location.assign("mailto:contact@myrealfood.app")
									}}
								>
									<Typography
										className={!!props.white ? classes.footerRightTopTextWhite : classes.footerRightTopText}
										variant="h6"
									>
										{Locale.getString('footer_contact')}
									</Typography>
								</Link>

								<Link
									component="button"
									onClick={() => {
										window.location.assign("https://www.notion.so/myrealfoodcareers/Careers-at-MyRealFood-d8742f2c32d841559323ab19d5771811")
									}}
								>
									<Typography
										className={!!props.white ? classes.footerRightTopTextWhite : classes.footerRightTopText}
										variant="h6"
									>
										{Locale.getString('footer_jobs')}
									</Typography>
								</Link>
							</Grid>
							<Grid
								container
								md={2}
								xs={6}
								direction="column"
								alignItems={"flex-start"}
							>
								<Typography
									className={!!props.white ? classes.footerRightTopHeaderWhite : classes.footerRightTopHeader}
									variant="h6"
								>
									{Locale.getString('footer_follow_us')}
								</Typography>

								<Link
									component="button"
									onClick={() => {
										window.location.assign("https://www.instagram.com/myrealfood_app/")
									}}
								>
									<Typography
										className={!!props.white ? classes.footerRightTopTextWhite : classes.footerRightTopText}
										variant="h6"
									>
										Instagram
									</Typography>
								</Link>

								<Link
									component="button"
									onClick={() => {
										window.location.assign("https://www.facebook.com/groups/myrealfood")
									}}
								>
									<Typography
										className={!!props.white ? classes.footerRightTopTextWhite : classes.footerRightTopText}
										variant="h6"
									>
										Facebook
									</Typography>
								</Link>

								<Link
									component="button"
									onClick={() => {
										window.location.assign("https://www.tiktok.com/@myrealfood/")
									}}
								>
									<Typography
										className={!!props.white ? classes.footerRightTopTextWhite : classes.footerRightTopText}
										variant="h6"
									>
										TikTok
									</Typography>
								</Link>

								<Link
									component="button"
									onClick={() => {
										window.location.assign("https://www.youtube.com/c/MyRealFood_app")
									}}
								>
									<Typography
										className={!!props.white ? classes.footerRightTopTextWhite : classes.footerRightTopText}
										variant="h6"
									>
										YouTube
									</Typography>
								</Link>

								<Link
									component="button"
									onClick={() => {
										window.location.assign("https://www.linkedin.com/company/myrealfood")
									}}
								>
									<Typography
										className={!!props.white ? classes.footerRightTopTextWhite : classes.footerRightTopText}
										variant="h6"
									>
										LinkedIn
									</Typography>
								</Link>
							</Grid>
							<Grid
								className={classes.footerRightTopGrow}
								item
								md={2}
							/>
						</Grid>
						<Grid
							className={classes.footerRightBottom}
							container
							md={12}
							sm={12}
							direction="row"
							alignItems="center"
							justify="space-around"
						>
							<Typography
								className={!!props.white ? classes.footerRightBottomTextWhite : classes.footerRightBottomText}
							>
								Copyright © {new Date().getFullYear()} MyRealFood
							</Typography>

							<Typography
								className={!!props.white ? classes.footerRightBottomTextWhite : classes.footerRightBottomText}
							>
								◦
							</Typography>

							<Link
								component="button"
								onClick={() => {
									window.location.assign("/docs/aviso_legal.pdf")
								}}
							>
								<Typography
									className={!!props.white ? classes.footerRightBottomTextWhite : classes.footerRightBottomText}
								>
									{Locale.getString('footer_legal_disclaimer')}
								</Typography>
							</Link>

							<Typography
								className={!!props.white ? classes.footerRightBottomTextWhite : classes.footerRightBottomText}
							>
								◦
							</Typography>

							<Link
								component="button"
								onClick={() => {
									window.location.assign("/docs/terminos_del_servicio.pdf")
								}}
							>
								<Typography
									className={!!props.white ? classes.footerRightBottomTextWhite : classes.footerRightBottomText}
								>
									{Locale.getString('footer_terms_services')}
								</Typography>
							</Link>

							<Typography
								className={!!props.white ? classes.footerRightBottomTextWhite : classes.footerRightBottomText}
							>
								◦
							</Typography>

							<Link
								component="button"
								onClick={() => {
									window.location.assign("/docs/politica_de_privacidad.pdf")
								}}
							>
								<Typography
									className={!!props.white ? classes.footerRightBottomTextWhite : classes.footerRightBottomText}
								>
									{Locale.getString('footer_privacy_policy')}
								</Typography>
							</Link>

							<Typography
								className={!!props.white ? classes.footerRightBottomTextWhite : classes.footerRightBottomText}
							>
								◦
							</Typography>

							<Link
								component="button"
								onClick={() => {
									window.location.assign("/docs/politica_uso_de_cookies.pdf")
								}}
							>
								<Typography
									className={!!props.white ? classes.footerRightBottomTextWhite : classes.footerRightBottomText}
								>
									{Locale.getString('footer_privacy_cookies')}
								</Typography>
							</Link>
							<Grid
								className={classes.footerRightBottomGrow}
								item
								md={2}
								sm={2}
							/>
						</Grid>

						<Grid
							className={classes.footerRightBottomExtra}
							container
							md={12}
							direction="row"
						>
							<Grid
								container
								md={12}
								xs={6}
							>
								<img
									className={classes.footerLeftDisplayExtra}
									src="/assets/backgrounds/bg_myrealfood_rocket.png"
									alt="MyRealFood"/>
							</Grid>
							<Grid
								container
								md={12}
								xs={12}
							>
								<Link
									component="button"
									onClick={() => {
										window.location.assign("/docs/aviso_legal.pdf")
									}}
								>
									<Typography
										className={!!props.white ? classes.footerRightBottomTextWhite : classes.footerRightBottomText}
									>
										◦ {Locale.getString('footer_legal_disclaimer')}
									</Typography>
								</Link>

								<br/>

								<Link
									component="button"
									onClick={() => {
										window.location.assign("/docs/terminos_del_servicio.pdf")
									}}
								>
									<Typography
										className={!!props.white ? classes.footerRightBottomTextWhite : classes.footerRightBottomText}
									>
										◦ {Locale.getString('footer_terms_services')}
									</Typography>
								</Link>

								<br/>

								<Link
									component="button"
									onClick={() => {
										window.location.assign("/docs/politica_de_privacidad.pdf")
									}}
								>
									<Typography
										className={!!props.white ? classes.footerRightBottomTextWhite : classes.footerRightBottomText}
									>
										◦ {Locale.getString('footer_privacy_policy')}
									</Typography>
								</Link>

								<br/>

								<Link
									component="button"
									onClick={() => {
										window.location.assign("/docs/politica_uso_de_cookies.pdf")
									}}
								>
									<Typography
										className={!!props.white ? classes.footerRightBottomTextWhite : classes.footerRightBottomText}
									>
										◦ {Locale.getString('footer_privacy_cookies')}
									</Typography>
								</Link>

								<br/>

								<Typography
									className={!!props.white ? classes.footerRightBottomTextWhite : classes.footerRightBottomText}
								>
									Copyright © {new Date().getFullYear()} MyRealFood
								</Typography>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</div>
		);
	}
}
