import React from 'react';
import style from './style'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

export default function BasicHeader() {
    const classes = style();

    return (
        <div className={classes.content}>
            <AppBar className={classes.appBar} position="sticky" color="white" elevation={0}>
                <Toolbar>
                            <img className={classes.image}
                                 src="/assets/icons/ic_myrealfood.png"
                                 alt="MyRealFood"/>
                </Toolbar>
            </AppBar>
        </div>
    );
}