import Typography from "@material-ui/core/Typography";
import React from "react";
import style from "./style";
import Grid from "@material-ui/core/Grid";
import {useMediaQuery, useTheme} from "@material-ui/core";

const PricingCardComponet = (props) => {
    const classes = style();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    return(
            <Grid
                item
                xs={12}
                md={3}
                onClick={props.onClick}
                className={props.selected ? classes.cardContainerSelected : classes.cardContainerNoneSelected}
                style={matches ? {order: props.offer.order[1]} : {order: props.offer.order[2]}}
            >
                <Grid
                    direction={'row'}
                    container
                    justify={"space-between"}>
                    <Typography
                        className={classes.cardTitle}
                        color={'primary'}
                    >
                        {props.offer.title}
                    </Typography>
                    <Typography
                        className={classes.cardTitle}
                        color={'primary'}
                    >
                        {props.offer.price}
                    </Typography>
                </Grid>
                <Grid
                    direction={'row'}
                    container
                    justify={"space-between"}>
                    <Typography className={classes.cardSubtitle}>
                        {props.offer.subtitle}
                    </Typography>
                    <Typography
                        dangerouslySetInnerHTML={{ __html: props.offer.oldPrice }}
                    />
                </Grid>
                <Grid
                    container
                    justify={"center"}>
                    <Typography
                        align={"center"}
                        className={classes.cardText}
                        dangerouslySetInnerHTML={{ __html: props.offer.text }}
                    />
                </Grid>
            </Grid>
    );
}

export default PricingCardComponet;