import {
  Locales,
  NutritionalInfoPrimitives,
  ProductImagesPrimitives,
  ProductLocales,
  ReducedProductPrimitives
} from "@myrealfood-sl/domain";

export type ReducedProductDocument = {
  id: string;
  additional: object;
  allergens: string[];
  brand: string;
  code: string;
  images: ProductImagesPrimitives;
  ingredients: string[];
  ingredients_text: string;
  isLiquid: boolean;
  name: string;
  name_locales: Locales;
  note: string;
  note_locales: Locales;
  nutritional: NutritionalInfoPrimitives;
  photo: string;
  relationalBrand: string;
  relationalIngredients: Record<string, string>;
  relationalIngredients_locales: ProductLocales;
  relationalTraces: Record<string, string>;
  relationalTrace_locales: ProductLocales;
  label: string;
  relationalLabel: string;
  traces: string[];
  thumb: string;
  score: number;
  rating: number;
  amount: number;
  fixed: number;
  unit: number;
};

export class ReducedProductTranslator {
  private static instance: ReducedProductTranslator;

  private constructor() {
    // empty
  }

  public static getInstance(): ReducedProductTranslator {
    if (!ReducedProductTranslator.instance) {
      ReducedProductTranslator.instance = new ReducedProductTranslator();
    }

    return ReducedProductTranslator.instance;
  }

  public fromDocumenToPrimitives(document: ReducedProductDocument): ReducedProductPrimitives {
    return {
      id: document.code,
      core: {
        allergens: {
          additional: document.additional,
          allergens: document.allergens,
          note: document.note,
          noteLocales: document.note_locales
        },
        essential: {
          name: document.name,
          nameLocales: document.name_locales,
          code: document.code,
          images: document.images,
          photo: document.photo,
          thumb: document.thumb
        }
      },
      nutritional: {
        relationalIngredients: document.relationalIngredients,
        relationalIngredientsLocales: document.relationalIngredients_locales,
        relationalTraces: document.relationalTraces,
        relationalTraceLocales: document.relationalTrace_locales,
        nutritional: document.nutritional,
        ingredients: document.ingredients,
        isLiquid: document.isLiquid,
        ingredientsText: document.ingredients_text,
        traces: document.traces
      },
      tags: {
        relationalBrand: document.relationalBrand,
        relationalLabel: document.relationalLabel,
        brand: document.brand,
        label: document.label
      },
      score: {
        score: document.score,
        rating: document.rating
      },
      tracker: {
        amount: 0,
        fixed: 0,
        unit: 0
      },
      claims: {
        notes: []
      }
    };
  }

  public fromPrimitivesToDocument(primitive: ReducedProductPrimitives): ReducedProductDocument {
    return {
      id: primitive.id,
      additional: primitive.core.allergens.additional,
      allergens: primitive.core.allergens.allergens,
      brand: primitive.tags.brand,
      code: primitive.id,
      images: primitive.core.essential.images,
      ingredients: primitive.nutritional.ingredients,
      ingredients_text: primitive.nutritional.ingredientsText,
      isLiquid: primitive.nutritional.isLiquid,
      name: primitive.core.essential.name,
      name_locales: primitive.core.essential.nameLocales,
      note: primitive.core.allergens.note,
      note_locales: primitive.core.allergens.noteLocales,
      nutritional: primitive.nutritional.nutritional,
      photo: primitive.core.essential.photo,
      relationalBrand: primitive.tags.relationalBrand,
      relationalIngredients: primitive.nutritional.relationalIngredients,
      relationalIngredients_locales: primitive.nutritional.relationalIngredientsLocales,
      relationalTraces: primitive.nutritional.relationalTraces,
      relationalTrace_locales: primitive.nutritional.relationalTraceLocales,
      label: primitive.tags.label,
      relationalLabel: primitive.tags.relationalLabel,
      traces: primitive.nutritional.traces,
      thumb: primitive.core.essential.thumb,
      score: primitive.score.score,
      rating: primitive.score.rating,
      amount: primitive.tracker.amount,
      fixed: primitive.tracker.fixed,
      unit: primitive.tracker.unit
    };
  }
}
export const reducedProductTranslator = ReducedProductTranslator.getInstance();
