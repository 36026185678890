import {makeStyles} from "@material-ui/core/styles";

export default makeStyles((theme) => ({
	paper: {
		position: 'absolute',
		backgroundColor: theme.palette.background.paper,
		border: '1px solid #000',
		boxShadow: theme.shadows[5],
		top: `50%`,
		left: `50%`,
		transform: `translate(-50%, -50%)`,
		overflow: 'scroll',
		height: '80%',
		display: 'block',
		width: '90%',
		padding: '5%',
		[theme.breakpoints.up('sm')]: {
			width: '70%',
			padding: '3%',
		},
		[theme.breakpoints.up('md')]: {
			width: '40%',
			padding: '3%',
		},
	},
	paperTitle: {
		fontWeight: 'bold',
		fontSize: 16,
		marginBottom: '1%',
		[theme.breakpoints.up('md')]: {
			fontSize: 22,
		},
	},
	paperDescription: {
		fontWeight: 'normal',
		fontSize: 14,
		paddingBottom: '2%',
		[theme.breakpoints.up('md')]: {
			fontSize: 16,
		},
	},
	paperSeparator: {
		height: '3%'
	},
	paperButton: {
		backgroundColor: '#19C692',
		paddingTop: '2%',
		paddingBottom: '2%',
		paddingLeft: '3%',
		paddingRight: '3%',
		borderRadius: 10,
		marginTop: '2%',
		marginBottom: '4%'
	},
	paperButtonText: {
		color: 'white',
		fontWeight: 'bold',
		fontSize: 12,
		[theme.breakpoints.up('md')]: {
			fontSize: 14,
		},
	},

	parent: {
		marginTop: '30%',
		display: 'flex',
		[theme.breakpoints.up('md')]: {
			display: 'none',
		},
	},
	rootBodySection1LeftButtons: {
		flexGrow: 1,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		width: '100%', height: '10%', position: 'fixed', bottom: 0, backgroundColor: '#19C692',
		[theme.breakpoints.up('md')]: {
			display: 'none',
		},
	},
	rootBodySection1LeftButtonsLeft: {
		paddingRight: '4%',
		width: '100%',
		[theme.breakpoints.up('md')]: {
			width: '100%',
		},
	},
	rootBodySection1RightMobileButtonsLeft: {
		width: '70%',
	},
	rootBodySection1LeftButtonsLeftImage: {
		objectFit: 'contain',
		width: '100%'
	},
	rootBodySection1RightMobileButtonsLeftImage: {
		objectFit: 'contain',
		width: '100%'
	},
	rootBodySection1LeftButtonsRight: {
		paddingRight: '4%',
		width: '100%',
		[theme.breakpoints.up('md')]: {
			width: '100%',
		},
	},
	rootBodySection1LeftMobileButtonsRight: {
		width: '70%',
	},
	rootBodySection1LeftButtonsRightImage: {
		objectFit: 'contain',
		width: '100%'
	},
	rootBodySection1LeftMobileButtonsRightImage: {
		objectFit: 'contain',
		width: '100%'
	},
	rootBodySection1RightDesktop: {
		paddingRight: '4%',
		width: '30%',
		display: 'none',
		[theme.breakpoints.up('md')]: {
			width: '50%',
			display: 'flex',
		},
	},
	rootBodySection1RightMobile: {
		display: 'flex',
		[theme.breakpoints.up('md')]: {
			display: 'none',
		},
	},
	rootBodySection1RightButtons: {
		display: 'flex',
		[theme.breakpoints.up('md')]: {
			display: 'none',
		},
	},
	rootBodySection1RightMobileButtons: {
		marginTop: '-20%'
	},
	rootBodySection1RightDesktopImage: {
		objectFit: 'contain',
		width: '80%',
		marginLeft: '20%'
	},
	rootBodySection1RightMobileImage: {
		objectFit: 'contain',
		width: '100%',
	},
}), {index: 1});
