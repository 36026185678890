import React from 'react';

import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions'

import Locale from "../../../controller/locale";

import style from './style';
import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Radio from '@material-ui/core/Radio';
import {withStyles} from '@material-ui/core/styles';

export default function PlanBoxTrial(props) {
	const classes = style();

	const [selectedValue, setSelectedValue] = React.useState(props.current.period);

	const selected = props.item.period === props.current.period;

	const handleChange = (event) => {
		setSelectedValue(event.target.value);
	};

	const GreenRadio = withStyles({
		root: {

		},
		checked: {},
	})((props) => <Radio color="default" {...props} />);

	const each = (!!props.discount && !!props.discount.oneTime) ? '' : (`/${(props.item.period === 1) ? Locale.getString('subscribe_month') : Locale.getString('year')}`);
	const header = (!!props.discount && !!props.discount.oneTime) ? (props.item.period === 12 ? ('12 ' + Locale.getString('subscribe_months')) : Locale.getString('one_moth')) : (props.item.header === Locale.getString('subscribe_popular') ? Locale.getString('anual') : props.item.header);

	console.log(props.discount, props.item);
	return (
		<Box
			className={selected ? classes.rootBodyContentRightBox : classes.rootBodyContentRightBoxUnselected}
			border={1}
			onClick={props.onClick}
		>
			<Grid
				className={classes.rootBodyContentRightBoxContainer}
				container
				direction={"column"}
				alignItems={"center"}
				justify={"center"}
				onClick={props.onClick}
			>
				<Typography
					className={selected ? classes.rootBodyContentRightBoxContainerTitle : classes.rootBodyContentRightBoxContainerTitleUnselected}
				>
					{header}
					<GreenRadio
						className={classes.rootBodyContentRightBoxContainerRadioMobile}
						checked={selected}
						onChange={handleChange}
						value={props.item.period}
						name="radio-button-demo"
						inputProps={{'aria-label': 'A'}}
					/>
				</Typography>

				<Grid
					container
					alignItems={'center'}
					justify={'center'}
				>
					<Grid
						item
						md={12}
						xs={6}
					>
						{(!!props.discount && !!props.discount.enabled && !!props.discount.applyToPeriod && !!props.discount.applyToPeriod[props.item.period]) ?
							<div>
								{`${props.item.period}` !== '1' && <Typography
									className={classes.rootBodyContentRightBoxContainerFinalPriceStroke}
								>
									{props.item.currencySymbolStart === true ? props.item.currencySymbol : ''}{Number(!!props.base ? (12 * props.base.price) : props.item.price).toFixed(2)}{props.item.currencySymbolStart === false ? props.item.currencySymbol : ''}{""}{each}
								</Typography>}
								<Typography
									className={classes.rootBodyContentRightBoxContainerFinalPrice}
								>
									{props.item.currencySymbolStart === true ? props.item.currencySymbol : ''}{Number(props.item.price * (!!props.discount.trialPeriodDays ? 1 : props.discount.discountToPeriod[props.item.period])).toFixed(2)}{props.item.currencySymbolStart === false ? props.item.currencySymbol : ''}{""}{each}
								</Typography>
							</div>
							:
							<Typography
								className={classes.rootBodyContentRightBoxContainerFinalPrice}
							>
								{props.item.currencySymbolStart === true ? props.item.currencySymbol : ''}{Number(props.item.price).toFixed(2)}{props.item.currencySymbolStart === false ? props.item.currencySymbol : ''}{}{""}{each}
							</Typography>}
					</Grid>
					<Grid
						item
						md={12}
						xs={6}
					>
						{!!props.discount && !!props.discount.enabled && !!props.discount.applyToPeriod && !!props.discount.applyToPeriod[props.item.period] &&
						(!!!props.discount.trialPeriodDays ?
							<Typography
								className={classes.rootBodyContentRightBoxContainerPrice}
							>
								{(!!props.discount && !!props.discount.oneTime && (Number((1 - props.item.price / props.item.originalPrice) * 100).toFixed(0)) !== '0') ? (Locale.getString('save_money') + Number((1 - (Number(props.item.price * (!!props.discount.trialPeriodDays ? 1 : props.discount.discountToPeriod[props.item.period])).toFixed(2)) / (Number(!!props.base ? (12 * props.base.price) : props.item.price).toFixed(2))) * 100).toFixed(0) + '%') : (Number(props.discount.discountToPeriod[props.item.period]) >= 1 ? '' : (Locale.getString('save_money') + Number(Number(1 - Number(props.discount.discountToPeriod[props.item.period])) * 100).toFixed(0) + '%'))}
							</Typography>
							:
							<Typography
								className={selected ? classes.rootBodyContentRightBoxContainerPrice : classes.rootBodyContentRightBoxContainerPriceUnselected}
							>
								{(Number((1 - props.item.price / props.item.originalPrice) * 100).toFixed(0)) === "0" ? Locale.getString('pay_month_a_month') : (Locale.getString('save_money') + (Number((1 - props.item.price / props.item.originalPrice) * 100).toFixed(0)) + '%')}
							</Typography>)
						}
					</Grid>
				</Grid>

				{!!props.onClick && false &&
				<Button
					variant={"outlined"}
					className={selected ? classes.rootBodyContentRightBoxContainerButton : classes.rootBodyContentRightBoxContainerButtonUnselected}
					onClick={() => {
						props.onClick();
					}}
				>
					{selected ? Locale.getString('plan_selected') : Locale.getString('plan_select') }
				</Button>}

				<GreenRadio
					className={classes.rootBodyContentRightBoxContainerRadioDesktop}
					checked={selected}
					onChange={handleChange}
					value={props.item.period}
					name="radio-button-demo"
					inputProps={{'aria-label': 'A'}}
					color={"#19C692"}
				/>

				{(!!props.discount && !!props.discount.enabled && !!props.discount.applyToPeriod && !!props.discount.applyToPeriod[props.item.period]) && props.displayHint &&
				<Typography
					className={classes.rootBodyContentRightBoxContainerHintPrice}
				>
					{props.item.currencySymbolStart === true ? props.item.currencySymbol : ''}{Number(props.item.price * props.discount.discountToPeriod[props.item.period]).toFixed(2)}{props.item.currencySymbolStart === false ? props.item.currencySymbol : ' '}
					{Locale.getString('subscribe_during')} {props.item.period === 12 ? ('12 ' + Locale.getString('subscribe_months')) : (props.item.period === 6 ? Locale.getString('six_months') : Locale.getString('one_month_number'))}.{" "}{Locale.getString('subscribe_after')} {props.item.currencySymbolStart === true ? props.item.currencySymbol : ''}{Number(props.item.price).toFixed(2)}{props.item.currencySymbolStart === false ? props.item.currencySymbol : ''}{props.item.period === 12 ? `/${Locale.getString('year')}` : (props.item.period === 6 ? `/${Locale.getString('six_months')}` : `/${Locale.getString('month')}`)}
				</Typography>
				}
				{!(!!props.discount && !!props.discount.enabled && !!props.discount.applyToPeriod && !!props.discount.applyToPeriod[props.item.period]) && props.displayHint &&
				<Typography
					className={classes.rootBodyContentRightBoxContainerHintPrice}
				>
					{props.item.currencySymbolStart === true ? props.item.currencySymbol : ''}{Number(props.item.price).toFixed(2)}{props.item.currencySymbolStart === false ? props.item.currencySymbol : ''}{props.item.period === 12 ? ` ${Locale.getString('to_year')}` : (props.item.period === 6 ? ` ${Locale.getString('six_months_while')}` : ` ${Locale.getString('to_month')}`)}
				</Typography>
				}
			</Grid>
		</Box>
	);
}
