import {makeStyles, fade} from "@material-ui/core/styles";

export default makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		width: '100%',
		backgroundColor: theme.palette.background.paper,
	},
	tab: {
		color: '#19C692',
	},
	separator: {
		width: '100%',
		height: 1,
		backgroundColor: '#CFCFCF',
		marginTop: 8,
		marginBottom: 8
	},
	content: {
		marginTop: 32
	},
	grow: {
		flexGrow: 1,
	},
	image: {
		width: 190,
	},
	imageMobile: {
		height: 32,
		width: 32,
	},
	search: {
		position: 'relative',
		borderRadius: theme.shape.borderRadius,
		backgroundColor: fade(theme.palette.common.white, 0.15),
		'&:hover': {
			backgroundColor: fade(theme.palette.common.white, 0.15),
		},
		marginRight: theme.spacing(2),
		marginLeft: 0,
		width: '100%',
		[theme.breakpoints.up('sm')]: {
			marginLeft: theme.spacing(3),
			width: 'auto',
		},
		borderStyle: 'solid',
		borderWidth: 2,
		borderColor: '#AFAFAF'
	},
	searchIcon: {
		padding: theme.spacing(0, 2),
		height: '100%',
		position: 'absolute',
		pointerEvents: 'none',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	inputRoot: {
		color: 'black',
	},
	inputInput: {
		padding: theme.spacing(1, 1, 1, 0),
		// vertical padding + font size from searchIcon
		paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
		transition: theme.transitions.create('width'),
		width: '100%',
		[theme.breakpoints.up('md')]: {
			width: '40ch',
		},
	},
	sectionDesktop: {
		display: 'none',
		[theme.breakpoints.up('md')]: {
			display: 'flex',
		},
	},
	sectionMobile: {
		display: 'flex',
		[theme.breakpoints.up('md')]: {
			display: 'none',
			paddingLeft: '0%',
			paddingRight: '0%',
		},
	},
	headerSignInText: {
		color: '#19C692',
		fontSize: 16,
		[theme.breakpoints.up('md')]: {
			fontSize: 14,
		},
	},
	headerSignUpText: {
		color: 'white',
		fontSize: 16,
		[theme.breakpoints.up('md')]: {
			fontSize: 14,
		},
	},
	headerButton: {
		'& > *': {
			margin: theme.spacing(1),
			color: '#19C692',
			fontSize: 12
		},
	},
	headerIcons: {
		'& > *': {
			margin: theme.spacing(1),
			color: '#19C692',
			fontSize: 12
		},
	},
	appBar: {
		position: 'fixed',
		[theme.breakpoints.up('md')]: {
			paddingRight: '14%',
			paddingLeft: '14%'
		},
	},
	icon: {
		width: '50%',
		height: '50%'
	},
	avatar: {},
	plusButton: {
		borderWidth: 1,
		backgroundColor: '#19C692',
		borderRadius: 10,
		color:"white",
		fontWeight: 'bold',
		[theme.breakpoints.up('md')]: {
			margin: '5%',
		},
		'&:hover': {
			backgroundColor: '#0D7D5C',
		}
	}
}), { index: 1 });
