import React from "react";
import style from "./style";
import {useHistory} from 'react-router-dom';
import NextButton from "../../nextButton";

const Review = ({onNext}) => {
    const classes = style();
    const history = useHistory();

    return (
        <div
            className={classes.root}
        >
            <img
                className={classes.img}
                src={'/assets/backgrounds/onboarding/reviewESP.png'}
            />
            <NextButton
                onNext={onNext}
            />
        </div>
    )
}

export default Review;
