import React, {useEffect} from 'react';
import style from './style'
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";

export default function DownloadAppBanner() {
	const classes = style();

	const [visible, setVisible] = React.useState(false);

	useEffect(() => {
		setVisible(!(!!window.location && !!window.location.pathname && (
			window.location.pathname.includes('/subscribe') ||
			window.location.pathname.includes('/offer') ||
			window.location.pathname.includes('/onboarding') ||
			window.location.pathname.includes('/comprar') ||
			window.location.pathname.includes('/register') ||
			(window.location.pathname.includes('/login') /*&& (!!window.location.pathname.includes('subscribe') || !!new URLSearchParams(window.location.search).get('rnd') || new URLSearchParams(window.location.search).get('platform') === 'mobile')*/)
		)))
	});

	if (visible) {
		return (
			<div className={classes.parent}>
				<Grid
					className={classes.rootBodySection1LeftButtons}
					container
					md={12}
					sm={12}
					xs={12}
					alignItems="center"
					direction="row"
				>
					<Grid
						container
						md={3}
						sm={3}
						xs={3}
					>
						<Button
							className={classes.rootBodySection1LeftButtonsLeft}
							disableFocusRipple
							disableElevation
							onClick={() => {
								window.location.assign("https://apps.apple.com/es/app/myrealfood/id1458031749");
								window.ttq.track('Download')
							}}
						>
							<img
								className={classes.rootBodySection1LeftButtonsLeftImage}
								src="/assets/buttons/ic_app_store.png"
								alt="App Store"
							/>
						</Button>
					</Grid>
					<Grid
						container
						md={3}
						sm={3}
						xs={3}
					>
						<Button
							className={classes.rootBodySection1LeftButtonsRight}
							disableFocusRipple
							disableElevation
							onClick={() => {
								window.location.assign("https://play.google.com/store/apps/details?id=es.myrealfood.myrealfood");
								window.ttq.track('Download')
							}}
						>
							<img
								className={classes.rootBodySection1LeftButtonsRightImage}
								src="/assets/buttons/ic_google_play.png"
								alt="Google Play"
							/>
						</Button>
					</Grid>
					<Grid
						container
						md={3}
						sm={3}
						xs={3}
					>
						<Button
							className={classes.rootBodySection1LeftButtonsRight}
							disableFocusRipple
							disableElevation
							onClick={() => {
								window.location.assign("https://appgallery.cloud.huawei.com/ag/n/app/C103495711?locale=es_ES&source=myrealfood.app&subsource=BANNER&shareTo=world&shareFrom=myrealfood.app'");
								window.ttq.track('Download')
							}}
						>
							<img
								className={classes.rootBodySection1LeftButtonsRightImage}
								src="/assets/buttons/ic_app_gallery.png"
								alt="App Gallery"
							/>
						</Button>
					</Grid>
				</Grid>
			</div>
		);
	} else {
		return <div></div>
	}

}
