import {Activity} from "./constants/Activity";
import {Gender} from "./constants/Gender";


export interface MenuSettingsPersonalDataPrimitives {
  activity: Activity;
  age: number;
  gender: Gender;
  height: number;
  weight: number;
}

export class MenuSettingsPersonalData {
  constructor(
    private gender: Gender,
    private age: number,
    private weight: number,
    private height: number,
    private activity: Activity
  ) {}

  public getWeight(): number {
    return this.weight;
  }

  public setWeight(value: number) {
    this.weight = value;
  }

  public getAge(): number {
    return this.age;
  }

  public setAge(value: number) {
    this.age = value;
  }

  public getHeight(): number {
    return this.height;
  }

  public setHeight(value: number) {
    this.height = value;
  }

  public getGender(): Gender {
    return this.gender;
  }

  public setGender(value: Gender) {
    this.gender = value;
  }

  public getActivity(): Activity {
    return this.activity;
  }

  public setActivity(value: Activity) {
    this.activity = value;
  }

  public static fromPrimitives(
    menuSettingsPersonalData: MenuSettingsPersonalDataPrimitives
  ): MenuSettingsPersonalData {
    return new MenuSettingsPersonalData(
      menuSettingsPersonalData.gender,
      menuSettingsPersonalData.age,
      menuSettingsPersonalData.weight,
      menuSettingsPersonalData.height,
      menuSettingsPersonalData.activity
    );
  }

  public toPrimitives(): MenuSettingsPersonalDataPrimitives {
    return {
      gender: this.getGender(),
      age: this.getAge(),
      weight: this.getWeight(),
      height: this.getHeight(),
      activity: this.getActivity()
    };
  }
}
