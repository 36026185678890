import {makeStyles} from "@material-ui/core/styles";

export default makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		backgroundColor: 'white',
		paddingLeft: '0%',
		paddingRight: '0%',
		[theme.breakpoints.up('md')]: {
			paddingLeft: '14%',
			paddingRight: '14%',
		}
	},
	rootSeparator: {
		height: 1,
		width: '16%',
		marginLeft: '42%',
		marginRight: '42%',
		marginTop: '10%',
		marginBottom: '7%',
		backgroundColor: '#19C692',
	},
	rootBody: {
		margin: '0%',
		paddingTop: '10%'
	},
	rootGrow: {
		flexGrow: 1
	},

	rootBodyHeader: {},
	rootBodyHeaderLeftMobile: {
		display: 'flex',
		[theme.breakpoints.up('md')]: {
			display: 'none',
		}
	},
	rootBodyHeaderLeftDesktop: {
		display: 'none',
		[theme.breakpoints.up('md')]: {
			display: 'flex',
		}
	},
	rootBodyHeaderLeftName: {
		fontWeight: 'bold',
		fontSize: 20,
		paddingRight: '3%',
		paddingLeft: '3%',
		[theme.breakpoints.up('md')]: {
			fontSize: 36,
			paddingLeft: '7%',
		}
	},
	rootBodyHeaderLeftDescription: {
		fontWeight: 'normal',
		fontSize: 16
	},
	rootBodyHeaderLeftTags: {
		paddingRight: '5%',
		paddingLeft: '5%',
		marginTop: '5%',
		marginBottom: '2%',
		[theme.breakpoints.up('md')]: {
			marginTop: '0%',
			marginBottom: '0%',
		}
	},
	rootBodyHeaderLeftTag: {
		backgroundColor: '#deffd3',
		fontWeight: 'bold',
		fontSize: 12,
		textAlign: 'center',
		borderRadius: 20,
		paddingTop: '4%',
		paddingBottom: '4%',
		margin: '4%',
		[theme.breakpoints.up('md')]: {
			fontSize: 14,
			paddingTop: '4%',
			paddingBottom: '4%',
			marginTop: '0%',
			marginBottom: '0%',
		}
	},
	rootBodyHeaderRight: {},
	rootBodyHeaderRightImage: {
		objectFit: 'cover',
		height: '100%',
		width: '100%',
		borderRadius: 20,
		maxHeight: 400,
		paddingRight: '2%',
		paddingLeft: '2%',
		[theme.breakpoints.up('sm')]: {
			maxHeight: 450,
			paddingRight: '0%',
			paddingLeft: '0%',
		},
		[theme.breakpoints.up('md')]: {
			maxHeight: 500,
			paddingRight: '0%',
			paddingLeft: '0%',
		}
	},

	rootBodySeparator: {
		height: 3,
		width: '98%',
		backgroundColor: '#CFCFCF',
	},
	rootBodySeparatorContent: {
		paddingLeft: '3%',
		paddingRight: '3%',
	},
	rootBodySeparatorAvatar: {
		width: 32,
		height: 32,
		[theme.breakpoints.up('md')]: {
			width: 50,
			height: 50,
		}
	},
	rootBodySeparatorAuthorText: {
		fontSize: 16,
		paddingLeft: '3%',
		paddingRight: '3%',
		[theme.breakpoints.up('md')]: {
			fontSize: 18,
		}
	},
	rootBodySeparatorFit: {
		height: 2,
		width: '98%',
		backgroundColor: '#CFCFCF',
		marginTop: '2%',
		marginBottom: '2%',
	},

	rootBodyContent: {
		paddingLeft: '3%',
		paddingRight: '3%',
		paddingBottom: '5%'
	},
	rootBodyContentLeft: {
		paddingLeft: '2%',
		[theme.breakpoints.up('md')]: {
			paddingLeft: '0%',
			paddingRight: '15%',
		}
	},
	rootBodyContentLeftName: {
		fontWeight: 'bold',
		marginTop: '3%',
		fontSize: 22,
		[theme.breakpoints.up('md')]: {
			fontSize: 28,
			marginTop: '2%',
		}
	},
	rootBodyContentLeftSteps: {
		marginTop: '3%',
		marginBottom: '3%'
	},
	rootBodyContentLeftStep: {
		marginTop: '2%',
		fontSize: 15,
		paddingLeft: '2%',
		paddingRight: '2%',
		[theme.breakpoints.up('md')]: {
			fontSize: 17,
			paddingLeft: '0%',
		}
	},
	rootBodyContentLeftStepBadge: {
		marginTop: '3%',
	},
	rootBodyContentLeftStepBadgeText: {
		fontSize: 17,
		color: 'white',
		textAlign: 'center',
		backgroundColor: '#19C692',
		borderRadius: 13,
		width: 26,
		height: 26
	},
	rootBodyContentLeftImage: {
		objectFit: 'contain',
		width: '50%',
		height: 'auto',
		borderRadius: 20,
		marginTop: '1%',
		marginBottom: '3%'
	},
	rootBodyContentRight: {
		paddingLeft: '2%',
		[theme.breakpoints.up('md')]: {
			paddingLeft: '0%',
		}
	},
	rootBodyContentRightName: {
		fontWeight: 'bold',
		marginTop: '3%',
		fontSize: 22,
		[theme.breakpoints.up('md')]: {
			fontSize: 28,
		}
	},
	rootBodyContentPeople: {
		fontWeight: 'bold',
		marginBottom: '5%',
		fontSize: 12,
		[theme.breakpoints.up('md')]: {
			fontSize: 16,
		}
	},
	rootBodyContentRightIngredients: {
		marginTop: '3%',
		marginBottom: '3%'
	},
	rootBodyContentRightIngredient: {
		marginTop: '3%'
	},
	rootBodyContentRightIngredientImage: {
		objectFit: 'cover',
		width: 50,
		height: 50,
		borderRadius: 25,
	},
	rootBodyContentRightIngredientName: {
		fontWeight: 'bold',
		fontSize: 16,
		paddingLeft: 32,
		[theme.breakpoints.up('md')]: {
			paddingLeft: 16,
		}
	},
	rootBodyContentRightIngredientBrand: {
		fontSize: 14,
		color: "#424242",
		paddingLeft: 32,
		[theme.breakpoints.up('md')]: {
			paddingLeft: 16,
		}
	},
	rootBodyContentInfoMobile: {
		marginTop: '3%',
		marginBottom: '5%',
		display: 'flex',
		[theme.breakpoints.up('md')]: {
			display: 'none',
		}
	},
	rootBodyContentInfoMobileCard: {
		marginTop: '3%'
	},
	rootBodyContentInfoDesktop: {
		display: 'none',
		[theme.breakpoints.up('md')]: {
			display: 'flex',
			paddingLeft: '5%',
			paddingRight: '5%'
		}
	},
}), { index: 1 });
