import React from 'react'
import ds from '../../controller/DataService'

export default async function AB(props) {

	const getMobileOperatingSystem = () => {
		let userAgent = navigator.userAgent || navigator.vendor || window.opera;

		// Windows Phone must come first because its UA also contains "Android"
		if (/windows phone/i.test(userAgent)) {
			return "Windows Phone";
		}

		if (/android/i.test(userAgent)) {
			return "Android";
		}

		// iOS detection from: http://stackoverflow.com/a/9039885/177710
		if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
			return "iOS";
		}

		return "unknown";
	};


	const rnd = Math.random() >= 0.5;
	const id = new URLSearchParams(window.location.search).get('id');

	let otherURL = 'https://myrealfood.app/';
	let iOSURL = 'https://apps.apple.com/es/app/myrealfood/id1458031749';
	let androidURL = 'https://play.google.com/store/apps/details?id=es.myrealfood.myrealfood';

	console.log(id, rnd);

	if (!!id) {
		switch (id) {
			case 'plus':
				otherURL = 'https://myrealfood.app/offer/?promo=O_INSTAGRAM';
				iOSURL = rnd ? 'https://link.myrealfood.app/share/TBKWB7k5JUNU6Mrg9' : 'https://myrealfood.app/offer/?promo=O_INSTAGRAM';
				androidURL = rnd ? 'https://link.myrealfood.app/share/TBKWB7k5JUNU6Mrg9' : 'https://myrealfood.app/offer/?promo=O_INSTAGRAM';
				await ds.incrementABTest(id, getMobileOperatingSystem() === 'unknown' ? 'other' : (rnd ? 'app' : 'web'));
				break;
			default:
				otherURL = '';
				iOSURL = rnd ? 'https://link.myrealfood.app/share/TBKWB7k5JUNU6Mrg9' : 'https://myrealfood.app/offer/?promo=O_INSTAGRAM';
				androidURL = rnd ? 'https://link.myrealfood.app/share/TBKWB7k5JUNU6Mrg9' : 'https://myrealfood.app/offer/?promo=O_INSTAGRAM';
				await ds.incrementABTest(id, getMobileOperatingSystem() === 'unknown' ? 'other' : (rnd ? 'app' : 'web'));
				break;
		}
	}

	await ds.storeUserCampaign({
		path: '/AB',
		rnd: rnd ?? '',
		id: id ?? '',
		otherURL: otherURL ?? '',
		iOSURL: iOSURL ?? '',
		androidURL: androidURL ?? '',
		search: window.location.search ?? '',
		unique: id ?? '',
	});

	return (
		<div style={{marginTop: '1em'}}>
			{getMobileOperatingSystem() === 'Android' && window.location.assign(androidURL)}
			{getMobileOperatingSystem() === 'iOS' && window.location.assign(iOSURL)}
			{getMobileOperatingSystem() === 'unknown' && window.location.assign(otherURL)}
		</div>
	)
}
