import React, {useEffect, useState} from 'react';

import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions'

import style from './style';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Link from "@material-ui/core/Link";
import {useHistory} from "react-router";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import * as yup from 'yup';
import ds from '../../../../controller/DataService'
import Modal from "@material-ui/core/Modal";
import CircularProgress from "@material-ui/core/CircularProgress";
import Locale from "../../../../controller/locale";
import Analytics from "../../../../controller/Analytics";

const OnBoardingLogin = ({goToRegister}) => {
    const classes = style();
    const history = useHistory();

    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState(false);
    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState(false);
    const [remember, setRemember] = useState(true);
    const [recoveryModalVisible, setRecoveryModalVisible] = useState(false);
    const [recoveryModalVisibleError, setRecoveryModalVisibleError] = useState(false);

    const [requesting, setRequesting] = useState(false);

    const [registerError, setRegisterError] = useState(false);

    const validationSchema = yup.object().shape({
        password: yup.string()
            .trim()
            .label(Locale.getString('password_label'))
            .required()
            .min(6, Locale.getString('password_conditions'))
            .required(Locale.getString('password_required')),
        email: yup.string()
            .trim()
            .label(Locale.getString('email_label'))
            .email(Locale.getString('email_conditions'))
            .required(Locale.getString('email_required')),
    });

    if (!!new URLSearchParams(window.location.search).get('rnd') || !!new URLSearchParams(window.location.search).get('mrt')) {
        return (
            <div>
            </div>
        )
    } else {
        return (
            <div>
                <Container
                    className={classes.root}
                    maxWidth="xl"
                >
                    <Grid
                        className={classes.rootBody}
                        container
                        md={12}
                        spacing={2}
                    >
                        <Grid
                            container
                            md={12}
                        >
                            <Grid
                                className={classes.rootBodyGrow}
                                item
                                md={4}
                            />
                            <Grid
                                className={classes.rootBodyLogo}
                                container
                                md={4}
                                alignItems={"center"}
                                justify={"center"}
                            >
                                <Link
                                    component="button"
                                    onClick={() => {
                                        history.push("/")
                                    }}
                                >
                                    <img
                                        className={classes.rootBodyLogoImage}
                                        src="/assets/icons/ic_myrealfood.png"
                                        alt="App Store"
                                    />
                                </Link>
                            </Grid>
                            <Grid
                                className={classes.rootBodyGrow}
                                item
                                md={4}
                            />
                        </Grid>

                        <div
                            className={classes.rootSeparator}
                        />

                        <Grid
                            className={classes.rootBodyContent}
                            container
                            md={12}
                            direction="column"
                            alignItems={"center"}
                            justify={"center"}
                        >
                            <Grid
                                container
                                alignItems={"center"}
                                justify={"center"}
                            >
                                <Typography
                                    className={classes.rootBodyContentTitle}
                                    variant="h5"
                                >
                                    {Locale.getString('login_screen_continue')}
                                </Typography>


                            </Grid>

                            <Grid
                                container
                                alignItems={"center"}
                                justify={"left"}
                            >
                                <Typography
                                    className={classes.rootBodyContentSubtitle}
                                >
                                    {Locale.getString('are_you_user')}
                                </Typography>
                            </Grid>

                            <Grid
                                container
                                direction="column"
                                alignItems={"center"}
                                justify={"center"}
                            >
                                <Button
                                    className={classes.rootBodyContentSignInWithApple}
                                    onClick={() => {
                                        ds.logInWithApple((user) => {
                                            console.log(">", user);
                                        }, (error) => {
                                            console.log(">", error);
                                        });
                                    }}
                                >
                                    <img
                                        className={classes.rootBodyContentSignInWithAppleLogo}
                                        src={"/assets/icons/ic_apple.png"}
                                        alt={"Google"}
                                    />
                                    <Typography
                                        className={classes.rootBodyContentSignInWithAppleText}
                                    >
                                        {Locale.getString('continue_with_apple')}
                                    </Typography>
                                </Button>

                                <Button
                                    className={classes.rootBodyContentSignInWithGoogle}
                                    onClick={() => {
                                        ds.logInWithGoogle((user) => {
                                            console.log(">", user);
                                        }, (error) => {
                                            console.log(">", error);
                                        });
                                    }}
                                >
                                    <img
                                        className={classes.rootBodyContentSignInWithGoogleLogo}
                                        src={"/assets/icons/ic_google.png"}
                                        alt={"Google"}
                                    />
                                    <Typography
                                        className={classes.rootBodyContentSignInWithGoogleText}
                                    >
                                        {Locale.getString('continue_with_google')}
                                    </Typography>
                                </Button>
                            </Grid>

                            <Grid
                                container
                                direction="row"
                                alignItems={"center"}
                                justify={"center"}
                            >
                                <Grid
                                    item
                                    md={5}
                                    className={classes.rootBodyContentSeparator}
                                />
                                <Grid
                                    container
                                    md={2}
                                    alignItems={"center"}
                                    justify={"center"}
                                >
                                    <Typography>
                                        {Locale.getString('or')}
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    md={5}
                                    className={classes.rootBodyContentSeparator}
                                />
                            </Grid>

                            <Grid
                                container
                                direction="column"
                                alignItems={"center"}
                                justify={"center"}
                            >
                                <TextField
                                    className={classes.rootBodyContentForm}
                                    error={!!emailError}
                                    id="standard-error-helper-text"
                                    label={Locale.getString('email_label_alternative')}
                                    defaultValue={email}
                                    helperText={!!emailError ? emailError : ""}
                                    value={email}
                                    onChange={(event) => setEmail(event.target.value.trim())}
                                />
                                <TextField
                                    className={classes.rootBodyContentForm}
                                    error={!!passwordError}
                                    id="standard-error-helper-text"
                                    label={Locale.getString('password_label')}
                                    defaultValue={password}
                                    helperText={!!passwordError ? passwordError : ""}
                                    value={password}
                                    onChange={(event) => setPassword(event.target.value)}
                                    type="password"
                                    autoComplete="current-password"
                                />
                            </Grid>

                            {registerError &&
                                <Typography
                                    className={classes.rootBodyContentSignInServerError}
                                >
                                    {registerError}
                                </Typography>
                            }

                            <Grid
                                className={classes.rootBodyContentSignIn}
                                container
                                direction="row"
                                alignItems={"center"}
                                justify={"space-between"}
                            >
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            color={"primary"}
                                            checked={remember}
                                            onChange={() => {
                                                setRemember(!remember)
                                            }}
                                            name="remember"/>
                                    }
                                    label={Locale.getString('login_remember_me')}
                                />

                                {!requesting &&
                                    <Button
                                        disabled={requesting}
                                        className={classes.rootBodyContentSignInButton}
                                        onClick={async () => {
                                            setRequesting(true);

                                            setEmailError(false);
                                            setPasswordError(false);
                                            setRegisterError(false);

                                            validationSchema.validate({password: password, email: email})
                                                .then(data => {
                                                    ds.userLogIn(email, password,
                                                        () => {
                                                            Analytics.logEvent("onboarding_web_login", {
                                                                screen: 'web',
                                                            });

                                                            window.scrollTo({top: 0, behavior: 'smooth'});
                                                            setRequesting(false);
                                                        }, (error) => {
                                                            setRequesting(false);
                                                            let str = Locale.getString('error_support_text');
                                                            if (error.code === 'auth/invalid-email') {
                                                                str = Locale.getString('auth_invalid_email');
                                                            } else if (error.code === 'auth/user-not-found' || error.code === 'auth/wrong-password') {
                                                                str = Locale.getString('auth_user_not_found');
                                                            } else if (error.code === 'auth/email-already-exists' || error.code === 'auth/email-already-in-use') {
                                                                str = Locale.getString('auth_duplicate_email');
                                                            } else {
                                                                str = Locale.getString('error_support_text');
                                                            }
                                                            setRegisterError(str);
                                                        })
                                                }).catch(error => {
                                                setRequesting(false);
                                                if (error.path === 'email') {
                                                    setEmailError(error.errors[0]);
                                                }
                                                if (error.path === 'password') {
                                                    setPasswordError(error.errors[0]);
                                                }
                                            });
                                        }}
                                    >
                                        <Typography
                                            className={classes.rootBodyContentSignInButtonText}
                                        >
                                            {Locale.getString('init_session')}
                                        </Typography>
                                    </Button>}

                                {requesting &&
                                    <Button
                                        className={classes.rootBodyContentSignInButton}
                                    >
                                        <CircularProgress
                                            size={'8%'}
                                            color="secondary"
                                        />
                                    </Button>}
                            </Grid>

                            <Grid
                                container
                                alignItems={"center"}
                                justify={"center"}
                            >
                                <Link
                                    component="button"
                                    onClick={() => {
                                        validationSchema.isValid({email: email, password: '123456789'})
                                            .then(valid => {
                                                if (valid) {
                                                    ds.userRecoverPassword(email,
                                                        () => {
                                                            console.log("Nice!");
                                                            setRecoveryModalVisibleError(false);
                                                            setRecoveryModalVisible(true);
                                                        }, (error) => {
                                                            setRecoveryModalVisibleError(true);
                                                            setRecoveryModalVisible(true);
                                                            console.log(error);
                                                        })
                                                } else {
                                                    setRecoveryModalVisibleError(true);
                                                    setRecoveryModalVisible(true);
                                                }
                                            })
                                            .catch(error => {
                                                setRecoveryModalVisibleError(true);
                                                setRecoveryModalVisible(true);
                                                console.log(error);
                                            });
                                    }}
                                >
                                    <Typography
                                        className={classes.rootBodyContentRetrievePassword}
                                    >
                                        {Locale.getString('login_forgot_password')}
                                    </Typography>
                                </Link>
                            </Grid>

                            <div
                                className={classes.rootBodyContentGreenSeparator}
                            />

                            <Grid
                                className={classes.rootBodyContentRegister}
                                container
                                alignItems={"center"}
                                justify={"left"}
                            >
                                <Typography
                                    className={classes.rootBodyContentRegisterTitle}
                                >
                                    {Locale.getString('login_not_user_question')}
                                </Typography>
                            </Grid>

                            <Button
                                variant={"outlined"}
                                className={classes.rootBodyContentRegisterButton}
                                onClick={goToRegister}
                            >
                                <Typography
                                    className={classes.rootBodyContentRegisterButtonText}
                                >
                                    {Locale.getString('register')}
                                </Typography>
                            </Button>
                        </Grid>

                        <Modal
                            open={recoveryModalVisible}
                            onClose={() => {
                                setRecoveryModalVisible(false);
                            }}
                            aria-labelledby="simple-modal-title"
                            aria-describedby="simple-modal-description"
                        >
                            <div className={classes.paper}>
                                <Grid
                                    container
                                    direction={"row"}
                                    alignItems={"center"}
                                >
                                    <Grid
                                        item
                                        xs={10}
                                        sm={11}
                                        md={11}
                                    >
                                        <Typography
                                            className={classes.paperTitle}
                                        >
                                            {Locale.getString('recovery_password')}
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={2}
                                        sm={1}
                                        md={1}
                                    >

                                    </Grid>
                                </Grid>
                                <Typography
                                    className={classes.paperDescription}
                                >
                                    {!!recoveryModalVisibleError ? (Locale.getString('email_conditions')) : (`${Locale.getString('recovery_password_send_email')} ${email}`)}
                                </Typography>

                                <Button
                                    variant={"outlined"}
                                    className={classes.paperButton}
                                    onClick={() => {
                                        setRecoveryModalVisible(false);
                                    }}
                                >
                                    <Typography
                                        className={classes.paperButtonText}
                                    >
                                        {Locale.getString('continue')}
                                    </Typography>
                                </Button>

                            </div>
                        </Modal>
                    </Grid>
                </Container>
            </div>
        );
    }
}

export default OnBoardingLogin;


