import {makeStyles} from "@material-ui/core/styles";
import React from "react";

export default makeStyles((theme) => ({
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: '100px 0 100px 0',
        '@media (max-width: 800px)': {
            flexDirection: 'column',
            alignItems: 'flex-start',
            margin: '18px 16px 50px 24px',
        },
    },
    headerIcon: {
        '@media (max-width: 800px)': {
            marginBottom: '32px'
        },
    },
    headerTitle: {
        fontSize: '25px',
        fontWeight: 'medium',
        lineHeight: '122.5%',
    },
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    container: {
        width: '600px',
        minHeight: '90vh',
        '@media (max-width: 800px)': {
            minHeight: '80vh',
        },
        flexDirection: 'column',
        display: 'flex',
    }
}), { index: 1 });
