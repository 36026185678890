import {makeStyles} from "@material-ui/core/styles";
import React from "react";

export default makeStyles((theme) => ({
    root: {
        background: "#D3F6E973",
        padding: "20px",
        display: "flex",
        gap: "10px",
        marginTop: "40px",
        borderRadius: "14px",
    }
}), { index: 1 });
