import {makeStyles} from "@material-ui/core/styles";
import React from "react";

export default makeStyles((theme) => ({
    root: {
        flex: 1,
        margin: '0 30px',
        position: 'relative',
        paddingBottom: '200px',
    },
    option: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        fontWeight: 'normal',
        fontSize: '18px',
        lineHeight: '161%',
        padding: '10px 0',
    }
}), { index: 1 });
