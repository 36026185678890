import {Goal, goals} from "./constants/Goal";
import {KcalMultiplierGetter} from "./constants/KcalMultiplier";
import {Activity} from "./constants/Activity";
import {IMCCalculator} from "./IMCCalculator";
import {Gender, genders} from "./constants/Gender";
import {maxKcals, minKcals} from "./constants/Kcals";

export class KcalCalculator {
  public static calculate(
    gender: Gender,
    weight: number,
    height: number,
    age: number,
    goal: Goal,
    activity: Activity
  ): number {
    const idealWeight = (22 * height * height) / 10000;
    const calculatedWeight = idealWeight + 0.25 * (weight - idealWeight);

    const objectiveWeight =
      goal === goals.LOSS && IMCCalculator.calculate(weight, height) >= 26
        ? calculatedWeight
        : weight;

    const MWeight = 10 * objectiveWeight;
    const MHeight = 6.25 * height;
    const MAge = 5 * age;
    const MGender = gender === genders.WOMAN ? -161 : 5;
    const multiplier = KcalMultiplierGetter.get(gender, goal, activity);

    const currentKcal = Math.round((MWeight + MHeight + MGender - MAge) * multiplier);

    return Math.max(minKcals, currentKcal);
  }

  public static calculateMaxKcal(kcals: number): number {
    return Math.max(maxKcals, kcals + (kcals - minKcals));
  }
}
