import React from 'react';

import Container from '@material-ui/core/Container';
import Header from "../../common/Header";

import file from './scientific_evidence_2024.pdf';

import style from './style';

export default function References() {
  window.location.href = "https://web.myrealfood.app/docs/scientific_evidence_2024.pdf"
	const classes = style();
  return (
    <Container
      className={classes.root}
      maxWidth="xl"
    >
      <Header/>
      {/* <embed src="https://myrealfood.app/docs/politica_de_privacidad.pdf" frameborder="0" width="100%" height="1000px" /> */}

      <iframe title="MyRealFood: Scientific Evidence" frameborder="0" style={{overflow: "auto", width: "100%", height: "100%"}} height="100%" width="100%" src={file} />
    </Container>
  );
}
