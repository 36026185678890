import React, {useEffect, useState} from "react";
import style from "./style";
import {useHistory} from 'react-router-dom';
import NextButton from "../../nextButton";

const ObjectiveOptions = [
    {
        title: 'Bajo',
        subtitle: 'Durante el día no soy físicamente activo o ejercito de 1 a 2 días por semana',
        id: 1,
        icon: '/assets/icons/activity/low.png'
    },
    {
        title: 'Moderado',
        id: 2,
        subtitle: 'Ejercito de 3 a 4 días por semana',
        icon: '/assets/icons/activity/med.png'
    },
    {
        title: 'Alto',
        id: 3,
        subtitle: 'Ejercito de 5 a 6 días por semana',
        icon: '/assets/icons/activity/high.png'
    },
    {
        title: 'Atleta',
        id: 4,
        subtitle: 'Ejercito todos los días',
        icon: '/assets/icons/activity/full.png'
    }
]


const Activity = ({onNext}) => {
    const classes = style();
    const history = useHistory();
    const [selected, setSelected] = useState();

    useEffect(() => {
        const activity = localStorage.getItem('premium.basic.activity');
        if(!!activity) {
            setSelected(JSON.parse(activity))
        }
    }, []);

    return (
        <div
            className={classes.root}
        >
            {
                ObjectiveOptions.map((option, index) => (
                    <div
                        onClick={() => {
                            setSelected(option.id);
                        }}
                        key={index}
                        className={option.id === selected ? classes.optionSelected : classes.option}
                    >
                        <div
                            className={classes.optionIcon}
                        >
                            <img
                                width={26}
                                height={14}
                                src={option.icon}/>
                        </div>
                        <div
                            className={classes.optionText}
                        >
                            <div
                                className={classes.optionTitle}
                            >
                                {option.title}
                            </div>
                            <div
                                className={classes.optionSubtitle}
                            >
                                {option.subtitle}
                            </div>
                        </div>

                    </div>
                ))
            }
            <NextButton
                disabled={!selected}
                onNext={() => {
                    localStorage.setItem('premium.basic.activity', selected);
                    onNext();
                }}
            />
        </div>
    )
}

export default Activity;
