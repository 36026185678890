import {makeStyles} from "@material-ui/core/styles";
import React from "react";

export default makeStyles((theme) => ({
    root: {
        flex: 1,
        margin: '0 30px',
        position: 'relative',
        padding: '0 30px 200px 30px',
    },
    option: {
        padding: "20px 25px",
        borderRadius: "14px",
        marginBottom: "16px",
        maxWidth: "500px",
        display: "flex",
        flexDirection: "row",
        border: "1px solid #DDDDDD",

        '@media (max-width: 800px)': {
            padding: "17px 20px",
        }
    },

    optionSelected: {
        padding: "20px 25px",
        borderRadius: "14px",
        marginBottom: "16px",
        maxWidth: "500px",
        display: "flex",
        flexDirection: "row",
        backgroundColor: "#D3F6E973",
        border: "1px solid #19C692",

        '@media (max-width: 800px)': {
            padding: "17px 20px",
        }
    },

    optionIcon: {
        display: "flex",
        paddingRight: "16px",
    },

    optionText: {
        display: "flex",
        flexDirection: "column",

        '@media (max-width: 800px)': {
            marginTop: "6px",
        }
    },

    optionTitle: {
        fontSize: "17px",
        fontWeight: 500,
    },

    optionSubtitle: {
        fontSize: "15px",
        marginTop: "4px",
        color: "#9BA0A5",
        fontWeight: 400,
    }
}), { index: 1 });
