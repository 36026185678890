export interface MenuSettingsInfoPrimitives {
  country: string;
  keepMenu: boolean;
  successGeneration: boolean;
  onboardingComplete: boolean;
}

export class MenuSettingsInfo {
  constructor(
    private country: string,
    private keepMenu: boolean,
    private successGeneration: boolean,
    private onboardingComplete: boolean
  ) {}

  public getCountry(): string {
    return this.country;
  }

  public isKeepMenu(): boolean {
    return this.keepMenu;
  }

  public isSuccessGeneration(): boolean {
    return this.successGeneration;
  }

  public isOnboardingComplete(): boolean {
    return this.onboardingComplete;
  }

  public setCountry(country: string) {
    this.country = country;
  }

  public setKeepMenu(keepMenu: boolean) {
    this.keepMenu = keepMenu;
  }

  public setSuccessGeneration(successGeneration: boolean) {
    this.successGeneration = successGeneration;
  }

  public setOnboardingComplete(onboardingComplete: boolean) {
    this.onboardingComplete = onboardingComplete;
  }

  public toPrimitives(): MenuSettingsInfoPrimitives {
    return {
      country: this.country.valueOf(),
      keepMenu: this.keepMenu,
      successGeneration: this.successGeneration,
      onboardingComplete: this.onboardingComplete
    };
  }

  public static fromPrimitives(primitives: MenuSettingsInfoPrimitives): MenuSettingsInfo {
    return new MenuSettingsInfo(
      primitives.country,
      primitives.keepMenu,
      primitives.successGeneration,
      primitives.onboardingComplete
    );
  }
}
