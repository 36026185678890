import React from 'react';

import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions'

import Locale from "../../../controller/locale";

import style from './style';
import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";

export default function PlanBox(props) {
	const classes = style();
	if (!props.item) {
		return null
	}
	return (
		<Box
			className={classes.rootBodyContentRightBox}
			border={1}
		>
			<Grid
				className={classes.rootBodyContentRightBoxContainer}
				container
				direction={"column"}
				alignItems={"center"}
				justify={"center"}
			>
				<Typography
					className={classes.rootBodyContentRightBoxContainerTitle}
				>
					{props.item.header}
				</Typography>
				<Typography
					className={classes.rootBodyContentRightBoxContainerSubtitle}
				>
					{props.item.period} {props.item.period === 1 ? Locale.getString('subscribe_month') : Locale.getString('subscribe_months')}
				</Typography>

				<Typography
					className={classes.rootBodyContentRightBoxContainerPrice}
				>
					{props.item.period !== 1 ? `${props.item.currencySymbolStart === true ? props.item.currencySymbol : ''}${props.item.originalPrice}${props.item.currencySymbolStart === false ? props.item.currencySymbol : ''}` : "-"}
				</Typography>
				{!props.freeTrial ? ((!!props.discount && !!props.discount.enabled && !!props.discount.applyToPeriod && !!props.discount.applyToPeriod[props.item.period]) ?
					<Typography
						className={classes.rootBodyContentRightBoxContainerFinalPrice}
					>
						{props.item.currencySymbolStart === true ? props.item.currencySymbol : ''}{Math.trunc(props.item.price * props.discount.discountToPeriod[props.item.period] * 100) / 100}{props.item.currencySymbolStart === false ? props.item.currencySymbol : ''}
					</Typography>
					:
					<Typography
						className={classes.rootBodyContentRightBoxContainerFinalPrice}
					>
						{props.item.currencySymbolStart === true ? props.item.currencySymbol : ''}{Number(props.item.price).toFixed(2)}{props.item.currencySymbolStart === false ? props.item.currencySymbol : ''}
					</Typography>) : (
					<Typography
						className={classes.rootBodyContentRightBoxContainerFinalPrice}
					>
						{Locale.getString('first_n_days_free', [`${props.discount.trialDays}`])}
					</Typography>
				)}

				{!!!props.displayHint && <Typography
					className={classes.rootBodyContentRightBoxContainerMonthlyPrice}
				>
					{(!!props.discount && !!props.discount.enabled && !!props.discount.applyToPeriod && !!props.discount.applyToPeriod[props.item.period]) ?
						(`${props.item.currencySymbolStart === true ? props.item.currencySymbol : ''}${Number(Number(props.item.price * props.discount.discountToPeriod[props.item.period]) / props.item.period).toFixed(2)}${props.item.currencySymbolStart === false ? props.item.currencySymbol : ''}/${Locale.getString('subscribe_month')}`)
						:
						(`${props.item.currencySymbolStart === true ? props.item.currencySymbol : ''}${Number(Number(props.item.price) / props.item.period).toFixed(2)}${props.item.currencySymbolStart === false ? props.item.currencySymbol : ''}/${Locale.getString('subscribe_month')}`)
					}
				</Typography>}
				{!!props.onClick &&
				<Button
					variant={"outlined"}
					className={classes.rootBodyContentRightBoxContainerButton}
					onClick={() => {
						props.onClick();
					}}
				>
					{Locale.getString('subscribe_select')}
				</Button>}
				{!props.freeTrial && (!!props.discount && !!props.discount.enabled && !!props.discount.applyToPeriod && !!props.discount.applyToPeriod[props.item.period]) && props.displayHint &&
				<Typography
					className={classes.rootBodyContentRightBoxContainerHintPrice}
				>
					{props.item.currencySymbolStart === true ? props.item.currencySymbol : ''}{Math.trunc(props.item.price * props.discount.discountToPeriod[props.item.period] * 100) / 100}{props.item.currencySymbolStart === false ? props.item.currencySymbol : ''} {Locale.getString('subscribe_during')} {props.item.period === 12 ? ('12 ' + Locale.getString('subscribe_months')) : (props.item.period === 6 ? ('6 ' + Locale.getString('subscribe_months')) : ('1 ' + Locale.getString('subscribe_month')))}.{" "}{Locale.getString('subscribe_after')} {props.item.currencySymbolStart === true ? props.item.currencySymbol : ''}{Number(props.item.price).toFixed(2)}{props.item.currencySymbolStart === false ? props.item.currencySymbol : ''}{props.item.period === 12 ? `/${Locale.getString('subscribe_year')}. ` : (props.item.period === 6 ? `/6 ${Locale.getString('subscribe_months')}. ` : `/${Locale.getString('subscribe_month')}. `)}
					{Locale.getString('subscribe_cancel_when_you_want')}.
				</Typography>
				}
				{!!props.freeTrial && (!!props.discount && !!props.discount.enabled && !!props.discount.applyToPeriod && !!props.discount.applyToPeriod[props.item.period]) && props.displayHint &&
				<Typography
					className={classes.rootBodyContentRightBoxContainerHintPrice}
				>
					{Locale.getString('subscribe_after')} {props.item.currencySymbolStart === true ? props.item.currencySymbol : ''}{Number(props.item.price).toFixed(2)}{props.item.currencySymbolStart === false ? props.item.currencySymbol : ''}{props.item.period === 12 ? `/${Locale.getString('subscribe_year')}. ` : (props.item.period === 6 ? `/6 ${Locale.getString('subscribe_months')}. ` : `/${Locale.getString('subscribe_month')}. `)}
					{Locale.getString('subscribe_cancel_when_you_want')}.
				</Typography>
				}
				{!(!!props.discount && !!props.discount.enabled && !!props.discount.applyToPeriod && !!props.discount.applyToPeriod[props.item.period]) && props.displayHint &&
				<Typography
					className={classes.rootBodyContentRightBoxContainerHintPrice}
				>
					{props.item.currencySymbolStart === true ? props.item.currencySymbol : ''}{Number(props.item.price).toFixed(2)}{props.item.currencySymbolStart === false ? props.item.currencySymbol : ''}{props.item.period === 12 ? ` ${Locale.getString('subscribe_per_year')}. ` : (props.item.period === 6 ? ` ${Locale.getString('subscribe_per_6_months')}. ` : ` ${Locale.getString('subscribe_per_month')}. `)}
					{Locale.getString('subscribe_cancel_when_you_want')}.
				</Typography>
				}
			</Grid>
		</Box>
	);
}
