import {makeStyles} from "@material-ui/core/styles";
import React from "react";

export default makeStyles((theme) => ({
    root: {
        flex: 1,
        margin: '0 30px',
        position: 'relative',
        paddingBottom: '200px',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
    },
    img: {
        width: '80%',
        marginTop: '-50px',
        '@media (max-width: 800px)': {
            width: '100%',
            marginTop: '-10px',
        }
    }
}), { index: 1 });
