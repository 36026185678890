import React, {useEffect, useState} from "react";
import style from "./style";
import {useHistory} from 'react-router-dom';
import NextButton from "../../nextButton";
import TextField from "@material-ui/core/TextField";
import Info from "../../info";

const Age = ({onNext}) => {
    const classes = style();
    const history = useHistory();

    const [age, setAge] = useState('');
    const [error, setError] = useState();
    const [valid, setValid] = useState(true);

    useEffect(() => {
        const age = localStorage.getItem('premium.basic.age');
        if (age) {
            setAge(Number(JSON.parse(age)));
        }
    }, []);

    return (
        <div
            className={classes.root}
        >
            <TextField
                className={classes.input}
                id="outlined-number"
                variant={'outlined'}
                placeholder={'25'}
                value={age}
                helperText={error ?? 'Introduce tu edad en años.'}
                type="number"
                error={!!error}
                onChange={(e) => {
                    if(e.target.value) {
                        setAge(Number(e.target.value));
                    }
                    else {
                        setAge('');
                    }
                }}
            />
            <Info
                text={"Tu información personal nos sirve para poder personalizar tu Plan nutricional de acuerdo a tus objetivos."}
            />
            <NextButton
                disabled={!age && !valid}
                onNext={() => {
                    if (age < 14 || age > 100) {
                        setError("No es una edad válida.");
                        setValid(false);
                    } else {
                        setValid(true);
                        localStorage.setItem('premium.basic.age', age)
                        onNext();
                    }
                }}
            />
        </div>
    )
}

export default Age;
