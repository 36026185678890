import {makeStyles} from "@material-ui/core/styles";
import React from "react";

export default makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: 'white',
        marginTop: '5%',
    },
    rootBodyContentTitle: {
        color: "black",
        marginTop: '30px',
        fontSize: 35,
        marginRight: 30,
        marginLeft: 30,
        [theme.breakpoints.down('md')]: {
            marginTop: '70px',
            fontSize: 28,
        },
    },
    rootBodyContentSubtitle: {
        marginTop: '30px',
        marginBottom: '30px',
        fontSize: 20,
        fontWeight: '600'
    },
    rootBodyContentNextButton: {
        backgroundColor: '#19C692',
        borderRadius: 38,
        '&:hover': {
            backgroundColor: '#0D7D5C',
        }
    },
    rootBodyContentNextButtonContainer: {
        marginTop: '50px',
        marginBottom: '50px',
        order: 4
    },
    rootBodyContentNextButtonText: {
        color: "white",
        fontSize: "20px",
        textTransform: "capitalize",
        fontWeight: '600',
        width: '300px',
    },
    rootBodyBanner: {
        backgroundImage: 'url(/assets/backgrounds/offerBack.png)',
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        backgroundSize: "cover",
        width: '100%',
        paddingBottom: '60px',
    },
    rootBodyBannerImage: {
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        backgroundSize: "cover",
        width: '100%',
        backgroundImage: 'url(/assets/backgrounds/offerImage.png)',
        [theme.breakpoints.down('sm')]: {
            height: '300px'
        },
    },
    rootBodyBannerTitle: {
        color:"white",
        fontSize:"24px",
        fontWeight:"600",
        margin:"60px 15% 20px 15%"

    },
    rootBodyBannerTextA: {
        width: "50%",
        margin: "auto auto 20px auto",
        [theme.breakpoints.down('md')]: {
            width: "70%",
        },
    },
    rootBodyBannerText: {
        color:"white",
        fontSize:"18px",
        marginTop:"30px",
    },
    rootBodyBannerContainer: {
        marginTop: '20px',
        backgroundColor: '#19C692',
    },
    rootReviewContainer: {
        marginTop: '100px'
    },
    rootReviewTitle: {
        fontSize: '28px',
        fontWeight: '600',
        marginBottom: '50px'
    },
    rootSeparator: {
        height: 2,
        width: '90%',
        margin: "auto",
        marginTop: '60px',
        marginBottom: '3%',
        backgroundColor: '#e6e6e6',
    },
}), { index: 1 });
