import React, {useEffect, useState} from "react";
import style from "./style";
import {useHistory} from 'react-router-dom';
import Checkbox from "../../checkbox";
import NextButton from "../../nextButton";

const ObjectiveOptions = [
    {
        title: 'Desayuno',
        id: "breakfast"
    },
    {
        title: 'Comida',
        id: "lunch"
    },
    {
        title: 'Snack',
        id: "snack"
    },
    {
        title: 'Cena',
        id: "dinner"
    }
]


const Foods = ({onNext}) => {
    const classes = style();
    const history = useHistory();
    const [selected, setSelected] = useState(["breakfast", "lunch", "snack", "dinner"]);

    useEffect(() => {
        const selected = localStorage.getItem('premium.basic.foods');
        if (selected) {
            setSelected(JSON.parse(selected));
        }
    }, []);

    return (
        <div
            className={classes.root}
        >
            {
                ObjectiveOptions.map((option, index) => (
                    <div
                        key={index}
                        className={classes.option}
                        onClick={() => {
                            if (selected.includes(option.id)) {
                                setSelected(selected.filter((item) => item !== option.id))
                            } else {
                                setSelected([...selected, option.id])
                            }
                        }}
                    >
                        {
                            option.title
                        }
                        <Checkbox
                            check={selected.includes(option.id)}
                        />
                    </div>))
            }
            <NextButton
                disabled={selected.length === 0}
                onNext={() => {
                    localStorage.setItem('premium.basic.foods', JSON.stringify(selected))
                    onNext(selected);
                }}
            />
        </div>
    )
}

export default Foods;
