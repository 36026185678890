import {
  DayFood,
  DayPlan,
  DayPlanPrimitives,
  DayProductMealsPrimitives,
  DayRecipeMealsPrimitives,
  WeekKey
} from "@myrealfood-sl/domain";

import { menuProductTranslator } from "./MenuProductTranslator";
import { menuRecipeTranslator } from "./MenuRecipeTranslator";
import { MenuDay, Products, Recipes } from "./FullDocumentMenu";

export class DayPlanTranslator {
  private static instance: DayPlanTranslator;

  private constructor() {
    // Empty
  }

  public static getInstance(): DayPlanTranslator {
    if (!DayPlanTranslator.instance) {
      DayPlanTranslator.instance = new DayPlanTranslator();
    }

    return DayPlanTranslator.instance;
  }

  public fromMenuDayDocumentToPrimitives(menuDay: MenuDay, week: WeekKey): DayPlanPrimitives {
    const planRecipes: DayRecipeMealsPrimitives = {};
    const planProducts: DayProductMealsPrimitives = {};

    Object.keys(menuDay.recipes).forEach((key) => {
      const foodTime = key as DayFood;

      if (!(foodTime in menuDay.recipes)) {
        return;
      }

      const recipes = menuDay.recipes[foodTime];

      if (!recipes) {
        return;
      }

      planRecipes[foodTime] = recipes.map((recipe) =>
        menuRecipeTranslator.transformFromDocumentToPrimitives(recipe, week, foodTime, menuDay.day)
      );
    });

    if (menuDay.products) {
      Object.keys(menuDay.products).forEach((key) => {
        const foodTime = key as DayFood;

        if (!(foodTime in menuDay.products)) {
          return;
        }

        const products = menuDay.products[foodTime];

        if (!products) {
          return;
        }

        planProducts[foodTime] = products.map((product) =>
          // eslint-disable-next-line @typescript-eslint/no-unsafe-return
          menuProductTranslator.transformFromDocumentToPrimitives(
            product,
            week,
            foodTime,
            menuDay.day
          )
        );
      });
    }

    return {
      day: menuDay.day,
      recipes: planRecipes,
      products: planProducts
    };
  }

  public fromPrimitivesToMenuDayDocument(dayPlan: DayPlan): MenuDay {
    const recipes: Recipes = {};
    const products: Products = {};

    const planRecipes = dayPlan.getAllRecipes();
    const planProducts = dayPlan.getAllProducts();

    planRecipes.forEach((recipe) => {
      const foodTime = recipe.getEntryReference().getFood() as DayFood;

      if (!(foodTime in recipes)) {
        recipes[foodTime] = [];
      }

      recipes[foodTime] = [
        ...(recipes[foodTime] ?? []),
        menuRecipeTranslator.transformFromPrimitivesToDocument(recipe.toPrimitives())
      ];
    });

    planProducts.forEach((product) => {
      const foodTime = product.getEntryReference().getFood() as DayFood;

      if (!(foodTime in planProducts)) {
        products[foodTime] = [];
      }

      products[foodTime] = [
        ...(products[foodTime] ?? []),
        menuProductTranslator.transformFromPrimitivesToDocument(product.toPrimitives())
      ];
    });

    return {
      day: dayPlan.getDay().toString(),
      recipes,
      products
    };
  }
}

export const dayPlanTranslator = DayPlanTranslator.getInstance();
