import React from 'react';

import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions'

import style from './style';
import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

export default function CardSummary(props) {
	const classes = style();

	return (
		<Grid
			className={classes.rootBodyCardOption}
			container
			md={6}
			xs={6}
			sm={3}
			onClick={props.onClick}
			alignItems={"center"}
			justify={"center"}
		>
			<Box
				className={classes.rootBodyCardOptionContainer}
				boxShadow={3}
				borderColor={!!props.selected ? "primary.main" : "secondary.main"}
				bgcolor={!!props.selected ? "primary.main" : "background.paper"}
				borderRadius={20}
				m={1}
				p={1}
				alignItems={"center"}
				justify={"center"}
			>
				<Grid
					style={{height: '100%'}}
					container
					alignItems={"center"}
					justify={"center"}
				>
					<img
						className={classes.rootBodyCardOptionImage}
						src={props.image}
						alt={"MyRealFood"}
					/>
					<Typography
						className={!!props.selected ? classes.rootBodyCardOptionTitleSelected : classes.rootBodyCardOptionTitle}
					>
						{props.title}
					</Typography>
					{!!props.subtitle &&
					<Typography
						className={!!props.selected ? classes.rootBodyCardOptionSubtitleSelected : classes.rootBodyCardOptionSubtitle}
					>
						{props.subtitle}
					</Typography>}
				</Grid>
			</Box>
		</Grid>
	);
}


