import React, {useEffect, useState} from 'react';

import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions'

import style from './style';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import {useHistory} from "react-router";
import ds from "../../../../controller/DataService";
import * as yup from "yup";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import {KeyboardDatePicker} from "@material-ui/pickers";
import moment from "moment";
import Collapse from "@material-ui/core/Collapse";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import Locale from "../../../../controller/locale";
import Analytics from "../../../../controller/Analytics";

export default function RegisterOnBoarding({goToLogin}) {
    const classes = style();
    const history = useHistory();

    const [displayName, setDisplayName] = useState('');
    const [displayNameError, setDisplayNameError] = useState(false);
    const [nickname, setNickname] = useState('');
    const [nicknameError, setNicknameError] = useState(false);
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState(false);
    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState(false);
    const [birthday, setBirthday] = useState(new Date('1993-01-01T15:00:00'));
    const [birthdayError, setBirthdayError] = useState(false);

    const [privacy, setPrivacy] = useState(false);
    const [privacyError, setPrivacyError] = useState(false);
    const [showPrivacyData, setShowPrivacyData] = useState(false);
    const [communications, setCommunications] = useState(false);

    const [registerError, setRegisterError] = useState(false);

    const [requesting, setRequesting] = useState(false);

    const validationSchema = yup.object().shape({
        birthday: yup.mixed()
            .test('valid-date', Locale.getString('valid_date'), val =>
                moment(val, 'DD/MM/YYYY').isValid()
            )
            .test('valid-length', Locale.getString('valid_date'), val => {
                return val ? val.replace(/[/_]/g, '').length === 8 : false
            })
            .test('is-of-age', Locale.getString('valid_years_old'), val => {
                return moment().diff(moment(val, 'DD/MM/YYYY'), 'year') >= 14
            }),
        password: yup.string()
            .label(Locale.getString('password_label'))
            .required()
            .min(6, Locale.getString('password_conditions'))
            .required(Locale.getString('password_required')),
        email: yup.string()
            .label(Locale.getString('email_label'))
            .email(Locale.getString('email_conditions'))
            .required(Locale.getString('email_required')),
        nickname: yup.string()
            .label(Locale.getString('nickname_label'))
            .required()
            .min(1, Locale.getString('nickname_conditions'))
            .matches(/^[a-z0-9\._]+$/i, {message: Locale.getString('nickname_matches')})
            .required(Locale.getString('nickname_required')),
        displayName: yup.string()
            .label(Locale.getString('fullname_label'))
            .required()
            .min(2, Locale.getString('fullname_conditions'))
            .matches(/^[\w'\-,.@][^0-9_!¡?÷?¿/\\+=#$%ˆ&*(){}|~<>;:[\]]{2,}$/, {message: Locale.getString('fullname_matches')})
            .required(Locale.getString('fullname_required')),
    });

    return (
        <div>
            <Container
                className={classes.root}
                maxWidth="xl"
            >

                <Grid
                    className={classes.rootBody}
                    container
                    md={12}
                    spacing={2}
                >
                    <Grid
                        container
                        md={12}
                    >
                        <Grid
                            className={classes.rootBodyGrow}
                            item
                            md={4}
                        />
                        <Grid
                            className={classes.rootBodyLogo}
                            container
                            md={4}
                            alignItems={"center"}
                            justify={"center"}
                        >
                            <Link
                                component="button"
                                onClick={() => {
                                    history.push("/")
                                }}
                            >
                                <img
                                    className={classes.rootBodyLogoImage}
                                    src="/assets/icons/ic_myrealfood.png"
                                    alt="App Store"
                                />
                            </Link>
                        </Grid>
                        <Grid
                            className={classes.rootBodyGrow}
                            item
                            md={4}
                        />
                    </Grid>

                    <div
                        className={classes.rootSeparator}
                    />

                    <Grid
                        className={classes.rootBodyContent}
                        container
                        md={12}
                        direction="column"
                        alignItems={"center"}
                        justify={"center"}
                    >
                        <Grid
                            container
                            alignItems={"center"}
                            justify={"left"}
                        >
                            <Typography
                                className={classes.rootBodyContentTitle}
                            >
                                {Locale.getString('get_a_realfoder')}
                            </Typography>

                            <Typography
                                className={classes.rootBodyContentSubtitle}
                            >
                                {Locale.getString('register_with')}
                            </Typography>
                        </Grid>

                        <Grid
                            container
                            direction="column"
                            alignItems={"center"}
                            justify={"center"}
                        >
                            <Button
                                className={classes.rootBodyContentSignInWithApple}
                                onClick={() => {
                                    ds.logInWithApple((user) => {
                                        console.log(">", user);
                                    }, (error) => {
                                        console.log(">", error);
                                    });
                                }}
                            >
                                <img
                                    className={classes.rootBodyContentSignInWithAppleLogo}
                                    src={"/assets/icons/ic_apple.png"}
                                    alt={"Google"}
                                />
                                <Typography
                                    className={classes.rootBodyContentSignInWithAppleText}
                                >
                                    {Locale.getString('continue_with_apple')}
                                </Typography>
                            </Button>

                            <Button
                                className={classes.rootBodyContentSignInWithGoogle}
                                onClick={() => {
                                    ds.logInWithGoogle((user) => {
                                        console.log(">", user);
                                    }, (error) => {
                                        console.log(">", error);
                                    });
                                }}
                            >
                                <img
                                    className={classes.rootBodyContentSignInWithGoogleLogo}
                                    src={"/assets/icons/ic_google.png"}
                                    alt={"Google"}
                                />
                                <Typography
                                    className={classes.rootBodyContentSignInWithGoogleText}
                                >
                                    {Locale.getString('continue_with_google')}
                                </Typography>
                            </Button>
                        </Grid>

                        <Grid
                            container
                            direction="row"
                            alignItems={"center"}
                            justify={"center"}
                        >
                            <Grid
                                item
                                md={5}
                                className={classes.rootBodyContentSeparator}
                            />
                            <Grid
                                container
                                md={2}
                                alignItems={"center"}
                                justify={"center"}
                            >
                                <Typography>
                                    {Locale.getString('or')}
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                md={5}
                                className={classes.rootBodyContentSeparator}
                            />
                        </Grid>

                        <Grid
                            container
                            direction="column"
                            justify={"center"}
                        >
                            <Typography
                                className={classes.rootBodyContentFormTitle}
                            >
                                {Locale.getString('register_with_email')}
                            </Typography>
                            <TextField
                                className={classes.rootBodyContentForm}
                                error={!!displayNameError}
                                id="standard-error-helper-text"
                                label={Locale.getString('fullname_label')}
                                defaultValue={displayName}
                                helperText={!!displayNameError ? displayNameError : ""}
                                value={displayName}
                                onChange={(event) => setDisplayName(event.target.value)}
                            />
                            <TextField
                                className={classes.rootBodyContentForm}
                                error={!!nicknameError}
                                id="standard-error-helper-text"
                                label={Locale.getString('nickname_label')}
                                defaultValue={nickname}
                                helperText={!!nicknameError ? nicknameError : ""}
                                value={nickname}
                                onChange={(event) => setNickname(event.target.value)}
                            />
                            <TextField
                                className={classes.rootBodyContentForm}
                                error={!!emailError}
                                id="standard-error-helper-text"
                                label={Locale.getString('email_label_alternative')}
                                defaultValue={email}
                                helperText={!!emailError ? emailError : ""}
                                value={email}
                                onChange={(event) => setEmail(event.target.value)}
                            />
                            <TextField
                                className={classes.rootBodyContentForm}
                                error={!!passwordError}
                                id="standard-error-helper-text"
                                label={Locale.getString('password_label')}
                                defaultValue={password}
                                helperText={!!passwordError ? passwordError : ""}
                                value={password}
                                onChange={(event) => setPassword(event.target.value)}
                                type="password"
                                autoComplete="current-password"
                            />
                            <KeyboardDatePicker
                                className={classes.rootBodyContentForm}
                                variant="inline"
                                format="dd/MM/yyyy"
                                margin="normal"
                                id="date-picker-inline"
                                error={!!birthdayError}
                                helperText={!!birthdayError ? birthdayError : ""}
                                label={Locale.getString('date_label')}
                                value={birthday}
                                onChange={(date, event) => {
                                    if (!!event) {
                                        let split = event.split('/');
                                        if (split.length === 3 && !isNaN(split[0]) && !isNaN(split[1]) && !isNaN(split[2])) {
                                            let date = new Date();
                                            date.setFullYear(Number(split[2]));
                                            date.setMonth(Number(split[1]) - 1);
                                            date.setDate(Number(split[0]))
                                            setBirthday(date);
                                        } else {
                                            setBirthday(date);
                                        }
                                    } else {
                                        setBirthday(date);
                                    }
                                    console.log(birthday);
                                }}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </Grid>

                        <Grid
                            className={classes.rootBodyContentSignIn}
                            container
                            justify={"space-between"}
                        >
                            <FormControlLabel
                                className={classes.rootBodyContentSignInCheckbox}
                                control={
                                    <Checkbox
                                        color={"primary"}
                                        checked={privacy}
                                        onChange={() => {
                                            setPrivacy(!privacy);
                                            setShowPrivacyData(!showPrivacyData);
                                            setPrivacyError(false);
                                        }}
                                        name="privacy"/>
                                }
                                label={Locale.getString('accept_terms_and_conditions')}
                            />

                            <Collapse in={showPrivacyData}>
                                <Typography
                                    className={classes.rootBodyContentSignInCheckboxHint}
                                >
                                    {`${Locale.getString('privacy_data_text')} ${<a
                                        href={Locale.getString('privacy_data_link')}
                                        style={{color: '#19C692'}}
                                        target={'_blank'}>{Locale.getString('privacy_data_title')}</a>} ${Locale.getString('privacy_data_finish_text')} ${
                                        <a style={{color: '#19C692'}}
                                           href={'https://myrealfood.app/terminos_del_servicio.pdf'}
                                           target={'_blank'}>{Locale.getString('privacy_data_finish_text_2')}</a>}`}
                                </Typography>
                            </Collapse>

                            {privacyError &&
                                <Typography
                                    className={classes.rootBodyContentSignInError}
                                >
                                    {Locale.getString('privacy_data_no_continue')}
                                </Typography>
                            }

                            <FormControlLabel
                                className={classes.rootBodyContentSignInCheckbox}
                                control={
                                    <Checkbox
                                        color={"primary"}
                                        checked={communications}
                                        onChange={() => {
                                            setCommunications(!communications)
                                        }}
                                        name="communications"/>
                                }
                                label={Locale.getString('privacy_data_authorization')}
                            />
                        </Grid>
                        <Grid
                            className={classes.rootBodyContentSignIn}
                            container
                            justify={"space-between"}
                        >
                            {!requesting &&
                                <Button
                                    disabled={requesting}
                                    className={classes.rootBodyContentSignInButton}
                                    onClick={async () => {
                                        setRequesting(true);

                                        setDisplayNameError(false);
                                        setNicknameError(false);
                                        setEmailError(false);
                                        setPasswordError(false);
                                        setBirthdayError(false);
                                        setPrivacyError(false);
                                        setRegisterError(false);

                                        validationSchema.validate({
                                            displayName: displayName,
                                            nickname: nickname,
                                            email: email,
                                            password: password,
                                            birthday: !!birthday ? (((birthday.getDate() < 10 ? '0' : '') + birthday.getDate()) + '/' + (((birthday.getMonth() + 1) < 10 ? '0' : '') + (birthday.getMonth() + 1)) + '/' + birthday.getFullYear()) : undefined
                                        })
                                            .then(data => {
                                                if (privacy) {
                                                    ds.createUser({
                                                            displayName: displayName,
                                                            nickname: nickname,
                                                            email: email,
                                                            password: password,
                                                            birthday: !!birthday ? (birthday.getFullYear() + '-' + (((birthday.getMonth() + 1) < 10 ? '0' : '') + (birthday.getMonth() + 1)) + '-' + ((birthday.getDate() < 10 ? '0' : '') + birthday.getDate())) : undefined,
                                                            tos: privacy,
                                                            comm: communications
                                                        },
                                                        (response) => {
                                                            let data = response.data;
                                                            let str = Locale.getString('error_support_text');
                                                            if (!!data.error) {
                                                                setRequesting(false);
                                                                if (!!data.error.errorInfo && !!data.error.errorInfo.code) {
                                                                    if (data.error.code === 'auth/invalid-email') {
                                                                        str = Locale.getString('auth_invalid_email');
                                                                    } else if (data.error.errorInfo.code === 'auth/user-not-found' || data.error.errorInfo.code === 'auth/wrong-password') {
                                                                        str = Locale.getString('auth_user_not_found');
                                                                    } else if (data.error.errorInfo.code === 'auth/email-already-exists' || data.error.errorInfo.code === 'auth/email-already-in-use') {
                                                                        str = Locale.getString('auth_duplicate_email');
                                                                    } else {
                                                                        str = Locale.getString('error_support_text');
                                                                    }
                                                                } else if (!!data.error && !!data.error.description) {
                                                                    str = data.error.description;
                                                                }
                                                                setRegisterError(str);
                                                                console.log(data)
                                                            } else {
                                                                if (!!data.uid) {
                                                                    window.ttq.track('Register', {
                                                                        email: email
                                                                    })
                                                                    ds.logInAsUser(email, password, () => {
                                                                        Analytics.logEvent("onboarding_web_register", {
                                                                            screen: 'web',
                                                                        });
                                                                        setRequesting(false);
                                                                    }, () => {
                                                                        setRequesting(false);
                                                                        let str = Locale.getString('error_support_text');
                                                                        setRegisterError(str);
                                                                    });
                                                                }
                                                            }
                                                        }, (error) => {
                                                            setRequesting(false);
                                                            let str = Locale.getString('error_support_text');
                                                            setRegisterError(str);
                                                        })
                                                } else {
                                                    setRequesting(false);
                                                    setPrivacyError(true);
                                                }
                                            }).catch(error => {
                                            setRequesting(false);
                                            console.log(error);
                                            if (error.path === 'displayName') {
                                                setDisplayNameError(error.errors[0]);
                                            }
                                            if (error.path === 'nickname') {
                                                setNicknameError(error.errors[0]);
                                            }
                                            if (error.path === 'email') {
                                                setEmailError(error.errors[0]);
                                            }
                                            if (error.path === 'password') {
                                                setPasswordError(error.errors[0]);
                                            }
                                            if (error.path === 'birthday') {
                                                setBirthdayError(error.errors[0]);
                                            }
                                        });
                                    }}
                                >
                                    <Typography
                                        className={classes.rootBodyContentSignInButtonText}
                                    >
                                        Registrar
                                    </Typography>
                                </Button>}

                            {requesting &&
                                <Button
                                    className={classes.rootBodyContentSignInButton}
                                >
                                    <CircularProgress
                                        size={'4%'}
                                        color="secondary"
                                    />
                                </Button>}

                            {registerError &&
                                <Typography
                                    className={classes.rootBodyContentSignInServerError}
                                >
                                    {registerError}
                                </Typography>
                            }
                        </Grid>

                        <div
                            className={classes.rootBodyContentGreenSeparator}
                        />

                        <Grid
                            className={classes.rootBodyContentRegister}
                            container
                            alignItems={"center"}
                            justify={"left"}
                        >
                            <Typography
                                className={classes.rootBodyContentRegisterTitle}
                            >
                                {Locale.getString('do_you_have_account')}
                            </Typography>
                        </Grid>

                        <Button
                            variant={"outlined"}
                            className={classes.rootBodyContentRegisterButton}
                            onClick={() => {
                                goToLogin();
                            }}
                        >
                            <Typography
                                className={classes.rootBodyContentRegisterButtonText}
                            >
                                {Locale.getString('init_session')}
                            </Typography>
                        </Button>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}


