import {makeStyles} from "@material-ui/core/styles";
import React from "react";

export default makeStyles((theme) => ({
    reviewContainer: {
        margin: theme.spacing(2)
    },
    reviewTitle: {
        fontSize: '20px',
        fontWeight: '600',
        marginBottom: "10px"
    },
    reviewText: {
        marginTop: "10px",
        marginBottom: "20px",
        fontSize: '15px',
        fontWeight: '400',
        width: '50%'
    },
}), { index: 1 });