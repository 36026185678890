import PoppinsRegular from "./fonts/Poppins-Regular.otf"
import PoppinsBold from "./fonts/Poppins-Bold.otf"
import {createMuiTheme, responsiveFontSizes} from "@material-ui/core";

const Poppins_Regular = {
	fontFamily: 'Poppins',
	fontStyle: 'normal',
	fontWeight: 'normal',
	src: `local('Poppins'), local('Poppins-Regular'), url(${PoppinsRegular}) format('truetype')`,
};
const Poppins_Bold = {
	fontFamily: 'Poppins',
	fontStyle: 'normal',
	fontWeight: 'bold',
	src: `local('Poppins'), local('Poppins-Bold'), url(${PoppinsBold}) format('truetype')`,
};


export let theme = createMuiTheme({
	palette: {
		background: {
			default: '#ffffff'
		},
		text: {
			primary: '#000000'
		},
		primary: {
			main: '#19C692',
		},
		secondary: {
			main: '#FFFFFF',
		},
		inverted: {
			main: 'FFFFFF'
		}
	},
	typography: {
		fontFamily: 'Poppins',
		fontSize: 14,
		h1: {},
		h2: {},
		h3: {
			fontWeight: 'bold',
			letterSpacing: -1,
		},
		h4: {
			fontSize: 23,
			letterSpacing: -1,
			lineHeight: 1.5
		},
		h5: {
			fontSize: 20,
			letterSpacing: -1,
			lineHeight: 1.5
		},
		h6: {
			fontSize: 17,
			letterSpacing: 0.5,
			lineHeight: 1.5
		},
		subtitle1: {},
		subtitle2: {},
		body1: {
			fontSize: 14,
			color: '#000',
			fontWeight: 'normal'
		},
		body2: {
			fontSize: 14,
			color: '#000',
			fontWeight: 'normal'
		},
		button: {},
		caption: {},
		overline: {},
	},
	overrides: {
		MuiCssBaseline: {
			'@global': {
				'@font-face': [Poppins_Regular, Poppins_Bold]
			},
		},
	},
});

theme = responsiveFontSizes(theme);
