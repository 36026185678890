import React from "react";
import style from "./style";
import Locale from "../../../controller/locale";

const Footer = () => {
    const classes = style();

    return (
        <footer
            className={classes.footer}
        >
            <div
                className={classes.footerText}
            >
                <div
                    className={classes.footerAbout}
                >© 2023 MyRealFood
                </div>
                <div>
                    <span className={classes.footerNonePoint}> · </span>
                    <a
                        className={classes.link}
                        href={Locale.getString("privacy_data_link")}
                    >
                        Política de Privacidad
                    </a>
                    <span className={classes.footerPoint}> · </span>
                    <a
                        className={classes.link}
                        href={"/docs/aviso_legal.pdf"}
                    >
                        Aviso Legal
                    </a>
                </div>
                <div>
                    <span className={classes.footerNonePoint}> · </span>
                    <a
                        className={classes.link}
                        href={"/docs/politica_uso_de_cookies.pdf"}
                    >
                        Política de Cookies
                    </a>
                    <span className={classes.footerPoint}> · </span>
                    <a
                        className={classes.link}
                        href={Locale.getString("terms_and_conditions_link")}
                    >
                        Términos de Servicio
                    </a>
                </div>
            </div>
            <div className={classes.media}>
                <a
                    className={classes.link}
                    href={"https://www.facebook.com/groups/myrealfood/"}
                >
                    <img
                        width={18}
                        height={18}
                        src={"/assets/icons/social/facebook.png"}
                    />
                </a>
                <a
                    className={classes.link}
                    href={"https://www.instagram.com/myrealfood_app"}
                >
                    <img
                        width={18}
                        height={18}
                        src={"/assets/icons/social/instagram.png"}
                    />
                </a>
                <a
                    className={classes.link}
                    href={"https://es.linkedin.com/company/myrealfood"}
                >
                    <img
                        width={18}
                        height={18}
                        src={"/assets/icons/social/linkedin.png"}
                    />
                </a>
                <a
                    className={classes.link}
                    href={"https://twitter.com/myrealfood_app"}
                >
                    <img
                        width={18}
                        height={18}
                        src={"/assets/icons/social/twitter.png"}
                    />
                </a>
                <a
                    className={classes.link}
                    href={"https://www.tiktok.com/@myrealfood"}
                >
                    <img
                        width={18}
                        height={18}
                        src={"/assets/icons/social/tiktok.png"}
                    />
                </a>
            </div>
        </footer>
    )
}

export default Footer;
