import React, {useEffect, useState} from 'react';

import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions'

import style from './style';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Header from "../../common/Header";
import Footer from "../../common/Footer";
import {useHistory} from "react-router";
import ds from "../../controller/DataService";

export default function Present() {
	const classes = style();
	const history = useHistory();

	const [auth, setAuth] = useState(undefined);

	useEffect(() => {
		ds.subscribeToAuthChanges('Present', (user) => {
			setAuth(user);
		});

		return () => {
			ds.unsubscribeFromAuthChanges('Present');
		}
	}, [auth]);

	history.push('/regalo/comprar');

	return (
		<div>
			<Container
				className={classes.rootGreen}
				maxWidth="xl"
			>
				<Header/>

				<Grid
					className={classes.rootBody}
					container
					md={12}
				>

				</Grid>
			</Container>
			<Footer
				white={true}
			/>
		</div>
	);
}
