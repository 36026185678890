import Locale from './locale';
import ds from './DataService';


Locale.init()
export const ONBOARDING_TITLE = [
	'gender_question_onboarding',
	'years_old_question_onboarding',
	'height_question_onboarding',
	'weight_question_onboarding',
	'activity_question_onboarding',
	'diet_type_question_onboarding',
	'diet_without_gluten_question',
	'how_much_food_question',
	'variety_food_question',
	'what_is_your_objetive_question',
	'onboarding_see_kcal_macronutrients',
];

export const ONBOARDING_ACTIVITY_TITLES = [
	'onboarding_activity_level_none',
	'onboarding_activity_level_low',
	'onboarding_activity_level_medium',
	'onboarding_activity_level_high',
	'onboarding_activity_level_very_high',
];

export const ONBOARDING_ACTIVITY_SUBTITLES = [
	'onboarding_activity_level_none_sub',
	'onboarding_activity_level_low_sub',
	'onboarding_activity_level_medium_sub',
	'onboarding_activity_level_high_sub',
	'onboarding_activity_level_very_high_sub',
];

export const ONBOARDING_FOODS_TITLES = [
	'1',
	'2',
	'3',
	'4',
];

export const ONBOARDING_FOODS_SUBTITLES = [
	'lunch',
	'lunch_dinner',
	'break_lunch_dinner',
	'break_lunch_snack_dinner',
];

export const ONBOARDING_VARIETY_TITLES = [
	'variety_title_none',
	'variety_title_low',
	'variety_title_medium',
	'variety_title_high',
	'variety_title_very_high',
];

export const ONBOARDING_VARIETY_SUBTITLES = [
	'variety_subtitle_none',
	'variety_subtitle_low',
	'variety_subtitle_medium',
	'variety_subtitle_high',
	'variety_subtitle_very_high',
	'variety_batch_cooking',
];

export const ONBOARDING_GOAL_TITLES = [
	'goals_title_lost_weight',
	'goals_title_healthy',
	'goals_title_gain_weight',
];


export const ONBOARDING_GOAL_SUBTITLES = [
	'goals_subtitle_lost_weight',
	'goals_subtitle_healthy',
	'goals_subtitle_gain_weight',
];

export const CATEGORY_ICONS = {
	"GREZ6gKqZRV5FhMlsAqv": '/assets/icons/categories/ic_oils_vinegar.png',
	"SlWfn2GLuKgVDKnBzKNz": '/assets/icons/categories/ic_realfooder_drink.png',
	"saUbnNDN3YzV3Yypw4Th": '/assets/icons/categories/ic_infant_feeding.png',
	"Nt6Ls87EAEmvwiOIAY1F": '/assets/icons/categories/ic_appetizers.png',
	"GmUhaQSfWQ4XN9DS80J4": '/assets/icons/categories/ic_pasta.png',
	"j8UCZPmTM29EQkvi6G4K": '/assets/icons/categories/ic_sugars.png',
	"q7L0ffA4AvZ7N6Bs4WXJ": '/assets/icons/categories/ic_alcohol.png',
	"TWbl4BxT6IgKq7l4cjul": '/assets/icons/categories/ic_vegetable_drinks.png',
	"NnWBjrFr8qsnLeDWutz6": '/assets/icons/categories/ic_chocolate.png',
	"jbbE2bRhmucOLHBEBgnZ": '/assets/icons/categories/ic_coffee.png',
	"MKokoDx5uh81t5vwPaHz": '/assets/icons/categories/ic_creams.png',
	"QPp9BIAvrrUj2YqTVxWP": '/assets/icons/categories/ic_meat.png',
	"M2nlq3cLY387Wu9Sv5Tw": '/assets/icons/categories/ic_sweet.png',
	"sBzvtOVFZyntSPeXps6J": '/assets/icons/categories/ic_alcohol.png',
	"sBzBpaTPhjKWXzJqmk36": '/assets/icons/categories/ic_meat.png',
	"a2jGJss3K5uXDNYW3WMm": '/assets/icons/categories/ic_syrup.png',
	"a6lRHH6uovjqq3H1Xnyc": '/assets/icons/categories/ic_prepared_food.png',
	"bovOnbabPURrcSBh0kMg": '/assets/icons/categories/ic_preserves.png',
	"0Y807s2RzfkZQH1FUgjs": '/assets/icons/categories/ic_spreads.png',
	"m33vQ7amBVJwWnzi1Raf": '/assets/icons/categories/ic_fruits.png',
	"ZOowfqXRWabDeD7UIrNP": '/assets/icons/categories/ic_nuts.png',
	"fAaM9Cf7MeL5rQzILEzV": '/assets/icons/categories/ic_sweet.png',
	"xNblDXlc6c554yubl3UZ": '/assets/icons/categories/ic_gazpacho.png',
	"SVFOO55M4wuHerbLISkp": '/assets/icons/categories/ic_flours.png',
	"2Nm40cYT8M7WgIh3op9s": '/assets/icons/categories/ic_ice.png',
	"791pWLL6O3l0qyITEXbz": '/assets/icons/categories/ic_spices.png',
	"UumNHkTZNhrmBdXcJPGa": '/assets/icons/categories/ic_eggs.png',
	"aGozfQ4LbRaSj1ko3ViE": '/assets/icons/categories/ic_coffee.png',
	"NUnwKqZbUGzP6WHpgEb3": '/assets/icons/categories/ic_syrup.png',
	"jjzaRuccx68x0SpRJ6f4": '/assets/icons/categories/ic_milks.png',
	"TrE3bKvYo2rPvMlXec9l": '/assets/icons/categories/ic_pulses.png',
	"yJhSJI8cIk0GhY4MQqrG": '/assets/icons/categories/ic_milks.png',
	"ySN2trEnLd0s88ulrmMP": '/assets/icons/categories/ic_mermelades.png',
	"MBy3SRSlxlLITZuOd7bm": '/assets/icons/categories/ic_honey.png',
	"NkWSQYhZPjVIKFBTmqWJ": '/assets/icons/categories/ic_bread.png',
	"RdZaQMLuum7U1C0IESn2": '/assets/icons/categories/ic_fish.png',
	"1kZSbSLm7L6rwm4awNnu": '/assets/icons/categories/ic_prepared_food.png',
	"9L91OYBK0v27jhQCJzec": '/assets/icons/categories/ic_ice.png',
	"iDK8d7x3G5bRscG6KKMu": '/assets/icons/categories/ic_chesses.png',
	"vpvV4fTlBgGYJYyxVWel": '/assets/icons/categories/ic_drinks.png',
	"GCUcFLzOfEAgHZWhgej5": '/assets/icons/categories/ic_spices.png',
	"NdOcGiNPM2Wxlz0PtRlv": '/assets/icons/categories/ic_sauces.png',
	"VH69Qq7QJM9rX3LDw3Ei": '/assets/icons/categories/ic_vegetables.png',
	"OxtRZBK2VK2tODGITcDv": '/assets/icons/categories/ic_vegan.png',
	"kcvXecQxEBLdQ9Y7Jmtg": '/assets/icons/categories/ic_vegetables.png',
	"JdIvcBGvsPer61PmYrB8": '/assets/icons/categories/ic_oils_vinegar.png',
	"holCZ8nGtv2KKFj1MzH1": '/assets/icons/categories/ic_vitamins.png',
	"Yn8GGwjbtczxGGbl1iDv": '/assets/icons/categories/ic_yogurts.png',
	"YeCIFVprv8i4eAeihXn1": '/assets/icons/categories/ic_juices.png',
}

export const CART_FIXED_VALUES = {
	"000000000000000000001": 150,
	"000000000000000000002": 150,
	"000000000000000000003": 150,
	"000000000000000000004": 500,
	"000000000000000000005": 60,
	"000000000000000000006": 80,
	"000000000000000000007": 90,
	"000000000000000000008": 150,
	"000000000000000000009": 150,
	"0000000000000000000010": 70,
	"0000000000000000000011": 15,
	"0000000000000000000012": 200,
	"0000000000000000000014": 90,
	"0000000000000000000016": 5,
	"0000000000000000000017": 5,
	"0000000000000000000018": 5,
	"0000000000000000000019": 5,
	"0000000000000000000020": 90,
	"0000000000000000000022": 80,
	"0000000000000000000023": 150,
	"0000000000000000000024": 275,
	"0000000000000000000025": 5,
	"0000000000000000000026": 500,
	"0000000000000000000027": 80,
	"0000000000000000000028": 300,
	"0000000000000000000029": 30,
	"0000000000000000000031": 5,
	"0000000000000000000032": 30,
	"0000000000000000000033": 5,
	"0000000000000000000034": 100,
	"0000000000000000000035": 5,
	"0000000000000000000036": 100,
	"0000000000000000000037": 100,
	"0000000000000000000038": 40,
	"0000000000000000000039": 50,
	"0000000000000000000040": 200,
	"0000000000000000000041": 250,
	"0000000000000000000042": 5,
	"0000000000000000000043": 80,
	"0000000000000000000044": 35,
	"0000000000000000000045": 100,
	"0000000000000000000046": 10,
	"0000000000000000000047": 150,
	"0000000000000000000049": 100,
	"0000000000000000000051": 250,
	"0000000000000000000052": 750,
	"0000000000000000000053": 150,
	"0000000000000000000054": 600,
	"0000000000000000000055": 20,
	"0000000000000000000058": 125,
	"0000000000000000000059": 125,
	"0000000000000000000060": 20,
	"0000000000000000000061": 80,
	"0000000000000000000062": 3,
	"0000000000000000000063": 100,
	"0000000000000000000064": 75,
	"0000000000000000000065": 80,
	"0000000000000000000066": 200,
	"0000000000000000000067": 250,
	"0000000000000000000068": 100,
	"0000000000000000000070": 500,
	"0000000000000000000072": 100,
	"0000000000000000000073": 200,
	"0000000000000000000074": 100,
	"0000000000000000000075": 10,
	"0000000000000000000076": 100,
	"0000000000000000000077": 100,
	"0000000000000000000078": 500,
	"0000000000000000000079": 15,
	"0000000000000000000081": 200,
	"0000000000000000000082": 200,
	"0000000000000000000083": 200,
	"0000000000000000000084": 400,
	"0000000000000000000087": 250,
	"0000000000000000000088": 200,
	"00000000000000000000104": 50,
	"00000000000000000000106": 50,
	"00000000000000000000107": 20,
	"00000000000000000000108": 20,
	"00000000000000000000109": 10,
	"00000000000000000000138": 5,
	"00000000000000000000187": 125,
	"00000000000000000000189": 50,
	"00000000000000000000190": 50,
	"00000000000000000000191": 50,
	"00000000000000000000227": 200,
	"00000000000000000000230": 60,
	"00000000000000000000231": 60,
	"00000000000000000000237": 15,
	"00000000000000000000238": 15,
	"00000000000000000000243": 100,
	"00000000000000000000250": 80,
	"00000000000000000000251": 50,
	"00000000000000000000256": 15,
	"00000000000000000000261": 150,
	"00000000000000000000281": 200,
	"00000000000000000000282": 300,
	"00000000000000000000283": 15,
	"00000000000000000000284": 15,
	"00000000000000000000288": 60,
	"00000000000000000000289": 800,
	"00000000000000000000291": 20,
	"00000000000000000000299": 15,
	"00000000000000000000314": 95,
	"00000000000000000000316": 20,
	"00000000000000000000323": 100,
	"00000000000000000000331": 200,
	"00000000000000000000338": 150,
	"00000000000000000000367": 125,
	"00000000000000000000396": 125,
	"00000000000000000000464": 150,
	"00000000000000000000472": 20,
	"00000000000000000000520": 4,
	"00000000000000000000523": 35,
};

export const NOT_ADJUST_CATEGORIES = [
	'791pWLL6O3l0qyITEXbz'
];

export const UNITS = [
	{id: 0, name: 'unit', plural: 'units', grams: undefined},
	{id: 1, name: 'gram', plural: 'grams', grams: '1'},

	{id: 2, name: 'paperboard', plural: 'cartons', grams: undefined},
	{id: 3, name: 'saucepan', plural: 'saucepans', grams: undefined},
	{id: 4, name: 'tablespoon', plural: 'tablespoons', grams: undefined},
	{id: 5, name: 'teaspoonful', plural: 'teaspoons', grams: undefined},
	{id: 6, name: 'fillet', plural: 'fillets', grams: undefined},
	{id: 7, name: 'leaf', plural: 'leafs', grams: undefined},
	{id: 8, name: 'can', plural: 'cans', grams: undefined},
	{id: 9, name: 'rasher', plural: 'rashers', grams: undefined},
	{id: 10, name: 'ounce', plural: 'ounces', grams: undefined},
	{id: 11, name: 'package', plural: 'packages', grams: undefined},
	{id: 12, name: 'piece', plural: 'pieces', grams: undefined},
	{id: 13, name: 'handful', plural: 'handfuls', grams: undefined},
	{id: 14, name: 'branch', plural: 'branches', grams: undefined},
	{id: 15, name: 'slice', plural: 'slices', grams: undefined},
	{id: 16, name: 'slice_alt', plural: 'slices_alt', grams: undefined},
	{id: 17, name: 'kitchen_envelope', plural: 'kitchen_envelopes', grams: undefined},
	{id: 18, name: 'tub', plural: 'tubs', grams: undefined},
	{id: 19, name: 'cup', plural: 'cups', grams: undefined},
	{id: 20, name: 'glass', plural: 'glasses', grams: undefined},
	{id: 21, name: 'ration', plural: 'rations_unit', grams: undefined},
	{id: 22, name: 'canister', plural: 'canisters', grams: undefined}
];

export const DEFAULT_PREMIUM_BASIC_OPTIONS = (country = 'ES') => {
	return [
		{
			currency: Locale.getString('currency_iso', [country === 'GBP' ? '' : 'EUR']),
			currencySymbol: Locale.getString('currency_symbol', [country === 'GB' ? '£' : '€']),
			currencySymbolStart: false,
			header: 'popular',
			period: 12,
			price: country === 'GB' ? 59.99 : 49.99,
			originalPrice: (country === 'GB' ? 9.99 : 9.99) * 12
		},
		{
			currency: Locale.getString('currency_iso', [country === 'GBP' ? '' : 'EUR']),
			currencySymbol: Locale.getString('currency_symbol', [country === 'GB' ? '£' : '€']),
			currencySymbolStart: false,
			header: 'biannual',
			period: 6,
			price: country === 'GB' ? 39.99 : 29.99,
			originalPrice: (country === 'GB' ? 9.99 : 9.99) * 6
		},
		{
			currency: Locale.getString('currency_iso', [country === 'GBP' ? '' : 'EUR']),
			currencySymbol: Locale.getString('currency_symbol', [country === 'GB' ? '£' : '€']),
			currencySymbolStart: false,
			header: 'monthly',
			period: 1,
			price: country === 'GB' ? 9.99 : 6.99,
			originalPrice: (country === 'GB' ? 9.99 : 9.99)
		},
	];
}

export const __DEV__ = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development');
export const __FREE_TRIAL__ = [];
__FREE_TRIAL__[0] = false;

export const __DEFAULT_PROMOCODE__ = [];
__DEFAULT_PROMOCODE__[0] = 'SEPTIEMBRE23WEB';

export const getMobileOperatingSystem = () => {
	let userAgent = navigator.userAgent || navigator.vendor || window.opera;

	// Windows Phone must come first because its UA also contains "Android"
	if (/windows phone/i.test(userAgent)) {
		return "Windows Phone";
	}

	if (/hmscore/i.test(userAgent)) {
		return "Huawei";
	}

	if (/android/i.test(userAgent)) {
		return "Android";
	}

	// iOS detection from: http://stackoverflow.com/a/9039885/177710
	if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
		return "iOS";
	}

	return "unknown";
};

