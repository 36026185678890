import React from 'react';

import Container from '@material-ui/core/Container';

export default function PrivacyPolicy() {
    return (
        <Container>
        </Container>
    );
}
