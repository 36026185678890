import React, {useEffect, useState} from 'react';

import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions'

import style from './style';

import Container from '@material-ui/core/Container';
import Header from "../../../common/Header";
import Footer from "../../../common/Footer";
import {useHistory} from "react-router";
import ds from "../../../controller/DataService";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import {CardElement, PaymentRequestButtonElement, useElements, useStripe} from "@stripe/react-stripe-js";
import {DEFAULT_PREMIUM_BASIC_OPTIONS, __DEV__, __DEFAULT_PROMOCODE__} from "../../../controller/Exports";
import TextField from "@material-ui/core/TextField/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel/InputLabel";
import Select from "@material-ui/core/Select/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import PlanBoxTrial from "../../Subscribe/PlanBoxTrial";
import Link from "@material-ui/core/Link";
import PlanBoxTrialCard from "../../Subscribe/PlanBoxTrialCard";
import Backdrop from "@material-ui/core/Backdrop/Backdrop";
import Fade from "@material-ui/core/Fade/Fade";
import Modal from "@material-ui/core/Modal";
import ReactPixel from "react-facebook-pixel";
import Locale from "../../../controller/locale";

const CARD_ELEMENT_OPTIONS = {
    style: {
        base: {
            color: "#000",
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: "antialiased",
            fontSize: "16px",
            "::placeholder": {
                color: "#828282",
            },
        },
        invalid: {
            color: "#fa755a",
            iconColor: "#fa755a",
        },
    },
};

const USER_COUNTRY_STRIPE_MAP = {
    ES: 'ES',
    GB: 'GB',
    UK: 'GB'
}

const USER_COUNTRY_CURRENCY_STRIPE_MAP = {
    ES: 'eur',
    GB: 'gbp',
    UK: 'gbp'
}

export default function PresentPurchase() {
    const classes = style();
    const history = useHistory();

    const stripe = useStripe();
    const elements = useElements();

    const [auth, setAuth] = useState(undefined);

    const [paymentConfig, setPaymentConfig] = useState(null);
    const [requestingWalletPay, setRequestingWalletPay] = useState(false);
    const [requestingWalletPayError, setRequestingWalletPayError] = useState(false);

    const [enabledCountries, setEnabledCountries] = useState();
    const [userCountry, setUserCountry] = useState(null);

    const [plan, setPlan] = useState(12);
    const [premiumBasicOptions, setPremiumBasicOptions] = useState(DEFAULT_PREMIUM_BASIC_OPTIONS(userCountry));
    const [current, setCurrent] = useState(Object.values(DEFAULT_PREMIUM_BASIC_OPTIONS(userCountry)).filter(option => option.period === 12)[0]);

    const [showModal, setShowModal] = useState(false);

    const [cardName, setCardName] = useState("");
    const [cardNameError, setCardNameError] = useState(false);

    const [cardAddress, setCardAddress] = useState("");
    const [cardAddressError, setCardAddressError] = useState(false);

    const [cardPostalCode, setCardPostalCode] = useState("");
    const [cardPostalCodeError, setCardPostalCodeError] = useState(false);

    const [cardCountry, setCardCountry] = useState("España");
    const [cardCountryError, setCardCountryError] = useState(false);

    const [cardNumber, setCardNumber] = useState("");
    const [cardNumberError, setCardNumberError] = useState(false);

    const [cardCVV, setCardCVV] = useState("");
    const [cardCVVError, setCardCVVError] = useState(false);

    const [cardExpirationDate, setCardExpirationDate] = useState("");
    const [cardExpirationDateError, setCardExpirationDateError] = useState(false);

    const [promo, setPromo] = useState(ds.retrunAvailablePromocode(__DEFAULT_PROMOCODE__[0]));
    const [promoError, setPromoError] = useState(false);

    const [discount, setDiscount] = useState(undefined);
    const [discountApplied, setDiscountApplied] = useState(undefined);

    const [requesting, setRequesting] = useState(false);
    const [requestingError, setRequestingError] = useState(false);

    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [code, setCode] = useState('');
    const [locale, setLocale] = useState({});
    const [purchased, setPurchased] = useState(false);

    const freeTrial = (!!current && !!current.period && !!discount && !!discount.freeTrialToPeriod && !!discount.freeTrialToPeriod[current.period]);


    useEffect(() => {
        // Retrieve prices country config
        ds.subscribeToAuthChanges('PresentPurchase', (user) => {
            setAuth(user);
        });
        retrieveConfig();
        ds.retrievePromoCode('PRESENT', true, (data, applied) => {
            if (!!applied) {
                setDiscount(data);
                setDiscountApplied(true);
            } else {
                setDiscount(data);
                setDiscountApplied(undefined);
            }
        }, (error, data) => {
            setDiscount(data);
        });

        window.ttq.track('InitiateCheckout', {
            content_id: plan,
            content_type: 'PRESENT',
            content_name: current.period,
            quantity: 1,
            price: Number(Number(current.price).toFixed(2)),
            value: Number(Number(current.price).toFixed(2)),
            currency: current.currency,
        })

        return () => {
            ds.unsubscribeFromAuthChanges('PresentPurchase');
        }
    }, [auth]);

    useEffect(() => {
        const selectedCurrent = current;
        const selectedDiscount = discount;
        if (stripe && selectedCurrent && selectedDiscount) {
            configurePaymentRequest(stripe, selectedCurrent, selectedDiscount)
        }
    }, [stripe, current, discount, email]);

    const retrieveConfig = async () => {
        try {
            const userCountry = await ds.retrieveUserCountry();
            const enabledCountries = await ds.retrieveEnabledCountries();
            setEnabledCountries(enabledCountries.map(c => c.name));
            const userEnabledCountry = enabledCountries.filter(c => c.code === userCountry);
            if (userEnabledCountry.length === 1) {
                setCardCountry(userEnabledCountry[0]['name']);
            }
            const userLanguage = await ds.retrieveUserLanguage();
            const countryLanguage = userEnabledCountry[0]['defaultLanguage'];
            const locale = {
                countries: [userCountry],
                country: userCountry,
                language: userLanguage || countryLanguage,
                relationalCountries: {
                    [userCountry]: true
                }
            }
            setLocale(locale);
            setUserCountry(userCountry);
            ds.retrievePremiumBasicOptions(userCountry).then(options => {
                setPremiumBasicOptions(options);
                setCurrent(Object.values(options).filter(option => option.period === plan)[0])
            })
        } catch (error) {
            /*__DEV__ &&*/
            console.log(`[retrieveConfig] ${error}`);
        }
    }

    const purchasedSucceeded = (code) => {
        try {
            window.ttq.track('Subscribe', {
                content_id: plan,
                content_type: 'PRESENT',
                content_name: current.period,
                quantity: 1,
                price: Number(Number(current.price).toFixed(2)),
                value: Number(Number(current.price).toFixed(2)),
                currency: current.currency,
            });
            window.ttq.track('CompletePayment', {
                content_id: plan,
                content_type: 'PRESENT',
                content_name: current.period,
                quantity: 1,
                price: Number(Number(current.price).toFixed(2)),
                value: Number(Number(current.price).toFixed(2)),
                currency: current.currency,
            });
            ReactPixel.track('Purchase', {currency: current.currency, value: Number(current.price).toFixed(2)});
            ReactPixel.trackCustom('mrf_purchase', {
                uid: !!ds.retrieveUserData() && !!ds.retrieveUserData()['public'] && !!ds.retrieveUserData()['public']['uid'] ? ds.retrieveUserData()['public']['uid'] : '',
                email: !!ds.retrieveUserEmail() ? ds.retrieveUserEmail() : '',
                nickname: !!ds.retrieveUserData() && !!ds.retrieveUserData()['public'] && !!ds.retrieveUserData()['public']['nickname'] ? ds.retrieveUserData()['public']['nickname'] : '',
                plan: plan,
                current: current,
                promo: 'PRESENT',
                period: current.period,
                name: cardName,
                address: cardAddress,
                city: cardPostalCode,
                country: cardCountry,
                countryCode: userCountry
            });
        } catch (e) {
            /*__DEV__ &&*/
            console.log(e)
        }

        window.scrollTo({top: 0, behavior: 'smooth'});
        setTimeout(() => {
            setCode(code);
            setPurchased(true);
        }, 500);
    };

    const getEmail = () => {
        return email;
    }
    const getPriceId = () => {
        if (!current || !discount) {
            return null
        }
        let priceId;
        let country = userCountry;
        const period = current.period;
        const priceIdToPeriod = discount["priceIdToPeriod"];
        const priceIdToPeriodLocales = discount["priceIdToPeriod_locales"];
        if (!!priceIdToPeriodLocales) {
            if (!!priceIdToPeriodLocales[country]) {
                priceId = priceIdToPeriodLocales[country][period];
            } else {
                priceId = priceIdToPeriodLocales["_"][period];
            }
        } else {
            priceId = priceIdToPeriod[period];
        }
        /*__DEV__ &&*/
        console.log(`[getPriceId] Returning priceID ${priceId} for period ${period} and country ${country}`)
        return priceId
    }

    const getCoupon = () => {
        if (!current || !discount) {
            return null
        }
        let coupon;
        let country = userCountry;
        const period = current.period;
        const couponToPeriod = discount["couponIdToPeriod"];
        const couponToPeriodLocales = discount["couponIdToPeriod_locales"];
        if (!!couponToPeriodLocales) {
            if (!!couponToPeriodLocales[country]) {
                coupon = couponToPeriodLocales[country][period];
            } else {
                coupon = couponToPeriodLocales["_"][period];
            }
        } else {
            coupon = couponToPeriod[period];
        }
        /*__DEV__ &&*/
        console.log(`[getCoupon] Returning coupon ${coupon} for period ${period} and country ${country}`)
        return coupon
    }


    const handleSubmit = async (event) => {
        setRequesting(true);
        setRequestingError(undefined);

        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();
        if (!stripe || !elements) {
            ds.log({}, {
                id: 'subscribe_handle_submit_1',
                data: {
                    stripe: !stripe,
                    elements: !elements
                }
            }, email);
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }
        // Elements stripe
        // to find your CardElement because there can only ever be one of
        // each type of element.
        const cardElement = elements.getElement(CardElement);

        const {error, paymentMethod} = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
        });

        if (error) {
            /*__DEV__ &&*/
            console.log('[createPaymentMethod error]', error);
            !!error && !!error && !!error.message && setRequestingError(error.message);
            !!error && Object.keys(error).length && setRequesting(false);
            ds.log({}, {
                id: 'subscribe_handle_submit_2',
                data: {
                    error: error
                }
            }, email);
        } else {
            const paymentMethodId = paymentMethod.id;
            createPayment({
                email: getEmail(),
                paymentMethodId,
                priceId: getPriceId(), // discount['priceIdToPeriod'][current.period],
                coupon: getCoupon(), // discount['couponIdToPeriod'][current.period],
                period: current.period,
                code: discount.id,
                cardName,
                cardAddress,
                cardPostalCode,
                cardCountry,
                method: 'card',
                countryCode: getCountryCode(),
                currency: current.currency,
                trialDays: !!current && !!current.period && !!discount && !!discount.freeTrialToPeriod && !!discount.freeTrialToPeriod[current.period] ? discount.trialDays : undefined
            });
        }
    };

    function createPayment({
                               email,
                               paymentMethodId,
                               priceId,
                               coupon,
                               period,
                               code,
                               cardName,
                               cardAddress,
                               cardPostalCode,
                               cardCountry,
                               method,
                               countryCode,
                               currency,
                               trialDays
                           }) {
        let purchase = null;
        return (
            fetch('https://europe-west1-realfooding-app.cloudfunctions.net/stripe_create_purchase_euw1', {
                method: 'post',
                headers: {
                    'Content-type': 'application/json',
                    'Access-Control-Allow-Origin': '*'
                },
                body: JSON.stringify({
                    auth: email,
                    paymentMethodId: paymentMethodId,
                    priceId: priceId,
                    coupon: coupon,
                    period: period,
                    code: code,
                    uname: cardName,
                    uaddress: cardAddress,
                    ucity: cardPostalCode,
                    ucountry: cardCountry,
                    countryCode: countryCode
                }),
            })
                .then((response) => {
                    return response.json();
                })
                // If the card is declined, display an error to the user.
                .then((result) => {
                    if (result.error) {
                        ds.log({}, {
                            id: 'subscribe_handle_submit_5',
                            data: {
                                error: result.error
                            }
                        }, email);
                        // The card had an error when trying to attach it to a customer.
                        throw result;
                    }
                    return result;
                })
                // Normalize the result to contain the object returned by Stripe.
                // Add the additional details we need.
                .then((result) => {
                    purchase = result;
                    return {
                        uid: email,
                        paymentMethodId: paymentMethodId,
                        priceId: priceId,
                        coupon: coupon,
                        purchase: result,
                        period: period,
                        code: code,
                        uname: cardName,
                        uaddress: cardAddress,
                        ucity: cardPostalCode,
                        ucountry: cardCountry,
                        countryCode: countryCode
                    };
                })
                // Some payment methods require a customer to be on session
                // to complete the payment process. Check the status of the
                // payment intent to handle these actions.
                .then(result => {
                    if (!!result && !!result.purchase && !!result.purchase.client_secret) {
                        console.log(elements.getElement(CardElement))
                        stripe.confirmCardPayment(result.purchase.client_secret, !!paymentMethodId ? {
                            payment_method: paymentMethodId,
                        } : {
                            payment_method: {
                                card: elements.getElement(CardElement),
                                billing_details: {
                                    address: {
                                        city: result.ucity,
                                        country: countryCode,
                                        line1: result.uaddress,
                                    },
                                    email: result.uid,
                                    name: result.uname,
                                }
                            }
                        }).then(result => {
                            if (result.error) {
                                let error = result.error;
                                /*__DEV__ &&*/
                                console.log('[createPayment error]', error);
                                !!error && !!error && !!error.message && setRequestingError(error.message);
                                !!error && Object.keys(error).length && setRequesting(false);
                                ds.log({}, {
                                    id: 'subscribe_handle_submit_2',
                                    data: {
                                        error: error
                                    }
                                }, email);
                            } else {
                                // The payment has been processed!
                                if (result.paymentIntent.status === 'succeeded') {
                                    // Show a success message to your customer
                                    // There's a risk of the customer closing the window before callback
                                    // execution. Set up a webhook or plugin to listen for the
                                    // payment_intent.succeeded event that handles any business critical
                                    // post-payment actions.
                                    ds.createPresentCode(email, current.period, 'STRIPE', purchase, locale, (code) => {
                                        purchasedSucceeded(code);
                                    }, (error) => {
                                        /*__DEV__ &&*/
                                        console.log('[createPayment error]', error);
                                        !!error && !!error && !!error.message && setRequestingError(error.message);
                                        !!error && Object.keys(error).length && setRequesting(false);
                                        ds.log({}, {
                                            id: 'subscribe_handle_submit_2',
                                            data: {
                                                error: error
                                            }
                                        }, email);
                                    })
                                }
                            }
                        }).catch(error => {
                            /*__DEV__ &&*/
                            console.log('[createPayment error]', error);
                            !!error && !!error && !!error.message && setRequestingError(error.message);
                            !!error && Object.keys(error).length && setRequesting(false);
                            ds.log({}, {
                                id: 'subscribe_handle_submit_2',
                                data: {
                                    error: error
                                }
                            }, email);
                        });
                    } else {
                        /*__DEV__ &&*/
                    }
                })
                .catch((error) => {
                    // An error has happened. Display the failure to the user here.
                    // We utilize the HTML element we created.
                    /*__DEV__ &&*/
                    console.log(error);
                    !!error && !!error.error && !!error.error.message && setRequestingError(error.error.message);
                    !!error && Object.keys(error).length && setRequesting(false);
                    ds.log({}, {
                        id: 'subscribe_handle_submit_6',
                        data: {
                            error: error
                        }
                    }, email);
                })
        );
    }

    function localizeStripeMessages(error) {
        switch (error) {
            case 'Your card was declined.':
                return 'Tu tarjeta fue rechazada.';
            case 'Your card has insufficient funds.':
                return 'Tu tarjeta no tiene fondos suficientes.';
            case 'Your card has expired':
                return 'Tu tarjeta ha caducado.';
            case 'Your card\'s security code is incorrect.':
                return 'El código de seguridad de su tarjeta es incorrecto.';
            case 'An error occurred while processing your card. Try again in a little bit.':
                return 'Se produjo un error al procesar su tarjeta. Vuelve a intentarlo en un momento.';
            case 'Your postal code is incomplete.':
                return 'El código postal está incompleto.';
            case 'Tu código postal está incompleto.':
                return 'El código postal está incompleto.';
            case 'Tu tarjeta ha sido rechazada':
                return 'Tu tarjeta ha sido rechazada';
            case 'El número de tu tarjeta está incompleto.':
                return 'El número de tu tarjeta está incompleto.';
            case 'El número de tu tarjeta no es válido.':
                return 'El número de tu tarjeta no es válido.';
            case 'El código de seguridad de tu tarjeta está incompleto.':
                return 'El código de seguridad de tu tarjeta está incompleto.';
            case 'La fecha de caducidad de tu tarjeta está incompleta.':
                return 'La fecha de caducidad de tu tarjeta está incompleta.';
            case 'Your card\'s security code is incomplete.':
                return 'El código de seguridad de tu tarjeta está incompleto.';
            case 'El número de tu tarjeta es incorrecto.':
                return 'El número de tu tarjeta es incorrecto.';
            case 'Your card number is incomplete.':
                return 'El número de tu tarjeta está incompleto.';
            case 'El código de seguridad de la tarjeta está incompleto.':
                return 'El código de seguridad de la tarjeta está incompleto.';
            case 'El año de caducidad de la tarjeta no es válido.':
                return 'El año de caducidad de la tarjeta no es válido.';
            case 'Tu tarjeta ha sido rechazada.':
                return 'Tu tarjeta ha sido rechazada.';
            case 'El año de caducidad de la tarjeta ya pasó.':
                return 'El año de caducidad de la tarjeta ya pasó.';
            case 'Your card number is invalid.':
                return 'El número de tarjeta es inválido.';
            case 'Your card number is incorrect.':
                return 'El número de la tarjeta es incorrecto.';
            case 'Estamos teniendo problemas para conectar con nuestro proveedor de pagos. No se te ha cobrado nada. Comprueba si tienes conexión a Internet y vuelve a intentarlo.':
                return 'Estamos teniendo problemas para conectar con nuestro proveedor de pagos. No se te ha cobrado nada. Comprueba si tienes conexión a Internet y vuelve a intentarlo.';
            case 'Votre numéro de carte est incomplet.':
                return 'El número de tu tarjeta está incompleto';
            case 'Your card\'s expiration date is incomplete.':
                return 'El código de seguridad de la tarjeta está incompleto.';
            case 'Your card does not support this type of purchase.':
                return 'Tu tarjeta no soporta este tipo de pago.';
            case 'Tu tarjeta no se admite.':
                return 'Tu tarjeta no se admite.';
            case 'Your card has expired.':
                return 'Tu tarjeta ha caducado.';
            case 'El número de tarjeta está incompleto.':
                return 'El número de tarjeta está incompleto.';
            case 'La fecha de caducidad de tu tarjeta ya ha pasado.':
                return 'La fecha de caducidad de tu tarjeta ya ha pasado.';
            case 'Le code de sécurité de votre carte est incomplet.':
                return 'El código de seguridad de tu tarjeta está incompleto.';
            case 'Il codice di sicurezza della tua carta è incompleto.':
                return 'El código de seguridad de tu tarjeta está incompleto.';
            case 'We are experiencing issues connecting to our payments provider. You have not been charged. Please check your internet connection and try again.':
                return 'Estamos teniendo problemas para conectar con nuestro proveedor de pagos. No se te ha cobrado nada. Comprueba si tienes conexión a Internet y vuelve a intentarlo.';
            case 'Asegúrate de que el campo del nombre no contenga un número de tarjeta.':
                return 'Asegúrate de que el campo del nombre no contenga un número de tarjeta.';
            case 'Se ha rechazado tu tarjeta. La solicitud se ha hecho en el modo real, pero nos consta que has usado una tarjeta de prueba.':
                return 'Se ha rechazado tu tarjeta. La solicitud se ha hecho en el modo real, pero nos consta que has usado una tarjeta de prueba.';
            case 'Il numero della tua carta è incompleto.':
                return 'El número de tu tarjeta está incompleto.';
            case 'El número de tarjeta no es válido.':
                return 'El número de tu tarjeta no es válido.';
            default:
                return 'Se produjo un error al procesar su tarjeta. Vuelve a intentarlo en un momento.';
        }
    }

    const getCountryCode = () => {
        return userCountry;
    }

    const configurePaymentRequest = (stripe, selectedCurrent, selectedDiscount) => {
        const pricePaidDiscount = !!selectedDiscount && !!selectedDiscount.discountToPeriod && !!selectedCurrent.period && !!selectedDiscount.discountToPeriod[selectedCurrent.period] ? selectedDiscount.discountToPeriod[selectedCurrent.period] : 1;
        const selectedCurrentPrice = Math.trunc(selectedCurrent.price * 100) / 100;
        const selectedCurrentPriceWithDiscount = Number.parseInt(Number(selectedCurrentPrice * pricePaidDiscount) * 100);
        const paymentRequestCountry = USER_COUNTRY_STRIPE_MAP[userCountry];
        const paymentRequestCurrency = USER_COUNTRY_CURRENCY_STRIPE_MAP[userCountry];
        const paymentRequest = stripe.paymentRequest({
            country: paymentRequestCountry || 'ES',
            currency: paymentRequestCurrency || 'eur',
            total: {
                label: `${selectedCurrent.period === 1 ? Locale.getString('subscribe_payment_request_monthly') : selectedCurrent.period === 6 ? Locale.getString('subscribe_payment_request_biannual') : Locale.getString('subscribe_payment_request_yearly')}`,
                amount: freeTrial ? 0 : selectedCurrentPriceWithDiscount,
            },
            requestPayerName: true,
            requestPayerEmail: true
        });

        // Check the availability of the Payment Request API.
        paymentRequest.canMakePayment().then(result => {
            if (result) {
                const config = {
                    paymentRequest: paymentRequest,
                    current: current,
                    price: freeTrial ? '0' : selectedCurrentPriceWithDiscount
                };
                setPaymentConfig(config);
            }
        });

        paymentRequest.on('paymentmethod', async (ev) => {
            if (!requestingWalletPay) {
                setRequestingWalletPay(true);
                setRequestingWalletPayError(undefined);

                const paymentMethodId = ev.paymentMethod.id;
                const payerName = ev.payerName;
                const payerEmail = ev.payerEmail;
                const walletName = ev.walletName;

                try {
                    await createPayment({
                        email: getEmail(),
                        paymentMethodId,
                        priceId: getPriceId(), // discount['priceIdToPeriod'][current.period],
                        coupon: getCoupon(), // discount['couponIdToPeriod'][current.period],
                        period: current.period,
                        code: discount.id,
                        cardName: payerName,
                        cardAddress: payerEmail,
                        cardPostalCode: walletName,
                        cardCountry: walletName,
                        method: walletName,
                        countryCode: getCountryCode(),
                        currency: current.currency,
                        trialDays: !!current && !!current.period && !!discount && !!discount.freeTrialToPeriod && !!discount.freeTrialToPeriod[current.period] ? discount.trialDays : undefined
                    });
                    ev.complete('success');
                } catch (error) {
                    ev.complete('fail');
                    !!error && !!error.error && !!error.error.message && setRequestingWalletPayError(error.error.message);
                    !!error && Object.keys(error).length && setRequestingWalletPay(false);
                    ds.log({}, {
                        id: 'subscribe_handle_submit_pay_1',
                        data: {
                            error: error
                        }
                    });
                }
            }
        })
    }

    function renderPaymentRequestButton(paymentConfig) {
        // const testWithDevUser = !!ds.retrieveUserData() && !!ds.retrieveUserData()['private'] && ds.retrieveUserData()['private']['dev'];
        if (paymentConfig) {
            const paymentRequest = paymentConfig.paymentRequest;
            const paymentCurrent = paymentConfig.current;
            const paymentPrice = paymentConfig.price;
            if (paymentRequest) {
                return (
                    <div key={paymentPrice} className={classes.paymentRequestButtonContainer}>
                        <PaymentRequestButtonElement key={paymentPrice} options={{paymentRequest}}/>
                        {!!requestingWalletPayError &&
                            <Typography className={classes.paymentRequestButtonError}>
                                {localizeStripeMessages(requestingWalletPayError)}
                            </Typography>
                        }
                        <div className={classes.paymentRequestButtonHintContainer}>
                            <div className={classes.paymentRequestButtonHintLine}/>
                            <div className={classes.paymentRequestButtonHintText}>
                                <Typography className={classes.rootBodyContentRightAccountHint}>
                                    {Locale.getString('subscribe_pay_with_card')}
                                </Typography>
                            </div>
                            <div className={classes.paymentRequestButtonHintLine}/>
                        </div>
                    </div>
                )
            }
        }
    }


    return (
        <div>
            <Container
                className={!purchased ? classes.root : classes.rootGreen}
                maxWidth="xl"
            >
                <Header/>

                {!purchased && <Grid
                    className={classes.rootBody}
                    container
                    md={12}
                    spacing={2}
                >

                    <Grid
                        className={classes.rootBodyHeader}
                        container
                        md={12}
                        direction={"column"}
                        alignItems={"center"}
                        justify={"center"}
                    >
                        <img
                            className={classes.rootBodyHeaderBottomImage}
                            src="/assets/backgrounds/bg_present.png"
                            alt="Regalo"
                        />
                    </Grid>

                    <Grid
                        className={classes.rootBodyContent}
                        container
                        md={12}
                        direction={"row"}
                    >
                        <Grid
                            className={classes.rootBodyContentRight}
                            container
                            md={4}
                            direction={"column"}
                        >
                            {premiumBasicOptions.map(item => {
                                return (
                                    <PlanBoxTrial
                                        discount={discount}
                                        current={current}
                                        base={Object.values(premiumBasicOptions).filter(option => option.period === 1)[0]}
                                        item={item}
                                        onClick={() => {
                                            setPlan(item.period)
                                            setCurrent(item);
                                        }}
                                    />
                                )
                            })}
                        </Grid>


                        <Grid
                            className={classes.rootBodyContentLeft}
                            container
                            md={8}
                            direction={"column"}
                            justify={"center"}
                        >
                            <Typography
                                className={classes.rootBodyContentLeftTitle}
                            >
                                {Locale.getString('gift_plan_plus_mkt')}
                            </Typography>

                            <Grid
                                className={classes.rootBodyContentRightMobile}
                                container
                                md={4}
                                direction={"row"}
                            >
                                <Grid
                                    item
                                    md={6}
                                    xs={6}
                                    alignItems={"center"}
                                    justify={"center"}
                                >
                                    <Typography
                                        className={classes.rootBodyContentRightTitle}
                                    >
                                        {Locale.getString('plan_plus_selected')}
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    md={6}
                                    xs={6}
                                    alignItems={"center"}
                                    justify={"center"}
                                >
                                    <Link
                                        className={classes.rootBodyContentRightLink}
                                        component="button"
                                        onClick={() => {
                                            setShowModal(true);
                                        }}
                                    >
                                        <Typography
                                            className={classes.rootBodyContentRightSubtitle}
                                        >
                                            {Locale.getString('change_plan_plus')}
                                        </Typography>
                                    </Link>
                                </Grid>

                                <PlanBoxTrialCard
                                    base={Object.values(premiumBasicOptions).filter(option => option.period === 1)[0]}
                                    displayHint={true}
                                    discount={discount}
                                    current={current}
                                    item={current}
                                />
                            </Grid>

                            <Typography
                                className={classes.rootBodyContentLeftSubtitle}
                            >
                                {Locale.getString('get_email')}
                            </Typography>

                            <Grid
                                container
                                direction={"column"}
                            >
                                <TextField
                                    className={classes.rootBodyContentForm}
                                    error={!!emailError}
                                    id="standard-error-helper-text"
                                    label={Locale.getString('email_label_alternative')}
                                    defaultValue={email}
                                    helperText={!!emailError ? emailError : ""}
                                    value={email}
                                    onChange={(event) => {
                                        setEmailError('');
                                        setEmail(event.target.value)
                                    }}
                                    onBlur={() => {
                                        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                                        if (!re.test(String(email).toLowerCase())) {
                                            setEmailError(Locale.getString('email_conditions'));
                                        } else {
                                            setEmailError(undefined);
                                        }
                                    }}
                                />

                                <Typography
                                    className={classes.rootBodyContentRightAccountHint}
                                >
                                    {Locale.getString('advice_about_bill')}
                                </Typography>
                            </Grid>

                            {emailError === undefined && renderPaymentRequestButton(paymentConfig)}

                            <Typography
                                className={classes.rootBodyContentLeftSubtitle}
                            >
                                {Locale.getString('bill_data')}
                            </Typography>

                            <Grid
                                container
                                direction={"column"}
                            >
                                <TextField
                                    className={classes.rootBodyContentForm}
                                    error={!!cardNameError}
                                    id="standard-error-helper-text"
                                    label={Locale.getString('subscribe_card_name')}
                                    defaultValue={cardName}
                                    helperText={!!cardNameError ? cardNameError : ""}
                                    value={cardName}
                                    onChange={(event) => setCardName(event.target.value)}
                                    onBlur={() => {
                                        if (cardName.length < 3) {
                                            setCardNameError(Locale.getString('subscribe_card_name_error'));
                                        } else {
                                            setCardNameError(undefined);
                                        }
                                    }}
                                />
                                <TextField
                                    className={classes.rootBodyContentForm}
                                    error={!!cardAddressError}
                                    id="standard-error-helper-text"
                                    label={Locale.getString('subscribe_card_address')}
                                    defaultValue={cardAddress}
                                    helperText={!!cardAddressError ? cardAddressError : ""}
                                    value={cardAddress}
                                    onChange={(event) => setCardAddress(event.target.value)}
                                    onBlur={() => {
                                        if (cardAddress.length < 3) {
                                            setCardAddressError(Locale.getString('subscribe_card_adress_error'));
                                        } else {
                                            setCardAddressError(undefined);
                                        }
                                    }}
                                />

                                <Grid
                                    container
                                    direction={"row"}
                                    alignItems={"center"}
                                    justify={"space-between"}
                                >
                                    <Grid>
                                        <TextField
                                            className={classes.rootBodyContentForm}
                                            error={!!cardPostalCodeError}
                                            id="standard-error-helper-text"
                                            label={Locale.getString('subscribe_postal_code')}
                                            defaultValue={cardPostalCode}
                                            helperText={!!cardPostalCodeError ? cardPostalCodeError : ""}
                                            value={cardPostalCode}
                                            onChange={(event) => setCardPostalCode(event.target.value)}
                                            onBlur={() => {
                                                if (cardPostalCode.length < 4) {
                                                    setCardPostalCodeError(Locale.getString('subscribe_card_postalcode_error'));
                                                } else {
                                                    setCardPostalCodeError(undefined);
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid>
                                        <FormControl
                                            style={{
                                                minWidth: 200
                                            }}
                                        >
                                            <InputLabel
                                                id="demo-simple-select-label">{Locale.getString('subscribe_country')}</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={cardCountry}
                                                defaultValue={cardCountry}
                                                error={!!cardCountryError}
                                                helperText={!!cardCountryError ? cardCountryError : ""}
                                                onChange={(event) => setCardCountry(event.target.value)}
                                                // disabled={!__DEV__}
                                                onBlur={() => {
                                                    if (!!cardCountry && cardCountry.length < 5) {
                                                        setCardCountryError(Locale.getString('subscribe_choose_country_placeholder'));
                                                    } else {
                                                        setCardCountryError(undefined);
                                                    }
                                                }}
                                            >
                                                {!!enabledCountries && enabledCountries.map(countries => {
                                                    return (<MenuItem value={countries}>{countries}</MenuItem>)
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Typography
                                className={classes.rootBodyContentLeftSubtitle}
                            >
                                {Locale.getString('subscribe_card_info')}
                            </Typography>

                            <div>
                                <CardElement options={CARD_ELEMENT_OPTIONS}/>
                            </div>
                            {!requesting &&
                                <Button
                                    disabled={(!!requesting || !stripe || cardName.length < 3 || cardAddress.length < 3 || !cardPostalCode || cardPostalCode.length < 3 || cardCountry.length < 3 || email.length < 3 || !!cardNameError || !!cardAddressError || !!cardPostalCodeError || !!cardCountryError || !!emailError)}
                                    variant={"outlined"}
                                    className={(!stripe || cardName.length < 3 || cardAddress.length < 3 || !cardPostalCode || cardPostalCode.length < 3 || cardCountry.length < 3 || email.length < 3 || !!cardNameError || !!cardAddressError || !!cardPostalCodeError || !!cardCountryError || !!emailError) ? classes.rootBodyContentLeftButtonDisabled : classes.rootBodyContentLeftButton}
                                    onClick={handleSubmit}
                                >

                                    <Typography
                                        className={classes.rootBodyContentSubscribeButtonText}
                                    >
                                        {Locale.getString('buy_gift')}
                                    </Typography>
                                </Button>}

                            {requesting &&
                                <Button
                                    disableRipple={true}
                                    disableFocusRipple={true}
                                    variant={"outlined"}
                                    className={classes.rootBodyContentLeftButton}
                                >
                                    <CircularProgress
                                        size={'4%'}
                                        color="secondary"
                                    />
                                </Button>}

                            {!!requestingError &&
                                <Typography
                                    className={classes.rootBodyContentSubscribeError}
                                >
                                    {localizeStripeMessages(requestingError)}
                                </Typography>
                            }
                        </Grid>
                    </Grid>

                    <div
                        className={classes.rootBodyContentGreenSeparator}
                    />

                    <Grid
                        className={classes.rootBodyContentPurchase}
                        container
                        alignItems={"center"}
                        justify={"center"}
                    >
                        <Grid
                            item
                        >
                            <Typography
                                className={classes.rootBodyContentPurchaseTitle}
                            >
                                {Locale.getString('do_you_have_code')}
                            </Typography>
                        </Grid>

                        <Grid
                            item
                        >
                            <Typography
                                className={classes.rootBodyContentPurchaseSubtitle}
                            >
                                {Locale.getString('gift_code_conditions_redeem')}
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        alignItems={'center'}
                        justify={'center'}
                    >
                        <Button
                            variant={"outlined"}
                            className={classes.rootBodyContentPurchaseButton}
                            onClick={() => {
                                history.push('/regalo/canjear')
                            }}
                        >
                            <Typography
                                className={classes.rootBodyContentPurchaseButtonText}
                            >
                                {Locale.getString('redeem_gift_alternative')}
                            </Typography>
                        </Button>
                    </Grid>
                </Grid>}

                {purchased && <Grid
                    className={classes.rootBody}
                    container
                >
                    <Grid
                        className={classes.rootBodyHeader}
                        container
                        md={12}
                        xs={12}
                        sm={12}
                        direction={'column'}
                        alignItems={"center"}
                        justify={"center"}
                    >
                        <Grid
                            item
                        >
                            <Typography
                                className={classes.rootBodyHeaderTitle}
                            >
                                {Locale.getString('gift_is_done')}
                            </Typography>
                        </Grid>
                        <Grid
                            item
                        >
                            <Typography
                                className={classes.rootBodyHeaderSubTitle}
                            >
                                {`${Locale.getString('send_code_text')} ${email} ${Locale.getString('send_code_text_2')}`}
                            </Typography>
                        </Grid>

                        <Grid
                            item
                        >
                            <Typography
                                className={classes.rootBodyHeaderCode}
                            >
                                {Locale.getString('your_code')}
                            </Typography>
                        </Grid>

                        <Box
                            className={classes.rootBodyBox}
                            boxShadow={3}
                            bgcolor="background.paper"
                            m={1}
                            p={1}
                        >
                            <Typography
                                className={classes.rootBodyBoxCode}
                            >
                                {code}
                            </Typography>
                        </Box>

                        <Grid
                            className={classes.rootBodyBoxHintMobile}
                            container
                            direction={'column'}
                            alignItems={"center"}
                            justify={"center"}
                            md={4}
                        >
                            <img
                                className={classes.rootBodyHeaderBottomImagePurchasedMobile}
                                src="/assets/backgrounds/bg_present_white.png"
                                alt="Regalo"
                            />
                        </Grid>

                        <Grid
                            className={classes.rootBodyBoxHint}
                            container
                            direction={'row'}
                            alignItems={"center"}
                            justify={"center"}
                        >
                            <Grid
                                className={classes.rootBodyBoxHint}
                                container
                                direction={'column'}
                                alignItems={"center"}
                                justify={"center"}
                                md={4}
                            >
                            </Grid>
                            <Grid
                                className={classes.rootBodyBoxHint}
                                container
                                direction={'column'}
                                alignItems={"center"}
                                justify={"center"}
                                md={4}
                            >
                                <Grid
                                    item
                                >
                                    <Typography
                                        className={classes.rootBodyBoxHintTitle}
                                    >
                                        {Locale.getString('have_a_questions')}
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                >
                                    <Link
                                        component="button"
                                        onClick={() => {
                                            history.push("/faq")
                                        }}
                                    >
                                        <Typography
                                            className={classes.rootBodyBoxHintSubtitle}
                                        >
                                            {Locale.getString('frequent_questions')}
                                        </Typography>
                                    </Link>
                                </Grid>
                            </Grid>
                            <Grid
                                className={classes.rootBodyBoxHintDesktop}
                                container
                                direction={'column'}
                                alignItems={"center"}
                                justify={"center"}
                                md={4}
                            >
                                <img
                                    className={classes.rootBodyHeaderBottomImagePurchasedDesktop}
                                    src="/assets/backgrounds/bg_present_white.png"
                                    alt="Regalo"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>}

                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.modal}
                    open={showModal}
                    onClose={() => {
                        setShowModal(false);
                    }}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={showModal}>
                        <Grid
                            className={classes.paperTrial}
                            container
                            alignItems={"center"}
                            justify={"center"}
                        >
                            <h2 id="transition-modal-title">{Locale.getString('subscribe_choose_plan')}</h2>
                            <Grid
                                container
                                md={12}
                                direction={"column"}
                                alignItems={"center"}
                                justify={"space-around"}
                                spacing={2}
                            >
                                <Grid
                                    container
                                    md={12}
                                    xs={12}
                                    alignItems={"center"}
                                    justify={"center"}
                                >
                                    <PlanBoxTrialCard
                                        base={Object.values(premiumBasicOptions).filter(option => option.period === 1)[0]}
                                        discount={discount}
                                        current={current}
                                        item={Object.values(premiumBasicOptions).filter(option => option.period === 1)[0]}
                                        onClick={() => {
                                            setShowModal(false);
                                            setCurrent(Object.values(premiumBasicOptions).filter(option => option.period === 1)[0])
                                        }}
                                    />
                                </Grid>
                                <Grid
                                    container
                                    md={12}
                                    xs={12}
                                    alignItems={"center"}
                                    justify={"center"}
                                >
                                    <PlanBoxTrialCard
                                        base={Object.values(premiumBasicOptions).filter(option => option.period === 1)[0]}
                                        discount={discount}
                                        current={current}
                                        item={Object.values(premiumBasicOptions).filter(option => option.period === 12)[0]}
                                        onClick={() => {
                                            setShowModal(false);
                                            setCurrent(Object.values(premiumBasicOptions).filter(option => option.period === 12)[0])
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Fade>
                </Modal>
            </Container>
            <Footer
                white={purchased}
            />
        </div>
    );
}
