import React, {useEffect, useRef} from "react";
import style from "./style";
import {useHistory} from 'react-router-dom';
import {useMediaQuery} from "@material-ui/core";

const ObjectiveOptions = [
    {
        title: 'Perder grasa',
        subtitle: 'Perder peso y ganar salud',
        id: 0,
        icon: '/assets/icons/onboarding/loss.png'
    },
    {
        title: 'Ganar músculo',
        subtitle: 'Ganar volumen y ganar salud',
        id: 2,
        icon: '/assets/icons/onboarding/gain.png'
    },
    {
        title: 'Mantener peso',
        subtitle: 'Y mejorar mi salud',
        id: 1,
        icon: '/assets/icons/onboarding/keep.png'
    }
]

const promos = {
    ABRIL23: {
        title: 'Empieza tu Plan Nutricional Personalizado',
        img: '/assets/backgrounds/onboarding/ABRILESPWEB.png',
        imgM: '/assets/backgrounds/onboarding/ABRILESPMOB.png'
    },
    ABRIL23MAIL: {
        title: 'Empieza tu Plan Nutricional Personalizado',
        img: '/assets/backgrounds/onboarding/ABRILESPWEB.png',
        imgM: '/assets/backgrounds/onboarding/ABRILESPMOB.png'
    },
    ABRIL23APP: {
        title: 'Empieza tu Plan Nutricional Personalizado',
        img: '/assets/backgrounds/onboarding/ABRILESPWEB.png',
        imgM: '/assets/backgrounds/onboarding/ABRILESPMOB.png'
    },
    ABRIL23WEB: {
        title: 'Empieza tu Plan Nutricional Personalizado',
        img: '/assets/backgrounds/onboarding/ABRILESPWEB.png',
        imgM: '/assets/backgrounds/onboarding/ABRILESPMOB.png'
    },
    PRIMAVERA23: {
        title: 'Empieza tu Plan Nutricional Personalizado',
        img: '/assets/backgrounds/onboarding/ABRILESPWEB.png',
        imgM: '/assets/backgrounds/onboarding/ABRILESPMOB.png'
    },
    PRIMAVERA23_MRF: {
        title: 'Empieza tu Plan Nutricional Personalizado',
        img: '/assets/backgrounds/onboarding/ABRILESPWEB.png',
        imgM: '/assets/backgrounds/onboarding/ABRILESPMOB.png'
    },
    FREETRIAL_MRF: {
        title: 'Empieza tu Plan Nutricional Personalizado',
        img: '/assets/backgrounds/onboarding/FREEWEB.png',
        imgM: '/assets/backgrounds/onboarding/FREEMOB.png'
    },
    FREETRIAL_AD: {
        title: 'Empieza tu Plan Nutricional Personalizado',
        img: '/assets/backgrounds/onboarding/FREEWEB.png',
        imgM: '/assets/backgrounds/onboarding/FREEMOB.png'
    },
    DEFAULT: {
        title: 'Aprovecha la oferta y consigue tu objetivo con el Plan Plus.',
        img: '/assets/backgrounds/bg_onboarding.png',
    }
}


const Objetive = ({onSelect, promocode}) => {
    const classes = style();
    const history = useHistory();
    const isRowBased = useMediaQuery('(max-width: 800px)');

    return (
        <>
            <header className={classes.header}>
                <img className={classes.logo} src="/assets/icons/ic_myrealfood.png" alt="MyRealFood"></img>
            </header>
            <div
                className={classes.root}
            >

                {promos[promocode] && promos[promocode].isVideo ?
                    <div className={classes.video} dangerouslySetInnerHTML={{
                        __html:
                            `<video class="${classes.video}" autoplay muted loop playsinline>
                                <source src="https://cdn3.myrealfood.app/s3-myrealfood/assets/videos/AD_IN_APP_01.mp4" type="video/mp4"/>
                            </video>`
                    }}/> :
                    <div className={classes.image}
                         style={{
                             backgroundImage: `url(${promos[promocode] ? isRowBased ? promos[promocode].imgM : promos[promocode].img : promos.DEFAULT.img})`,
                         }}
                    />
                }

                <div
                    className={classes.containerText}
                >
                    <div
                        className={classes.title}
                    >
                        {promos[promocode] ? promos[promocode].title : promos.DEFAULT.title}
                    </div>

                    <div
                        className={classes.subtitle}
                    >
                        ¿Cuál es tu objetivo?
                    </div>

                    {
                        ObjectiveOptions.map((option, index) => (
                            <div
                                onClick={() => {
                                    localStorage.setItem('premium.basic.objective', option.id)
                                    onSelect(index === 0)
                                }}
                                key={index}
                                className={classes.option}
                            >
                                <div
                                    className={classes.optionIcon}
                                >
                                    <img
                                        width={22}
                                        height={22}
                                        src={option.icon}/>
                                </div>
                                <div
                                    className={classes.optionText}
                                >
                                    <div
                                        className={classes.optionTitle}
                                    >
                                        {option.title}
                                    </div>
                                    <div
                                        className={classes.optionSubtitle}
                                    >
                                        {option.subtitle}
                                    </div>
                                </div>

                            </div>
                        ))
                    }

                </div>
            </div>
        </>
    )
}

export default Objetive;
