import React, {useEffect, useState} from 'react';

import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions'

import style from './style';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Header from "../../common/Header";
import Footer from "../../common/Footer";
import {useHistory} from "react-router";
import ds from "../../controller/DataService";
import Typography from "@material-ui/core/Typography";
import Locale from "../../controller/locale";


ds.retrieveUserLanguage().then(language => {
	Locale.init(language);
});


const FAQS = [
	{
		title: Locale.getString('faq_question_1'),
		expandable: [Locale.getString('faq_answer_1')]
	},  {
		title: Locale.getString('faq_question_4'),
		expandable: Locale.getString('faq_answer_4')
	}, {
		title: Locale.getString('faq_question_5'),
		expandable: [
			Locale.getString('faq_answer_5'),
			<a style={{color: '#19C692'}} href={'https://myrealfood.app/present/redeem'} target={"_blank"}>{Locale.getString('here')}</a>,
			Locale.getString('faq_answer_5_1')]
	}, {
		title: Locale.getString('faq_question_2'),
		expandable: [ `${Locale.getString('faq_answer_2')}\n\n ${Locale.getString('faq_answer_2_1')}`]
	}, {
		title: Locale.getString('faq_question_3'),
		expandable: [Locale.getString('faq_answer_3')]
	}, {
		title: Locale.getString('faq_question_6'),
		expandable: [Locale.getString('faq_answer_6')]
	}, {
		title: Locale.getString('faq_question_7'),
		expandable: [Locale.getString('faq_answer_7')]
	}, {
		title: Locale.getString('faq_question_8'),
		expandable: [Locale.getString('faq_answer_8')]
	}, {
		title: Locale.getString('faq_question_9'),
		expandable: [Locale.getString('faq_answer_9')]
	}, {
		title: Locale.getString('faq_question_10'),
		expandable: [Locale.getString('faq_answer_10')]
	}, {
		title: Locale.getString('faq_question_11'),
		expandable: [Locale.getString('faq_answer_11')]
	}, {
		title: Locale.getString('faq_question_12'),
		expandable: [ Locale.getString('faq_answer_12')]
	}, {
		title: Locale.getString('faq_question_13'),
		expandable: [Locale.getString('faq_answer_13')]
	}, {
		title: Locale.getString('faq_question_14'),
		expandable: [Locale.getString('faq_answer_14')]
	}, {
		title: Locale.getString('faq_question_15'),
		expandable: [Locale.getString('faq_answer_15')]
	},{
		title: Locale.getString('faq_question_16'),
		expandable: [Locale.getString('faq_answer_16')]
	},{
		title: Locale.getString('faq_question_17'),
		expandable: [Locale.getString('faq_answer_17')]
	},{
		title: Locale.getString('faq_question_18'),
		expandable: [Locale.getString('faq_answer_18')]
	}, {
		title: Locale.getString('faq_question_19'),
		expandable: [Locale.getString('faq_answer_19')]
	}, {
		title: Locale.getString('faq_question_20'),
		expandable: [Locale.getString('faq_answer_20')]
	}, {
		title: Locale.getString('faq_question_21'),
		expandable: [Locale.getString('faq_answer_21')]
	}, {
		title: Locale.getString('faq_question_22'),
		expandable: [Locale.getString('faq_answer_22')]
	}, {
		title: Locale.getString('faq_question_23'),
		expandable: [Locale.getString('faq_answer_23')]
	}, {
		title: Locale.getString('faq_question_24'),
		expandable: [Locale.getString('faq_answer_24'), <a style={{color: '#19C692'}} onClick={() => {
			ds.retrieveUserSubscriptionPanel(
				response => {
					response.json().then(data => {
						console.log(data.url)
						window.location = data.url;
					}).catch(error => {
						console.log(error);
					})
					/*if (!!response && !!response.url) {
						window.location = response.url;
					} else {
						console.log("404")
					}*/
				}, error => {
					console.log(error);
				})
		}} target={"_blank"}>{Locale.getString('subscription')}</a> , '.']
	}, {
		title: Locale.getString('faq_question_25'),
		expandable: [
		Locale.getString('faq_answer_25'),
			<a style={{color: '#19C692'}} href={'https://support.apple.com/es-es/HT202039'}
			   target={"_blank"}>{Locale.getString('apple_company')}</a>, '.\n',
			   Locale.getString('faq_answer_25_1'),
			<a style={{color: '#19C692'}}
			   href={'https://support.google.com/googleplay/answer/7018481'} target={"_blank"}>Google</a>,
			   Locale.getString('faq_answer_25_2'),
			<a style={{color: '#19C692'}} href={'https://myrealfood.app/'} target={"_blank"}>MyRealFood
				Web</a>, Locale.getString('faq_answer_25_3')]
	}, {
		title: Locale.getString('faq_question_26'),
		expandable: [
			Locale.getString('faq_answer_26_1'),
			<a style={{color: '#19C692'}} href={Locale.getString('terms_and_conditions_link')} target={"_blank"}>{Locale.getString('faq_answer_26_2')}</a>, 
			Locale.getString('faq_answer_26_3'),
			<a style={{color: '#19C692'}} href={'https://support.apple.com/contact'} target={"_blank"}>iTunes Support</a>, 
			Locale.getString('faq_answer_26_4'),
			<a style={{color: '#19C692'}}
			   href={'https://support.google.com/googleplay/answer/2479637#apps'}
			   target={"_blank"}>Google</a>,
			Locale.getString('faq_answer_26_5')
		]
	}
]

export default function FAQ() {
	const classes = style();
	const history = useHistory();

	const [auth, setAuth] = useState(undefined);

	useEffect(() => {
		
		ds.subscribeToAuthChanges('FAQ', setAuth);
		return () => {
			ds.unsubscribeFromAuthChanges('FAQ');
		}
	});

	return (
		<div>
			<Container
				className={classes.root}
				maxWidth="xl"
			>
				<Header/>
				<Grid
					className={classes.rootBody}
					container
					md={12}
					spacing={2}
				>
					<Typography
						className={classes.rootBodyFAQHeader}
					>
						{Locale.getString('frecuent_question_text')}
					</Typography>
					{FAQS.map((FAQ, id) => {
						return (
							<Grid
								key={id+FAQ.expandable.length}
								className={classes.rootBodyFAQ}
								item
								md={12}
								sm={12}
								xs={12}
							>
								<Typography
									className={classes.rootBodyFAQTitle}
								>
									{FAQ.title}
								</Typography>
								<Typography
									className={classes.rootBodyFAQDescription}
								>
									{FAQ.expandable}
								</Typography>
							</Grid>
						)
					})}
				</Grid>
			</Container>
			<Footer/>
		</div>
	);
}


