import {makeStyles} from "@material-ui/core/styles";

export default makeStyles((theme) => ({
	root: {
    flex: 1,
    height: '100vh',
		paddingTop: 80,
		paddingHorizontal: "12%",
		backgroundColor: 'white',
	},
	rootSeparator: {
		height: 1,
		width: '16%',
		marginLeft: '42%',
		marginRight: '42%',
		marginTop: '10%',
		marginBottom: '7%',
		backgroundColor: '#19C692',
	},
}), { index: 1 });
