import React, {useEffect, useState} from 'react';

import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions'

import style from './style';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Header from "../../../common/Header";
import Footer from "../../../common/Footer";
import {useHistory} from "react-router";
import ds from "../../../controller/DataService";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import Locale from "../../../controller/locale";

export default function PresentRedeem() {
	const classes = style();
	const history = useHistory();

	const [auth, setAuth] = useState(undefined);

	const [redeemed, setRedeemed] = useState(false);

	const [requesting, setRequesting] = useState(false);
	const [referrer, setReferrer] = useState('');
	const [code, setCode] = useState('');
	const [codeError, setCodeError] = useState('');
	const [error, setError] = useState('');

	useEffect(() => {
		if (!ds.retrieveAuthData()) {
			let uri = `/regalo/canjear/${encodeURIComponent(window.location.search)}`;
			let paramMrt = new URLSearchParams(window.location.search).get('mrt');
			history.push(`/login/${paramMrt ? `?mrt=${paramMrt}&` : `?`}redirect=${uri}`)
		} else {
			if (!(!!ds.retrieveUserData() && !!ds.retrieveUserData()['public'] && !!ds.retrieveUserData()['public']['nickname'])) {
				let uri = `/regalo/canjear/${encodeURIComponent(window.location.search)}`;
				history.push(`/register/complete/?redirect=${uri}`);
			} else {
				ds.subscribeToAuthChanges('PresentRedeem', (user) => {
					setAuth(user);
				});

				let paramReferrer = new URLSearchParams(decodeURIComponent(window.location.search)).get('referrer');
				if (!!paramReferrer && !!paramReferrer.length) {
					setReferrer(paramReferrer);
				}

				let paramCode = new URLSearchParams(decodeURIComponent(window.location.search)).get('code');
				if (!!paramCode && !!paramCode.length) {
					setCode(paramCode);
				}

				return () => {
					ds.unsubscribeFromAuthChanges('PresentRedeem');
				}
			}
		}
	}, [auth]);

	return (
		<div>
			<Container
				className={classes.root}
				maxWidth="xl"
			>
				<Header/>

				<Grid
					className={classes.rootBody}
					container
				>
					{!redeemed && <Grid
						className={classes.rootBodyHeader}
						container
						md={12}
						xs={12}
						sm={12}
						direction={'column'}
						alignItems={"center"}
						justify={"center"}
					>
						<Grid
							item
						>
							<Typography
								className={classes.rootBodyHeaderTitle}
							>
								{Locale.getString('redeem_gift')}
							</Typography>
						</Grid>
						<Grid
							item
						>
							{referrer === 'HUAWEI' ?
								<Typography
									className={classes.rootBodyHeaderSubTitle}
								>
									 {`${Locale.getString('thank_buy_huawei')} ${ds.retrieveUserEmail()}`}
								</Typography>
								:
								<Typography
									className={classes.rootBodyHeaderSubTitle}
								>
									{`${Locale.getString('redeem_code_huawei')} ${ds.retrieveUserEmail()}`}
								</Typography>}
						</Grid>
						<Box
							className={classes.rootBodyBox}
							boxShadow={3}
							bgcolor="background.paper"
							m={1}
							p={1}
						>
							<Grid
								container
								direction={'column'}
								alignItems={"center"}
								justify={"center"}
							>
								<TextField
									className={classes.rootBodyBoxInput}
									id="filled-basic"
									placeholder={Locale.getString('redeem_code_placeholder')}
									variant="outlined"
									error={!!codeError}
									label={Locale.getString('redeem_code_label')}
									defaultValue={code}
									helperText={!!codeError ? codeError : ""}
									value={code}
									onChange={(event) => setCode(event.target.value)}
								/>
							</Grid>
							<Grid
								container
								direction={'column'}
								justify={"center"}
							>
								{!requesting && <Button
									className={classes.rootBodyBoxButton}
									variant={"outlined"}
									onClick={() => {
										setRequesting(true);
										setError(false);
										ds.redeemCode(code, (response) => {
											if (response.status === 200) {
												setRequesting(false);

												response.json().then(data => {
													if (!!data.error && data.error === 'code_not_found') {
														setError(Locale.getString('code_not_found'));
													} else if (!!data.error && data.error === 'error_redeeming_code') {
														setError(Locale.getString('error_support_text'));
													} else if (!!data.status && data.status === 'code_already_redeemed') {
														setError(Locale.getString('code_already_redeemed'));
													} else if (!!data.status && data.status === 'user_already_is_plus') {
														setError(Locale.getString('user_already_is_plus'));
													} else if (!!data.status && data.status === 'code_successfully_redeemed') {
														window.scrollTo({top: 0, behavior: 'smooth'});
														setTimeout(() => {
															setRedeemed(true);
														}, 250);
													} else {
														setError(Locale.getString('error_support_text'));
													}
												}).catch(error => {
													setError(Locale.getString('error_support_text'));
												})
											} else {
												setRequesting(false);
												setError(Locale.getString('error_support_text'));
											}
										}, (error) => {
											setRequesting(false);
											setError(Locale.getString('error_support_text'));
											console.log(error);
										});
									}}
								>
									Validar
								</Button>}

								{requesting &&
								<Button
									className={classes.rootBodyBoxButton}
									variant={"outlined"}
								>
									<CircularProgress
										size={'20%'}
										color="secondary"
									/>
								</Button>}
							</Grid>

							{!!error &&
							<Typography
								className={classes.rootBodyContentError}
							>
								{error}
							</Typography>
							}

							<Grid
								className={classes.rootBodyBoxHint}
								container
								direction={'column'}
								alignItems={"center"}
								justify={"center"}
							>
								<Grid
									item
								>
									<Typography
										className={classes.rootBodyBoxHintTitle}
									>
										{Locale.getString('question_text')}
									</Typography>
								</Grid>
								<Grid
									item
								>
									<Link
										component="button"
										onClick={() => {
											history.push("/faq")
										}}
									>
										<Typography
											className={classes.rootBodyBoxHintSubtitle}
										>
											Preguntas Frecuentes
										</Typography>
									</Link>
								</Grid>
							</Grid>
						</Box>
					</Grid>}

					{redeemed && <Grid
						className={classes.rootBodyHeader}
						container
						md={12}
						xs={12}
						sm={12}
						direction={'column'}
						alignItems={"center"}
						justify={"center"}
					>
						<Grid
							item
						>
							<Typography
								className={classes.rootBodyHeaderTitle}
							>
								{Locale.getString('redeemed_gift')}
							</Typography>
						</Grid>
						<Grid
							item
						>
							<Typography
								className={classes.rootBodyBottomSubTitle}
							>
								{`${Locale.getString('plan_plus_activated_text')} ${!!ds.plusData() && !!ds.plusData()['to'] ? `${new Date(ds.plusData()['to'].toDate()).getDate() + '/' + (new Date(ds.plusData()['to'].toDate()).getMonth() + 1) + '/' + new Date(ds.plusData()['to'].toDate()).getFullYear()}` : ''} 
								${Locale.getString('plan_plus_activated_text_2')} ${ds.retrieveUserEmail()}${Locale.getString('plan_plus_activated_text_3')}`}
							</Typography>
						</Grid>

						<Grid
							className={classes.rootBodyDownloadApp}
							container
							direction={'row'}
							alignItems={"center"}
							justify={"center"}
						>
							<Grid
								container
								direction={'column'}
								alignItems={"center"}
								justify={"center"}
								md={2}
								xs={12}
								sm={12}
							>
							</Grid>
							<Grid
								container
								direction={'column'}
								alignItems={"center"}
								justify={"center"}
								md={4}
								xs={12}
								sm={12}
							>
								<Grid
									item
								>
									<Typography
										className={classes.rootBodyDownloadAppTitle}
									>
										{Locale.getString('donwload_apple')}
									</Typography>
								</Grid>
								<Grid
									container
									alignItems={"center"}
									justify={"center"}
								>
									<Button
										className={classes.rootBodyDownloadAppStoreButton}
										disableFocusRipple
										disableElevation
										onClick={() => {
											window.location.assign("https://apps.apple.com/es/app/myrealfood/id1458031749");
											window.ttq.track('Download', {
												os: 'ios'
											})
										}}
									>
										<img
											className={classes.rootBodyDownloadAppStoreButtonImage}
											src="/assets/buttons/ic_app_store.png"
											alt="App Store"
										/>
									</Button>
								</Grid>
							</Grid>
							<Grid
								container
								direction={'column'}
								alignItems={"center"}
								justify={"center"}
								md={4}
								xs={12}
								sm={12}
							>
								<Grid
									item
								>
									<Typography
										className={classes.rootBodyDownloadAppTitle}
									>
										{Locale.getString('download_google')}
									</Typography>
								</Grid>
								<Grid
									container
									alignItems={"center"}
									justify={"center"}
								>
									<Button
										className={classes.rootBodyDownloadAppStoreButton}
										disableFocusRipple
										disableElevation
										onClick={() => {
											window.location.assign("https://play.google.com/store/apps/details?id=es.myrealfood.myrealfood");
											window.ttq.track('Download', {
												os: 'android'
											})
										}}
									>
										<img
											className={classes.rootBodyDownloadAppStoreButtonImage}
											src="/assets/buttons/ic_google_play.png"
											alt="Google Play"
										/>
									</Button>
								</Grid>
							</Grid>
							<Grid
								container
								direction={'column'}
								alignItems={"center"}
								justify={"center"}
								md={4}
								xs={12}
								sm={12}
							>
								<Grid
									item
								>
									<Typography
										className={classes.rootBodyDownloadAppTitle}
									>
										{Locale.getString('download_huawei')}
									</Typography>
								</Grid>
								<Grid
									container
									alignItems={"center"}
									justify={"center"}
								>
									<Button
										className={classes.rootBodyDownloadAppStoreButton}
										disableFocusRipple
										disableElevation
										onClick={() => {
											window.location.assign("https://appgallery.cloud.huawei.com/ag/n/app/C103495711?locale=es_ES&source=myrealfood.app&subsource=PRESENT&shareTo=world&shareFrom=myrealfood.app");
											window.ttq.track('Download', {
												os: 'huawei'
											})
										}}
									>
										<img
											className={classes.rootBodyDownloadAppStoreButtonImage}
											src="/assets/buttons/ic_app_gallery.png"
											alt="App Gallery"
										/>
									</Button>
								</Grid>
							</Grid>
							<Grid
								container
								direction={'column'}
								alignItems={"center"}
								justify={"center"}
								md={2}
								xs={12}
								sm={12}
							>
							</Grid>
						</Grid>

						<Grid
							item
						>
							<Typography
								className={classes.rootBodyHeaderSubTitle}
							>
								{Locale.getString('web_menu_access')}
							</Typography>
						</Grid>

						<Grid
							container
							alignItems={"center"}
							justify={"center"}
						>
							<Button
								variant={"outlined"}
								className={classes.rootBodyDownloadAppButton}
								onClick={() => {
									history.push("/onboarding")
								}}
							>
								{Locale.getString('start_config_test')}
							</Button>
						</Grid>
					</Grid>}
				</Grid>
			</Container>
			<Footer
				white={true}
			/>
		</div>
	);
}
