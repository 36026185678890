import {Goal} from "./constants/Goal";
import {MacrosByGoalActivityGetter} from "./constants/MacrosByGoalActivity";
import {Activity} from "./constants/Activity";
import {Macros} from "./Macros";
import {macrosAdjust} from "./constants/Kcals";

export class MacrosCalculator {
  public static calculate(goal: Goal, activity: Activity): Macros {
    return MacrosByGoalActivityGetter.get(goal, activity);
  }

  public static calculateGramsByMacros(macros: Macros, kcals: number): Macros {
    const proteins = Math.round((kcals * macros.proteins) / 100 / 4);
    const carbohydrates = Math.round((kcals * macros.carbohydrates) / 100 / 4);
    const fats = Math.round((kcals * macros.fats) / 100 / 9);

    return {
      proteins,
      carbohydrates,
      fats
    };
  }

  public static convertMacrosToBaseOne(macros: Macros): number[] {
    return [macros.carbohydrates / 100, macros.proteins / 100, macros.fats / 100];
  }

  public static calculateMinMacros(macros: Macros): Macros {
    return MacrosCalculator.addToMacros(macros, -macrosAdjust);
  }

  public static calculateMaxMacros(macros: Macros): Macros {
    return MacrosCalculator.addToMacros(macros, macrosAdjust);
  }

  private static addToMacros(macros: Macros, value: number): Macros {
    return {
      carbohydrates: macros.carbohydrates + value,
      proteins: macros.proteins + value,
      fats: macros.fats + value
    };
  }
}
