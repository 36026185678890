import {makeStyles} from "@material-ui/core/styles";
import React from "react";

export default makeStyles((theme) => ({
    header: {
        visibility: 'hidden',
        display: "none",
        '@media (max-width: 800px)': {
            visibility: 'visible',
            display: "flex",
            padding: '8px 20px',
            alignItems: 'center',
        }
    },
    logo: {
        width: 200,
        height: "auto",
    },
    root: {
        display: 'flex',
        height: '90vh',
        flexDirection: 'row',
        overflowY: 'scroll',
        alignItems: 'stretch',
        '@media (max-width: 800px)': {
            flexDirection: 'column',
            height: '100%',
        }
    },
    image: {
        flex: 2,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        height: '100%',
        width: 'auto',

        '@media (max-width: 800px)': {
            minHeight: 300,
        }
    },

    video: {
        flex: 1,
        height: '100%',

        '@media (max-width: 800px)': {
            minHeight: 300,
            width: '100%'
        }
    },
    containerText: {
        flex: 3,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: "60px",
        '@media (max-width: 800px)': {
            padding: "32px 30px 120px 30px",
            flex: 2,
        }
    },

    title: {
        fontSize: "40px",
        fontWeight: "medium",
        lineHeight: "50px",
        marginBottom: 40,
        letterSpacing: "-1.5%",
        '@media (max-width: 800px)': {
            fontSize: "30px",
            lineHeight: "40px",
            marginBottom: 20,
        }
    },

    subtitle: {
        fontSize: "25px",
        fontWeight: 500,
        marginBottom: 24,
        letterSpacing: "-1.5%",
        '@media (max-width: 800px)': {
            fontSize: "20px",
            marginBottom: 16,
        }
    },

    option: {
        padding: "25px",
        borderRadius: "14px",
        marginBottom: "16px",
        maxWidth: "500px",
        display: "flex",
        flexDirection: "row",
        border: "1px solid #DDDDDD",

        '@media (max-width: 800px)': {
            height: "100px",
            padding: "17px 20px",
        },

        '&:hover': {
            backgroundColor: "#D3F6E973",
            border: "1px solid #19C692"
        }
    },

    optionIcon: {
        display: "flex",
        paddingRight: "16px",
        paddingTop: "8px",
    },

    optionText: {
        display: "flex",
        flexDirection: "column",

        '@media (max-width: 800px)': {
           marginTop: "6px",
        }
    },

    optionTitle: {
        fontSize: "17px",
        fontWeight: 500,
    },

    optionSubtitle: {
        fontSize: "15px",
        marginTop: "4px",
        color: "#9BA0A5",
        fontWeight: 400,
    }

}), { index: 1 });
