import {makeStyles} from "@material-ui/core/styles";

export default makeStyles((theme) => ({
	rootBodyCardOption: {
		width: '80%',
		paddingTop: '10%',
		paddingBottom: '10%',
	},
	rootBodyCardOptionDotContainer: {
		marginTop: '10%',
		marginBottom: '10%'
	},
	rootBodyCardOptionDot: {
		width: 50,
		height: 50,
		borderRadius: 25,
		backgroundColor: '#828282'
	},
	rootBodyCardOptionDotSelected: {
		width: 50,
		height: 50,
		borderRadius: 25,
		backgroundColor: '#FFF'
	},
	rootBodyCardOptionImage: {
		objectFit: 'contain',
		width: '100%',
		paddingTop: '10%',
		paddingBottom: '10%',
		paddingRight: '20%',
		paddingLeft: '20%',
	},
	rootBodyCardOptionTitle: {
		textAlign: 'center',
		fontWeight: 'bold',
		fontSize: 20,
	},
	rootBodyCardOptionTitleSelected: {
		textAlign: 'center',
		fontWeight: 'bold',
		color: 'white',
		fontSize: 20,
	},
	rootBodyCardOptionSubtitle: {
		marginTop: '5%',
		textAlign: 'center',
		fontWeight: 'bold',
		paddingBottom: '10%',
		fontSize: 13,
		color: '#828282',
		whiteSpace: "pre-wrap"
	},
	rootBodyCardOptionSubtitleSelected: {
		marginTop: '5%',
		textAlign: 'center',
		fontWeight: 'bold',
		paddingBottom: '10%',
		color: 'white',
		fontSize: 13,
		whiteSpace: "pre-wrap"
	}
}), { index: 1 });
