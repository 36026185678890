import {makeStyles} from "@material-ui/core/styles";

export default makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		backgroundColor: 'white',
		paddingLeft: '3%',
		paddingRight: '3%',
		[theme.breakpoints.up('md')]: {
			paddingLeft: '14%',
			paddingRight: '14%',
		},
	},
	rootSeparator: {
		height: 1,
		width: '16%',
		marginLeft: '42%',
		marginRight: '42%',
		marginTop: '10%',
		marginBottom: '7%',
		backgroundColor: '#19C692',
	},
	rootSeparatorGrey: {
		height: 1,
		width: '120%',
		marginLeft: 'auto',
		marginRight: 'auto',
		marginTop: '5%',
		marginBottom: '5%',
		backgroundColor: '#DADADA',
	},
	rootBody: {
		marginTop: '5%'
	},
	rootBodyHeader: {},
	rootBodyHeaderTopImage: {
		objectFit: 'contain',
		width: '20%',
		height: 'auto'
	},
	rootBodyHeaderBottomImage: {
		objectFit: 'contain',
		marginTop: '10%',
		width: '30%',
		height: 'auto',
		[theme.breakpoints.up('md')]: {
			marginTop: '5%',
			width: '20%',
		},
	},
	rootBodyContent: {
		marginTop: '20%',
		marginBottom: '5%',
		[theme.breakpoints.up('md')]: {
			marginTop: '5%',
			marginBottom: '5%',
		},
	},
	rootBodyContentLeft: {
		paddingLeft: '5%',
		paddingRight: '5%',
		[theme.breakpoints.up('md')]: {
			paddingLeft: '10%',
			paddingRight: '10%',
		},
	},
	rootBodyContentLeftTitle: {
		color: '#000',
		fontWeight: 'bold',
		fontSize: 26,
		marginBottom: '0%',
		[theme.breakpoints.up('md')]: {
			fontSize: 30,
			marginBottom: '5%',
		},
	},
	rootBodyContentLeftTitleSub: {
		color: '#424242',
		fontWeight: 'bold',
		fontSize: 18,
		marginTop: '3%',
		marginBottom: '5%',
		[theme.breakpoints.up('md')]: {
			marginTop: '0%',
			fontSize: 20,
		},
	},
	rootBodyContentLeftSubtitle: {
		color: '#000',
		fontWeight: 'bold',
		fontSize: 20,
	},
	rootBodyContentLeftSubtitleContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		marginTop: '5%',
		marginBottom: '3%'
	},
	rootBodyContentCardsIcons: {
		height: '30px',
		marginLeft: '10px'
	},
	rootBodyContentPadding: {
		marginTop: '10%'
	},
	rootBodyContentForm: {
		width: '100%',
		paddingBottom: '5%'
	},
	rootBodyContentLeftButton: {
		width: '100%',
		marginTop: '15%',
		marginBottom: '5%',
		color: '#FFF',
		backgroundColor: '#19C692',
		borderRadius: 10,
		paddingTop: '2%',
		paddingBottom: '2%',
		fontWeight: 'bold',
		fontSize: 12,
	},
	rootBodyContentLeftButtonDisabled: {
		width: '100%',
		marginTop: '15%',
		marginBottom: '5%',
		color: '#FFF',
		backgroundColor: '#ABABAB',
		borderRadius: 10,
		paddingTop: '2%',
		paddingBottom: '2%',
		fontWeight: 'bold',
		fontSize: 12,
	},
	rootBodyContentFormButton: {
		width: '100%',
		color: '#FFF',
		backgroundColor: '#19C692',
		borderRadius: 10,
		paddingTop: '4%',
		paddingBottom: '4%',
		fontWeight: 'bold',
		fontSize: 12,
		[theme.breakpoints.up('sm')]: {
			paddingTop: '3%',
			paddingBottom: '3%',
		},
		[theme.breakpoints.up('md')]: {
			paddingTop: '3%',
			paddingBottom: '3%',
		},
	},
	rootBodyContentFormButtonAplied: {
		width: '100%',
		color: '#ABABAB',
		backgroundColor: '#FFF',
		borderRadius: 10,
		paddingTop: '4%',
		paddingBottom: '4%',
		fontWeight: 'bold',
		fontSize: 12,
		[theme.breakpoints.up('sm')]: {
			paddingTop: '3%',
			paddingBottom: '3%',
		},
		[theme.breakpoints.up('md')]: {
			paddingTop: '3%',
			paddingBottom: '3%',
		},
	},
	rootBodyContentFormButtonText: {
		color: 'white',
		fontWeight: 'bold',
		fontSize: 10,
		[theme.breakpoints.up('sm')]: {
			fontSize: 12,
		},
		[theme.breakpoints.up('md')]: {
			fontSize: 13,
		},
	},
	rootBodyContentFormButtonTextAplied: {
		color: '#ABABAB',
		fontWeight: 'bold',
		fontSize: 10,
		[theme.breakpoints.up('sm')]: {
			fontSize: 12,
		},
		[theme.breakpoints.up('md')]: {
			fontSize: 13,
		},
	},
	rootBodyContentSubscribeError: {
		fontWeight: 'normal',
		fontSize: 12,
		letterSpacing: 0,
		lineHeight: 1.3,
		color: '#ff0000',
		marginTop: '1%',
		marginBottom: '4%',
		textAlign: 'center'
	},
	rootBodyContentRight: {
		paddingLeft: '5%',
		paddingRight: '5%',
		display: 'none',
		[theme.breakpoints.up('md')]: {
			display: 'flex',
		},
	},
	rootBodyContentRightMobile: {
		display: 'flex',
		[theme.breakpoints.up('md')]: {
			display: 'none',
			paddingLeft: '5%',
			paddingRight: '5%',
		},
	},
	rootBodyContentRightTitle: {
		color: '#000',
		fontWeight: 'bold',
		fontSize: 16,
		[theme.breakpoints.up('md')]: {
			fontSize: 20,
		},
	},
	rootBodyContentRightTitleHint: {
		color: '#000',
		fontSize: 14,
		marginTop: '5%',
		marginBottom: '5%',
		[theme.breakpoints.up('md')]: {
			fontSize: 15,
			fontWeight: 'bold',
			marginTop: '5%',
			marginBottom: '0%',
		},
	},
	rootBodyContentRightAccountHint: {
		color: '#000',
		fontSize: 14,
		marginTop: '5%',
		marginBottom: '5%',
		[theme.breakpoints.up('md')]: {
			fontSize: 15,
			fontWeight: 'bold',
			marginTop: '0%',
			marginBottom: '5%',
		},
	},
	rootBodyContentRightLink: {
		width: '100%',
		[theme.breakpoints.up('md')]: {
			width: '80%',
		},
	},
	rootBodyContentRightSubtitle: {
		color: '#19C692',
		fontWeight: 'bold',
		fontSize: 15,
		textAlign: 'right',
		marginRight: '15%',
		[theme.breakpoints.up('md')]: {
			textAlign: 'left',
			marginRight: '0%',
		},
	},
	rootBodyContentImagePoweredByStripeContent: {
		width: '80%',
		[theme.breakpoints.down('sm')]: {
			width: '120px',

		},
	},
	rootBodyContentImagePoweredByStripe: {
		width: '65%',
		marginTop: '10px',
		objectFit: 'contain',
		[theme.breakpoints.down('sm')]: {
			width: '120px',

		},
	},
	rootBodyContentRightBox: {
		marginTop: '8%',
		width: '55%',
		borderWidth: '1',
		borderColor: '#19C692',
		borderRadius: 20
	},
	rootBodyContentRightBoxContainer: {},
	rootBodyContentRightBoxContainerTitle: {
		borderTopRightRadius: 20,
		borderTopLeftRadius: 20,
		width: '100%',
		backgroundColor: '#19C692',
		textAlign: 'center',
		paddingTop: '6%',
		paddingBottom: '6%',
		fontWeight: 'bold',
		color: 'white',
		fontSize: 18,
	},
	rootBodyContentRightBoxContainerSubtitle: {
		fontSize: 18,
		fontWeight: 'bold',
		textAlign: 'center',
		marginTop: '15%',
	},
	rootBodyContentRightBoxContainerPrice: {
		fontSize: 18,
		textAlign: 'center',
		marginTop: '10%',
		marginBottom: '15%',
	},
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	paper: {
		backgroundColor: theme.palette.background.paper,
		border: '1px solid #FFF',
		borderRadius: 20,
		boxShadow: theme.shadows[5],
		padding: theme.spacing(0, 1.5, 0),
		alignItems: 'center',
		justify: 'center',
		width: '95%',
		paddingBottom: '10%',
		[theme.breakpoints.up('md')]: {
			padding: theme.spacing(2, 4, 3),
			width: '60%',
			paddingBottom: '3%',
		},
	},
	paperTrial: {
		backgroundColor: theme.palette.background.paper,
		border: '1px solid #FFF',
		borderRadius: 20,
		boxShadow: theme.shadows[5],
		padding: theme.spacing(0, 1.5, 0),
		alignItems: 'center',
		justify: 'center',
		width: '95%',
		paddingBottom: '10%',
		overflow: 'scroll',
		height: '80%',
		display: 'block',
		[theme.breakpoints.up('md')]: {
			padding: theme.spacing(2, 4, 3),
			width: '30%',
			paddingBottom: '3%',
		},
	},
	rootBodyContentSubscribePromotionalCodeApplied: {
		fontSize: 14,
		color: '#19C692',
		fontWeight: 'bold',
		paddingRight: '5%',
	},
	rootBodyContentSubscribePromotionalCodeNotApplied: {
		fontSize: 14,
		color: '#F00',
		paddingRight: '5%',
	},
	paperMobileOnly: {
		display: 'none',
		[theme.breakpoints.up('md')]: {
			display: 'none'
		},
	},
	rootBodyContentSubscribeButtonText: {
		color: 'white',
		fontWeight: 'bold',
	},
	rootBodyContentSubscribeButtonSafePay: {
		color: "#ABABAB"
	},
	rootBodyContentMobileBoxContainerSubtitle: {
		marginTop: '5%',
		fontWeight:
			'bold',
		fontSize:
			16,
		[theme.breakpoints.up('md')]:
			{
				fontSize: 18,
			}
		,
	},
	rootBodyContentMobileBoxContainerPrice: {
		textDecoration: 'line-through',
		color:
			'#828282',
		fontSize:
			16,
		[theme.breakpoints.up('md')]:
			{
				fontSize: 18,
			}
		,
	}
	,
	rootBodyContentMobileBoxContainerFinalPrice: {
		fontSize: 16,
		[theme.breakpoints.up('md')]:
			{
				fontSize: 18,
				marginBottom:
					'3%',
			}
		,
	},
	rootBodyContentMobileBoxContainerHintPrice: {
		color: '#828282',
		whiteSpace: "pre-wrap",
		fontSize: 10,
		marginTop: '2%',
		[theme.breakpoints.up('md')]: {
			fontSize: 12,
		},
	},
	paymentRequestButtonContainer: {
		marginTop: '10px'
	},
	paymentRequestButtonHintContainer: {
		display: 'flex',
		alignContent: 'center',
		alignItems: 'center',
		marginTop: '20px'
	},
	paymentRequestButtonHintLine: {
		flex: 1,
		height: '2px',
		backgroundColor: '#f1f1f1',
		marginRight: '5px',
		marginLeft: '5px'
	},
	paymentRequestButtonHintText: {
		marginRight: '15px',
		marginLeft: '15px'
	},
	paymentRequestButtonError: {
		fontWeight: 'normal',
		fontSize: 12,
		letterSpacing: 0,
		lineHeight: 1.3,
		color: '#ff0000',
		marginTop: '1%',
		marginBottom: '4%',
		textAlign: 'center'
	},
}), {index: 1});
