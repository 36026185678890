import React, {useEffect, useState} from 'react';
import style from './style'
import Typography from '@material-ui/core/Typography';
import Link from "@material-ui/core/Link";
import {useHistory} from "react-router";
import CookieConsent from "react-cookie-consent";
import {useCookies} from 'react-cookie';
import Modal from '@material-ui/core/Modal';
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Switch from "@material-ui/core/Switch";
import {__DEV__} from "../../controller/Exports";
import ds from "../../controller/DataService";
import ReactPixel from "react-facebook-pixel";
import Locale from "../../controller/locale";

function rand() {
	return Math.round(Math.random() * 20) - 10;
}

export default function CookieBanner() {
	const classes = style();
	const history = useHistory();

	const [open, setOpen] = React.useState(false);
	const [cookies, setCookie] = useCookies(['myRealFoodConsent']);
	const [cookieAnalytics, setCookieAnalytics] = useState(false);

	const [visible, setVisible] = React.useState(true);

	useEffect(() => {
		setVisible(!(!!window.location && !!window.location.pathname && (
			window.location.pathname.includes('/login') ||
			window.location.pathname.includes('/subscribe') ||
			window.location.pathname.includes('/comprar')
		)))
	});

	const initTrackingScripts = () => {
		const script = document.createElement("script");
		script.type = "text/javascript";
		script.src = "https://www.googletagmanager.com/gtag/js?id=UA-131824376-7";
		document.getElementsByTagName("head")[0].appendChild(script);

		let advancedMatching = {};

		let options = {
			autoConfig: true,
			debug: __DEV__,
		};

		if (!!ds.retrieveUserData() && !!ds.retrieveUserData()['public'] && !!ds.retrieveUserData()['public']['uid'] && !!ds.retrieveUserEmail()) {
			advancedMatching = {
				em: ds.retrieveUserEmail(),
				external_id: ds.retrieveUserData()['public']['uid']
			}
		}

		ReactPixel.init('297577238442174', advancedMatching, options);

		window.ttq.load('C4J1F80ASI8C5G6I2Q3G');
		window.ttq.page();
	}

	useEffect(() => {
		if (cookies.myRealFoodConsent === "true") {
			initTrackingScripts();
		}
	});

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	if (!!cookies.myRealFoodConsent || !visible) {
		return (<div/>)
	} else {
		return (
			<CookieConsent
				overlay={false}
				location="bottom"
				hideOnAccept={true}
				acceptOnScroll={false}
				cookieSecurity={true}
				acceptOnScrollPercentage={5}

				cookieName="myRealFoodConsent"
				cookieValue={true}
				declineCookieValue={false}
				setDeclineCookie={false}
				expires={365}

				enableDeclineButton={false}
				buttonText={Locale.getString('cookie_banner_btn_accept')}
				declineButtonText={Locale.getString('cookie_banner_btn_dismiss')}
				flipButtons={false}

				style={{
					background: "#000D",
					borderTopWidth: 1,
					borderColor: 'black',
					paddingRight: '10',
					alignItems: 'center'
				}}
				buttonStyle={{
					color: "#FFF",
					fontSize: "14px",
					backgroundColor: '#19C692',
					borderRadius: 20,
					fontFamily: 'Poppins',
					fontWeight: 'bold',
					whiteSpace: "pre",
					padding: 10,
				}}
				declineButtonStyle={{
					color: "#FFF",
					fontSize: "14px",
					borderRadius: 20,
					fontFamily: 'Poppins',
				}}

				onAccept={({acceptedByScrolling}) => {
					initTrackingScripts();
				}}
				onDecline={() => {

				}}
			>
				<Typography
					style={{
						color: 'white',
						paddingLeft: '3%',
						alignItems: 'center',
						justify: 'center',
						whiteSpace: "pre-wrap",
					}}
				>
					{Locale.getString('cookie_banner_text')}
					<Link
						component="button"
						onClick={() => {
							setOpen(true)
						}}
					><Typography
						style={{color: 'white', fontWeight: 'bold', whiteSpace: "pre-wrap", paddingBottom: 2}}
					>
						{" "}{Locale.getString('here')}
					</Typography></Link>
				</Typography>
				<Modal
					open={open}
					onClose={handleClose}
					aria-labelledby="simple-modal-title"
					aria-describedby="simple-modal-description"
				>
					<div className={classes.paper}>
						<Grid
							container
							direction={"row"}
							alignItems={"center"}
						>
							<Grid
								item
								xs={10}
								sm={11}
								md={11}
							>
								<Typography
									className={classes.paperTitle}
								>
									{Locale.getString('cookie_banner_preference')}
								</Typography>
							</Grid>
							<Grid
								item
								xs={2}
								sm={1}
								md={1}
							>

							</Grid>
						</Grid>
						<Typography
							className={classes.paperDescription}
						>
							{Locale.getString('cookie_banner_description')}
						</Typography>

						<Typography
							className={classes.paperDescription}
						>
							<Link
								component="button"
								onClick={() => {
									window.location.assign("/docs/politica_uso_de_cookies.pdf")
								}}
							>
								{Locale.getString('cookie_banner_more_info')}
							</Link>
						</Typography>
						<Button
							variant={"outlined"}
							className={classes.paperButton}
							onClick={() => {
								setCookie('myRealFoodConsent', true, {path: '/'});
								handleClose();
							}}
						>
							<Typography
								className={classes.paperButtonText}
							>
								{Locale.getString('cookie_banner_accept_all')}
							</Typography>
						</Button>

						<Grid
							container
							direction={"row"}
							alignItems={"center"}
						>
							<Grid
								item
								xs={10}
								sm={11}
								md={11}
							>
								<Typography
									className={classes.paperTitle}
								>
									{Locale.getString('cookie_banner_strict_accept')}
								</Typography>
							</Grid>
							<Grid
								item
								xs={2}
								sm={1}
								md={1}
							>
								<Switch
									color="primary"
									defaultChecked
									disabled
									inputProps={{'aria-label': 'checkbox with default color'}}
								/>
							</Grid>
						</Grid>
						<Typography
							className={classes.paperDescription}
						>
							{Locale.getString('cookie_explanation')}
						</Typography>
						<Typography
							className={classes.paperDescription}
						>
							<Link
								component="button"
								onClick={() => {
									window.location.assign("/docs/politica_uso_de_cookies.pdf")
								}}
							>
								{Locale.getString('cookie_banner_more_info')}
							</Link>
						</Typography>

						<Grid
							className={classes.paperSeparator}
						/>

						<Grid
							container
							direction={"row"}
							alignItems={"center"}
						>
							<Grid
								item
								xs={10}
								sm={11}
								md={11}
							>
								<Typography
									className={classes.paperTitle}
								>
									{Locale.getString('cookie_banner_directed')}
								</Typography>
							</Grid>
							<Grid
								item
								xs={2}
								sm={1}
								md={1}
							>
								<Switch
									color="primary"
									checked={cookieAnalytics}
									onChange={(event) => setCookieAnalytics(event.target.checked)}
									inputProps={{'aria-label': 'checkbox with default color'}}
								/>
							</Grid>
						</Grid>
						<Typography
							className={classes.paperDescription}
						>
							{Locale.getString('cookie_banner_explanation')}
						</Typography>
						<Typography
							className={classes.paperDescription}
						>
							<Link
								component="button"
								onClick={() => {
									window.location.assign("/docs/politica_uso_de_cookies.pdf")
								}}
							>
								{Locale.getString('cookie_banner_more_info')}
							</Link>
						</Typography>
						<Button
							variant={"outlined"}
							className={classes.paperButton}
							onClick={() => {
								setCookie('myRealFoodConsent', cookieAnalytics, {path: '/'});
								handleClose();
							}}
						>
							<Typography
								className={classes.paperButtonText}
							>
								{Locale.getString('cookie_banner_confirm_preferences')}
							</Typography>
						</Button>

						<Grid
							className={classes.paperSeparator}
						/>
					</div>
				</Modal>
			</CookieConsent>
		);
	}
}
