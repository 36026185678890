import React, { useEffect, useState } from "react";

import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Header from "../../../common/Header";
import Footer from "../../../common/Footer";
import { useHistory } from "react-router";
import ds from "../../../controller/DataService";
import Typography from "@material-ui/core/Typography";
import { plusMenuInteractor } from "../PlusMenu/interactors/PlusMenuInteractor";
import { WeekKey } from "@myrealfood-sl/domain";

import Locale from "../../../controller/locale";
import {
  __DEV__,
  UNITS,
  CATEGORY_ICONS,
  CART_FIXED_VALUES,
  __DEFAULT_PROMOCODE__
} from "../../../controller/Exports";

import style from "./style";

export default function PlusCart() {
  const classes = style();
  const history = useHistory();

  const [menu, setMenu] = useState([]);
  const [cartWeek, setCartWeek] = useState("");

  const [ingredientsCategories, setIngredientCategories] = useState({});
  const [ingredientsOrderedCategories, setIngredientOrderedCategories] = useState([]);
  const [ingredientsByCategory, setIngredientsByCategory] = useState({});
  const [ingredientListLoaded, setIngredientListLoaded] = useState(false);

  const MONTHS = [
    Locale.getString("january"),
    Locale.getString("february"),
    Locale.getString("march"),
    Locale.getString("april"),
    Locale.getString("may"),
    Locale.getString("june"),
    Locale.getString("july"),
    Locale.getString("august"),
    Locale.getString("september"),
    Locale.getString("october"),
    Locale.getString("november"),
    Locale.getString("december")
  ];

  const retrieveSelectedPlan = async (week) => {
    if (!!ds.hasCompletedPlusOnboarding()) {
      const menu = await plusMenuInteractor.getMenu(new WeekKey(week));
      const fullMenu = menu.toPrimitives().plan.dayPlans;
      setMenu(fullMenu);

      ds.retrieveProductsFromMenu(fullMenu, ({ categories, headers, orderedCategories }) => {
        setIngredientOrderedCategories(orderedCategories);
        setIngredientCategories(headers);
        setIngredientsByCategory(categories);
        setIngredientListLoaded(true);
      });
    }
  };

  useEffect(() => {
    if (!ds.retrieveAuthData()) {
      let paramMrt = new URLSearchParams(window.location.search).get('mrt');
      history.push(`/login/${paramMrt ? `?mrt=${paramMrt}&` : `?`}redirect=${`/plus/menu`}`)
    } else {
      if (ds.isPlusUser()) {
        if (!ds.hasCompletedPlusOnboarding()) {
          history.push("/onboarding");
        }
        const cartWeek = new URLSearchParams(window.location.search).get("week");
        if (!cartWeek) {
          history.push("/plus/menu");
        } else {
          setCartWeek(cartWeek);
          retrieveSelectedPlan(cartWeek);
        }

        return () => {};
      } else {
        history.push(
          `/plus/?period=12&promo=${ds.retrunAvailablePromocode(__DEFAULT_PROMOCODE__[0])}`
        );
      }
    }
  }, [cartWeek]);

  return (
    <div>
      <Container className={classes.root} maxWidth="xl">
        <Header />
        <Grid className={classes.rootBody} container md={12}>
          <Grid
            className={classes.rootBodyContent}
            container
            md={12}
            direction={"row"}
            alignItems={"center"}
            justify={"center"}
          >
            <Grid
              className={classes.rootGrow}
              container
              md={12}
              alignItems={"center"}
              justify={"center"}
            >
              <Typography className={classes.rootBodyContentHeaderTitle} variant="h3">
                {Locale.getString("shopping_list")}
              </Typography>
            </Grid>
          </Grid>

          <div className={classes.rootSeparator} />

          {!ingredientListLoaded && (
            <Typography className={classes.rootBodyContentHeaderTitle} variant="h3"></Typography>
          )}
          {ingredientListLoaded && (
            <Grid container md={12}>
              <Grid
                className={classes.rootGrow}
                container
                md={12}
                alignItems={"center"}
                justify={"center"}
              >
                <Typography className={classes.rootBodyContentHeaderTitle} variant="h5">
                  {!!menu && menu[0]
                    ? MONTHS[new Date(menu[0].day).getMonth()] +
                      `\n${Locale.getString("week")} ` +
                      new Date(menu[0].day).getDate() +
                      "-" +
                      new Date(menu[6].day).getDate()
                    : ""}
                </Typography>
              </Grid>

              {ingredientsOrderedCategories.map((categoryName) => {
                const categoryId = ingredientsCategories[categoryName];
                const categoryIngredients = ingredientsByCategory[categoryId];
                const categoryIcon = CATEGORY_ICONS[categoryId];

                return (
                  <Grid className={classes.rootBodyContentShoppingList} container md={12}>
                    <Grid className={classes.rootBodyContentCategory} container md={12}>
                      {/* <Grid
                                                        className={classes.rootBodyContentCategoryHeader}
                                                        container
                                                        md={12}
                                                        direction={"row"}
                                                    >
                                                     */}
                      <Grid className={classes.rootBodyContentCategoryHeader} container spacing={1}>
                        <Grid item>
                          <img
                            className={classes.rootBodyContentCategoryHeaderIcon}
                            src={categoryIcon}
                            alt={Locale.getString(Locale.getLocaledCategoryProduct(categoryId))}
                          />
                        </Grid>
                        <Grid item xs zeroMinWidth>
                          <Typography
                            className={classes.rootBodyContentCategoryHeaderName}
                            variant="h5"
                          >
                            {Locale.getLocaledCategoryProduct(categoryId)}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        className={classes.rootBodyContentCategoryIngredients}
                        container
                        md={12}
                      >
                        {categoryIngredients
                          .sort((a, b) => (a.name > b.name ? 1 : -1))
                          .map((ingredient) => {
                            const fixed = ingredient.fixed;
                            const amount = ingredient.amount;
                            const units = ingredient.units;
                            const string_units =
                              Number(parseFloat(Math.ceil(amount / fixed)).toFixed(0)) !== 1
                                ? Locale.getString(UNITS[units].plural)
                                : Locale.getString(UNITS[units].name);
                            const ingredientNameWithAmount = `${
                              !!ingredient.name ? ingredient.name : "-"
                            } (${
                              (!!CART_FIXED_VALUES[ingredient.code] || fixed === 1
                                ? Number(parseFloat(Math.ceil(amount / fixed)).toFixed(0)) +
                                  " " +
                                  string_units +
                                  (fixed === 1 ? "" : " - ")
                                : "") +
                              (fixed === 1
                                ? ""
                                : Number(parseFloat(Math.ceil(amount)).toFixed(0)) +
                                  ` ${Locale.getString("grams")}`)
                            })`;

                            return (
                              <Grid
                                className={classes.rootBodyContentCategoryIngredientItem}
                                container
                                md={12}
                              >
                                <Typography
                                  className={classes.rootBodyContentCategoryIngredientItemText}
                                  variant="h5"
                                >
                                  {ingredientNameWithAmount}
                                </Typography>
                              </Grid>
                            );
                          })}
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          )}
        </Grid>
      </Container>
      <Footer />
    </div>
  );
}
