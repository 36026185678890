import {Goal} from "./Goal";
import {Activity} from "./Activity";
import {Macros} from "../Macros";

export const macrosByGoalActivity = {
  0: {
    0: { carbohydrates: 35, proteins: 25, fats: 40 },
    1: { carbohydrates: 35, proteins: 25, fats: 40 },
    2: { carbohydrates: 35, proteins: 25, fats: 40 },
    3: { carbohydrates: 45, proteins: 25, fats: 30 },
    4: { carbohydrates: 45, proteins: 25, fats: 30 }
  },
  1: {
    0: { carbohydrates: 40, proteins: 20, fats: 40 },
    1: { carbohydrates: 40, proteins: 20, fats: 40 },
    2: { carbohydrates: 40, proteins: 20, fats: 40 },
    3: { carbohydrates: 50, proteins: 20, fats: 30 },
    4: { carbohydrates: 50, proteins: 20, fats: 30 }
  },
  2: {
    0: { carbohydrates: 50, proteins: 20, fats: 30 },
    1: { carbohydrates: 50, proteins: 20, fats: 30 },
    2: { carbohydrates: 50, proteins: 20, fats: 30 },
    3: { carbohydrates: 55, proteins: 20, fats: 25 },
    4: { carbohydrates: 55, proteins: 20, fats: 25 }
  }
};

export class MacrosByGoalActivityGetter {
  public static get(goal: Goal, activity: Activity): Macros {
    return macrosByGoalActivity[goal][activity];
  }
}
