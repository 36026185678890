import React, {useEffect, useState} from "react";
import style from "./style";
import {useHistory} from 'react-router-dom';
import NextButton from "../../nextButton";

const ObjectiveOptions = [
    {
        title: 'Omnívora',
        subtitle: 'Como de todo',
        id: 1,
        icon: '/assets/icons/diets/omni.png'
    },
    {
        title: 'Vegetariana',
        id: 2,
        subtitle: 'No como carne ni pescado',
        icon: '/assets/icons/diets/vegetarian.png'
    },
    {
        title: 'Vegana',
        id: 3,
        subtitle: 'No como nada de procedencia animal',
        icon: '/assets/icons/diets/vegan.png'
    }
]


const Diet = ({onNext}) => {
    const classes = style();
    const history = useHistory();
    const [selected, setSelected] = useState();

    useEffect(() => {
        const diet = localStorage.getItem('premium.basic.diet');
        if(diet !== undefined) {
            setSelected(JSON.parse(diet))
        }
    }, []);

    return (
        <div
            className={classes.root}
        >
            {
                ObjectiveOptions.map((option, index) => (
                    <div
                        onClick={() => {
                            setSelected(option.id);
                        }}
                        key={index}
                        className={option.id === selected ? classes.optionSelected : classes.option}
                    >
                        <div
                            className={classes.optionIcon}
                        >
                            <img
                                width={52}
                                height={52}
                                src={option.icon}/>
                        </div>
                        <div
                            className={classes.optionText}
                        >
                            <div
                                className={classes.optionTitle}
                            >
                                {option.title}
                            </div>
                            <div
                                className={classes.optionSubtitle}
                            >
                                {option.subtitle}
                            </div>
                        </div>

                    </div>
                ))
            }
            <NextButton
                disabled={!selected}
                onNext={() => {
                    localStorage.setItem('premium.basic.diet', selected)
                    onNext();
                }}
            />
        </div>
    )
}

export default Diet;
