import React, {useEffect, useState} from "react";
import style from "./style";
import {useHistory} from 'react-router-dom';
import NextButton from "../../nextButton";
import TextField from "@material-ui/core/TextField";
import Info from "../../info";

const Height = ({onNext}) => {
    const classes = style();
    const history = useHistory();

    const [height, setHeight] = useState('');
    const [error, setError] = useState();
    const [valid, setValid] = useState(true);

    useEffect(() => {
        const height = localStorage.getItem('premium.basic.height');
        if(height) {
            setHeight(Number(height));
        }
    }, []);

    return (
        <div
            className={classes.root}
        >
            <TextField
                className={classes.input}
                id="outlined-number"
                variant={'outlined'}
                placeholder={'160'}
                helperText={error ?? 'Introduce tu altura en centímetros.'}
                type="number"
                value={height}
                error={!!error}
                onChange={(e) => {
                    if(e.target.value) {
                        setHeight(Number(e.target.value));
                    }
                    else {
                        setHeight('');
                    }
                }}
            />
            <Info
                text={"Tu información personal nos sirve para poder personalizar tu Plan nutricional de acuerdo a tus objetivos."}
            />
            <NextButton
                disabled={!height && !valid}
                onNext={() => {
                    if(height < 120 || height > 250) {
                        setError("No es una altura válida.");
                        setValid(false);
                    } else {
                        setValid(true);
                        localStorage.setItem('premium.basic.height', height)
                        onNext();
                    }
                }}
            />
        </div>
    )
}

export default Height;
