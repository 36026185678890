import {makeStyles} from "@material-ui/core/styles";

export default makeStyles((theme) => ({
	rootBodyCardOption: {
		marginTop: '0%',
		marginBottom: '4%',
		minHeight: 180,
		[theme.breakpoints.up('sm')]: {
			minHeight: 170,
			marginTop: '2%',
		},
		[theme.breakpoints.up('md')]: {
			minHeight: 100,
			marginTop: '2%',
		}
	},
	rootBodyCardOptionContainer: {
		height: '100%'
	},
	rootBodyCardOptionImage: {
		objectFit: 'contain',
		width: '100%',
		paddingTop: '10%',
		paddingBottom: '10%',
		paddingRight: '20%',
		paddingLeft: '20%',
	},
	rootBodyCardOptionTitle: {
		textAlign: 'center',
		fontWeight: 'bold',
		fontSize: 15,
	},
	rootBodyCardOptionTitleSelected: {
		textAlign: 'center',
		fontWeight: 'bold',
		color: 'white',
		fontSize: 15,
	},
	rootBodyCardOptionSubtitle: {
		marginTop: '5%',
		textAlign: 'center',
		fontWeight: 'bold',
		paddingBottom: '10%',
		fontSize: 13,
		color: '#828282',
		whiteSpace: "pre-wrap"
	},
	rootBodyCardOptionSubtitleSelected: {
		marginTop: '5%',
		textAlign: 'center',
		fontWeight: 'bold',
		paddingBottom: '10%',
		color: 'white',
		fontSize: 13,
		whiteSpace: "pre-wrap"
	}
}), { index: 1 });
