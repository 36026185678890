import {makeStyles, fade} from "@material-ui/core/styles";

export default makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		width: '100%',
		backgroundColor: theme.palette.background.paper,
	},
	image: {
		width: 190,
	},
	appBar: {
		position: 'fixed',
		[theme.breakpoints.up('md')]: {
			paddingRight: '14%',
			paddingLeft: '14%'
		},
	},
}), { index: 1 });
