import React, {useEffect, useState} from 'react';

import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions'

import style from './style';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Header from "../../common/Header";
import Footer from "../../common/Footer";
import {useHistory} from "react-router";
import ds from "../../controller/DataService";

export default function Profile() {
	const classes = style();
	const history = useHistory();

	const [auth, setAuth] = useState(undefined);

	useEffect(() => {
		if (!ds.retrieveAuthData()) {
			let paramMrt = new URLSearchParams(window.location.search).get('mrt');
			history.push(`/login/${paramMrt ? `?mrt=${paramMrt}&` : `?`}redirect=${`/profile`}`)
		} else {
			ds.subscribeToAuthChanges('Profile', setAuth);
			return () => {
				ds.unsubscribeFromAuthChanges('Profile');
			}
		}
	});

	return (
		<div>
			<Container
				className={classes.root}
				maxWidth="xl"
			>
				<Header/>
				<Grid
					className={classes.rootBody}
					container
					md={12}
					spacing={2}
				>
				</Grid>
			</Container>
			<Footer/>
		</div>
	);
}
